<template>
  <el-card>
    <el-row>
     <el-col :span="4">
         <el-cascader
        v-model="cascaderValue"
        :options="optionsList"
        :props="{checkStrictly: true}"
        @change="changeCascader"
        placeholder="请选择省市区"
        ></el-cascader>
        </el-col>
        <el-col :span="4">
          <el-select @change="changeSelect" v-model="stationType" placeholder="请选择换电站类型">
            <el-option
              v-for="item in stationTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <el-select v-model="stationValue" placeholder="请选择换电站">
            <el-option
              v-for="item in stationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>

      <el-col :span="10">
        <el-button @click="getCellAll" type="primary">查询</el-button>
        <el-button @click="resFuntion">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
<!--      <el-button size="small" @click="cellInfo =true" class="el-icon-plus" type="primary"> 新增</el-button>-->
<!--      <el-button size="small"  @click="dialogImport = true" icon="el-icon-upload2" type="primary" >批量导入</el-button>-->
      <el-button size="small"  icon="el-icon-download" @click="excelAll" type="primary">批量导出</el-button>
<!--      <el-button size="small" @click="removeAll" type="danger" icon="el-icon-delete">选中删除</el-button>-->
    </el-row>
    <el-row>
      <el-alert show-icon type="info" :closable="false">
        <span slot="title">
          已选择{{batchNum.length}}项
           <span @click="cleanList" style="margin-left:10px;color:#409eff;cursor:pointer;">清空</span>
        </span>
      </el-alert>
    </el-row>
    <el-row>
      <el-table
        id="cellInfo"
        ref="cellInfoTable"
        @selection-change="batchList"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column width="50" type="index"  :index="renIndex" label="编号"></el-table-column>
        <el-table-column prop="btyModeCode" label="电池型号编号"></el-table-column>
        <el-table-column prop="btyModeName" label="电池型号名称"></el-table-column>
         <el-table-column prop="stationNo" label="换电站编码"></el-table-column>
        <el-table-column prop="status" label="通用状态">
          <template slot-scope="scope">
            <div class="status1" v-if="scope.row.status === 1">
              <span>启用</span>
            </div>
            <div class="status2" v-else>
              <span>禁用</span>
            </div>
          </template>
        </el-table-column>
         <el-table-column prop="creator" label="创建者"></el-table-column>
<!--        <el-table-column label="操作" >-->
<!--          <template slot-scope="scope">-->
<!--            <el-button-->
<!--            @click="compileInfo(scope.row)"-->
<!--            size="small"-->
<!--            type="primary"-->
<!--            icon="el-icon-edit"-->
<!--          >编辑</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
    </el-row>
     <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[10,20, 25, 30]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-row>
    <el-dialog
      :title="iscellInfo?'新增电池型号信息':'修改电池型号信息'"
      :visible.sync="cellInfo"
      width="30%"
      @close="close"
    >
      <el-form size="small" ref="Infoform" :rules="infoCell" :model="cellFrom" label-width="110px">
        <el-row>
          <el-col style="margin:0;" :span="22">
            <el-form-item label="换电站编码:" prop="stationNo">
                <el-select v-model="cellFrom.stationNo" placeholder="请选择换电站编码">
                  <el-option
                    v-for="item in stationList1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="电池型号编号:" prop="btyModeCode">
              <el-input v-model="cellFrom.btyModeCode"></el-input>
            </el-form-item>
            <el-form-item label="电池型号名称:" prop="btyModeName">
              <el-input v-model="cellFrom.btyModeName"></el-input>
            </el-form-item>
            <el-form-item label="通用状态:" prop="status">
               <el-select v-model="cellFrom.status" placeholder="请选择电池状态">
                  <el-option
                    v-for="item in stateList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="创建者:" >
              <span>{{cellFrom.creator}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cellInfo = false">取 消</el-button>
        <el-button type="primary" @click="newaddorset">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 批量导入 -->
    <el-dialog title="请选择导入文件" :visible.sync="dialogImport" width="30%" @close="closeLoad">
      <el-upload
        class="upload-demo"
        ref="upload"
        action
        :on-change="handleChange"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :before-upload="beforeUpload"
        :file-list="fileList"
        :limit="1"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        :auto-upload="false"
      >
        <el-button size="small" type="primary">请选择 xlsx / xls 文件</el-button>
      </el-upload>
      <span slot="footer" class="dowload dialog-footer">
         <div>
          <el-button @click="dowtemplate">下载模版</el-button>
          <span>请不要擅自修改或删除模版</span>
        </div>
        <div>
          <el-button @click="dialogImport = false">取消</el-button>
        <el-button type="primary" :disabled="uploadDisabled" style="margin-top: 16px" @click="handleUpload">开始上传</el-button>
        </div>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
getCellInfoAll,
getCellInfoPage,
addCellInfo,
setCellInfo,
deleteCellInfo,
getForceList,
regionCodeSation,
InfoleadFile
 } from '@/utils/station'
 import { regionData } from "element-china-area-data"
import * as myStore from "@/utils/stroage";
export default {
  name: "infoName",
  data() {
    return {
      query: {
        pageNum: 1,
        pageSize: 10,
        resPageNum:1
      },
       cascaderValue:[],
      stationType:'',
      optionsList: regionData,
         stationTypeList: [
        {
          value: '0',
          label: "A类型",
        },
        {
          value: '1',
          label: "B类型",
        },
      ],
      stateList:[
        {
          value:1,
          label:'启用'
        },
        {
          value:0,
          label:'禁用'
        }
      ],
      stationList:[],
      stationList1:[],
      stationValue:'',
      cellInfo: false,
      iscellInfo: true,
      isPage:true,
      batchNum: [],
      tableData: [],
      cellListAll:[],
      cellFrom: {
        stationNo:'',
        btyModeCode:'',
        btyModeName:'',
        creator:'',
        status:null
      },
      infoCell:{
        stationNo:[
          { required: true, message: '请选择换电站编码', trigger: 'change' }
        ],
          btyModeCode:[
             { required: true, message: '请输入电池型号编号', trigger: 'blur' }
          ],
          btyModeName:[
             { required: true, message: '请输入电池型号名称', trigger: 'blur' }
          ],
          status:[
             { required: true, message: '通用状态', trigger: 'change' }
          ]
      },
      total:0,
      options:[],
      columns:[
        {prop:'btyModeCode',label:'电池型号编号'},
        {prop:'btyModeName',label:'电池型号名称'},
        {prop:'stationNo',label:'换电站编号'},
        {prop:'status',label:'通用状态'}
      ],
      userInfo:{},
      dialogImport:false,
      fileList:[],//上传的文件列表
      uploadDisabled:true
    }
  },
  computed:{
    userName(){
      return JSON.parse(this.$store.state.user).username
    },
    cascader(){
       let reginCode = ''
      if(this.cascaderValue.length === 1){
        reginCode = this.cascaderValue[0]
      }else if(this.cascaderValue.length === 2){
        reginCode = this.cascaderValue[1]
      }else if(this.cascaderValue.length === 3){
       reginCode = this.cascaderValue[2]
      }else{
        reginCode = ''
      }
      return reginCode
    },
    downloadList(){
       if(this.batchNum.length === 0){
        return this.cellListAll.map(item=>{
            item.status = item.status === 0?'禁用':'启用'
            return item
         })
       }else{
        return  this.batchNum.map(item=>{
            item.status = item.status === 0?'禁用':'启用'
            return item
         })
       }
    }
  },
  created() {
    this.cellFrom.creator = this.userName
    this.getCellInfo()
    this.getCellListAll()
    this.getStation()
  },
  methods: {
   async getCellInfo(){
         try {
              const data={
              page:this.query.pageNum,
              size:this.query.pageSize
            }
          const res =await getCellInfoPage(data)
          if(res.status !== 200)return this.$msg.error('获取电池型号信息失败!')
          this.tableData = res.data.data.content
          this.total = res.data.data.totalElements

         } catch (error) {

         }
    },
    //获取换电站编号
   async getStation(){
       try {
         const res =await getForceList()
       if(res.status !== 200)return this.$msg.error('获取换电站编码失败!')
      if(res.data.data.length !== 0){
          this.stationList = res.data.data.map(item=>{
         return {
           value:item.stationNo,
           label:item.stationName
         }
       })
       this.stationList1 = this.stationList
      }else{
        this.stationList = []
      }

       } catch (error) {

       }

    },
    //获取所有电池型号信息
   async getCellListAll(){
       const res =await getCellInfoAll()
      this.cellListAll = res.data.data
    },
   async excelAll() {
       const confirm =await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e=>e)
        if(confirm !== 'confirm')return
        const data = JSON.parse(JSON.stringify(this.columns))
        data.creator = '创建者'
        this.$download(this.downloadList,data,'电池型号信息')

    },
       //下载模版
    dowtemplate(){
      const list =[
        {
          btyModeCode:'BZ-1C-500/100-40DCEV-118MM',
          btyModeName:'ST00000000',
          stationNo:'00000AST00000000',
          status:'启用1禁用0',
        }
      ]
      this.$download(list, this.columns, "电池型号信息")
    },
    resFuntion() {
      this.cascaderValue = ''
      this.stationType = ''
      this.stationValue = ''
      this.isPage = true
      this.query ={
        pageNum:1,
        pageSize:20,
        resPageNum:1
      }
      this.getCellInfo()
      this.getStation()
    },
     renIndex(index){
      return (this.query.pageNum -1 ) * this.query.pageSize + index +1
    },
    batchList(list) {
       this.batchNum = list
    },
    //修改电池信息填充
    compileInfo(list) {
      this.cellInfo = true
      this.iscellInfo = false
      let data = JSON.parse(JSON.stringify(list))
      this.cellFrom = data

    },
    //电池型号信息新增与修改
    newaddorset(){
         this.$refs.Infoform.validate(async valid=>{
         if(!valid)return this.$msg.info('电池型号信息不能为空!')
         try {
          let data = JSON.parse(JSON.stringify(this.cellFrom))
            if(this.iscellInfo){
                  const res =await addCellInfo(data)
                if (res.status !== 200 && res.data.message === '电池型号信息已存在') return this.$msg.warning("电池型号信息已存在，请重新添加!")
                if(res.status !== 200 && res.data.message !== '电池型号信息已存在')return this.$msg.error('新增电池型号信息失败!')
                this.$msg.success('新增电池型号信息成功!')
                  this.cellInfo = false
                  this.getCellInfo()
            }else{
                const res =await setCellInfo(data)
            if(res.status !== 200)return this.$msg.error('修改电池型号信息失败!')
            this.$msg.success('修改电池型号信息成功!')
              this.cellInfo = false
              this.getCellInfo()
            }
             } catch (e) {
         }

    })

    },
    close(){
      const data={
        creator:this.userName
        }
        this.$refs.Infoform.resetFields()
       this.cellFrom = data
       this.iscellInfo = true
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      if(this.isPage){
         this.getCellInfo();
      }else{
        this.getCellAll()
      }

    },
    handleCurrentChange(val) {
        this.query.pageNum = val
         if(this.isPage){
         this.getCellInfo();
          }else{
            this.query.resPageNum = val
            this.getCellAll()
          }
    },
   async getCellAll(){
      if(this.cascader === '' && this.stationType === '' && this.stationValue === '')return

     try {
        const data ={
        regionCode:this.cascader,
        stationNo:this.stationValue,
        stationType:this.stationType
      }
      this.storage('cellInfo',data)
      data.page = this.query.resPageNum
      data.size = this.query.pageSize
      const res =await getCellInfoPage(data)
      if(res.status !== 200)return this.$msg.error('查询失败')
        this.tableData = res.data.data.content
        this.total = res.data.data.totalElements
        this.isPage = false

     } catch (e) {
        console.log(e);
     }

    },
       cleanList(){
      this.$refs.cellInfoTable.clearSelection()
    },
  async  removeAll(){
         if(this.batchNum.length === 0)return this.$msg.info('请选择需要删除的项')
       const config = await this.$MessageBox.confirm(
        "此操作将永久删除选中项, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(e => e);
      if (config !== "confirm") return;
       try {
         const list = this.batchNum.map(item=>{
         return item.id
       })
       const res =await deleteCellInfo(list)
       if(res.status !== 200)return this.$msg.error('删除失败')
        this.tableData = this.tableData.filter((item,i)=>{
           return item.id !== list[i]
        })
       this.$refs.cellInfoTable.clearSelection()
       if(this.query.pageNum > 1 && this.tableData.length === 0){
         this.query.pageNum = this.query.pageNum -1
       }
       this.$msg.success('删除成功')
       this.getCellInfo()

       } catch (e) {
          console.log(e);
       }
    },
   async changeCascader(){
       this.stationValue = ''
       const data ={
          regionCode:this.cascader,
          stationType:this.stationType
       }
      const res =await regionCodeSation(data)
       if(res.status !== 200)return this.$msg.error('换电站查询失败!')
      if(res.data.data.length !== 0){
          this.stationList = res.data.data.map(item=>{
         return {
           value:item.stationNo,
           label:item.stationName
         }
       })
      }else{
        this.stationList = []
      }
      if(this.stationList.length !== 0){
         const newList = JSON.parse(getItem('cellInfo'))
        if(newList && newList[1].regionCode === this.cascader && newList[0].index > 3){
          this.cascaderValue = this.changeDetSelect(newList[1].regionCode,this.optionsList)
          this.stationValue =  newList[1].stationNo
          this.stationType =  newList[1].stationType
        }
      }
    },
   async changeSelect(){
        this.stationValue = ''
         const data ={
          regionCode:this.cascader,
          stationType:this.stationType
       }
      const res =await regionCodeSation(data)
       if(res.status !== 200)return this.$msg.error('换电站查询失败!')
      if(res.data.data.length !== 0){
          this.stationList = res.data.data.map(item=>{
         return {
           value:item.stationNo,
           label:item.stationName
         }
       })
      }else{
        this.stationList = []
      }
    },
    //清空上传的文件
    closeLoad(){
        this.uploadDisabled = true
         this.$refs.upload.clearFiles();
    },
    //上传文件改变
    handleChange(file,fileList){
        if(fileList.length > 0){
          this.uploadDisabled = false
          this.fileList = fileList
        }
    },
     //上传文件之前
    beforeUpload(file){
        let fileTemp = file.raw
       if(fileTemp){
         if(fileTemp.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
         fileTemp.type === "application/vnd.ms-excel"){
        }else{
          this.$msg.warning('附件格式错误，请删除后重新上传!')
        }
       }
    },
    //上传文件移除
    handleRemove(file,fileList){
      this.fileList = fileList
      if(fileList.length < 1){
          this.uploadDisabled = true
      }
    },
   //开始上传
   async handleUpload(){
        if(!this.fileList.length === 0)return this.$msg.info('请选择要上传的文件')
        let formData = new FormData()
        formData.append('file',this.fileList[0].raw)
        formData.append('creator',this.userName)
        const res =await InfoleadFile(formData)
        if(res.status !== 200) return this.$msg.error('文件上传失败')
        if(res.data && res.data.slice(0,4) === '导入失败')return this.$msg.error('文件上传失败')
        this.$msg.success('文件上传成功')
        this.dialogImport = false
        this.getCellInfo()
    },
    //删除文件之前的钩子
   async beforeRemove(file){
       return this.$MessageBox.confirm(`确定移除 ${file.name}？`)
    }

  }

};
</script>

<style lang='less' scoped>
.el-row {
  margin: 15px 0;
  .el-col {
    margin-right: 20px;
  }
}
.el-cascader,.el-select{
  width: 100%;
}
.icon {
 width: 30px;
 height: 30px;
 border-radius: 50%;
 background-color: #409eff;
 display: flex;
 align-items: center;
 justify-content: center;
 >i{
   color: #fff;
   margin: 0;
 }
}
.clean {
  color: #409eff;
  margin-left: 10px;
  cursor: pointer;
}
.status1 {
  position: relative;
  padding-left: 10px;
}
.status1::after {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #13ce66;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  border-radius: 50%;
}
.status2 {
  position: relative;
  padding-left: 10px;
}
.status2::after {
  content: "";
  width: 6px;
  height: 6px;
  background-color: red;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  border-radius: 50%;
}
.dowload{
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   >div{
     display: flex;
     align-items: center;
     &:first-child{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: flex-start;
       >span{
         font-size: 12px;
         color: #e10505;
       }
     }
     >button{
       margin-top: 0!important;
     }
   }
 }
</style>
