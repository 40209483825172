<template>
    <div class="app-container">
        <el-row class="main-flex">
            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="轮播图名称：">
                                <el-input
                                    v-model="queryParams.name"
                                    placeholder="请输入轮播图名称"
                                    clearable
                                    style="width: 240px"
                                    @keyup.enter.native="handleQuery"
                                />
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="14">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="轮播图状态：">
                                <el-select
                                    v-model="queryParams.status"
                                    placeholder="轮播图状态"
                                    clearable
                                    style="width: 240px">
                                    <el-option
                                        v-for="item in statusList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"/>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="3">
                        <div class="btn-end">
                            <span class="btn-rest">
                          <el-button plain @click="resetQuery">重置</el-button>

                            </span>
                            <span class="btn-submit">
                         <el-button type="primary" @click="handleQuery">搜索</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            轮播图列表
                        </div>
                        <div>
                            <!--                            <span class="pain-btn">
                                                            <el-button
                                                                plain
                                                                size="mini"
                                                                :disabled="single"
                                                                @click="handleUpdate"
                                                                v-hasCode="['app:banner:edit']"
                                                            >修改
                                            </el-button>
                                                        </span>-->
                            <span class="pain-btn">
                                <el-button
                                    plain
                                    size="mini"
                                    :disabled="multiple"
                                    @click="handleDelete"
                                    v-hasCode="['app:banner:delete']">删除
                </el-button>
                            </span>
                            <span class="add-btn">
                               <el-button
                                   type="primary"
                                   plain
                                   size="mini"
                                   @click="handleAdd"
                                   v-hasCode="['app:banner:add']">新增
                </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table :data="appBannerData" @selection-change="handleSelectionChange"
                                      :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo">
                                <el-table-column type="selection" width="55" align="center"/>
                                <el-table-column label="编号" align="center" prop="id"/>
                                <el-table-column label="轮播图名称" align="center" prop="name"
                                                 :show-overflow-tooltip="true"/>


                                <el-table-column align="center" prop="url" label="图片" min-width="120">
                                    <template slot-scope="scope">
                                        <div style="display: flex;justify-content: center;align-items: center;">
                                            <el-image
                                                style="width: 30px; height: 30px;border-radius: 4px;"
                                                :src="scope.row.url"
                                                fit="fit"></el-image>
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column label="状态" align="center" prop="status">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.status === 0" style="color: red;">禁用</span>
                                        <span v-else-if="scope.row.status === 1" style="color: #1890ff;">启用</span>
                                        <span v-else style="color:orange;">未知</span>
                                    </template>
                                </el-table-column>

                                <el-table-column label="优先级" align="center" prop="level"/>

                                <el-table-column label="是否长期有效" align="center" prop="status">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.longTerm === 0" style="color: orange;">否</span>
                                        <span v-else-if="scope.row.longTerm === 1" style="color: #1890ff;">是</span>
                                        <span v-else>未知</span>
                                    </template>
                                </el-table-column>

                                <el-table-column label="生效时间" align="center" prop="startTime" min-width="130">
                                </el-table-column>

                                <el-table-column label="失效时间" align="center" prop="endTime" min-width="130">
                                </el-table-column>


                                <el-table-column label="图片地址" align="center" prop="url"
                                                 :show-overflow-tooltip="true" min-width="120"/>

                                <el-table-column label="创建时间" align="center" prop="createTime" min-width="130">
                                </el-table-column>

                                <el-table-column label="备注" align="center" prop="remark"
                                                 :show-overflow-tooltip="true"/>

                                <el-table-column label="操作" align="center" min-width="150">
                                    <template slot-scope="scope">
                                        <span class="edit-style" type="text"
                                              @click="handleUpdate(scope.row)"
                                              v-hasCode="['app:banner:edit']"
                                        >修改
                                        </span>

                                        <span style="padding-left: 15px" class="edit-style"
                                              @click="statusEnable(scope.row)"
                                              v-hasCode="['app:banner:status']">
                                            {{ scope.row.status === 1 ? '禁用' : '启用' }}
                                        </span>

                                    </template>
                                </el-table-column>
                            </el-table>

                        </el-row>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                v-show="total>0"
                                :total="total"
                                :page.sync="queryParams.pageNum"
                                :limit.sync="queryParams.pageSize"
                                @pagination="getList"
                                layout="total, sizes, prev, pager, next"/>

                        </el-row>
                    </el-col>
                </div>

            </el-col>
        </el-row>


        <!-- 添加或修改参数配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body @close="cancel">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入名称" maxlength="30" show-word-limit/>
                </el-form-item>

                <el-form-item label="是否启用" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio
                            v-for="item in statusList"
                            :key="item.value"
                            :label="item.value"
                        >{{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="长期有效" prop="longTerm">
                    <el-radio-group v-model="form.longTerm">
                        <el-radio
                            v-for="item in longTermList"
                            :key="item.value"
                            :label="item.value"
                        >{{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="生效时间:" prop="startTime" v-if="form.longTerm === 0">
                    <template>
                        <div class="block">
                            <el-date-picker
                                v-model="form.startTime"
                                type="datetime"
                                style="width:100%;"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择生效时间">
                            </el-date-picker>
                        </div>
                    </template>
                </el-form-item>

                <el-form-item label="失效时间:" prop="endTime" v-if="form.longTerm === 0">
                    <template>
                        <div class="block">
                            <el-date-picker
                                v-model="form.endTime"
                                type="datetime"
                                style="width:100%;"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择失效时间">
                            </el-date-picker>
                        </div>
                    </template>
                </el-form-item>

                <el-form-item label="图片" prop="url">
                    <template>
                        <Avater @on-avater="onAvater" @on-imgchange="imgchange" :uploadImg="uploadImg"
                                :isAvater="isAvater"/>
                    </template>
                </el-form-item>

                <el-form-item label="优先级" prop="level">
                    <el-input placeholder="请输入数字,数字越小优先级越高,在APP中越靠前" v-model="form.level"
                              onkeyup="this.value=this.value.match(/\d+\d{0,2}/)"
                              onafterpaste="this.value=this.value.match(/\d+\d{0,2}/)"
                              maxlength="3"
                              show-word-limit clearable/>
                </el-form-item>

                <el-form-item label="备注:" prop="description">
                    <el-input type="textarea" :rows="3" v-model="form.remark" placeholder="请输入备注信息"
                              maxlength="100" show-word-limit></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {addAppBanner, batchDeleteAppBanner, editAppBanner, enableAppBanner, getAppBannerPage} from "@/utils/app";
import Avater from "@/components/avater";


export default {
    name: "appBanner",
    components: {
        Avater
    },
    data() {
        const validateLogo = (rule, value, callback) => {
            if (!this.uploadImg && !this.fileBanner) {
                callback(new Error('请上传图片'))
            } else {
                callback()
            }
        }
        return {
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // app首页轮播图表格数据
            appBannerData: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                name: null,
                status: null,
                longTerm: null
            },
            // 表单参数
            form: {
                pageNum: 1,
                pageSize: 10,
                name: null,
                url: '',
                status: 1,
                longTerm: 1,
                startTime: null,
                endTime: null
            },
            // 表单校验
            rules: {
                name: [
                    {required: true, message: "名称不能为空", trigger: "blur"}
                ],
                status: [
                    {required: true, message: "请选择是否启用", trigger: "blur"}
                ],
                longTerm: [
                    {required: true, message: "请选择是否长期有效", trigger: "blur"}
                ],
                level: [
                    {required: true, message: "请输入优先级", trigger: "blur"}
                ],
                url: [
                    {required: true, validator: validateLogo, trigger: 'change'}

                ],
                startTime: [
                    {required: true, message: "请选择生效时间", trigger: "blur"}
                ],
                endTime: [
                    {required: true, message: "请选择失效时间", trigger: "blur"}
                ],
            },

            statusList: [
                {value: 1, label: "启用"},
                {value: 0, label: "禁用"}
            ],

            longTermList: [
                {value: 1, label: "是"},
                {value: 0, label: "否"}
            ],

            uploadImg: null,
            fileBanner: null,
            isAvater: true,

        };
    },
    created() {
        this.getList();
    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        async getList() {
            const data = {
                name: this.queryParams.name,
                status: this.queryParams.status,
                longTerm: this.queryParams.longTerm,
                page: this.queryParams.pageNum,
                size: this.queryParams.pageSize
            }

            getAppBannerPage(data).then(response => {
                    let dataList = JSON.parse(JSON.stringify(response.data.data.list))
                    this.appBannerData = dataList;
                    this.total = response.data.data.total;
                }
            );
        },

        cancel() {
            this.form = {
                pageNum: 1,
                pageSize: 10,
                name: null,
                url: null,
                status: 1,
                longTerm: 1,
                startTime: null,
                endTime: null
            };
            this.open = false;
            this.isAvater = false;
            this.fileBanner = null;
            this.uploadImg = null
            setTimeout(() => {
                this.$refs.form.resetFields();
            }, 500)
        },

        reset() {
            this.queryParams = {
                pageNum: 1,
                pageSize: 10,
                name: null,
                status: null,
                longTerm: null
            };
            this.resetForm();
        },

        resetForm() {
            this.form = {
                pageNum: 1,
                pageSize: 10,
                name: null,
                url: null,
                status: 1,
                longTerm: 1,
                startTime: null,
                endTime: null
            };
            this.uploadImg = null;
            this.fileBanner = null;
        },

        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.reset();
            this.handleQuery();
        },

        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length != 1
            this.multiple = !selection.length
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "新增轮播图";
        },

        handleUpdate(row) {
            this.reset();
            this.form = row;
            this.uploadImg = row.url;
            this.open = true;
            this.title = "修改轮播图";
        },

        async statusEnable(row) {
            let title = "是否禁用此轮播图片";
            if (row.status === 0) {
                title = "是否启用此轮播图片";
            }
            const config = await this.$MessageBox.confirm(
                title,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const res = await enableAppBanner(row.id);
                if (res.data.code !== 200) return this.$msg.error('修改轮播图状态失败');
                this.$msg.success('修改轮播图状态成功');
                this.getList();
            } catch (error) {

            }
        },

        submitForm: function () {
            this.$refs.form.validate(async valid => {
                if (!valid) return

                if (this.fileBanner) {
                    this.form.url = this.fileBanner.url;
                }
                if (this.form.longTerm === 1) {
                    this.form.startTime = null
                    this.form.endTime = null
                }
                try {
                    if (this.form.id != undefined || this.form.id != null) {
                        const response = await editAppBanner(this.form);
                        if (response.data.code != 200) {
                            this.$msg.error('修改失败! ' + response.data.msg)
                        } else {
                            this.$msg.success('修改成功')
                            this.open = false;
                            this.isAvater = false;
                            this.fileBanner = null;
                            this.getList();
                        }
                    } else {
                        const response = await addAppBanner(this.form);
                        if (response.data.code != 200) {
                            this.$msg.error('新增失败! ' + response.data.msg)
                        } else {
                            this.$msg.success('新增成功')
                            this.open = false;
                            this.getList();
                        }
                    }
                } catch (e) {
                }
            });
        },

        async handleDelete(row) {
            const ids = row.id || this.ids;
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除选中项, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const response = await batchDeleteAppBanner(ids);
                if (response.data.code != 200) {
                    this.$msg.error('删除失败! ' + response.data.msg)
                } else {
                    this.$msg.success('删除成功')
                    this.getList();
                }
            } catch (e) {
                console.log(e);
            }
        },

        onAvater(file) {
            this.fileBanner = file
        },
        imgchange(val) {
            this.isAvater = val
        },

    }
};
</script>
<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;

    /deep/ .el-select {
        width: 260px !important;
    }
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';

.open-btn {
    .el-button {
        border: 1px solid #0D6CE4;
        color: #0D6CE4;
        background: #fff;
        padding: 8px 16px;
        line-height: 20px;
        font-size: 14px;
        width: 88px;
    }
}
</style>
