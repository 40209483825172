<template>
    <div class="app-container">
        <el-row class="main-flex">
<!--            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>
            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>-->
            <!-- 搜索区域 -->
            <el-col :span="24" class="main-content">
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="换电站：">
                                <el-select v-model="query.name" placeholder="请选择换电站" clearable>
                                    <!--                                <el-option-->
                                    <!--                                    v-for="item in billingEnumList"-->
                                    <!--                                    :key="item.k"-->
                                    <!--                                    :label="item.v"-->
                                    <!--                                    :value="item.k"-->
                                    <!--                                ></el-option>-->
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label=" 年月：">
                                <el-input v-model.trim="query.name" placeholder="请选择年月"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="日期：">
                                <el-date-picker
                                    v-model="query.dateVal"
                                    type="date"
                                    placeholder="请选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="5">
                        <div class="btn-end">
                        <span class="btn-rest export-btn">
                                <el-button @click="resFuntion" plain>
                                导出
                                </el-button>
                        </span>
                            <span class="btn-rest">
                                <el-button @click="resFuntion" plain>
                                重置
                                </el-button>
                            </span>
                            <span class="btn-submit">
                                <el-button type="primary" @click="getSearch" plain>查询
                                </el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--数据看板-->
                <div class="plant-box">
                    <div v-for="(item,index) in plantList" :key="index"
                    :style="'background-color:'+item.color " :class="{'plant-inf':true, 'left-add':isopenShow}" >
                        <div class="plant-value">{{item.value}}</div>
                        <div class="plant-title">{{item.name}}</div>
                    </div>
                </div>
                <!-- -->
                <div class="bar-box">
                    <div class="bar-title">营收情况统计</div>
                    <douBar :barId="barNumSta" :barColor="barColorFir" :yVal="yFirst"
                    style="width: 100%;height: 242px"></douBar>
                </div>
                <!--                -->
                <div class="bar-box">
                    <div class="bar-title">订单数量统计</div>
                    <bar :barId="bsiTimeSta" :barColor="barColorSec" :yVal="ySec"
                    style="width: 100%;height: 242px"></bar>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
// import operatorTreeVue from "@/components/tree/operatorTree.vue";
import douBar from "./douBar.vue";
import bar from '../powerChanges/barGroup.vue';

export default {
    name: "index",
    components: {douBar,bar},
    data() {
        return {
            isopenShow: true,
            //左侧运营商树
            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },
            query: {
                name: '',
                dateVal: ''
            },
            yFirst:'金额（元）',
            ySec:'订单数（单）',
            barNumSta: 'barNumSta',
            bsiTimeSta: 'bsiTimeSta',
            barColorFir: ['#0D6CE4','rgba(102, 187, 131, 0.95)'],
            barColorSec: '#0D6CE4',
            plantList:[
                {
                name:'订单数（单）',
                value:'5432',
                color:'#0D6CE4'
                },{
                    name:'消费金额（元）',
                    value:'5432',
                    color:'#FA9600'
                },{
                    name:'充值金额（元）',
                    value:'5432',
                    color:'#66BB83'
                }
            ]

        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        getTreeData(data) {
            this.treeData = data
        },
        isHidden(message) {
            this.isopenShow = message
        },
        resFuntion() {

        },
        getSearch() {

        }
    }
}
</script>

<style scoped lang="less">
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.main-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    height: 68px;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    //background: #FFFFFF;
    margin-top: 16px;
    //padding: 0px 0px 0px 16px;
    display: flex;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.main-flex {
    display: flex;

}

.export-btn {
    margin-right: 16px;
}

.plant-box {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}
.plant-inf{
    width: 100%;
    padding: 16px;
    border-radius: 4px;
    color: #FFFFFF;
    margin-right: 16px;
}
.plant-inf:last-child{
    margin-right: 0;
}
.plant-value {
    font-family: Arial;
    font-size: 24px;
    font-style: italic;
    font-weight: 700;
    line-height: 28px;
}
.plant-title{
    font-size: 14px;
    font-weight: 400;
}
.bar-title{
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    color: #2F3133;
}
.bar-box{
    margin-top: 16px;
    background: #FFFFFF;
    padding: 24px 16px;
}
.left-add:first-child{
    margin-left: 16px;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
