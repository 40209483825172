<template>
  <!-- 场站用电结果 -->
  <el-card>
    <el-row>
<!--      <el-col :span="4">-->
<!--        <el-cascader-->
<!--          v-model="cascaderValue"-->
<!--          :options="optionsList"-->
<!--          @change="changeCascader"-->
<!--          :props="{checkStrictly: true}"-->
<!--          placeholder="请选择省市区"-->
<!--        ></el-cascader>-->
<!--      </el-col>-->
<!--      <el-col :span="4">-->
<!--        <el-select @change="CascaderSelect"  v-model="stationType" placeholder="请选择换电站类型">-->
<!--          <el-option-->
<!--            v-for="item in stationTypeList"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value"-->
<!--          ></el-option>-->
<!--        </el-select>-->
<!--      </el-col>-->
      <el-col :span="4">
        <el-select @change="stationChange" v-model="stationValue" placeholder="请选择换电站">
          <el-option
            v-for="item in stationList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
<!--    </el-row>-->
<!--    <el-row>-->
      <el-col :span="4">
        <el-select @change="changeSelect" v-model="dateValue" placeholder="请选择日期">
          <el-option
            v-for="item in dateList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <Quarter @quarter="quarterClick" v-if="dateValue === 4"></Quarter>
        <el-date-picker
          v-else
          v-model="timeValue"
          :type="timeType"
          :format="timeMat"
          :placeholder="timeholder"
        ></el-date-picker>
      </el-col>
      <el-col :span="10">
        <el-button @click="search" type="primary">查询</el-button>
        <el-button @click="resFuntion">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <revenueCompoments
        :newPie="newPie"
        :newObj="newObj"
        :classData="classData"
        :timeState="timeState"
      ></revenueCompoments>
    </el-row>
    <el-row class="content-bottom">
      <div class="content-box-title">
        <span>{{stationName}}</span>
        <el-button type="primary" @click="excelAll" class="el-icon-download" size="small">批量导出</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="index" label="编号" width="50"></el-table-column>
        <el-table-column prop="stationNo" label="换电站编码"></el-table-column>
        <el-table-column prop="stationName" label="换电站编码"></el-table-column>
        <el-table-column :formatter="$toDecimal2" :prop="objChane.chargin" label="充电能耗值(KWh)"></el-table-column>
        <el-table-column :formatter="$toDecimal2" :prop="objChane.switching" label="换电能耗值(KWh)"></el-table-column>
        <el-table-column :formatter="$toDecimal2" :prop="objChane.other" label="其它能耗值(KWh)"></el-table-column>
        <el-table-column :formatter="$toDecimal2" :prop="objChane.total" label="总量能耗值(KWh)"></el-table-column>
        <el-table-column prop="startTime" label="开始时间"></el-table-column>
        <el-table-column prop="endTime" label="结束时间"></el-table-column>
      </el-table>
    </el-row>
  </el-card>
</template>

<script>
  import {
    searchMonStation,
    searchDayStation,
    searchStation,
    searchEleAll
  } from "@/utils/revenue";
  import { regionData } from "element-china-area-data";
  import * as myStore from "@/utils/stroage";
  import { getForceList,regionCodeSation } from "@/utils/station";
  import Quarter from "@/components/quarter/index";
  import revenueCompoments from "@/components/revenueCompoments/index";
  import moment from "moment";
  export default {
    name: "forcerResult",
    components: {
      Quarter,
      revenueCompoments,
    },
    data() {
      return {
        station: "",
        stationStr: "",
        stationName:'',
        optionsList: regionData,
        stationTypeList: [
          {
            value: '0',
            label: "A类型",
          },
          {
            value: '1',
            label: "B类型",
          },
        ],
        stationList: [],
        cascaderValue: [],
        stationType: "",
        stationValue: "",
        dateList: [
          {
            value: 5,
            label: "年",
          },
          {
            value: 4,
            label: "季度",
          },
          {
            value: 3,
            label: "月",
          },
          {
            value: 2,
            label: "周",
          },
          {
            value: 1,
            label: "日",
          },
        ],
        dateValue: 5,
        week: "一",
        timeValue: "",
        tableData: [],
        total: 0,
        timeState: [],
        newObj: {
          item: [],
          list: [],
        },
        newPie: {
          list: [],
          title: "",
        },
        arrList: ["充电", "换电", "其它", "总量"],
        classData: {
          totalName: "换电站用电结果",
          monthsName: "换电站本月用电结果",
          daysName: "换电站今日用电结果",
          unit: "元",
          total: 0,
          currentMonth: 0,
          currentday: 0,
        },
        stationListAll:[],
        objChane:{
          chargin:'monthChargingValue',
          other:'monthOtherValue',
          switching:'monthSwitchingValue',
          total:'monthTotalValue'
        }
      };
    },
    computed: {
      timeType() {
        switch (this.dateValue) {
          case 5:
            return "year";
          case 4:
            return "week";
          case 3:
            return "month";
          case 2:
            return "week";
          case 1:
            return "date";
          default:
            return "";
        }
      },
      timeMat() {
        let time = this.timeValue || new Date();
        let i = moment(time).month() + 1;
        let wekStr = this.getWeek(
          moment(time).year(),
          moment(time).month() + 1,
          moment(time).date()
        );
        switch (this.dateValue) {
          case 5:
            return moment(time).year() + "年";
          case 4:
            return moment(time).year() + "年第" + this.week + "季度";
          case 3:
            return moment(time).year() + "年" + i + "月";
          case 2:
            return wekStr;
          case 1:
            return `${moment(time).year()}年${moment(time).month() + 1}月${moment(
              time
            ).date()}日`;
          default:
            return "";
        }
      },
      timeholder() {
        switch (this.dateValue) {
          case 5:
            return "请选择年";
          case 4:
            return "week";
          case 3:
            return "请选择月份";
          case 2:
            return "请选择周";
          case 1:
            return "请选择日期";
          default:
            return "什么都不选";
        }
      },
      selectTime() {
        let time = this.timeValue || new Date();
        let startTime = null;
        let endTime = null;
        switch (this.dateValue) {
          case 5:
            startTime = moment(time)
              .startOf("year")
              .format("YYYY-MM-DD HH:mm:ss");
            endTime = moment(time).endOf("year").format("YYYY-MM-DD HH:mm:ss");
            break;
          case 4:
            startTime = moment(time)
              .startOf("quarter")
              .format("YYYY-MM-DD HH:mm:ss");
            endTime = moment(time).endOf("quarter").format("YYYY-MM-DD HH:mm:ss");
            break;
          case 3:
            startTime = moment(time)
              .startOf("month")
              .format("YYYY-MM-DD HH:mm:ss");
            endTime = moment(time).endOf("month").format("YYYY-MM-DD HH:mm:ss");
            break;
          case 2:
            startTime = moment(time)
              .startOf("week")
              .format("YYYY-MM-DD HH:mm:ss");
            endTime = moment(time).endOf("week").format("YYYY-MM-DD HH:mm:ss");
            break;
          case 1:
            startTime = moment(time)
              .startOf("date")
              .format("YYYY-MM-DD HH:mm:ss");
            endTime = moment(time).add(1,'d').startOf("date").format("YYYY-MM-DD HH:mm:ss");
            break;
          default:
            return "";
        }
        return {
          startTime,
          endTime,
        };
      },
      dataList() {
        let chargin = "";
        let other = "";
        let switching = "";
        let total = "";
        if (this.dateValue === 5 || this.dateValue === 4) {
          chargin = "monthChargingValue";
          other = "monthOtherValue";
          switching = "monthSwitchingValue";
          total = "monthTotalValue";
        } else if (
          this.dateValue === 3 ||
          this.dateValue === 2 ||
          this.dateValue === 1
        ) {
          chargin = "dayChargingValue";
          other = "dayOtherValue";
          switching = "daySwitchingValue";
          total = "dayTotalValue";
        }
        return {
          chargin,
          other,
          switching,
          total,
        };
      },
      cascader() {
        return this.cascaderValue.join(',')
      },
    },
    created() {
      this.resFuntion()
    },
    methods: {
      //获取换电站编码
      async getStation() {
        const res = await getForceList();
        let newList = res.data.data;
        this.stationList = newList.map((item) => {
          return {
            value: item.stationNo,
            label: item.stationName,
          };
        });

        this.station = this.stationList[0].value;
        this.stationValue = this.stationList[0].value;
        this.stationStr = this.stationList[0].label;
        this.stationListAll = JSON.parse(JSON.stringify(this.stationList))
        this.checkStation();
        this.getdayPage(this.station);
      },
      getRender(val){
        return val.map(item=>{
          let i = this.stationListAll.findIndex(c=>c.value === item.stationNo)
          i !== -1 ?item.stationName = this.stationListAll[i].label:item.stationNam = ''
          return item
        })
      },
      //查询初始化
      async checkStation() {
        try {
          let index = this.dateValue;
          let res = null;
          let Newlist = null;
          let newArr = null;
          let stationNo = this.stationValue || this.station
          const data = {
            regionCode:this.cascader,
            stationNo: stationNo,
            stationType:this.stationType,
            startTime: this.selectTime.startTime,
            endTime: this.selectTime.endTime,
          };
          let newData = JSON.parse(JSON.stringify(data))
          newData.timeValue = this.timeValue || new Date()
          newData.dateValue = index
          this.storage('forceResult',newData)
          if (index === 5 || index === 4) {
            res = await searchMonStation(data);
            Newlist = res.data.data;
            newArr = this.sort(Newlist.result);
            if (res.status !== 200)
              return this.$msg.error("获取换电站用电结果失败");
          } else if (index === 3 || index === 2 || index === 1) {
            res = await searchDayStation(data);
            Newlist = res.data.data;
            newArr = this.sort(Newlist.result);
            if (res.status !== 200)
              return this.$msg.error("获取换电站用电结果失败");
          }
          this.tableData = this.getRender(newArr);
          this.rendeerEcharts(JSON.parse(JSON.stringify(newArr)));
          this.redule(JSON.parse(JSON.stringify(newArr)));
        } catch (error) {
        }
      },
      //获取总数量
      async getdayPage(value) {
        const res = await searchStation(value);
        const result = await searchEleAll({regionCode:'',stationNo:value})
        if(res.status !== 200 || result.status !== 200)return this.$msg.error('获取场站总用电失败')
        let newList = res.data.data;
        this.classData.total = newList.year;
        this.classData.currentMonth = newList.month;
        // this.classData.currentday = newList.day;
        this.classData.currentday = result.data.data.totalCount;
      },
      search(){
        let stationNo = this.stationValue || this.station
        if(this.dateValue === 5 || this.dateValue === 4){
          this.objChane.chargin = 'monthChargingValue'
          this.objChane.other = 'monthOtherValue'
          this.objChane.switching = 'monthSwitchingValue'
          this.objChane.total = 'monthTotalValue'
        }else if(this.dateValue === 3 || this.dateValue === 2 || this.dateValue === 1){
          this.objChane.chargin = 'dayChargingValue'
          this.objChane.other = 'dayOtherValue'
          this.objChane.switching = 'daySwitchingValue'
          this.objChane.total = 'dayTotalValue'
        }
        this.checkStation()
        this.getdayPage(stationNo)
      },
      rendeerEcharts(Newlist) {
        let time = this.timeValue || new Date();
        let monthChargingValue = [];
        let monthOtherValue = [];
        let monthSwitchingValue = [];
        let monthTotalValue = [];
        let newList = [];
        let i = 0;
        let dayList = [];
        switch (this.dateValue) {
          case 5: //年
            dayList = this.dayLIST(12);
            dayList.forEach((item) => {
              newList.push(this.monthLIST(item));
              i = Newlist.findIndex(
                (c) => moment(c.endTime).month() + 1 === item
              );
              if (i === -1) {
                monthChargingValue.push(0);
                monthOtherValue.push(0);
                monthSwitchingValue.push(0);
                monthTotalValue.push(0);
              } else if (i !== -1) {
                monthChargingValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.chargin]
                );
                monthOtherValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.other]
                );
                monthSwitchingValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.switching]
                );
                monthTotalValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.total]
                );
              }
            });
            break;
          case 4: //季度
            dayList = this.quarterList(this.week);
            console.log(dayList);
            dayList.forEach((item) => {
              newList.push(this.monthLIST(item));
              i = Newlist.findIndex(
                (c) => moment(c.endTime).month() + 1 === item
              );
              if (i === -1) {
                monthChargingValue.push(0);
                monthOtherValue.push(0);
                monthSwitchingValue.push(0);
                monthTotalValue.push(0);
              } else if (i !== -1) {
                monthChargingValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.chargin]
                );
                monthOtherValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.other]
                );
                monthSwitchingValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.switching]
                );
                monthTotalValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.total]
                );
              }
            });
            break;
          case 3: //月
            let day = moment(time).daysInMonth();
            dayList = this.dayLIST(day);
            dayList.forEach((item) => {
              newList.push(item);
              i = Newlist.findIndex((c) => moment(c.endTime).date() === item);
              if (i === -1) {
                monthChargingValue.push(0);
                monthOtherValue.push(0);
                monthSwitchingValue.push(0);
                monthTotalValue.push(0);
              } else if (i !== -1) {
                monthChargingValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.chargin]
                );
                monthOtherValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.other]
                );
                monthSwitchingValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.switching]
                );
                monthTotalValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.total]
                );
              }
            });
            break;
          case 2: //周
            dayList = this.dayLIST(7);
            dayList.forEach((item) => {
              newList.push(this.weekList(item));
              i = Newlist.findIndex((c) => {
                return moment(c.endTime).isoWeekday() === item;
              });
              if (i === -1) {
                monthChargingValue.push(0);
                monthOtherValue.push(0);
                monthSwitchingValue.push(0);
                monthTotalValue.push(0);
              } else if (i !== -1) {
                monthChargingValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.chargin]
                );
                monthOtherValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.other]
                );
                monthSwitchingValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.switching]
                );
                monthTotalValue.push(
                  Newlist.length === 0 ? 0 : Newlist[i][this.dataList.total]
                );
              }
            });
            break;
          case 1: //日
            let name = ''
            if(Newlist.length === 0){
              monthChargingValue.push(0)
              monthOtherValue.push(0)
              monthSwitchingValue.push(0)
              monthTotalValue.push(0)
            }else{
              name = Newlist[i].endTime.split(' ')[0]
              monthChargingValue.push(Newlist[i][this.dataList.chargin])
              monthOtherValue.push(Newlist[i][this.dataList.other])
              monthSwitchingValue.push(Newlist[i][this.dataList.switching])
              monthTotalValue.push(Newlist[i][this.dataList.total])
            }
            newList.push(name)
            break;
          default:
            return false;
        }
        let list = [];
        let item = this.arrList;
        item.forEach((item1) => {
          if (item1 === item[0]) {
            list.push({
              name: item1,
              type: "bar",
              data: monthChargingValue,
              itemStyle: { color: "#1b80e1" },
            });
          } else if (item1 === item[1]) {
            list.push({
              name: item1,
              type: "bar",
              data: monthSwitchingValue,
              itemStyle: { color: "#00baad" },
            });
          } else if (item1 === item[2]) {
            list.push({
              name: item1,
              type: "bar",
              data: monthOtherValue,
              itemStyle: { color: "#9cdc7e" },
            });

          } else if (item1 === item[3]) {
            list.push({
              name: item1,
              type: "bar",
              data: monthTotalValue,
              itemStyle: { color: "#ee9e4d" },
            });
          }
        });
        this.timeState = newList;
        this.stationName = `${this.stationStr}${this.timeMat}${this.dataList.total === "monthTotalValue" ? "月" : "天"
        }营收结果统计表`
        this.newObj = {
          item,
          list,
          name: `${this.stationStr}${
            this.timeMat
          }用电结果`,
        };
      },
      //获取周
      getWeek(a, b, c) {
        /**
         * a = d = 当前日期
         * b = 6 - w = 当前周的还有几天过完(不算今天)
         * a + b 的和在除以7 就是当天是当前月份的第几周
         */
        var date = new Date(a, parseInt(b) - 1, c),
          w = date.getDay(),
          d = date.getDate();
        if (w == 0) {
          w = 7;
        }
        var config = {
          getMonth: date.getMonth() + 1,
          getYear: date.getFullYear(),
          getWeek: Math.ceil((d + 6 - w) / 7),
        };

        return `${config.getYear}年${config.getMonth}月${config.getWeek}周`;
      },
      //获取季度
      quarterClick(e) {
        this.timeValue = moment(e.startTime).format("YYYY-MM-DD HH:mm:ss");
        if (e.week === 0) {
          this.week = "一";
        } else if (e.week === 1) {
          this.week = "二";
        } else if (e.week === 2) {
          this.week = "三";
        } else if (e.week === 3) {
          this.week = "四";
        }
      },
      resFuntion() {
        this.cascaderValue = [];
        this.stationType = "";
        this.dateValue = 5;
        this.stationValue = "";
        this.timeValue = new Date()
        this.getStation()
      },
      //排序
      sort(item) {
        if (item.length === 0) return [];
        let list = JSON.parse(JSON.stringify(item));
        list.forEach((item1) => {
          item1.startTime = +moment(item1.startTime).format("x");
        });
        list.sort((a, b) => a.startTime - b.startTime);
        list.forEach((item1) => {
          item1.startTime = moment(item1.startTime).format("YYYY-MM-DD HH:mm:ss");
        });
        return list;
      },
      //转换数组
      dayLIST(list) {
        if (list.length === 0) return [];
        let newArr = [];
        if (this.dateValue !== 1) {
          for (let i = 1; i < list + 1; i++) {
            newArr.push(i);
          }
        } else {
          for (let i = 0; i < list; i++) {
            newArr.push(i);
          }
        }
        return newArr;
      },
      changeSelect() {
        this.timeValue = "";
      },
      //转换月
      monthLIST(list) {
        switch (list) {
          case 1:
            return "一月";
          case 2:
            return "二月";
          case 3:
            return "三月";
          case 4:
            return "四月";
          case 5:
            return "五月";
          case 6:
            return "六月";
          case 7:
            return "七月";
          case 8:
            return "八月";
          case 9:
            return "九月";
          case 10:
            return "十月";
          case 11:
            return "十一月";
          case 12:
            return "十二月";
          default:
            break;
        }
      },
      //转换季度
      quarterList(index) {
        if (index === "一") {
          return [1, 2, 3];
        } else if (index === "二") {
          return [4, 5, 6];
        } else if (index === "三") {
          return [7, 8, 9];
        } else if (index === "四") {
          return [10, 11, 12];
        }
      },
      //转换周
      weekList(list) {
        switch (list) {
          case 1:
            return "周一";
          case 2:
            return "周二";
          case 3:
            return "周三";
          case 4:
            return "周四";
          case 5:
            return "周五";
          case 6:
            return "周六";
          case 7:
            return "周日";
          default:
            break;
        }
      },
      async changeCascader(){
        this.stationValue = ''
        const data ={
          regionCode:this.cascader === ''?null:this.cascader,
          stationType:this.stationType === ''?null:this.stationType
        }
        const res =await regionCodeSation(data)
        if(res.status !== 200)return this.$msg.error('换电站查询失败!')

        if(res.data.data.length !== 0){
          this.stationList = res.data.data.map(item=>{
            return {
              value:item.stationNo,
              label:item.stationName
            }
          })
        }else{
          this.stationList = []
        }
        if(this.stationList.length !== 0 && this.cascader !== ''){
          const newList = JSON.parse(getItem('forceResult'))
          if(newList && newList[1].regionCode === this.cascader && newList[0].index > 3){
            this.cascaderValue = this.changeDetSelect(newList[1].regionCode,this.optionsList)
            this.stationValue =  newList[1].stationNo
            this.stationType =  newList[1].stationType
            this.timeValue =  moment(newList[1].timeValue).toString()
            this.dateValue =  newList[1].dateValue
          }
        }
      },
      async CascaderSelect(){
        this.stationValue = ''
        const data ={
          regionCode:this.cascader === ''?null:this.cascader,
          stationType:this.stationType === ''?null:this.stationType
        }
        const res =await regionCodeSation(data)
        if(res.status !== 200)return this.$msg.error('换电站查询失败!')

        if(res.data.data.length !== 0){
          this.stationList = res.data.data.map(item=>{
            return {
              value:item.stationNo,
              label:item.stationName
            }
          })
        }else{
          this.stationList = []
        }
      },
      stationChange(e){
        let value = this.stationList.filter(c=>c.value === e)
        this.stationStr = value[0].label
      },
      async excelAll(){
        let coulm = [
          {prop:'stationNo',label:'换电站编码'},
          {prop:this.dataList.chargin,label:'充电能耗值(KWh)'},
          {prop:this.dataList.switching,label:'其它能耗值(KWh)'},
          {prop:this.dataList.other,label:'换电能耗值(KWh)'},
          {prop:this.dataList.total,label:'总量能耗值(KWh)'},
          {prop:'startTime',label:'开始时间'},
          {prop:'endTime',label:'结束时间'},
        ]
        const confirm =await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e=>e)
        if(confirm !== 'confirm')return
        this.$download(this.tableData,coulm,'换电站用电结果')
      }
    },
  };
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;
    .el-col {
      margin-right: 20px;
    }
  }
  /deep/.el-cascader,
  .el-select,
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
  .content-bottom{
    margin-top: 25px;
    border-top: 1px solid #e8e8e8;
    padding-top: 10px;
  }
  .content-box-title{
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 15px;
    span{
      color: #5d5d5d;
    }
    .el-button{
      margin-left: 10px;
    }
  }
</style>
