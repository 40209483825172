<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <!--                    <el-input placeholder="请从左侧选择运营商" v-model.trim="treeData.operatorNameTree"-->
                <!--                              disabled></el-input>-->
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="角色名称：">
                                <el-input placeholder="请输入角色名称" v-model.trim="inputValue"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="18">
                        <div class="btn-end">
                            <span class="btn-rest">
                        <el-button @click="replacement" plain>重置</el-button>
                            </span>
                            <span class="btn-submit">
                           <el-button @click="getSearch" type="primary" plain>查询</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            角色列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                          已选择{{ batchNum.length }}项
                            <span @click="cleanList" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                            <el-button @click="removeRolesAll"
                                       plain
                                       v-hasCode="['system:role:delete']"> 删除
                    </el-button>
                            </span>
                            <span class="add-btn">
                                <el-button  @click="addRole" type="primary"
                                           plain
                                           v-hasCode="['system:role:add']"> 新增
                            </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                            <el-row class="table-box">
                                <el-table ref="rolesTable" @selection-change="batchList" :data="tableData"
                                           :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo">
                                    <!--        <el-table-column  type="selection" width="50" :selectable="selectable"></el-table-column>-->
                                    <el-table-column align="center" type="selection" width="50"></el-table-column>
                                    <el-table-column align="center" :index="renIndex" type="index" label="编号"
                                                     min-width="80"></el-table-column>
                                    <el-table-column align="center" prop="roleName" label="角色名称" min-width="100"></el-table-column>
                                    <el-table-column align="center" prop="operatorName" label="归属运营商" min-width="150"
                                                     :show-overflow-tooltip="true"></el-table-column>
                                    <el-table-column align="center" prop="createName" label="创建者" min-width="130"></el-table-column>
                                    <el-table-column align="center" prop="updateTime" label="创建时间" min-width="150">
                                        <template slot-scope="scope">
                                            {{ formatting(scope.row.updateTime) }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" label="操作" min-width="180">
                                        <template slot-scope="scope">
                                            <span @click="compileRole(scope.row)" class="edit-style edit-margin"
                                                       v-hasCode="['system:role:update']">编辑
                                            </span>

                                            <span @click="bindAuthority(scope.row)" class="edit-style"
                                                       v-hasCode="['system:role:bind']">权限绑定
                                            </span>
                                            <!--
                                            <el-button @click="$router.push({path:'/authority',query:{data:JSON.stringify(scope.row)}})"
                                                       size="small" type="warning" icon="el-icon-setting">权限绑定
                                            </el-button>-->


                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>
                    </el-col>

                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[20, 25, 30]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>

                </div>

            </el-col>


        </el-row>

        <!-- 添加、修改角色信息 -->
        <el-dialog
            :title="isRoles ?'创建角色':'修改角色信息'"
            :visible.sync="isAddRoles"
            @close="close"
            width="55%">
            <el-form size="middle" ref="addform" :rules="addRules" :model="rolesFrom" label-width="100px">
                <el-row>
                    <el-col :span="16">

                        <el-form-item label="归属运营商" prop="operatorId">
                            <TreeSelect v-model="rolesFrom.operatorId" :options="treeData.operatorOptions"
                                        placeholder="请选择运营商"/>
                            <!-- <TreeSelect v-model="operatorId" :options="operatorOptions" placeholder="选择上级目录" @select="change(node)"/>-->
                        </el-form-item>

                        <el-form-item label="角色名称:" prop="roleName">
                            <el-input :disabled="isShow" v-model="rolesFrom.roleName"></el-input>
                        </el-form-item>
                        <el-form-item label="备注:">
                            <el-input type="textarea" v-model="rolesFrom.remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
          <el-button @click="isAddRoles = false">取 消</el-button>
          <el-button type="primary" @click="addRolesMsg">确 定</el-button>
        </span>
        </el-dialog>

        <!-- 添加或修改角色配置对话框 -->
        <el-dialog :title="isAuthority ?'创建角色':'修改绑定权限信息'" :visible.sync="open" width="600px"
                   append-to-body>
            <el-form ref="form" :model="authorityForm" :rules="authorityRules" label-width="100px"
                     style="overflow:auto">
                <el-form-item label="归属运营商" prop="roleName">
                    <el-input v-model="authorityForm.operatorName" disabled/>
                </el-form-item>
                <el-form-item label="角色名称" prop="roleName">
                    <el-input v-model="authorityForm.roleName" disabled/>
                </el-form-item>
                <el-form-item label="菜单权限">
                    <el-checkbox v-model="menuExpand" @change="handleCheckedTreeExpand($event, 'menu')">展开/折叠
                    </el-checkbox>
                    <el-checkbox v-model="menuNodeAll" @change="handleCheckedTreeNodeAll($event, 'menu')">全选/全不选
                    </el-checkbox>
                    <el-checkbox v-model="authorityForm.menuCheckStrictly"
                                 @change="handleCheckedTreeConnect($event, 'menu')">
                        父子联动
                    </el-checkbox>
                    <el-tree
                        class="tree-border"
                        :data="menuOptions"
                        show-checkbox
                        ref="menu"
                        node-key="id"
                        :check-strictly="!authorityForm.menuCheckStrictly"
                        empty-text="加载中，请稍候"
                        :props="defaultProps"
                    ></el-tree>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    addRoles,
    putRoles,
    removeAll, getRolesPage, getAuthAll, rolesIdAuth, AuthBindin
} from '@/utils/user'
import moment from 'moment'

import operatorTreeVue from "@/components/tree/operatorTree"
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {operatorTree} from "@/utils/operatorApi";
import {handleTree2} from "@/utils/tree";

export default {
    name: "RolesName",
    components: {
        TreeSelect, operatorTreeVue
    },
    data() {
        return {
            isopenShow: true,
            id: null,
            inputValue: "",
            query: {
                pageNum: 1,
                pageSize: 20,
            },
            total: 0,
            isAddRoles: false,
            isRoles: true,
            rolesFrom: {
                operatorId: null,
                roleName: '',
                remark: ''
            },
            addRules: {
                roleName: [
                    {required: true, message: '请输入角色名称', trigger: 'blur'}
                ],
                operatorId: [
                    {required: true, message: '请选择运营商', trigger: 'blur'}
                ]
            },
            tableData: [],
            currentPage3: 1,
            batchNum: [],
            isShow: false,

            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },

            isAuthority: true,
            open: false,
            authorityForm: {},//权限表单
            menuExpand: false,
            menuNodeAll: false,
            defaultProps: {
                children: "children",
                label: "label"
            },//权限树默认填充

            // 表单校验
            authorityRules: {
                roleName: [
                    {required: true, message: "角色名称不能为空", trigger: "blur"}
                ],
                /*
                roleKey: [
                  { required: true, message: "权限字符不能为空", trigger: "blur" }
                ],*/
            },
            // 菜单列表
            menuOptions: [],


        };
    },
    computed: {
        userRoleId() {
            return JSON.parse(this.$store.state.user).roleLevel
        }
    },
    created() {
        this.getOperatorTree()
        this.getRolesList()
    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        //获取用户角色列表
        async getRolesList() {
            let page = {
                size: this.query.pageSize,
                current: this.query.pageNum
            };
            let data = {
                roleName: this.inputValue,
                operatorId: this.treeData.operatorIdTree,
                page: page
            };
            try {
                // const data = {
                //   pageNum: this.query.pageNum,
                //   pageSize: this.query.pageSize
                // }
                // var url = "page=" + this.query.pageNum + "&size=" + this.query.pageSize;
                // if (this.inputValue != "") {
                //   url += "&name=" + this.inputValue;
                // }

                // if (this.treeData.operatorNameTree != "" && this.treeData.operatorNameTree != null) {
                //   url += "&operatorId=" + this.treeData.operatorIdTree
                // }

                // data.url = url;
                const res = await getRolesPage(data)
                console.log("getRolesPage->res:", res);
                if (res == undefined || res == null) {
                    return this.$msg.error("获取角色列表为空");
                } else if (res.data.code !== 200) {
                    return this.$msg.error('获取角色列表失败')
                }

                this.tableData = res.data.data.records;
                this.total = res.data.data.total;
            } catch (error) {
            }
        },

        getSearch() {
            this.query.pageNum = 1;
            this.getRolesList();
        },

        selectable(row) {
            if (row.id === 3 || row.id === 4) {
                return false
            }
            return true
        },
        //新增角色信息
        addRolesMsg() {
            try {
                this.$refs.addform.validate(async valid => {
                    if (!valid) return this.$msg.error('角色信息不能为空')
                    // data.creator = JSON.parse(this.user).
                    if (this.isRoles) {
                        let data = JSON.parse(JSON.stringify(this.rolesFrom))
                        const res = await addRoles(data)
                        if (res.status !== 200) return this.$msg.error('创建用户角色失败!')
                        this.$msg.success('创建用户角色成功!')
                        this.isAddRoles = false
                        this.getRolesList()
                    } else {
                        //去掉多余数据
                        this.$delete(this.rolesFrom, "createTime")
                        let data = JSON.parse(JSON.stringify(this.rolesFrom))
                        const res = await putRoles(data)
                        if (res.status !== 200) return this.$msg.error('修改用户角色失败!')
                        this.$msg.success('修改用户角色成功!')
                        this.isAddRoles = false
                        this.getRolesList()
                    }

                })

            } catch (error) {
                console.log(error);
            }
        },
        close() {
            this.$refs.addform.resetFields()
            this.rolesFrom = {}
            this.isRoles = true
        },
        compileRole(data) {
            this.isAddRoles = true
            this.isRoles = false
            this.isShow = data.id === 4
            this.rolesFrom = JSON.parse(JSON.stringify(data))
        },
        //获取选中的角色
        batchList(list) {
            this.batchNum = list.filter(item => {
                return item.name !== '超级管理员'
            })
        },
        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        addRole() {
            this.isAddRoles = true
            this.rolesFrom = {
                operatorId: null,
                roleName: '',
                remark: ''
            }
        },

        //批量删除角色
        async removeRolesAll() {
            if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的角色')
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除选中角色, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            const list = this.batchNum.map(item => {
                return item.id
            })
            const res = await removeAll(list)
            if (res.status !== 200) return this.$msg.error('批量删除角色失败!')
            this.tableData = this.tableData.filter((item, i) => {
                return item.id !== this.batchNum[i]
            })
            this.$refs.rolesTable.clearSelection()
            if (this.query.pageNum > 1 && this.tableData.length === 0) {
                this.query.pageNum = this.query.pageNum - 1
            }
            this.$msg.success('批量删除角色成功!')
            this.getRolesList()
        },
        //每页几条数据
        handleSizeChange(val) {
            this.query.pageSize = val
            this.getRolesList()
        },
        //当前第几页
        handleCurrentChange(val) {
            this.query.pageNum = val
            this.getRolesList()
        },
        cleanList() {
            this.$refs.rolesTable.clearSelection()
        },
        //重置
        replacement() {
            this.inputValue = ''
            this.treeData.operatorNameTree = ""
            this.treeData.operatorIdTree = null
            this.query = {
                pageNum: 1,
                pageSize: 20
            }
            this.getRolesList()
        },
        formatting(time) {
            return moment(time).format('YYYY-MM-DD HH:mm:ss')
        },

        getTreeData(data) {
            this.treeData = data
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.treeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.status !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorOptions.push(res.data.data)
                this.treeData.operatorIdTree = res.data.data.id
                this.treeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },

        //权限绑定
        async bindAuthority(row) {
            this.reset();
            this.isAuthority = false;
            this.authorityForm.roleId = row.id;
            this.authorityForm.roleName = row.roleName;
            this.authorityForm.operatorId = row.operatorId;
            this.authorityForm.operatorName = row.operatorName;

            try {
                //查询所有权限
                let authAllRes = await getAuthAll();
                if (authAllRes.data.code !== 200) return this.$msg.error('获取权限列表失败!' + authAllRes.data.code)
                let authAllData = authAllRes.data.data
                authAllData.forEach((v) => {
                    v.label = v.menuName
                });
                const roleMenu = handleTree2(authAllData, "id")
                this.menuOptions = roleMenu;
                //查询已经绑定权限
                const authOwnedRes = await rolesIdAuth(row.id)
                if (authOwnedRes.data.code !== 200) return this.$msg.error('获取已有权限列表失败!' + authOwnedRes.data.code)
                let authOwnedData = authOwnedRes.data.data

                this.open = true;
                this.$nextTick(() => {
                    let checkedKeys = authOwnedData
                    checkedKeys.forEach((v) => {
                        this.$nextTick(() => {
                            this.$refs.menu.setChecked(v.id, true, false);
                        })
                    });
                });


            } catch (e) {
                console.log(e);
            }

        },

        // 权限表单重置
        reset() {
            if (this.$refs.menu != undefined) {
                this.$refs.menu.setCheckedKeys([]);
            }
            this.isAuthority = true;
            this.menuExpand = false;
            this.menuNodeAll = false;
            this.authorityForm = {
                operationId: null,
                operationName: null,
                roleId: undefined,
                roleName: undefined,
                menuIds: [],
                menuCheckStrictly: true,
            };
            this.resetForm("authorityForm");
        },

        // 树权限（展开/折叠）
        handleCheckedTreeExpand(value, type) {
            if (type == 'menu') {
                let treeList = this.menuOptions;
                for (let i = 0; i < treeList.length; i++) {
                    this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value;
                }
            }
        },
        // 树权限（全选/全不选）
        handleCheckedTreeNodeAll(value, type) {
            if (type == 'menu') {
                this.$refs.menu.setCheckedNodes(value ? this.menuOptions : []);
            } //
        },
        // 树权限（父子联动）
        handleCheckedTreeConnect(value, type) {
            try {
                if (type == 'menu') {
                    this.form.menuCheckStrictly = value ? true : false;
                }
            } catch (e) {

            }
        },

        resetForm(refName) {
            if (this.$refs[refName]) {
                this.$refs[refName].resetFields();
            }
        },

        /** 提交按钮 */
        async submitForm() {
            var form = {}
            form.roleId = this.authorityForm.roleId;
            form.menuInfoIdList = this.getMenuAllCheckedKeys();
            const res = await AuthBindin(form)
            if (res.status !== 200) return this.$msg.error('权限赋予失败' + res.data.msg)
            this.$msg.success('权限赋予成功')
            this.open = false
        },

        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },

        // 所有菜单节点数据
        getMenuAllCheckedKeys() {
            // 目前被选中的菜单节点
            let checkedKeys = this.$refs.menu.getCheckedKeys();
            // 半选中的菜单节点
            let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
            checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
            return checkedKeys;
        },


    },
};
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

::v-deep .fixstyle {
    padding-left: 0 !important;
}

.inner-container {
    min-width: 1300px;
    height: var(--tableHeight);;
    overflow: hidden;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}
.edit-margin{
    margin-right: 16px;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>

