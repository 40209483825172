<template>
    <div class="app-container">
        <el-row class="main-flex">
            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="字典名称：">
                                <el-input
                                    v-model="queryParams.dictName"
                                    placeholder="请输入字典名称"
                                    clearable
                                    style="width: 240px"
                                    @keyup.enter.native="handleQuery"
                                />
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="字典编码：">
                                <el-input
                                    v-model="queryParams.dictType"
                                    placeholder="请输入字典编码"
                                    clearable
                                    style="width: 240px"
                                    @keyup.enter.native="handleQuery"
                                />
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="字典状态：">
                                <el-select
                                    v-model="queryParams.status"
                                    placeholder="字典状态"
                                    clearable
                                    style="width: 240px"
                                >
                                    <el-option
                                        v-for="dict in dict.type.sys_dict_status"
                                        :key="dict.value"
                                        :label="dict.label"
                                        :value="dict.value"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="3">
                        <div class="btn-end">
                            <span class="btn-rest">
                          <el-button plain @click="resetQuery">重置</el-button>

                            </span>
                            <span class="btn-submit">
                         <el-button type="primary" @click="handleQuery">搜索</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            字典列表
                        </div>
                        <div>
                            <span class="open-btn">
                                <el-button
                                    plain
                                    size="mini"
                                    @click="handleRefreshCache"
                                >刷新缓存
                </el-button>
                            </span>
<!--                            <span class="pain-btn">
                                <el-button
                                    plain
                                    size="mini"
                                    :disabled="single"
                                    @click="handleUpdate"
                                    v-hasCode="['system:dict:edit']"
                                >修改
                </el-button>
                            </span>-->
                            <span class="pain-btn">
                                <el-button
                                    plain
                                    size="mini"
                                    :disabled="multiple"
                                    @click="handleDelete"
                                    v-hasCode="['system:dict:delete']"
                                >删除
                </el-button>
                            </span>
                            <span class="add-btn">
                               <el-button
                                   type="primary"
                                   plain
                                   size="mini"
                                   @click="handleAdd"
                                   v-hasCode="['system:dict:add']"
                               >新增
                </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table :data="typeList" @selection-change="handleSelectionChange"
                                       :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo">
                                <el-table-column type="selection" width="55" align="center"/>
                                <el-table-column label="编号" align="center" prop="id"/>
                                <el-table-column label="字典名称" align="center" prop="dictName" :show-overflow-tooltip="true"/>
                                <el-table-column label="字典编码" align="center" :show-overflow-tooltip="true">
                                    <template slot-scope="scope">
                                        <router-link :to="'/dict-data/' + scope.row.id" class="link-type">
                                            <span>{{ scope.row.dictType }}</span>
                                        </router-link>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态" align="center" prop="status">
                                    <template slot-scope="scope">
                                        <dict-tag :options="dict.type.sys_dict_status" :value="scope.row.status"/>
                                    </template>
                                </el-table-column>
                                <el-table-column label="备注" align="center" prop="remark" :show-overflow-tooltip="true"/>
                                <el-table-column label="创建时间" align="center" prop="createTime" width="180">
                                </el-table-column>
                                <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                                    <template slot-scope="scope">
                                        <el-button class="edit-style" type="text"
                                            @click="handleUpdate(scope.row)"
                                            v-hasCode="['system:dict:edit']"
                                        >修改
                                        </el-button>
                                        <el-button class="edit-style"
                                                   type="text"
                                            @click="handleDelete(scope.row)"
                                            v-hasCode="['system:dict:delete']"
                                        >删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>

                        </el-row>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                v-show="total>0"
                                :total="total"
                                :page.sync="queryParams.pageNum"
                                :limit.sync="queryParams.pageSize"
                                @pagination="getList"
                                layout="total, sizes, prev, pager, next"
                            />

                        </el-row>
                    </el-col>
                </div>

            </el-col>
        </el-row>





        <!-- 添加或修改参数配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="字典名称" prop="dictName">
                    <el-input v-model="form.dictName" placeholder="请输入字典名称"/>
                </el-form-item>
                <el-form-item label="字典编码" prop="dictType">
                    <el-input v-model="form.dictType" placeholder="请输入字典编码"/>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio
                            v-for="dict in dict.type.sys_dict_status"
                            :key="dict.value"
                            :label="dict.value"
                        >{{ dict.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="form.remark" type="textarea" placeholder="请输入内容"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    getDictTypeList, getDictType, deleteDictType, addDictType, editDictType, refreshDictTypeCache
} from "@/utils/dict";


export default {
    name: "dictType",
    dicts: ['sys_dict_status'],
    data() {
        return {
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 字典表格数据
            typeList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                dictName: null,
                dictType: null,
                status: null
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                dictName: [
                    {required: true, message: "字典名称不能为空", trigger: "blur"}
                ],
                dictType: [
                    {required: true, message: "字典编码不能为空", trigger: "blur"}
                ]
            },

            dictStatus: []

        };
    },
    created() {
        this.getList();
    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        async getList() {
            const data = {
                dictName: this.queryParams.dictName,
                dictType: this.queryParams.dictType,
                status: this.queryParams.status
            }

            const url = "page=" + this.queryParams.pageNum + "&size=" + this.queryParams.pageSize;
            data.url = url;
            getDictTypeList(data).then(response => {
                    this.typeList = response.data.data.data.list;
                    this.total = response.data.data.data.total;
                }
            );
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        reset() {
            this.queryParams = {
                pageNum: 1,
                pageSize: 10,
                dictName: null,
                dictType: null,
                status: null
            },
                this.form = {
                    id: undefined,
                    dictName: undefined,
                    dictType: undefined,
                    status: "0",
                    remark: undefined
                };
        },

        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.reset();
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加字典类型";
        },

        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length != 1
            this.multiple = !selection.length
        },

        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids
            getDictType(id).then(response => {
                this.form = response.data.data;
                this.open = true;
                this.title = "修改字典类型";
            });
        },

        submitForm: function () {
            this.$refs.form.validate(async valid => {
                if (!valid) return this.$msg.info('信息不能为空!');
                try {
                    if (this.form.id != undefined || this.form.id != null) {
                        const response = await editDictType(this.form);
                        if (response.data.code != 200) {
                            this.$msg.error('修改失败! ' + response.data.msg)
                        } else {
                            this.$msg.success('修改成功')
                            this.open = false;
                            this.getList();
                        }
                    } else {
                        const response = await addDictType(this.form);
                        if (response.data.code != 200) {
                            this.$msg.error('新增失败! ' + response.data.msg)
                        } else {
                            this.$msg.success('新增成功')
                            this.open = false;
                            this.getList();
                        }
                    }
                } catch (e) {
                }
            });
        },

        async handleDelete(row) {
            const dictIds = row.id || this.ids;
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除选中项, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const response = await deleteDictType(dictIds);
                if (response.data.code != 200) {
                    this.$msg.error('删除失败! ' + response.data.msg)
                } else {
                    this.$msg.success('删除成功')
                    this.getList();
                }
            } catch (e) {
                console.log(e);
            }
        },

        handleRefreshCache() {
            refreshDictTypeCache().then(() => {
                this.$msg.success('刷新成功')
                this.getList();
            });
        }
    }
};
</script>
<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
    /deep/.el-select{
        width: 260px !important;
    }
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
.open-btn{
    .el-button {
        border: 1px solid #0D6CE4;
        color: #0D6CE4;
        background: #fff;
        padding: 8px 16px;
        line-height: 20px;
        font-size: 14px;
        width: 88px;
    }
}
</style>
