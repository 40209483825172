<template>
  <el-card>
    <el-row class="user-content">
      <span>用户账号:</span>
      <span>{{userName}}</span>
    </el-row>
     <el-form  ref="pwdform" :model="form" :rules="rulesPWD" label-width="150px">
     <el-row>
       <el-col :span="15">
          <el-form-item label="请输入原密码：" prop="password">
        <el-input type="password" v-model.trim="form.password" placeholder="输入密码"></el-input>
          </el-form-item>
          <el-form-item label="请输入新密码：" prop="password1">
            <el-input type="password" v-model.trim="form.password1" placeholder="输入密码"></el-input>
          </el-form-item>
          <el-form-item label="请再次确认新密码：" prop="password2">
            <el-input type="password" v-model.trim="form.password2" placeholder="输入密码"></el-input>
          </el-form-item>
          <el-form-item>
         <el-button @click="sunmitpwd" type="primary">确定</el-button>
         <el-button @click="removeForm">取消</el-button>
        </el-form-item>
       </el-col>
     </el-row>
     </el-form>

  </el-card>
</template>

<script>
import { security } from '@/utils/user'
export default {
  name:"Password",
  data() {
    var pwd = (rule,value,cb)=>{
      if(value === '')return cb(new Error('密码不能为空'))
     if(this.form.password1 !== value)return cb(new Error('两次密码不一致'))
    return cb()
   }
    return {
       form:{
         password:'',
         password1:'',
         password2:''
       },
       rulesPWD:{
        password:[
            { required: true, message: '请输入原密码', trigger: 'blur' }
        ],
        password1:[
            { required: true, message: '请输入新密码', trigger: 'blur' },
            { min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur' }
        ],
        password2:[
           { required: true,validator:pwd, trigger: 'blur' }
        ]
       }
    };
  },
  computed:{
   userName(){
     return JSON.parse(this.$store.state.user).username
   },
   userId(){
     return JSON.parse(this.$store.state.user).id
   }
  },
  created() {
  },
  methods: {
    sunmitpwd(){
      this.$refs.pwdform.validate(async valid=>{
        if(!valid)return
      const data={
        id:this.userId+'',
        password:this.form.password,
        newPassword:this.form.password2
      }
          const res =await security(data)
          if(res.status !== 200 && !res.data)return this.$msg.error('重置密码失败!')
          this.$msg.success('重置密码成功!')
          this.form = {}
          this.$router.push('/login')
      })
    },
    removeForm(){
      this.$refs.pwdform.resetFields()
      this.form = {}
    }
  }
};
</script>

<style scoped lang="less">
.el-input{
  width: 35%;
}
.user-content{
      margin-bottom: 20px;
    padding-left: 44px;
   span{
      color: #606266;
   }
    >span:nth-child(2){
      padding-left: 16px;
    }
}

</style>
