<template>
  <el-card>
    <h2 style="text-align: center;">客户汽车信息</h2>
    <el-steps :active="2" finish-status="success" process-status="wait" align-center style="margin-bottom: 40px;">
      <el-step title="客户信息显示"></el-step>
      <el-step title="客户汽车信息"></el-step>
      <el-step title="客户信息审核"></el-step>
    </el-steps>

    <el-divider></el-divider>


    <el-form ref="form" :model="swapInfo" label-width="90px">
      <el-col :span="12">

        <!--        <el-image-->
        <!--          style="width: 100px; height: 100px"-->
        <!--          :src="swapInfo.fcarApc"-->
        <!--        ></el-image>-->

        <el-form-item label="汽车车牌号:">
          <!--        <el-input v-model="swapInfo.frefundId"></el-input>-->
          <span style="color: #409EFF">{{swapInfo.fcarNo}}</span>
        </el-form-item>
        <el-form-item label="车辆VIN码:">
          <span style="color: #409EFF">{{swapInfo.fcarVin}}</span>
        </el-form-item>
        <el-form-item label="卡号:">
          <span style="color: #409EFF">{{swapInfo.frfidId}}</span>
        </el-form-item>
        <el-form-item label="底盘号码:">
          <span style="color: #409EFF">{{swapInfo.fchassisNumber}}</span>
        </el-form-item>
        <el-form-item label="电动机号码:">
          <span style="color: #409EFF">{{swapInfo.fengineNumber}}</span>
        </el-form-item>

      </el-col>
      <el-col :span="12">
        <el-form-item label="车辆识别码:">
          <span style="color: #409EFF">{{swapInfo.fidentifyCode}}</span>
        </el-form-item>
        <el-form-item label="品牌:">
          <span style="color: #409EFF">{{swapInfo.fbrand}}</span>
        </el-form-item>
        <el-form-item label="车辆型号:">
          <span style="color: #409EFF">{{swapInfo.fcarModel}}</span>
        </el-form-item>
        <el-form-item label="所有人:">
          <span style="color: #409EFF">{{swapInfo.fproducer}}</span>
        </el-form-item>


        <el-form-item label="审核进度:">
          <!--0:手机号码；1:微信号码;2:网站注册号码;3:支付宝号码-->
          <span style="color: #F56C6C" v-if="swapInfo.fauditState===0">未注册</span>
          <span style="color: #F56C6C" v-if="swapInfo.fauditState===1">审核中</span>
          <span style="color: #409EFF" v-if="swapInfo.fauditState===2">审核通过</span>
          <span style="color: #F56C6C" v-if="swapInfo.fauditState===3">审核未通过</span>

        </el-form-item>
        <!--        <el-form-item label="申请时间:">-->
        <!--          <span style="color: #409EFF">{{swapInfo.ftime}}</span>-->
        <!--        </el-form-item>-->
      </el-col>
    </el-form>


    <el-button @click="previous">上一步</el-button>
    <el-button style="margin-top: 12px;" @click="next">下一步</el-button>

  </el-card>
</template>

<script>
  import {
    getClientPage,
    newAddClient,
    chanegClient,
    removeClientMsg,
    restWord
  } from "@/utils/operation";
  import {
    CarBaseByCarNo,
    BlackWhiteListByCarNo
  } from "@/utils/operation2";
  import moment from "moment";
  import * as myStore from "@/utils/stroage";

  export default {
    name: "clienName",
    data() {
      return {
        active: 0,
        swapInfo:{}
      };
    },
    computed: {
      userId(){
        return JSON.parse(this.$store.state.user).id
      }
    },
    created() {
      this.init()
  },
    methods: {
      //页面初始化方法
      async init() {
        if (this.$route.params && this.$route.params.fcarNo) {
          this.fCarNo = this.$route.params.fcarNo
          console.log(this.fCarNo )
          const result = await CarBaseByCarNo(this.fCarNo)
          console.log(result)
          this.swapInfo=result.data.data.rows
        }
        else {

        }
      },
      //上一步
      previous() {
        this.$router.push({path:'/client/'+this.swapInfo.fcarNo})
      },
      next() {
        this.$router.push({path:'/audit/'+this.swapInfo.fcarNo})
      },

    },
  };
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;
    .el-col {
      margin-right: 10px;
    }
  }
  ul {
    width: 0;
  }
  .el-select{
    width: 100%;
  }
</style>
