<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>
            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="计费模型名称：">
                                <el-input v-model.trim="query.name" placeholder="请输入计费模型名称"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="计费模型：">
                                <el-select v-model="query.type" placeholder="请选择计费模型" clearable>
                                    <el-option
                                        v-for="item in billingEnumList"
                                        :key="item.k"
                                        :label="item.v"
                                        :value="item.k"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="10">
                        <div class="btn-end">
                            <span class="btn-rest">
                                <el-button @click="resFuntion" plain>
                                重置
                            </el-button></span>
                            <span class="btn-submit">
                                <el-button type="primary" @click="getSearch" plain>查询
                            </el-button>
                            </span>
                        </div>
                    </el-col>

                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            换电计费模型列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                          已选择{{ batchNum.length }}项
                            <span @click="cleanList" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                                <el-button @click="removeAll"
                                           v-hasCode="['operation:billmodel:delete']" plain>删除
                            </el-button>
                            </span>
                            <span class="add-btn">
                                <el-button @click="addModel" type="primary"
                                           v-hasCode="['operation:billmodel:add']" plain> 新增
                            </el-button>
                            </span>
                        </div>

                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table ref="equipTable" id="equipment" @selection-change="batchList"
                                      :data="tableData" height="100%"
                                      :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo">
                                <el-table-column align="center" type="selection"></el-table-column>
                                <el-table-column align="center" label="编号" type="index" :index="renIndex"></el-table-column>
                                <el-table-column align="center" prop="operatorName" label="归属运营商" min-width="120"
                                                 :show-overflow-tooltip="true"></el-table-column>

                                <el-table-column align="center" label="计费模型名称" prop="name" min-width="150"
                                                 :show-overflow-tooltip="true">
                                    <template slot-scope="scope">
                                        <!-- <router-link :to="'/timeBilling/' + scope.row.id + '/' + scope.row.name" class="link-type">
                                            <span>{{ scope.row.name }}</span>
                                        </router-link>-->
                                        <a href="javascript:void(0);" @click="toModelDetail(scope.row)">{{ scope.row.name }}</a>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" prop="type" label="计费模型"
                                                 :formatter="billingModelFormat"
                                                 min-width="110"></el-table-column>

                                <el-table-column align="center" prop="periodsAmount" label="时段个数"
                                                 min-width="110"></el-table-column>


                                <el-table-column align="center" prop="remark" label="备注信息"
                                                 min-width="110" :show-overflow-tooltip="true"></el-table-column>

                                <el-table-column align="center" prop="createTime" label="创建时间"
                                                 min-width="110"></el-table-column>



                                <el-table-column align="center" label="操作" min-width="150" fixed="right">
                                    <template slot-scope="scope">

                                          <span class="edit-style" @click="timeSet(scope.row)" v-hasCode="['operation:billmodel:update']">
                                                时段设置
                                            </span>

                                        <span class="edit-style" @click="compileEqu(scope.row)" v-hasCode="['operation:billmodel:update']">
                                                编辑
                                            </span>

                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10, 30, 50,100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>
                </div>

                <!--                    <el-input placeholder="请从左侧选择运营商" v-model.trim="treeData.operatorNameTree"-->
                <!--                              disabled></el-input>-->
            </el-col>
            <!--                <el-col :span="5">
                                <el-button @click="getSearch" type="primary" icon="el-icon-search" plain>查询</el-button>
                                <el-button @click="resFuntion" plain icon="el-icon-refresh-right">重置</el-button>
                            </el-col>-->
        </el-row>
        <el-dialog
            :title="isAdd?'新增信息':'修改信息'"
            :visible.sync="addOrUpdate"
            width="30%"
            @close="close"
        >
            <el-form size="middle" ref="equipform" :rules="equipmentRules" :model="informationForm" label-width="100px">
                <el-row>
                    <el-col :span="22">
                        <el-form-item label="归属运营商:" prop="operatorId" label-width="120px">
                            <TreeSelect v-model="informationForm.operatorId" :options="operatorTreeData.operatorOptions"
                                        placeholder="请选择运营商"/>
                        </el-form-item>

                        <el-form-item label="计费模型:" prop="type" label-width="120px">
                            <el-select v-model="informationForm.type" placeholder="请选择计费模型">
                                <el-option
                                    v-for="item in billingEnumList"
                                    :key="item.k"
                                    :label="item.v"
                                    :value="item.k"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="计费模型名称:" prop="name" label-width="120px">
                            <el-input v-model="informationForm.name"></el-input>
                        </el-form-item>

                        <el-form-item label="备注信息:" prop="remark" label-width="120px">
                            <el-input type="textarea" :rows="3" v-model="informationForm.remark" maxlength="100" show-word-limit></el-input>
                        </el-form-item>

                    </el-col>

                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="addOrUpdate = false">取 消</el-button>
        <el-button type="primary" @click="newequipemt">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    addBillingModel,
    updateBillingModel,
    batchDeleteBillingModel,
    selectBillingModelPage
} from '@/utils/operation'
import {regionData} from "element-china-area-data";
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {operatorTree} from "@/utils/operatorApi";
import operatorTreeVue from "@/components/tree/operatorTree";

export default {
    name: 'staBillingModel',
    components: {TreeSelect, operatorTreeVue},
    data() {
        return {
            isopenShow: true,
            billingEnumList: [{k: 1, v: "SOE差值"}, {k: 2, v: "剩余SOE"}, {k: 3, v: "充电电量"}, {k: 4, v: "SOC差值"}],

            options: regionData,
            query: {
                pageNum: 1,
                pageSize: 10,
                name:"",
                type:null
            },
            total: 0,
            addOrUpdate: false,
            isAdd: true,
            batchNum: [],
            tableData: [],

            informationForm: {
                operatorId: null,
                name: "",
                type: null,
                remark: ""
            },
            equipmentRules: {
                operatorId: [
                    {required: true, message: '请选择归属运营商', trigger: 'blur'}
                ],
                name: [
                    {required: true, message: '请输入计费模型名称', trigger: 'blur'}
                ],
                type: [
                    {required: true, message: '请选择计费模型', trigger: 'blur'}
                ]
            },

            //左侧运营商树
            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },

            //新增、修改时运营商树选择
            operatorTreeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },

        }
    },
    computed: {},
    created() {
        this.getOperatorTree();
        this.getAllPage()
    },
    methods: {
        toModelDetail(e){
            this.$router.push({
                path: '/timeBilling',
                query:{
                    id:e.id,
                    name:e.name
                    // operatorName:row.name,
                    // name:'vehicle-msg',
                }
            })
        },
        headerStyleEvent({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row){
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },

        forceUpdate() {
            this.$forceUpdate();
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        //条件分页查询
        async getAllPage() {
            const data = {
                page: this.query.pageNum,
                size: this.query.pageSize,
            }

            if (this.query.name != "" && this.query.name!= null) {
                data.name = this.query.name;
            }

            if (this.query.type != "" && this.query.type != null) {
                data.type = this.query.type;
            }

            if (this.treeData.operatorIdTree != "" && this.treeData.operatorIdTree != null) {
                data.operatorId = this.treeData.operatorIdTree;
            }
            const result = await selectBillingModelPage(data)
            if (result.data.code !== 200) return this.$msg.error('获取换电计费模型信息失败!')
            this.tableData = result.data.data.list;
            this.total = result.data.data.total;
        },

        getSearch() {
            this.query.pageNum = 1
            this.getAllPage()
        },


        //重置
        resFuntion() {
            this.resetForm();
            this.query.name = "";
            this.query.type = "";
            this.getAllPage();
        },
        batchList(list) {
            this.batchNum = list
        },
        close() {
            this.resetForm();
            this.isAdd = true;
        },

        resetForm(){
            this.informationForm = {
                operatorId: null,
                name: "",
                type: null,
                remark: ""
            }
        },


        //新增与修改
        newequipemt() {
            this.$refs.equipform.validate(async valid => {
                if (!valid) return this.$msg.info('信息不能为空!')
                const data = JSON.parse(JSON.stringify(this.informationForm))
                try {
                    if (this.isAdd) {
                        const res = await addBillingModel(data)
                        if (res.data.code !== 200) return this.$msg.error('新增信息失败!' + res.data.msg);
                        this.$msg.success('新增信息成功!')
                        this.addOrUpdate = false
                        this.getAllPage()
                    } else {
                        const res = await updateBillingModel(data)
                        if (res.data.code  !== 200) return this.$msg.error('修改信息失败!' + res.data.msg);
                        this.$msg.success('修改信息成功!')
                        this.addOrUpdate = false
                        this.getAllPage()
                    }
                } catch (error) {
                }
            })
        },

        //修改信息填充
        compileEqu(row) {
            this.informationForm = row;
            this.isAdd = false;
            this.addOrUpdate = true;
        },

        async getBillingEnumList() {
            const result = await getBillingEnum();
            this.billingEnumList = result.data.data;
        },

        billingModelFormat(row) {
            var billingModelName = "未知";
            this.billingEnumList.some(item => {
                if (item.k != "" && item.k === row.type) {
                    billingModelName = item.v;
                    return true
                }
            })
            return billingModelName;
        },

        handleSizeChange(val) {
            this.query.pageSize = val;
            this.getAllPage()
        },
        handleCurrentChange(val) {
            this.query.pageNum = val;
            this.getAllPage()
        },
        cleanList() {
            this.$refs.equipTable.clearSelection()
        },

        addModel() {
            this.addOrUpdate = true;
            this.resetForm();
        },

        setTimeDivision(row) {
            this.informationForm = row;
            this.isAdd = false;
            this.addOrUpdate = true;
        },

        timeSet(e){
            //       <router-link :to="'/timeBilling/' + scope.row.id + '/' + scope.row.name" class="link-type">
            // this.$router.push('/timeBilling/' + row.id + '/' + row.name)
            this.$router.push({
                path: '/timeBilling',
                query:{
                    id:e.id,
                    name:e.name
                    // operatorName:row.name,
                    // name:'vehicle-msg',
                }
            })
        },


        //批量删除
        async removeAll() {
            if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除选中项, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const list = this.batchNum.map(item => {
                    return item.id
                })
                const res = await batchDeleteBillingModel(list)
                if (res.data.code !== 200) {
                    return this.$msg.error('删除失败! ' + res.data.msg)
                }

                this.tableData = this.tableData.filter((item, i) => {
                    return item.id !== list[i]
                })
                this.$refs.equipTable.clearSelection()
                if (this.query.pageNum > 1 && this.tableData.length === 0) {
                    this.query.pageNum = this.query.pageNum - 1
                }
                this.$msg.success('删除成功')
                this.getAllPage()
            } catch (e) {
                console.log(e);
            }
        },


        /** 查询运营商树结构 */
        async getOperatorTree() {
            try {
                this.operatorTreeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.data.code !== 200) return this.$msg.error("获取运营商信息失败");
                this.operatorTreeData.operatorOptions.push(res.data.data)
                this.operatorTreeData.operatorIdTree = res.data.data.id
                this.operatorTreeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },

        getTreeData(data) {
            this.treeData = data
        },

    }


}
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

::v-deep .fixstyle {
    padding-left: 0 !important;
}


.inner-container {
    min-width: 1000px;
    height: var(--tableHeight);
    overflow: hidden;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    height: 68px;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}
.edit-style{
    color: #0D6CE4;
    font-size: 14px;
    cursor:pointer;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>

