<template>
  <el-card>
    <el-row>
      <el-form :inline="true">
        <el-col :span="3">
          <el-input placeholder="请输入大屏设备编号" v-model=" informationVo.flargeScreenNo"></el-input>
        </el-col>
      </el-form>
      <el-col :span="3">
        <el-input placeholder="请输入大屏设备名称" v-model="informationVo.flargeScreenName"></el-input>
      </el-col>
      <el-col :span="3">
        <el-select v-model="informationVo.fstationNo" placeholder="请选择换电站">
          <el-option
            v-for="item in stationList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-button @click="getAllPage()" type="primary" icon="el-icon-search">查询</el-button>
        <el-button @click="resFuntion">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
<!--      <el-button size="small" @click="addOrUpdate =true" class="el-icon-plus" type="primary"> 新增</el-button>-->
<!--      <el-button size="small" icon="el-icon-upload2" type="primary">批量导入</el-button>-->
      <el-button size="small" icon="el-icon-download" @click="excelAll" type="primary">批量导出</el-button>
<!--      <el-button size="small" @click="removeAll" type="danger" icon="el-icon-delete">选中删除</el-button>-->
    </el-row>
    <el-row>
      <el-alert show-icon type="info">
        <span slot="title">
          已选择{{batchNum.length}}项
           <span @click="cleanList" style="margin-left:10px;color:#409eff;cursor:pointer;">清空</span>
        </span>
      </el-alert>
    </el-row>
    <el-row>
      <el-table ref="equipTable" id="equipment" @selection-change="batchList" :data="tableData" style="width: 100%">
        <el-table-column type="selection" ></el-table-column>
        <el-table-column label="编码" type="index" ></el-table-column>
        <el-table-column label="换电站编码" prop="fstationNo" ></el-table-column>
        <el-table-column label="大屏设备编号" prop="flargeScreenNo" min-width="120"></el-table-column>
        <el-table-column label="大屏设备名称" prop="flargeScreenName" min-width="120"></el-table-column>
        <el-table-column label="品牌" prop="fbrand" ></el-table-column>
        <el-table-column label="大屏设备投产状态" prop="flargeScreenState" min-width="120">
          <template slot-scope="scope">
            <span style="color: #00baad" v-if="scope.row.flargeScreenState === 1">在役</span>
            <span v-else style="color: #F56C6C">退役</span>
          </template>
        </el-table-column>
        <el-table-column label="大屏设备版本" prop="flargeScreenVer" min-width="120"></el-table-column>
        <el-table-column label="安装位置" prop="finstallationSite" ></el-table-column>
        <el-table-column label="设备型号" prop="funitType" ></el-table-column>
        <el-table-column label="出厂日期" prop="freleaseDate" min-width="120"></el-table-column>
        <el-table-column label="所属供应商" prop="fsupplierName" min-width="120"></el-table-column>
        <el-table-column label="额定电流(A)" prop="felectricity" min-width="120"></el-table-column>
        <el-table-column label="额定电压(V)" prop="fvoltage" min-width="120"></el-table-column>
        <el-table-column label="频率(Hz)" prop="ffrequency" ></el-table-column>
        <el-table-column label="额定功率(KW)" prop="fratedPwr" min-width="120"></el-table-column>
        <el-table-column label="备注(设备参数)" prop="fremark" min-width="120"></el-table-column>
<!--        <el-table-column label="操作" fixed="right" width="150">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button @click="compileEqu(scope.row)" size="small" type="primary" icon="el-icon-edit">编辑</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
    </el-row>

    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[5, 10, 15]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-row>

    <el-dialog
      :title="isequipemt?'新增大屏设备信息':'修改大屏设备信息'"
      :visible.sync="addOrUpdate"
      width="50%"
      @close="close"
    >
      <el-form size="small" ref="equipform" :rules="equipmentRules" :model="informationForm" label-width="140px">
        <el-row>
          <el-col :span="11">
            <el-form-item label="大屏设备编号:" prop="flargeScreenNo">
              <el-input v-model="informationForm.flargeScreenNo"></el-input>
            </el-form-item>
            <el-form-item label="大屏设备名称:" prop="flargeScreenName">
              <el-input v-model="informationForm.flargeScreenName"></el-input>
            </el-form-item>
            <el-form-item label="换电站编码:" prop="fstationNo">
              <el-input v-model="informationForm.fstationNo"></el-input>
            </el-form-item>
            <el-form-item label="大屏设备版本:" prop="flargeScreenVer">
              <el-input v-model="informationForm.flargeScreenVer"></el-input>
            </el-form-item>
            <el-form-item label="大屏设备投产状态:" prop="flargeScreenState">
              <el-radio-group v-model="informationForm.flargeScreenState">
                <el-radio-button label="1">在役</el-radio-button>
                <el-radio-button label="0">退役</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="安装位置:" prop="finstallationSite">
              <el-input v-model="informationForm.finstallationSite"></el-input>
            </el-form-item>
            <el-form-item label="所属供应商:" prop="fsupplierName">
              <el-input v-model="informationForm.fsupplierName"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="11">
            <el-form-item label="品牌:" prop="fbrand">
              <el-input v-model="informationForm.fbrand"></el-input>
            </el-form-item>
            <el-form-item label="设备型号:" prop="funitType">
              <el-input v-model="informationForm.funitType"></el-input>
            </el-form-item>
            <el-form-item label="出厂日期:" prop="freleaseDate">
              <el-date-picker
                v-model="informationForm.freleaseDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="额定电压(V):" prop="fvoltage">
              <el-input v-model="informationForm.fvoltage"></el-input>
            </el-form-item>
            <el-form-item label="额定电流(A):" prop="felectricity">
              <el-input v-model="informationForm.felectricity"></el-input>
            </el-form-item>
            <el-form-item label="频率(Hz):" prop="ffrequency">
              <el-input v-model="informationForm.ffrequency"></el-input>
            </el-form-item>
            <el-form-item label="额定功率(KW):" prop="fratedPwr">
              <el-input v-model="informationForm.fratedPwr"></el-input>
            </el-form-item>
            <el-form-item label="备注(设备参数):" prop="fremark">
              <el-input type="textarea" :rows="5" v-model="informationForm.fremark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addOrUpdate = false">取 消</el-button>
        <el-button type="primary" @click="newequipemt">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
  import {
    checklargeScreenPage,
    addlargeScreen,
    setlargeScreen,
    deletelargeScreenList,
  } from '@/utils/station2'
  import {getForceList} from '@/utils/station'
  import moment from 'moment'
  import {createAPI} from "../../../utils/request";
  import {mapState} from "vuex";

  export default {
    name: 'largeScreen',
    data() {
      return {
        coulm:[
          // {prop:'fid',label:'序号'},
          {prop:'fstationNo',label:'换电站编码'},
          {prop:'flargeScreenNo',label:'大屏设备编号'},
          {prop:'flargeScreenName',label:'大屏设备名称'},
          {prop:'fbrand',label:'品牌'},
          {prop:'flargeScreenState',label:'大屏设备投产状态'},
          {prop:'flargeScreenVer',label:'大屏设备版本'},
          {prop:'finstallationSite',label:'安装位置'},
          {prop:'funitType',label:'设备型号'},
          {prop:'freleaseDate',label:'出厂日期'},
          {prop:'fsupplierName',label:'所属供应商'},
          {prop:'felectricity',label:'额定电流(A)'},
          {prop:'fvoltage',label:'额定电压(V)'},
          {prop:'ffrequency',label:'频率(Hz)'},
          {prop:'fratedPwr',label:'额定功率(KW)'},
          {prop:'fremark',label:'备注(设备参数)'}
        ],
        informationVo: {

        },
        stationList: [],
        isPage: true,//查询
        query: {
          pageNum: 1,
          pageSize: 5
        },
        total: 0,
        addOrUpdate: false,
        isequipemt: true,
        batchNum: [],
        tableData: [],
        allList: [],
        stateList: [
          {
            value: 1,
            label: '在役'
          },
          {
            value: 0,
            label: '退役'
          }
        ],
        informationForm: {

        },
        equipmentRules: {
          flargeScreenNo: [
            {required: true, message: '请输入大屏设备编号', trigger: 'blur'}
          ],
          flargeScreenName: [
            {required: true, message: '请输入大屏设备名称', trigger: 'blur'}
          ],
          flargeScreenState: [
            {required: true, message: '请选择大屏设备投产状态', trigger: 'blur'}
          ],
          fstationNo: [
            {required: true, message: '请输入换电站编码', trigger: 'blur'}
          ],
        },
        brandList: [],
        unitTypeList: [],
      }
    },
    computed: {
      ...mapState(['user']),
        username() {
        return JSON.parse(this.user).name
      }

    },
    created() {
      this.informationForm.fcreator = this.username
      this.getAllPage()
      this. getStation()
    },
    methods: {

      //获取换电站编号
      async getStation() {
        const res = await getForceList();
        // console.log(res)
        this.stationList = res.data.data.map((item) => {
          return {
            value: item.stationNo,
            label: item.stationName,
          };
        });
      },
      async getAllPage() {
        const result = await checklargeScreenPage(this.query.pageNum, this.query.pageSize, this.informationVo)
        if (result.status !== 200)
          return this.$msg.error('获取换电站设备型号信息失败!')
        this.tableData = result.data.data.rows.records
        this.total = result.data.data.rows.total
         console.log(result)
      },
      //重置
      resFuntion() {
        this.informationVo = {}
        this.getAllPage()
      },
      batchList(list) {
        this.batchNum = list
      },
      close() {
        const data = {
          fcreator: this.username,
        }
        this.$refs.equipform.resetFields()
        this.informationForm = data
        this.isequipemt = true
      },

      //新增与修改
      newequipemt() {
        this.$refs.equipform.validate(async valid => {
          if (!valid) return this.$msg.info('换电站设备型号信息不能为空!')
          const data = JSON.parse(JSON.stringify(this.informationForm))
          data.releaseDate = moment(data.releaseDate).format('x')
          try {
            if (this.isequipemt) {
              const res = await addlargeScreen(data)
              if (res.status !== 200) return this.$msg.error('新增换电站设备型号信息失败!')
              this.$msg.success('新增换电站设备型号信息成功!')
              this.addOrUpdate = false
              this.getAllPage()
            } else {
              console.log(data);
              const res = await setlargeScreen(data)
              console.log(res);
              if (res.status !== 200) return this.$msg.error('修改换电站设备型号信息失败!')
              this.$msg.success('修改换电站设备型号信息成功!')
              this.addOrUpdate = false
              this.getAllPage()
            }
          } catch (error) {
          }
        })

      },
      //修改信息填充
      compileEqu(list) {
        this.addOrUpdate = true
        this.isequipemt = false
        var data = JSON.parse(JSON.stringify(list))
        this.informationForm = data
      },

      handleSizeChange(val) {
        this.query.pageSize = val
        if (this.isPage) {
          this.getequipment()
        } else {
          this.checkEqui()
        }
      },
      handleCurrentChange(val) {
        this.query.pageNum = val
        if (this.isPage) {
          this.getequipment()
        } else {
          this.checkEqui()
        }
      },
      // //获取所有电池型号信息
      // async getAllList(){
      //   const res =await getVehicleModelsAll()
      //   this.allList = res.data.data
      //   console.log(res)
      // },
      async excelAll() {
        const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e => e)
        if (confirm !== 'confirm') return
        if (this.batchNum.length === 0) {
          this.$download(this.allList, this.coulm, '大屏设备管理')
        } else {
          this.$download(this.batchNum, this.coulm, '大屏设备管理')
        }
      },
      cleanList() {
        this.$refs.equipTable.clearSelection()
      },
      //批量删除
      async removeAll() {
        if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
        const config = await this.$MessageBox.confirm(
          "此操作将永久删除选中项, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        ).catch(e => e);
        if (config !== "confirm") return;
        try {
          const list = this.batchNum.map(item => {
            return item.flargeScreenNo
          })
          const res = await deletelargeScreenList(list)
          if (res.status !== 200) return this.$msg.error('删除失败')
          this.tableData = this.tableData.filter((item, i) => {
            return item.id !== list[i]
          })
          this.$refs.equipTable.clearSelection()
          if (this.query.pageNum > 1 && this.tableData.length === 0) {
            this.query.pageNum = this.query.pageNum - 1
          }
          this.$msg.success('删除成功')
          this.getAllPage()
        } catch (e) {
          console.log(e);
        }
      }
    },
  }
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 20px;
    }
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
  }
</style>
