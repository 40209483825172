<template>
  <div class="content">
    <div class="content-title">
      <el-row class="head-section ">
        <el-col :span="6">
            <el-form :inline="true">
                <el-form-item label="换电站：">
                    <el-select @change="stationChange" v-model="stationNo" placeholder="请选择换电站">
                        <el-option v-for="item in stationList" :key="item.id" :label="item.stationName" :value="item.stationNo"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :span="7">
            <el-form :inline="true">
                <el-form-item label="日期：">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="monthValue" type="month" placeholder="选择月" clearable></el-date-picker>
                </el-form-item>
            </el-form>

        </el-col>

        <el-col :span="11">
            <div class="btn-end">
                    <span class="btn-rest">
                      <el-button @click="resFuntion" plain >重置</el-button>
                     </span>
                <span class="btn-submit">
                <el-button @click="search" type="primary" plain >查询</el-button>
                        </span>
            </div>
        </el-col>
      </el-row>
    </div>

    <el-row class="content-content">
      <div>
        <span class="content-content_pos">{{stationStr}}月用电量同比</span>
        <elecEnergyBasisPie :newPie="newPie"></elecEnergyBasisPie>
      </div>
      <div>
        <span class="content-content_pos">{{stationStr}}月每日用电量同比</span>
        <elecEnergyBasisBar :newBar="newBar"></elecEnergyBasisBar>
      </div>
    </el-row>

    <el-row class="content-bottom">
      <div class="content-box-title">
        <span class="content-content_pos">{{stationStr}}月用电量详情</span>
      </div>
        <div class="table-box mar-table">
            <el-table style="width: 100%;padding-left: 5px;" :data="list" :header-cell-style="{'text-align':'center'}"
                      :cell-style="{'text-align':'center'}"  >
                <el-table-column prop="name"  label="时间"> </el-table-column>
                <el-table-column prop="stationNo" label="场站编码"></el-table-column>
                <el-table-column prop="stationName" label="场站名称"></el-table-column>
                <el-table-column prop="value" label="总能耗值(kw/h)"></el-table-column>
                <el-table-column prop="chargeCost" label="充电能耗值(kw/h)"></el-table-column>
                <el-table-column prop="changeCost" label="换电能耗值(kw/h)"></el-table-column>
                <el-table-column prop="otherCost" label="其它能耗值(kw/h)"></el-table-column>
            </el-table>
        </div>

    </el-row>
  </div>


</template>

<script>
import '@/assets/font/font.css';
import moment from "moment";
import {getStaEnergyValOnYear} from '@/utils/statistics'
import elecEnergyBasisPie from "@/components/elec-energy-basis/elec-energy-basis-pie";
import elecEnergyBasisBar from "@/components/elec-energy-basis/elec-energy-basis-bar";
import elecEnergyBasisDayPie from "@/components/elec-energy-basis-day/elec-energy-basis-pie";
import elecEnergyBasisDayBar from "@/components/elec-energy-basis-day/elec-energy-basis-bar";
import {getForceList} from '@/utils/station'

export default {
  name: "elec-energy-basis",
  components: {
    elecEnergyBasisPie,
    elecEnergyBasisBar
  },
  data() {
    return {
      stationNo: "",
      stationName: null,
      tempValue: 3,
      monthValue: "",
      stationList: [],
      tableData: [],
      newPie: {},
      newBar:{},
      list: [],
      week: '一',
      coulm: [],
      isEcharts: true,
      stationStr:'',
      staEnergyValOnMonth:null,
      isClick: true
    };
  },
  computed: {
    selectTime() {
      let time = this.monthValue || new Date();
      let startTime = null;
      let endTime = null;
      let listName = null;
      let listNameLast = null;
      let listNameBeforeLast = null;
      switch (this.tempValue) {
        case 5:
          startTime = moment(time)
            .startOf("year")
            .format("YYYY-MM-DD HH:mm:ss");
          endTime = moment(time).endOf("year").format("YYYY-MM-DD HH:mm:ss");
          break;
        case 4:
          startTime = moment(time)
            .startOf("quarter")
            .format("YYYY-MM-DD HH:mm:ss");
          endTime = moment(time).endOf("quarter").format("YYYY-MM-DD HH:mm:ss");
          break;
        case 3:
          startTime = moment(time)
            .startOf("month")
            .format("YYYY-MM-DD HH:mm:ss");
          endTime = moment(time).endOf("month").format("YYYY-MM-DD HH:mm:ss");
          listName = moment(time).endOf("month").format("YYYY-MM")
          listNameLast = moment(time).endOf("month").add(-1, 'y').format("YYYY-MM")
          listNameBeforeLast = moment(time).endOf("month").add(-2, 'y').format("YYYY-MM")
          break;
        case 2:
          startTime = moment(time)
            .startOf("week")
            .format("YYYY-MM-DD HH:mm:ss");
          endTime = moment(time).endOf("week").format("YYYY-MM-DD HH:mm:ss");
          break;
        case 1:
          startTime = moment(time)
            .startOf("date")
            .format("YYYY-MM-DD HH:mm:ss");
          endTime = moment(time).endOf("date").format("YYYY-MM-DD HH:mm:ss");
          break;
        default:
          return "";
      }
      return {
        listName,
        startTime,
        endTime,
        listNameLast,
        listNameBeforeLast
      };
    },

    yearList(){
      let month = this.monthValue
      month = month !== ''?moment(month).format('YYYY-MM-DD'):moment().month('YYYY-MM-DD')
      return {
        time:moment(month).format('YYYY-MM'),
        lastTime:moment(month).add(-1, 'y').format("YYYY-MM"),
        beforeTime:moment(month).add(-2, 'y').format("YYYY-MM")
      }
    },

    changeCount() {
      let str = null
      if (this.tempValue === 5 || this.tempValue === 4) {
        str = 'monthSwapCount'
      } else if (this.tempValue === 3 || this.tempValue === 2 || this.tempValue === 1) {
        str = 'daySwapAmount'
      }
      return str
    }
  },

  created() {
    this.resFuntion()
    //this.resFuntion()
    //this.findThreeYears()
  },

  mounted() {
  },

  methods: {
    resFuntion() {
      this.staEnergyValOnMonth = null;
      this.stationNo = this.stationList.length>0?this.stationList[0].stationNo:"";
      this.stationName = this.stationList.length>0?this.stationList[0].stationName:"";
      this.tempValue = 3;
      this.monthValue = new Date();
      this.isClick = true;
      this.getStation()
      //this.findThreeYears()
    },

    async getStation() {
      const res = await getForceList();
      this.stationList = res.data.data;
      this.stationNo = this.stationList.length>0?this.stationList[0].stationNo:"";
      this.stationName = this.stationList.length>0?this.stationList[0].stationName:"";

      //获取场站过去三年每月同一天用电量信息
      this.getStaEnergyValByYear()

      //获取场站过去三年每月用电量信息
      //this.findThreeYears()
    },

    stationChange(e) {
      let value = this.stationList.filter(c => c.stationNo === e)
      this.stationName = value[0].stationName
      this.stationNo = value[0].stationNo
    },

    async findThreeYears() {
      var thisYearNme = `${this.selectTime.listName}`
      var lastYearNme = `${this.selectTime.listNameLast}`
      var listNameBeforeLast = `${this.selectTime.listNameBeforeLast}`
      const data = {
        fstationNo: this.stationNo,
        fstartTime: this.selectTime.startTime,
        fendTime: this.selectTime.endTime
      }
      //前三年中同一个月每天的用电情况
      const res = await getStaEnergyValByYear(data)
      if (res.data.code !== 200) return this.$msg.error('获取信息失败')
      this.xMoData = [
        '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'
      ]

      this.yDataThisYear = res.data.data.thisYearData
      this.yDataLastYear = res.data.data.lastYearData
      this.yDataBeforLast = res.data.data.beforeYearData

      let newObjDay = {
        name: `场站电量结果同比`,
        item: this.xData,
        list1: this.yDataThisYear,
        list2: this.yDataLastYear,
        list3: this.yDataBeforLast,
        title1: thisYearNme,
        title2: lastYearNme,
        title3: listNameBeforeLast,
        unit: '能耗值(KW/H)'
      }
    },

    //获取近三年没有同一天用电信息
    async getStaEnergyValByYear(){
      try {
        let month = this.monthValue
        month = month !== ''?moment(month).format('YYYY-MM-DD'):moment().month('YYYY-MM-DD')
        const data = {
          stationNo: this.stationNo,
          startTime:moment(month).format('YYYY-MM-DD HH:mm:ss')
        }
        const result = await getStaEnergyValOnYear(data)
        if (result.data.code !== 200) return this.$msg.error('获取信息失败')
        var thisYearTotalCons = (result.data.data.thisYearTotal.mt).toFixed(2)
        var lastYearTotalCons = (result.data.data.lastYearTotal.mt).toFixed(2)
        var beforeYearTotalCons = (result.data.data.beforeYearTotal.mt).toFixed(2)
        var thisYearNme = `${this.selectTime.listName}`
        var lastYearNme = `${this.selectTime.listNameLast}`
        var beforeYearName = `${this.selectTime.listNameBeforeLast}`
        this.list = [
          {value: thisYearTotalCons, name: thisYearNme,
            stationNo:this.stationNo,stationName:this.stationName,
            chargeCost:result.data.data.thisYearTotal.mc,changeCost:result.data.data.thisYearTotal.ms,otherCost:result.data.data.thisYearTotal.mv},
          {value: lastYearTotalCons, name: lastYearNme,
            stationNo:this.stationNo,stationName:this.stationName,
            chargeCost:result.data.data.lastYearTotal.mc,changeCost:result.data.data.lastYearTotal.ms,otherCost:result.data.data.lastYearTotal.mv},
          {value: beforeYearTotalCons, name: beforeYearName,
            stationNo:this.stationNo,stationName:this.stationName,
            chargeCost:result.data.data.beforeYearTotal.mc,changeCost:result.data.data.beforeYearTotal.ms,otherCost:result.data.data.beforeYearTotal.mv}
        ]

        this.staEnergyValOnMonth = result.data.data;
        this.staEnergyValOnMonth.thisYearNme = thisYearNme;
        this.staEnergyValOnMonth.lastYearNme = lastYearNme;
        this.staEnergyValOnMonth.beforeYearName = beforeYearName;
        this.staEnergyValOnMonth.stationName = this.stationName;
        this.staEnergyValOnMonth.stationNo = this.stationNo;

        this.newPie = {
          list: this.list,
          name: `场站电量结果同比`
        }
        var list1 = result.data.data.thisYearData;
        var list2 = result.data.data.lastYearData;
        var list3 = result.data.data.beforeYearData;

        this.stationStr = `${this.stationName}-${this.yearList.time}`
        //柱图、折线图数据格式化
        this.tranfromBar(list1,list2,list3,month)
      } catch (error) {
      }
    },

    async excelAll() {
      let coulm = [
        {prop: 'value', label: '车牌号'},
        {prop: this.changeCount, label: months},
        {prop: 'startTime', label: '开始时间'},
        {prop: 'endTime', label: '结束时间'},
      ]
      const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(e => e)
      if (confirm !== 'confirm') return
      this.$download(this.tableData, coulm, '车辆换电记录')
    },

    search() {
      this.isClick = false
      this.getStaEnergyValByYear()
      //this.findThreeYears()
    },

    //换电量同比
    tranfromBar(list1,list2,list3,month){
      let arrAll = []
      let arrLine = []
      let newArr = []
      let lastArr = []
      let beforeArr = []
      let monthList = []
      let dayList = this.dayLIST(moment(month).daysInMonth())
      if(list1.length !== 0){
        let obj = this.redcueList(list1,dayList)
        newArr = obj.newArr
        monthList = obj.monthList
        if(list2.length !== 0){
          let obj = this.redcueList(list2,dayList)
          lastArr = obj.newArr
        }
        if(list3.length !== 0){
          let obj = this.redcueList(list3,dayList)
          beforeArr = obj.newArr
        }
      }else{
        if(list2.length !== 0){
          let obj = this.redcueList(list2,dayList)
          lastArr = obj.newArr
          monthList = obj.monthList
          if(list3.length !== 0){
            let obj = this.redcueList(list3,dayList)
            beforeArr = obj.newArr
          }
        }else{
          if(list3.length !== 0){
            let obj = this.redcueList(list2,dayList)
            beforeArr = obj.newArr
            monthList = obj.monthList
          }else{
            dayList.forEach(item=>{
              newArr.push(0)
              lastArr.push(0)
              beforeArr.push(0)
              monthList.push(item)
            })
          }
        }
      }
      this.isEcharts? arrAll.push({name:this.yearList.time,type:'bar',data:newArr}):arrLine.push({name:this.yearList.time,type:'line',data:newArr})
      this.isEcharts? arrAll.push({name:this.yearList.lastTime,type:'bar',data:lastArr}):arrLine.push({name:this.yearList.lastTime,type:'line',data:lastArr})
      this.isEcharts? arrAll.push({name:this.yearList.beforeTime,type:'bar',data:beforeArr}):arrLine.push({name:this.yearList.beforeTime,type:'line',data:beforeArr})
      this.newBar ={
        arrAll:this.isEcharts?arrAll:arrLine,
        monthList:dayList,
        yearList:[this.yearList.time,this.yearList.lastTime,this.yearList.beforeTime]
      }
    },

    dayLIST(list) {
      let newArr = []
      if (list.length === 0) return [];
      for (let i = 1; i < list+1 ; i++) {
        newArr.push(i);
      }
      return newArr;
    },

    redcueList(val,dayList){
      let newArr = []
      let monthList = []
      dayList.forEach(item=>{
        let index = monthList.findIndex(c=>c === item)
        index === -1 && monthList.push(item)
        let i = val.findIndex(c=>moment(c.st).date() === item) //判断返回数组里面的换电日期是否等于当前月份里的日期
        if(i === -1){
          newArr.push({name:item,value:0})
        }else{
          newArr.push({name:item,value:val[i].dt}) //每天的用电量
        }
      })
      return {
        newArr,
        monthList
      }
    },

  },
};
</script>

<style lang='less' scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.el-col {
  position: relative;
}

.content {
  border: 0;
  box-shadow: none;
  background-color: #f0f2f5;

}

.content-title {
    background-color: #fff;
    margin: 0;
    padding: 16px 12px;
}

.content-content {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    div {
        position: relative;
        background-color: #fff;

        &:nth-child(1) {
            width: 28%;
            margin-right: 15px;
        }

        &:nth-child(2) {
            width: 71%;
        }

        .content-content_pos {
            display: inline-block;
            font-weight: 400;
            color: #2F3133;
            font-size: 16px;
            padding: 24px 0 0 16px;
            font-family: PingFang SC;
        }
    }
}
//.content-content-day {
//  display: flex;
//  justify-content: space-between;
//  margin: 15px 0;
//
//  div {
//    background-color: #fff;
//    width: 100%;
//    margin-right: 15px;
//    padding: 5px;
//
//  }
//}

.content-bottom {
    background-color: #fff;
    padding: 24px 16px 16px 16px;
    margin-bottom: 30px;
}

.electric-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  padding-left: 20px;
  font-weight: 700;
  border-left: 6px solid #2a82e4;
}

#changeI {
  position: absolute;
    top: 12%;
    right: 0%;
  cursor: pointer;
  color: #666;
  margin-right: 50px;
  z-index: 999;
}

#changeI:hover {
  color: #2a81e3;
}

.content-box-title {
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 16px;
    color: #2F3133;
    font-family: PingFang SC;

    span {
        color: #2F3133;
    }
}
.btn-end {
    display: flex;
    justify-content: flex-end;
}
.mar-table{
    margin-top: 16px;
}
.page-num{
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
.open-btn{
    .el-button {
        border: 1px solid #0D6CE4;
        color: #0D6CE4;
        background: #fff;
        padding: 8px 16px;
        line-height: 20px;
        font-size: 14px;
        width: 88px;
    }
}
</style>
