<template>
    <div class="app-container">
        <el-row class="main-flex">

            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorStationTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorStationTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>


            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="换电站编号：">
                                <el-input placeholder="请输入换电站编号" v-model.trim="stationNoValue"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="17">
                        <div class="btn-end">
                            <span class="btn-rest">
                            <el-button @click="resFuntion" plain>重置</el-button>
                            </span>
                            <span class="btn-submit">
                      <el-button @click="getAllPage()" type="primary" plain>查询
                    </el-button>
                            </span>
                        </div>
                    </el-col>

                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            电池运营模型列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                          已选择{{ batchNum.length }}项
                            <span @click="cleanList" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                                <el-button @click="removeAll"
                                           plain
                                           v-hasCode="['operation:batterymodel:delete']">删除
                        </el-button>
                            </span>
                            <span class="add-btn">
                               <el-button @click="addBatteryModel"
                                          type="primary" plain
                                          v-hasCode="['operation:batterymodel:add']"> 新增
                        </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                            <el-row class="table-box">
                                <el-table ref="equipTable" id="equipment" @selection-change="batchList"
                                          :data="tableData"
                                          :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo">
                                    <el-table-column align="center" type="selection" width="46"></el-table-column>
                                    <el-table-column align="center" type="index" :index="renIndex" label="编号"></el-table-column>
                                    <el-table-column align="center" prop="stationNo" label="换电站编码"
                                                     min-width="130"></el-table-column>
                                    <el-table-column align="center" label="换电站名称" prop="stationName" min-width="140"
                                                     :show-overflow-tooltip="true"></el-table-column>
                                    <el-table-column align="center" prop="startTime" label="开始时刻"></el-table-column>
                                    <el-table-column align="center" prop="endTime" label="结束时刻"></el-table-column>
                                    <el-table-column align="center" prop="batteryTypeCode" label="电池类型"
                                                     :formatter="batteryTypeCodeFormat"></el-table-column>
                                    <el-table-column align="center" prop="batteryCount" label="最小服务电池数量"
                                                     min-width="160"></el-table-column>
                                    <!--    <el-table-column prop="creator" label="创建者"></el-table-column>     -->
                                    <!--    <el-table-column prop="createTime" label="创建时间" min-width="160"></el-table-column>-->
                                    <el-table-column align="center" label="操作" min-width="160">
                                        <template slot-scope="scope">
                                            <span @click="compileEqu(scope.row)" class="edit-style edit-margin"
                                                       v-hasCode="['operation:batterymodel:update']">编辑
                                            </span>
                                            <span @click="compileRun(scope.row)" class="edit-style"
                                                       v-hasCode="['operation:batterymodel:send']">发送
                                            </span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>
                    </el-col>

                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10, 30, 50,100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>
                </div>

            </el-col>


        </el-row>

        <el-dialog :title="isequipemt?'新增电池模型信息':'修改电池模型信息'" :visible.sync="addOrUpdate" width="40%"
                   @close="close">
            <el-form size="middle" ref="equipform" :rules="equipmentRules" :model="informationForm" label-width="100px">
                <el-row>
                    <el-col :span="24">

                        <el-form-item label="归属运营商" prop="operatorId">
                            <TreeSelect v-model="informationForm.operatorId" :options="operatorTreeData.operatorOptions"
                                        :disabled="!isequipemt"  placeholder="请选择运营商" />
                        </el-form-item>

                        <el-form-item label="换电站:" prop="stationId">
                            <el-select v-model="informationForm.stationId" placeholder="请选择换电站" :disabled="!isequipemt">
                                <el-option
                                    v-for="item in stationList"
                                    :key="item.id"
                                    :label="item.stationName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="电池类型" prop="batteryTypeCode">
                            <el-select v-model="informationForm.batteryTypeCode" placeholder="请选择电池类型">
                                <el-option
                                    v-for="item in batteryTypeList"
                                    :key="item.btyTypeCode"
                                    :label="item.btyTypeName"
                                    :value="item.btyTypeCode"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="电池数量" prop="batteryCount">
                            <el-input v-model="informationForm.batteryCount" type="number"></el-input>
                        </el-form-item>

                        <el-form-item label="开始时刻" prop="startTime">
                            <el-time-picker v-model="informationForm.startTime"
                                            format="HH:mm:ss"
                                            value-format="HH:mm:ss"
                                            placeholder="选择开始时间"/>
                        </el-form-item>

                        <el-form-item label="结束时刻" prop="endTime">
                            <el-time-picker v-model="informationForm.endTime"
                                            format="HH:mm:ss"
                                            value-format="HH:mm:ss"
                                            placeholder="选择结束时间"/>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>

            <span slot="footer" class="dialog-footer">
        <el-button @click="addOrUpdate = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>

            <!--
            <el-form size="middle" v-for="(item,i) in setOpModeSetting.zones" :key="i" label-width="90px">
              <el-row>
                <el-col :span="20">
                  <el-form-item label="归属运营商" prop="operatorId">
                    <TreeSelect v-model="informationForm.operatorId" :options="operatorTreeData.operatorOptions"
                                placeholder="请选择运营商"/>
                  </el-form-item>
                </el-col>

                <el-col :span="20">
                  <el-form-item label="换电站:" prop="stationNo">
                    <el-select v-model="item.stationNo" placeholder="请选择换电站">
                      <el-option v-for="item in stationList" :key="item.id" :label="item.stationName" :value="item.id" @change="$forceUpdate"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="20">
                  <el-form-item label="开始时刻" prop="startTime" v-if="setOpModeSetting.zones.length>=1">
                    <el-time-select
                      value-format="HH:mm:ss"
                      v-model="item.startTime"
                      :picker-options="{start: '00:00',step: '00:01',end: '23:59',minTime:setOpModeSetting.zones.startTime}"
                      placeholder="选择时间">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="20">
                  <el-form-item label="结束时刻" prop="endTime">
                    <el-time-select
                      value-format="HH:mm:ss"
                      v-model="item.endTime"
                      :picker-options="{start: '00:00',step: '00:01',end: '23:59',minTime:item.startTime}"
                      placeholder="选择时间">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="20">
                  <el-form-item label="电池类型" prop="batteryTypeCode">
                    <el-select v-model="item.batteryTypeCode" placeholder="请选择电池类型">
                      <el-option
                        v-for="item in batteryTypeList"
                        :key="item.btyTypeCode"
                        :label="item.btyTypeName"
                        :value="item.btyTypeCode"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="20">
                  <el-form-item label="电池数量" prop="batteryCount">
                    <el-input v-model="item.batteryCount"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="20">
                  <el-form-item>
                    <el-button type="primary" @click="setOpModelSettingAdd" size="mini" v-if="isequipemt">增加</el-button>
                    <el-button type="warning" @click="setOpModelSettingRemove(i)" size="mini" v-if="isequipemt">删除</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="addOrUpdate = false">取 消</el-button>
              <el-button type="primary" @click="newequipemt">确 定</el-button>
            </span>
            -->

        </el-dialog>

    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    gateControl, getStationById
} from '@/utils/station2'
import {
    getBatteryModelPage,
    deleteSetOpModel,
    setBatteryOpModelMqtt,
    addBatteryModel, updateBatteryModel
} from '@/utils/operation'
import {getForceList, getOperatorStationTree, getStationInfoList} from '@/utils/station'
import moment from 'moment'
import {mapState} from "vuex";
import {getBatteryTypeAll} from "@/utils/batteryModelManagementApi";
import operatorStationTreeVue from "@/components/tree/operatorStationTree";
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {handleTree} from "@/utils/tree";
import {operatorTree} from "@/utils/operatorApi";

export default {
    name: 'setOpModel',
    components: {TreeSelect, operatorStationTreeVue},
    data() {
        // var fstationName = (rule, value, cb) => {
        //   if (value === '') return cb(new Error('不能为空'))
        //   if (this.addFrom.fstationNo !== value) return cb(new Error('两次密码不一致'))
        //   return cb()
        // }
        return {
            isopenShow: true,
            idValue: null,
            stationNoValue: "",
            batteryTypeList: [],//电池类型数组
            newTime: '',
            startTime: '',
            endTime: '',
            informationVo: {},
            stationList: [],
            isPage: true,//查询
            query: {
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            addOrUpdate: false,
            isequipemt: true,
            batchNum: [],
            tableData: [],
            stationInfo: [],
            stationCode: [],
            allList: [],
            stationRunList: [
                {
                    value: 1,
                    label: "营业时间",
                },
                {
                    value: 2,
                    label: "歇业时间",
                },
                {
                    value: 3,
                    label: "维护时间",
                },
            ],
            stateList: [
                {
                    value: 0,
                    label: '未激活'
                },
                {
                    value: 1,
                    label: '正常'
                },
                {
                    value: 2,
                    label: '停用'
                },
                {
                    value: 3,
                    label: '暂停'
                },
                {
                    value: 4,
                    label: '其它'
                }
            ],
            stationInfoList: [],
            informationForm: {
                operatorId: null,
                stationId: null,
                batteryTypeCode: "",
                batteryCount: 0,
                startTime: "",
                endTime: ""
            },

            setOpModeSetting: {
                ////区间jlcfhl
                zones: [{
                    operatorId: null,
                    stationNo: '',
                    startTime: "",
                    endTime: "",
                    batteryTypeCode: "",
                    batteryCount: 0
                }]
            },
            equipmentRules: {
                operatorId: [
                    {required: true, message: '请选择运营商', trigger: 'blur'}
                ],
                stationId: [
                    {required: true, message: '请选择换电站', trigger: 'blur'}
                ],
                batteryTypeCode: [
                    {required: true, message: '请选择电池类型', trigger: 'blur'}
                ],
                batteryCount: [
                    {required: true, message: '请输入电池数量', trigger: 'blur'}
                ],
                startTime: [
                    {required: true, message: '请选择开始时间', trigger: 'blur'}
                ],
                endTime: [
                    {required: true, message: '请选择结束时间', trigger: 'blur'}
                ],
            },
            brandList: [],
            unitTypeList: [],

            isEdit: false,
            //左侧 运营商-场站树
            treeData: {
                operatorStationOptions: undefined,  //运营商-场站树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorStationNameTree: "",
                operatorStationIdTree: null,
                defaultExpandAll: true,
            },

            //新增、修改时运营商树选择
            operatorTreeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },

        }
    },
    computed: {},
    created() {
        this.getOperatorTree();
        this.getAllPage();
        this.getCurrentTime();
        this.getBatteryType();

    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        /**
         * 添加
         */
        setOpModelSettingAdd() {
            this.setOpModeSetting.zones.push({
                startTime: "",
                endTime: "",
                batteryCount: 0
            })
        },
        /**
         * 移除
         */
        setOpModelSettingRemove(index) {
            if (this.setOpModeSetting.zones.length <= 1) {
                this.$msg.error('至少保留一条数据');
            } else {
                this.setOpModeSetting.zones.splice(index, 1);
            }
        },

        resetForm() {
            this.isEdit = false;
            this.informationForm = {
                operatorId: null,
                stationId: null,
                batteryTypeCode: "",
                batteryCount: 0,
                startTime: "",
                endTime: ""
            }

        },

        submit() {
            this.$refs.equipform.validate(async valid => {
                if (!valid) return this.$msg.info('信息不能为空!')
                try {
                    if (this.informationForm.startTime > this.informationForm.endTime) {
                        return this.$msg.info("结束时间不能大于开始时间");
                    }

                    let data = JSON.parse(JSON.stringify(this.informationForm))
                    if (this.isequipemt) {
                        const res = await addBatteryModel(data)
                        if (res.data.code !== 200) {
                            await this.getAllPage()
                            return this.$msg.error('新增电池运营模型失败！原因是：' + res.data.msg)
                        }
                        this.$msg.success('新增电池运营模型成功!')
                        this.addOrUpdate = false
                        this.resetForm()
                        await this.getAllPage()
                    } else {
                        const res = await updateBatteryModel(data)
                        if (res.data.code !== 200) {
                            await this.getAllPage()
                            return this.$msg.error('修改电池运营模型失败！原因是：' + res.data.msg)
                        }
                        this.$msg.success('修改电池运营模型成功!')
                        this.addOrUpdate = false
                        this.resetForm()
                        await this.getAllPage()
                    }
                } catch (error) {
                }
            })
        },

        getCurrentTime() {
            //获取当前时间并打印
            var _this = this;
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            let dd = new Date().getDate();
            let hh = new Date().getHours();
            let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
            let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
            _this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
            console.log(_this.gettime)
            this.newTime = _this.gettime
        },
        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },
        //获取换电站编号
        async getStation() {
            const res = await getForceList();
            this.stationList = res.data.data.map((item) => {
                return {
                    value: item.stationNo,
                    label: item.stationName,
                };
            });
        },
        dateForma: function (row, column) {
            var date = row[column.property];
            if (date == undefined) {
                return ''
            }
            ;
            return moment(date).format("HH:mm:ss")
        },

        //条件分页查询
        async getAllPage() {
            const data = {
                page: this.query.pageNum,
                size: this.query.pageSize,
            }
            var url = "?page=" + this.query.pageNum + "&size=" + this.query.pageSize;
            var id = this.treeData.operatorStationIdTree;
            if (id != "" && id != null) {
                if (id.indexOf("operator") != -1) {
                    id = id.slice(9);
                    url += "&operatorId=" + Number(id)
                } else {
                    id = id.slice(8);
                    url += "&stationId=" + Number(id)
                }
            }
            if (this.stationNoValue != "" && this.stationNoValue != null) {
                url += "&stationNo=" + this.stationNoValue;
            }
            data.url = url;
            const result = await getBatteryModelPage(data)
            if (result.data.code !== 200) return this.$msg.error('获取场站运营模型信息失败!')

            this.tableData = result.data.data.content
            this.total = result.data.data.totalElements;
            this.idValue = null

        },

        async getBatteryType() {
            var data = {};
            data.url = "";
            const res = await getBatteryTypeAll(data);
            this.batteryTypeList = res.data.data
        },

        batteryTypeCodeFormat(row) {
            var batteryTypeName = "未知";
            this.batteryTypeList.some(item => {
                if (item.btyTypeCode != "" && item.btyTypeCode === row.batteryTypeCode) {
                    batteryTypeName = item.btyTypeName;
                    return true
                }
            })
            return batteryTypeName;
        },

        async getStationById(fstationNo) {
            const result = await getStationById(fstationNo)
            if (result.data.code !== 200) return this.$msg.error('获取信息失败!')
            this.stationInfoList = result.data.data.rows
        },
        //重置
        resFuntion() {
            this.informationVo = {}
            this.stationNoValue = ""
            this.isEdit = false
            this.treeData.operatorStationNameTree = ""
            this.treeData.operatorStationIdTree = null
            this.getAllPage()
        },
        batchList(list) {
            this.batchNum = list
        },
        close() {
            const data = {
                fcreator: this.userName,
            }
            this.setOpModeSetting.zones = [
                {
                    stationNo: '',
                    startTime: "",
                    endTime: "",
                    batteryCount: 0
                }
            ]
            this.isequipemt = true
        },
        //新增与修改
        async newequipemt() {
            let data = JSON.parse(JSON.stringify(this.setOpModeSetting))

            if (this.isequipemt) {
                for (const info of data.zones) {
                    if (!info.stationNo || !info.startTime || !info.endTime || !info.batteryCount || !info.batteryTypeCode) {
                        this.$msg.error('请填写完整时间和需要电池数量');
                        return;
                    }
                }
                for (let i = 0; i < data.zones.length; i++) {
                    const info = data.zones[i]
                    const res = await addSetOpModel(info)
                    if (res.data.code !== 200) return this.$msg.error('新增信息失败!')
                }
                this.$msg.success('新增信息成功!')
                this.addOrUpdate = false
                this.getAllPage()
            } else {
                for (let i = 0; i < data.zones.length; i++) {
                    const info = data.zones[i]
                    info.id = this.idValue

                    const res = await updateSetOpModel(info)
                    if (res.data.code !== 200) return this.$msg.error('修改信息失败!')
                }
                // if (res.data.code !== 200) return this.$msg.error('修改信息失败!')
                this.$msg.success('修改信息成功!')
                this.addOrUpdate = false
                this.getAllPage()
            }

        },
        //修改信息填充   单行
        // compileEqu(list) {
        //   this.addOrUpdate = true
        //   this.isequipemt = false
        //   var data = JSON.parse(JSON.stringify(list))
        //   this.informationForm = data
        //   this.setOpModeSetting.zones = [
        //     {
        //       stationNo: data.stationNo,
        //       startTime: data.startTime,
        //       endTime: data.endTime,
        //       batteryCount: data.batteryCount
        //     },
        //   ]
        // },
        async compileEqu(row) {
            let data = {};
            data.url = "?operatorId=" + row.operatorId;
            const res1 = await getBatteryTypeAll(data)
            const res2 = await getStationInfoList(data);
            if (res1.data.code != 200 || res2.data.code != 200) {
                return this.$msg.error('获取息失败!')
            } else {
                this.batteryTypeList = res1.data.data
                this.stationList = res2.data.data
                this.addOrUpdate = true
                this.isequipemt = false
                this.isEdit = true
                this.informationForm = row
            }
        },
        async compileRun(list) {
            const config = await this.$MessageBox.confirm(
                // "向"+list.fstationNo+"下发换电运营模型信息, 是否继续?",
                "下发电池运营模型信息, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;

            var data = JSON.parse(JSON.stringify(list))
            const info = {
                "stationCode": data.stationNo,
                "ut": moment(new Date()).format('HH:mm:ss')
            }

            const result = await setBatteryOpModelMqtt(JSON.parse(JSON.stringify(info)))
            if (result.data.code !== 200) return this.$msg.error("操作失败")
            this.addOrUpdate = false
            this.$msg.success("操作成功")
            this.close()
        }
        ,
        //时间格式化
        dateFormat(row, column, cellValue) {
            return cellValue ? fecha.format(new Date(cellValue), 'yyyy-MM-dd ') : '';
        },
        handleSizeChange(val) {
            this.query.pageSize = val
            if (this.isPage) {
                this.getAllPage()
            } else {
                this.checkEqui()
            }
        },
        handleCurrentChange(val) {
            this.query.pageNum = val
            if (this.isPage) {
                this.getAllPage()
            } else {
                this.checkEqui()
            }
        },
        async excelAll() {
            const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(e => e)
            if (confirm !== 'confirm') return
            if (this.batchNum.length === 0) {
                this.$download(this.allList, this.coulm, '换电辅助设备管理')
            } else {
                this.$download(this.batchNum, this.coulm, '换电辅助设备管理')
            }
        }
        ,
        cleanList() {
            this.$refs.equipTable.clearSelection()
        },

        addBatteryModel() {
            this.addOrUpdate = true;
            this.stationList = [];
            this.resetForm();
        },

        //批量删除
        async removeAll() {
            if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除选中项, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const list = this.batchNum.map(item => {
                    return item.id
                })
                const res = await deleteSetOpModel(list)
                if (res.status !== 200) return this.$msg.error('删除失败')
                this.tableData = this.tableData.filter((item, i) => {
                    return item.id !== list[i]
                })
                this.$refs.equipTable.clearSelection()
                if (this.query.pageNum > 1 && this.tableData.length === 0) {
                    this.query.pageNum = this.query.pageNum - 1
                }
                this.$msg.success('删除成功')
                this.getAllPage()
            } catch (e) {
                console.log(e);
            }
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.operatorTreeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.data.code !== 200) return this.$msg.error("获取运营商信息失败");
                this.operatorTreeData.operatorOptions.push(res.data.data)
                this.operatorTreeData.operatorIdTree = res.data.data.id
                this.operatorTreeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },

        getTreeData(data) {
            this.treeData = data
        },

    },

    watch: {
        'informationForm.operatorId': {
            async handler(newName, oldName) {
                //修改
                if (this.isEdit == true && newName != null && newName != undefined && (oldName == undefined || oldName == null)) {
                    return;
                }


                //新增、修改时改动过运营商
                if (newName !== null && newName != undefined) {
                    // this.informationForm.stationId = null;
                    let data = {}
                    data.url = "?operatorId=" + newName;
                    const res = await getStationInfoList(data);
                    const res2 = await getBatteryTypeAll(data)

                    if (res.data.code != 200 || res2.data.code != 200) {
                        return this.$msg.error('获取息失败!')
                    }
                    this.stationList = res.data.data;
                    this.batteryTypeList = res2.data.data;
                    return;
                }
            },
            deep: true,
            immediate: false
        },
    },


}
</script>

<style lang='less' scoped>
.el-table {
    scrollbar-width: thin; /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */

    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #dddee0; /*滚动条的背景颜色*/
    }
}

.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

.inner-container {
    width: 1300px;
    height: var(--tableHeight);
    overflow: hidden;
}

::v-deep .fixstyle {
    padding-left: 0 !important;
}

.el-select {
    width: 100%;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

.more-search {
    display: inline-block;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    cursor: pointer;
    position: relative;
    left: -15px;
}
.edit-margin {
    margin-right: 16px;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
.el-time-spinner__item{
    height: 30px;
    line-height: 30px;
}
.el-time-spinner__list::after, .el-time-spinner__list::before{
    height: 73px;
}
</style>
