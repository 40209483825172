<template>
<div :id="barId"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
    name: "barGroup",
    props: {
        barId: {
            type: String,
            required: true,
            value: () => {
            }
        },
        barColor: {
            type: String,
            required: true,
            value: () => {
            }
        },
        yVal: {
            type: String,
            required: true,
            value: () => {
            }
        },
        // newPie: {
        //     type: Array,
        //     required: true,
        //     value: () => {
        //     }
        // },
    },
    data(){
        return{
            barChart: null
        }
    },
    mounted() {
        this.renderInit()
    },
    created() {
    },
    methods:{
        renderInit(){
            if (this.barChart != null && this.barChart != "" && this.barChart != undefined) {
                this.barChart.dispose();//销毁
            }
            this.barChart = echarts.init(document.getElementById(this.barId));
            var option = {
                grid: {
                    top:'20%',
                    left: '1%',
                    right: '1%',
                    bottom: '0%',
                    containLabel: true
                },
                tooltip : {
                    show: true,
                    trigger: 'axis',
                },
                xAxis:
                    [
                        {
                            type: 'category',
                            data:['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisTick:{
                                show:false
                            },
                            axisLine:{
                                lineStyle:{
                                    color:'#e0e6f1'
                                }
                            },
                            axisLabel: {
                                color: '#585858' // 设置y轴字体颜色
                            },
                        }
                    ],
                yAxis: [
                    {
                        type: 'value',
                        name: this.yVal,
                        axisLabel: {
                            color: '#585858', // 设置y轴字体颜色
                        },
                        nameTextStyle:{
                            color:'#2F3133',
                            padding:[0,0,0,35],		//表示[上,右,下,左]的边距
                        },
                    }
                ],
                series: [
                    {
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar'
                    }
                ],
                color:this.barColor,
            }
            this.barChart.setOption(option)
            let chart = this.barChart
            setTimeout(() => {
                window.addEventListener("resize", () => {
                    chart.resize();
                })
            }, 200)
        }
    }
}
</script>

<style scoped >

</style>
