<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="7">
                        <el-form :inline="true" ref="ruleForm" :model="queryParams">
                            <el-form-item label="菜单名称：" prop="menuName">
                                <el-input
                                    v-model="queryParams.menuName"
                                    placeholder="请输入菜单名称"
                                    clearable
                                    @keyup.enter.native="handleQuery"
                                />
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="17">
                        <div class="btn-end">
                            <span class="btn-rest">
                            <el-button plain @click="resetQuery">重置</el-button>
                            </span>
                            <span class="btn-submit">
                             <el-button type="primary" @click="handleQuery" plain>搜索</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            菜单列表
                        </div>
                        <div>
                            <span class="open-btn">
                                <el-button
                                    plain
                                    @click="toggleExpandAll"
                                >展开/折叠
                </el-button>
                            </span>
                            <span class="add-btn">
                            <el-button
                                type="primary"
                                plain
                                size="mini"
                                @click="handleAdd"
                                v-hasCode="['system:menu:add']"
                            >新增
                </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box table-loop">
                            <el-table
                                v-if="refreshTable"
                                :data="menuList"
                                row-key="id"
                                :default-expand-all="isExpandAll"
                                :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                                <el-table-column align="center" prop="menuName" label="菜单名称" :show-overflow-tooltip="true"
                                                 width="250"></el-table-column>
                                <el-table-column prop="menuImage" label="图标" align="center" width="120">
                                    <template slot-scope="scope">
                                        <svg-icon :icon-class="scope.row.menuImage"/>
                                    </template>
                                </el-table-column>

                                <el-table-column prop="menuLevel" label="菜单等级" align="center" width="140">
                                    <template slot-scope="scope">
                                    <span v-if="scope.row.menuLevel === '0' || scope.row.menuLevel === 0" class="states"><i
                                        style="background-color:#1890ff;"></i>一级菜单</span>
                                        <span v-else-if="scope.row.menuLevel === '1' || scope.row.menuLevel === 1" class="states"><i
                                            style="background-color:#808080;"></i>二级菜单</span>
                                        <span v-else-if="scope.row.menuLevel === '2' || scope.row.menuLevel === 2" class="states"><i
                                            style="background-color:#ff8d1a;"></i> 权限按钮</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="menuOrder" label="排序" align="center" width="150"></el-table-column>
                                <el-table-column prop="functionCode" label="权限标识" :show-overflow-tooltip="true"
                                                 width="230"></el-table-column>
                                <el-table-column prop="path" label="路由路径" :show-overflow-tooltip="true" width="230"></el-table-column>
                                <el-table-column label="创建时间" align="center" prop="createTime" width="160">
                                    <!--
                                            <template slot-scope="scope">
                                              <span>{{ parseTime(scope.row.createTime) }}</span>
                                            </template>
                                            -->
                                </el-table-column>
                                <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                                    <template slot-scope="scope">
                                        <el-button
                                            size="mini" class="edit-style"
                                            type="text"
                                            @click="handleUpdate(scope.row)"
                                            v-hasCode="['system:menu:update']"
                                        >修改
                                        </el-button>
                                        <el-button class="edit-style"
                                            size="mini"
                                            type="text" v-if="scope.row.menuLevel != 2"
                                            @click="handleAdd(scope.row)"
                                            v-hasCode="['system:menu:add']"
                                        >新增
                                        </el-button>
                                        <el-button class="edit-style"
                                            size="mini"
                                            type="text"
                                            @click="handleDelete(scope.row)"
                                            v-hasCode="['system:menu:delete']"
                                        >删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>
                </div>
            </el-col>

        </el-row>


        <!-- 添加或修改菜单对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="720px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="上级菜单">
                            <treeselect
                                v-model="form.parentId"
                                :options="menuOptions"
                                :normalizer="normalizer"
                                :show-count="true"
                                placeholder="选择上级菜单"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="菜单类型" prop="menuLevel">
                            <el-radio-group v-model="form.menuLevel">
                                <el-radio label="0" >一级菜单</el-radio>
                                <el-radio label="1" >二级菜单</el-radio>
                                <el-radio label="2" >权限按钮</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="form.menuLevel !== '2'">
                        <el-form-item label="菜单图标" prop="menuImage">
                            <el-popover
                                placement="bottom-start"
                                width="580"
                                trigger="click"
                                @show="$refs['iconSelect'].reset()">
                                <IconSelect ref="iconSelect" @selected="selected"/>
                                <el-input slot="reference" v-model="form.menuImage" placeholder="点击选择图标" readonly>
                                    <svg-icon
                                        v-if="form.menuImage"
                                        slot="prefix"
                                        :icon-class="form.menuImage"
                                        class="el-input__icon"
                                        style="height: 32px;width: 16px;"
                                    />
                                    <i v-else slot="prefix" class="el-icon-search el-input__icon"/>
                                </el-input>
                            </el-popover>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="菜单名称" prop="menuName">
                            <el-input v-model="form.menuName" placeholder="请输入菜单名称"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="显示排序" prop="menuOrder">
                            <el-input v-model="form.menuOrder" controls-position="right" type="number"/>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24" v-if="form.menuLevel !== '2'">
                        <el-form-item prop="path">
                          <span slot="label">
                            路由地址
                          </span>
                            <el-input v-model="form.path" placeholder="请输入路由地址"/>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24" v-if="form.menuLevel == '2'">
                        <el-form-item>
                            <el-input v-model="form.functionCode" placeholder="请输入权限标识" maxlength="100"/>
                            <span slot="label">
                                权限字符
                            </span>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import IconSelect from "@/components/IconSelect";
import {getAuthAll, AddAuth, compileAuths, removeAuth} from "@/utils/user";
import {handleTree2} from "@/utils/tree";


export default {
    name: "AuthName",
    components: {Treeselect, IconSelect},
    data() {
        return {
            // 显示搜索条件
            showSearch: true,
            // 菜单表格树数据
            menuList: [],
            // 菜单树选项
            menuOptions: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 是否展开，默认全部折叠
            isExpandAll: false,
            // 重新渲染表格状态
            refreshTable: true,
            // 查询参数
            queryParams: {
                menuName: undefined,
                visible: undefined
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                menuName: [
                    {required: true, message: "菜单名称不能为空", trigger: "blur"}
                ],
                path: [
                    {required: true, message: "路由地址不能为空", trigger: "blur"}
                ]
            },

            menuData: [],
            menuLevelShow:null
        };
    },
    created() {
        window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
        // window.addEventListener('onload', e => this.onload(e))
        this.getList();
    },
    destroyed() {
        // window.addEventListener('onunload', e => this.onunload(e))
        window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
    },
    methods: {
        beforeunloadFn(e) {
            console.log('刷新或关闭');
            // this.getList();
        },
        onload(e) {
            console.log('刷新');
            // this.getList();
        },
        // 选择图标
        selected(name) {
            this.form.menuImage = name;
        },
        /** 查询菜单列表 */
        async getList() {
            try {
                //this.menuList = []
                //this.queryParams 查询参数
                const res = await getAuthAll();
                if (res.data.code !== 200) {
                    return this.$msg.error("获取权限列表失败");
                } else {
                    this.menuData = res.data.data;
                    this.total = res.data.data.length;
                    this.menuList = handleTree2(res.data.data, "id");
                }
            } catch (e) {
                console.log(e);
            }

        },
        /** 转换菜单数据结构 */
        normalizer(node) {
            if (node.children && !node.children.length) {
                delete node.children;
            }
            return {
                id: node.id,
                label: node.menuName,
                children: node.children
            };
        },
        /** 查询菜单下拉树结构 */
        async getTreeselect() {

            const res = await getAuthAll();
            if (res.data.code !== 200) {
                return this.$msg.error("获取权限信息失败");
            } else {
                this.menuOptions = [];
                const menu = {id: 0, menuName: "主类目", children: []};
                menu.children = handleTree2(res.data.data, "id");
                this.menuOptions.push(menu);
            }

            /**
             this.menuOptions = [];
             const menu = { id: 0, menuName: '主类目', children: [] };
             //menu.children = handleTree2(this.menuData, "id");
             menu.children = this.menuList
             this.menuOptions.push(menu);
             **/

        },
        // 取消按钮
        cancel() {
            try {
                this.open = false;
                this.reset();
            } catch (e) {
            }
        },
        // 表单重置
        reset() {
            this.form = {
                id: undefined,
                parentId: null,
                menuLevel: "0",
                menuOrder: "0",
                menuName: "",
                menuCaption: "",
                menuImage: "#",
                path: ""
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            // this.resetForm("queryForm");
            this.$refs['ruleForm'].resetFields();
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd(row) {
            console.log(row,'row===============')
            this.reset();
            this.getTreeselect();
            if (row != null && row.id) {
                this.form.parentId = row.id;
            } else {
                this.form.parentId = 0;
            }
            this.open = true;
            this.title = "添加菜单";
            this.menuLevelShow=row.menuLevel
            console.log(row.menuLevel,'wwwww')
        },
        /** 展开/折叠操作 */
        toggleExpandAll() {
            this.refreshTable = false;
            this.isExpandAll = !this.isExpandAll;
            this.$nextTick(() => {
                this.refreshTable = true;
            });
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.getTreeselect();
            this.form = row;
            this.form.menuLevel = row.menuLevel + "";
            this.open = true;
            this.title = "修改菜单";

            /** 详情查询
             getMenu(row.id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改菜单";
      });
             **/
        },
        /** 提交按钮 */
        submitForm: function () {
            this.$refs["form"].validate(async valid => {
                if (valid) {
                    if (this.form.id != undefined && this.form.id != 0) {
                        //修改权限
                        /**
                         const res =await compileAuths(data)
                         if(res.status !== 200)return this.$msg.error('修改权限失败')
                         this.$msg.success('修改权限成功')
                         this.addAuth = false
                         this.getAuthList()
                         **/

                        const res = await compileAuths(this.form);
                        if (res.data.code !== 200) {
                            return this.$msg.error("修改权限失败!" + res.data.msg);
                        }
                        this.$msg.success("修改权限成功");
                        this.open = false;
                        this.getList();

                    } else {
                        //添加权限
                        /**
                         let data = JSON.parse(JSON.stringify(this.form))
                         data.menuOrder = 0
                         if(data.parentId == null || data.parentId == ""){
               data.parentId = 0
              }
                         data.menuLevel = data.parentId?1:0
                         const res =await AddAuth(data)
                         **/

                        const res = await AddAuth(this.form);
                        if (res.data.code !== 200) {
                            return this.$msg.error("添加权限失败!" + res.data.msg);
                        } else {
                            this.$msg.success("添加权限成功");
                            this.open = false;
                            this.getList();
                        }
                    }
                }
            });
        },
        /** 删除按钮操作 */
        async handleDelete(row) {
            try {
                const confirm = await this.$MessageBox.confirm(
                    "是否确认删除名称为 ‘" + row.menuName + "’ 的数据项?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                );
                if (confirm !== "confirm") return;
                const res = await removeAuth(row.id);
                if (res.status !== 200) {
                    this.$msg.error("权限删除失败！" + res.data.msg);
                } else {
                    this.$msg.success("权限删除成功");
                }
                this.getList();

            } catch (e) {
            }
        },

        resetForm(refName) {
            if (this.$refs[refName]) {
                this.$refs[refName].resetFields();
            }
        }

    }
};
</script>
<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
.open-btn{
    .el-button {
        border: 1px solid #0D6CE4;
        color: #0D6CE4;
        background: #fff;
        padding: 8px 14px;
        line-height: 20px;
        font-size: 14px;
        width: 88px;
    }
}
.table-loop{
    .el-table {
        height: 600px !important;
        max-height: 600px !important;
    }
    .el-table__body-wrapper,.is-scrolling-none{
        overflow-y: auto;
        height: 550px !important;//table-固定头部的高度
    }
}

</style>
