<template>
    <div class="content">
        <div class="content-title">
            <el-row class="head-section ">

                <el-col :span="6">
                    <el-form :inline="true">
                        <el-form-item label="换电站：">
                            <el-select v-model="stationNo" placeholder="请选择换电站">
                                <el-option
                                    v-for="item in stationList"
                                    :key="item.id"
                                    :label="item.stationName"
                                    :value="item.stationNo"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>

                </el-col>

                <el-col :span="7">
                    <el-form :inline="true">
                        <el-form-item label="日期：">
                            <!--    按季度      -->
                            <Quarter @quarter="quarterClick" ref="child" v-if="tempValue === 4"></Quarter>
                            <!--    按月份      -->
                            <el-date-picker
                                v-else
                                firstDayOfWeek="1"
                                v-model="dateValue"
                                :type="timeType"
                                :format="timeMat"
                                :placeholder="timeholder"
                            ></el-date-picker>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="11">
                    <div class="btn-end">
                    <span class="btn-rest">
                    <el-button @click="resFuntion" plain>重置</el-button>
                     </span>
                        <span class="btn-submit">
                         <el-button @click="search" type="primary" plain>查询</el-button>
                        </span>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-row class="content-content">
            <div>
                <span class="content-content_pos" style="padding-left:10px">{{ stationStr }}月用电量占比</span>
                <stationPie :newPie="newPie"></stationPie>
            </div>
            <div>
                <span class="content-content_pos" style="padding-left:10px">{{ stationStr }}月每日用电量占比</span>
                <stationBar :newBar="newBar"></stationBar>
            </div>
        </el-row>

        <el-row class="content-bottom">
            <div class="content-box-title">
                <span class="content-content_pos" style="padding-left:5px">{{ stationStr }}月用电量占比详情</span>
                <!--        <el-button type="primary" @click="excelAll" class="el-icon-download" size="small">批量导出</el-button>-->
            </div>
            <div class="table-box mar-table">
                <el-table :data="list" style="width: 100%" :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}">
                    <el-table-column prop="name" label="分类"></el-table-column>
                    <el-table-column prop="value" label="能耗值(kw/h)"></el-table-column>
                </el-table>
            </div>

        </el-row>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import moment from "moment";
import {getStaEnergyValRatio} from '@/utils/statistics'
import Quarter from "@/components/quarter";
import stationPie from "@/components/elec-statistics-components/station-pie";
import stationBar from "@/components/elec-statistics-components/station-bar";
import {getForceList} from '@/utils/station'

export default {
    name: "vehicleConsume",
    components: {
        Quarter,
        stationPie,
        stationBar,
    },
    data() {
        return {
            stationStr: "",
            stationNo: null,
            vehicle: "",
            carValue: "",
            tempValue: null,
            dateValue: "",
            titleStr: '',
            stationList: [],
            timeoptions: [
                {
                    value: 5,
                    label: "年",
                },
                {
                    value: 4,
                    label: "季度",
                },
                {
                    value: 3,
                    label: "月",
                },
                {
                    value: 2,
                    label: "周",
                }
            ],
            tableData: [],
            newBar: {},
            newPie: {},
            list: [],
            week: '一',
            coulm: [],
            isEcharts: true,
            isClick: true,
        };
    },
    computed: {
        timeType() {
            switch (this.tempValue) {
                case 5:
                    return "year";
                case 4:
                    return "week";
                case 3:
                    return "month";
                case 2:
                    return "week";
                case 1:
                    return "date";
                default:
                    return "";
            }
        },
        timeMat() {
            let time = this.dateValue || new Date();
            let i = moment(time).month() + 1;
            switch (this.tempValue) {
                case 5:
                    return moment(time).year() + "年";
                case 4:
                    return moment(time).year() + "年第" + this.week + "季度";
                case 3:
                    return moment(time).year() + "年" + i + "月";
                case 2:
                    return this.getWeek(
                        moment(time).year(),
                        moment(time).month() + 1,
                        moment(time).date()
                    );
                default:
                    return "";
            }
        },
        timeholder() {
            switch (this.tempValue) {
                case 5:
                    return "请选择年";
                case 4:
                    return "week";
                case 3:
                    return "请选择月份";
                case 2:
                    return "请选择周";
                case 1:
                    return "请选择日期";
                default:
                    return "请先选择时间方式";
            }
        },

        selectTime() {
            let time = this.dateValue || new Date();
            let startTime = null;
            let endTime = null;
            switch (this.tempValue) {
                case 5:
                    startTime = moment(time)
                        .startOf("year")
                        .format("YYYY-MM-DD HH:mm:ss");
                    endTime = moment(time).endOf("year").format("YYYY-MM-DD HH:mm:ss");
                    break;
                case 4:
                    startTime = moment(time)
                        .startOf("quarter")
                        .format("YYYY-MM-DD HH:mm:ss");
                    endTime = moment(time).endOf("quarter").format("YYYY-MM-DD HH:mm:ss");
                    break;
                case 3:
                    startTime = moment(time)
                        .startOf("month")
                        .format("YYYY-MM-DD HH:mm:ss");
                    endTime = moment(time).endOf("month").format("YYYY-MM-DD HH:mm:ss");
                    break;
                case 2:
                    startTime = moment(time)
                        .startOf("week")
                        .format("YYYY-MM-DD HH:mm:ss");
                    endTime = moment(time).endOf("week").format("YYYY-MM-DD HH:mm:ss");
                    break;
                case 1:
                    startTime = moment(time)
                        .startOf("date")
                        .format("YYYY-MM-DD HH:mm:ss");
                    endTime = moment(time).endOf("date").format("YYYY-MM-DD HH:mm:ss");
                    break;
                default:
                    return "";
            }
            return {
                startTime,
                endTime,
            };
        },

        changeCount() {
            let str = null
            if (this.tempValue === 5 || this.tempValue === 4) {
                str = 'monthSwapCount'
            } else if (this.tempValue === 3 || this.tempValue === 2 || this.tempValue === 1) {
                str = 'daySwapAmount'
            }
            return str
        }
    },

    created() {
        this.resFuntion()
    },

    mounted() {
    },

    methods: {
        async getStation() {
            const res = await getForceList();
            this.stationList = res.data.data;
            this.stationNo = this.stationList.length > 0 ? this.stationList[0].stationNo : "";
            this.stationName = this.stationList.length > 0 ? this.stationList[0].stationName : "";
            this.changeVehicle()
        },

        async changeVehicle() {
            try {
                let month = this.dateValue
                month = month !== '' ? moment(month).format('YYYY-MM-DD') : moment().month('YYYY-MM-DD')
                const data = {
                    stationNo: this.stationNo,
                    startTime: moment(month).format('YYYY-MM-DD HH:mm:ss')
                }

                const res = await getStaEnergyValRatio(data)

                if (res.data.code !== 200) return this.$msg.error('获取信息失败')
                var ChargeCons = (res.data.data.staEnergyValTotal.mc).toFixed(2)
                var ExchangeCons = (res.data.data.staEnergyValTotal.ms).toFixed(2)
                var OtherCons = (res.data.data.staEnergyValTotal.mv).toFixed(2)
                this.list = [
                    {value: ChargeCons, name: "充电能耗值"},
                    {value: ExchangeCons, name: "换电能耗值"},
                    {value: OtherCons, name: "其它能耗值"},
                ]

                this.newPie = {
                    list: this.list,
                    //name: `用电占比统计信息`
                }
                this.stationStr = "" + this.stationName + "-" + moment(month).format('YYYY-MM')
                //柱图、折线图数据格式化
                this.tranfromBar(res.data.data.staEnergyValList, month)
            } catch (error) {

            }
        },

        //获取季度
        quarterClick(e) {
            this.dateValue = moment(e.startTime).format('YYYY-MM-DD HH:mm:ss')
            if (e.week === 0) {
                this.week = '一'
            } else if (e.week === 1) {
                this.week = '二'
            } else if (e.week === 2) {
                this.week = '三'
            } else if (e.week === 3) {
                this.week = '四'
            }
        },
        weekLIST(list) {
            switch (list) {
                case 1:
                    return "星期一";
                case 2:
                    return "星期二";
                case 3:
                    return "星期三";
                case 4:
                    return "星期四";
                case 5:
                    return "星期五";
                case 6:
                    return "星期六";
                case 7:
                    return "星期天";
                default:
                    return;
            }
        },

        //排序
        sort(item) {
            if (item.length === 0) return [];
            let list = item;
            list.forEach((item1) => {
                item1.startTime = +moment(item1.startTime).format("x");
            });
            list.sort((a, b) => a.startTime - b.startTime);
            list.forEach((item1) => {
                item1.startTime = moment(item1.startTime).format("YYYY-MM-DD HH:mm:ss");
            });
            return list;
        },
        getWeek(a, b, c) {
            /**
             * a = d = 当前日期
             * b = 6 - w = 当前周的还有几天过完(不算今天)
             * a + b 的和在除以7 就是当天是当前月份的第几周
             */
            var date = new Date(a, parseInt(b) - 1, c),
                w = date.getDay(),
                d = date.getDate();
            if (w == 0) {
                w = 7;
            }
            var config = {
                getMonth: date.getMonth() + 1,
                getYear: date.getFullYear(),
                getWeek: Math.ceil((d + 6 - w) / 7),
            };

            return `${config.getYear}年${config.getMonth}月${config.getWeek}周`;
        },
        //时间格式化函数，此处仅针对yyyy-MM-dd hh:mm:ss 的格式进行格式化
        dateFormat(time) {
            let date = new Date(time);
            let year = date.getFullYear();
            /* 在日期格式中，月份是从0开始的，因此要加0
             * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
             * */
            let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
            let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            // 拼接
            return year + "-" + month;
        },
        resFuntion() {
            this.stationNo = 'SZ0001'
            this.tempValue = 3
            this.dateValue = new Date()
            this.isClick = true
            this.changeVehicle()
            this.getStation()
        },
        async excelAll() {
            let months = this.changeCount === 'monthSwapCount' ? '每月换电次数' : '每日换电次数'
            let coulm = [
                {prop: 'carNo', label: '车牌号'},
                {prop: this.changeCount, label: months},
                {prop: 'startTime', label: '开始时间'},
                {prop: 'endTime', label: '结束时间'},
            ]
            const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(e => e)
            if (confirm !== 'confirm') return
            this.$download(this.tableData, coulm, '车辆换电记录')
        },

        search() {
            let name=this.stationList.find(item => item.stationNo == this.stationNo)
            this.stationName =name.stationName
            this.changeVehicle()
        },

        changeEcharts() {
            this.isEcharts = !this.isEcharts
            this.changeVehicle()
        },

        //换电量占比
        tranfromBar(list, month) {
            let arrAll = []
            let dayList = this.dayLIST(moment(month).daysInMonth())

            //echart数据生成
            let data1 = this.monthListD(list, dayList)
            this.isEcharts ? arrAll.push({
                name: "充电能耗值",
                type: 'bar',
                data: data1.chargeArr
            }) : arrAll.push({name: "充电能耗值", type: 'line', data: data1.chargeArr})
            this.isEcharts ? arrAll.push({
                name: "换电能耗值",
                type: 'bar',
                data: data1.changeArr
            }) : arrAll.push({name: "换电能耗值", type: 'line', data: data2.changeArr})
            this.isEcharts ? arrAll.push({
                name: "其它能耗值",
                type: 'bar',
                data: data1.otherArr
            }) : arrAll.push({name: "其它能耗值", type: 'line', data: data2.otherArr})


            /**
             let count = this.countPercentage([data1.count,data2.count])
             this.newPie ={
        list:[{name:this.selectTime.newName,value:count[0]},{name:this.selectTime.lastName,value:count[1]}]
      }
             **/
            this.newBar = {
                arrAll,
                monthList: dayList,
                yearList: ["充电能耗值", "换电能耗值", "其它能耗值"]
            }

            console.log("-------this.newBar--------((", this.newBar, "))-----------------")

        },

        dayLIST(list) {
            if (list.length === 0) return [];
            let newArr = [];
            for (let i = 1; i < list + 1; i++) {
                newArr.push(i);
            }
            return newArr;
        },

        //格式化当前月份每天的用电量数据
        monthListD(list, dayList) {
            let chargeArr = [] //充电能耗
            let changeArr = [] //换电能耗
            let otherArr = [] //其它能耗
            let count = 0
            dayList.forEach(item => {
                let i = list.findIndex(c => moment(c.st).date() === item) //场站用电日期等于当前日历中的日期
                if (i === -1) {
                    chargeArr.push({name: item, value: 0})
                    changeArr.push({name: item, value: 0})
                    otherArr.push({name: item, value: 0})
                } else {
                    chargeArr.push({name: item, value: list[i].dc})  //场站充电能耗
                    changeArr.push({name: item, value: list[i].ds})  //场站换电能耗
                    otherArr.push({name: item, value: list[i].dv})  //场站其它能耗
                }

            })
            list.forEach(item => {
                count += item.dt
            })
            return {
                chargeArr,
                changeArr,
                otherArr,
                count
            }
        },

    },
};
</script>

<style lang='less' scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.el-col {
    position: relative;
}

.content {
    border: 0;
    box-shadow: none;
    background-color: #f0f2f5;

}

.content-title {
    background-color: #fff;
    margin: 0;
    padding: 16px 12px;
}

.content-content {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    div {
        position: relative;
        background-color: #fff;

        &:nth-child(1) {
            width: 28%;
            margin-right: 15px;
        }

        &:nth-child(2) {
            width: 71%;
        }

        .content-content_pos {
            display: inline-block;
            font-weight: 400;
            color: #2F3133;
            font-size: 16px;
            padding: 24px 0 0 16px;
            font-family: PingFang SC;
        }
    }
}

.content-bottom {
    background-color: #fff;
    padding: 24px 16px 16px 16px;
    margin-bottom: 30px;
}


#changeI {
    position: absolute;
    top: 15px;
    right: 0%;
    cursor: pointer;
    color: #666;
    margin-right: 50px;
    z-index: 999;
}

#changeI:hover {
    color: #2a81e3;
}

.content-box-title {
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 16px;
    color: #2F3133;
    font-family: PingFang SC;

    span {
        color: #2F3133;
    }
}
.btn-end {
    display: flex;
    justify-content: flex-end;
}
.mar-table{
    margin-top: 16px;
}
.page-num{
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
.open-btn{
    .el-button {
        border: 1px solid #0D6CE4;
        color: #0D6CE4;
        background: #fff;
        padding: 8px 16px;
        line-height: 20px;
        font-size: 14px;
        width: 88px;
    }
}
</style>
