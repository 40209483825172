<template>
  <el-card>
    <el-row>
      <el-col :span="4">
        <el-input placeholder="请输入充电卡号"  v-model.trim="plateValue"></el-input>
      </el-col>
      <el-col :span="4">
        <el-input @blur="userNumber" placeholder="请输入持卡者姓名"  v-model.trim="userValue"></el-input>
      </el-col>
       <el-col :span="4">
         <el-select v-model="modeValue" placeholder="请选择充电卡性质">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
      </el-col>
       <el-col :span="4">
         <el-select v-model="magcardValue" placeholder="请选择持卡者性质">
              <el-option
                v-for="item in optionstype"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
      </el-col>
    </el-row>
    <el-row>

      <el-col :span="4">
         <el-select v-model="rechargeValue" placeholder="请选择充值审核进度">
              <el-option
                v-for="item in audittype"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
      </el-col>
      <el-col :span="6">
        <el-button @click="search" type="primary">查询</el-button>
        <el-button @click="resFuntion">重置</el-button>
      </el-col>
    </el-row>
<!--    <el-row>-->
<!--      <el-button size="small"  @click="addchange = true" class="el-icon-plus" type="primary"> 新增</el-button>-->
<!--        <el-button size="small" @click="removeAll" type="danger" icon="el-icon-delete">选中删除</el-button>-->
<!--    </el-row>-->
    <el-row>
      <el-alert show-icon type="info">
        <span slot="title">
          已选择{{batchNum.length}}项
           <span @click="cleanList" style="margin-left:10px;color:#409eff;cursor:pointer;">清空</span>
        </span>
      </el-alert>
    </el-row>
    <el-row>
      <el-table ref="rechargeTable"  id="excel-recharge"   @selection-change="batchList" :data="tableData" style="width: 100%">
        <el-table-column type="selection" min-width="40"></el-table-column>
        <el-table-column label="编号"  :index="renIndex" type="index" min-width="50"></el-table-column>
        <el-table-column prop="cardNo" label="充电卡号" min-width="120"></el-table-column>
        <el-table-column prop="usageMode" label="充电卡性质" min-width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.usageMode === '1'">金额卡</span>
            <span v-else>电量卡</span>
          </template>
        </el-table-column>
        <el-table-column prop="userNo" label="持卡者编号" min-width="100"></el-table-column>
        <el-table-column prop="userName" label="持卡者姓名" min-width="100"></el-table-column>
        <el-table-column prop="userType" label="持卡者性质" min-width="100">
           <template slot-scope="scope">
            <span v-if="scope.row.userType === 1">客户</span>
            <span v-else>会员</span>
          </template>
        </el-table-column>
        <el-table-column prop="refillAmount" label="本次充值金额(元)" min-width="150"></el-table-column>
        <el-table-column prop="remainAmount" label="剩余充值金额(元)" min-width="150"></el-table-column>
        <el-table-column prop="totalAmout" label="充值总金额(元)" min-width="150"></el-table-column>
        <el-table-column prop="refillEnergy" label="本次充值电量(kwh)" min-width="150"></el-table-column>
        <el-table-column prop="remainEnergy" label="剩余充值电量(kwh)" min-width="150"></el-table-column>
        <el-table-column prop="totalEnerty" label="充值总电量(kwh)" min-width="150"></el-table-column>
        <el-table-column prop="totalTimes" label="充值总次数(次)" min-width="150"></el-table-column>
        <el-table-column prop="auditState" label="充值审核进度" min-width="110">
           <template slot-scope="scope">
             <el-tag v-if="scope.row.auditState === 0" type="info">未注册</el-tag>
             <el-tag v-else-if="scope.row.auditState === 1" type="success">正常使用</el-tag>
             <el-tag v-else type="danger">注销</el-tag>
          </template>
      </el-table-column>
<!--      <el-table-column label="操作" fixed="right" min-width="200">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button @click="compile(scope.row)" size="small" type="primary" icon="el-icon-edit">编辑</el-button>-->
<!--            <el-button @click="recharge(scope.row)" size="small" type="danger" >充值修改</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
    </el-row>
     <el-row>
     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNum"
      :page-sizes="[20, 25, 30]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </el-row>
    <!-- 添加修改充电卡 -->
    <el-dialog
  :title="isRechart?'新增充电卡信息':'修改充电卡信息'"
  :visible.sync="addchange"
   @close="close"
  width="30%">
   <el-form size="small" :rules="reaharRules" ref="recarform" :model="rechargeFrom" label-width="110px">
      <el-form-item label="充电卡号:" prop="cardNo">
          <el-input v-model="rechargeFrom.cardNo"></el-input>
        </el-form-item>
      <el-form-item label="持卡者编号:"  prop="userNo">
          <el-input v-model="rechargeFrom.userNo"></el-input>
        </el-form-item>
      <el-form-item label="持卡者姓名:"  prop="userName">
          <el-input v-model="rechargeFrom.userName"></el-input>
        </el-form-item>
      <el-form-item label="持卡者性质:"  prop="userType">
           <el-select v-model="rechargeFrom.userType" placeholder="请选择持卡者性质">
            <el-option
              v-for="item in optionstype"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      <el-form-item label="充值审核进度:" prop="auditState">
           <el-select v-model="rechargeFrom.auditState" placeholder="请选择本次充值进度">
            <el-option
              v-for="item in audittype"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充电卡性质:"  prop="usageMode">
           <el-select :disabled="isdisabled" v-model="rechargeFrom.usageMode" placeholder="请选择充电卡性质">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="rechargeFrom.usageMode === '1'" label="本次充值金额:"  prop="refillAmount">
          <el-input v-model.number="rechargeFrom.refillAmount"></el-input>
        </el-form-item>
        <el-form-item v-else-if="rechargeFrom.usageMode === '2'" label="本次充值电量:"  prop="refillEnergy">
          <el-input v-model.number="rechargeFrom.refillEnergy"></el-input>
        </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="addchange = false">取 消</el-button>
    <el-button type="primary" @click="addchangeList">确 定</el-button>
  </span>
    </el-dialog>
    <!-- 修改充值数值 -->
       <el-dialog
  :title="istext?'充值金额修改':'充值电量修改'"
  :visible.sync="isrecharge"
   @close="rechargeClose"
  width="25%">
   <el-form size="small" :rules="restRules" ref="restform" :model="restFrom" label-width="110px">
      <el-form-item :label="istext?'充值金额':'充值电量'" prop="updDate">
          <el-input v-model.number="restFrom.updDate"></el-input>
        </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="isrecharge = false">取 消</el-button>
    <el-button type="primary" @click="addRestList">确 定</el-button>
  </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  getrechargeAll,
  addRecharge,
  checkRecharPage,
  removeRechar,
  changeRechar,
  restCard
   } from '@/utils/operation2'
import * as myStore from "@/utils/stroage";

export default {
  name: "rechartName",
  data() {
    return {
      plateValue:'',
      userValue:'',
     magcardValue:'',
     modeValue:'',
     rechargeValue:'',
     residueValue:'',
     totalValue:'',
      query: {
        pageNum:1,
        pageSize:20,
        resPageNum:1
      },
      rechargeFrom:{
        cardNo:'',
        usageMode:'1',
        userNo:'',
        userName:'',
        userType:null,
        refillEnergy:null,
        refillAmount:null,
        auditState:null
      },
      restFrom:{
        id:null,
        isAmount:null,
        updDate:null
      },
      restRules:{
        updDate:[
          { required: true, message: '请输入要更改的充值数', trigger: 'blur' }
        ]
      },
      total:0,
      isPage:true,
      isRechart:true,
      addchange:false,//对话框
      isdisabled:false,
      isrecharge:false,
      istext:true,
      tableData: [],
      batchNum: [],
      rechargeList:[],
      options:[
        {
          value:'1',
          label:'金额卡'
        },
        {
          value:'2',
          label:'电量卡'
        }
      ],
      optionstype:[
        {
          value:1,
          label:'客户'
        },
        {
          value:2,
          label:'会员'
        }
      ],
      audittype:[
        {
          value:0,
          label:'未注册'
        },
        {
          value:1,
          label:'正常使用'
        },
        {
          value:2,
          label:'注销'
        }
      ],
      reaharRules:{
        cardNo:[
           { required: true, message: '请输入充电卡号', trigger: 'blur' }
        ],
        usageMode:[
           { required: true, message: '请选择充电卡性质', trigger: 'change' }
        ],
        userNo:[
            { required: true, message: '请输入持卡者编号', trigger: 'blur' }
        ],
        userName:[
            { required: true, message: '请输入持卡者姓名', trigger: 'blur' }
        ],
        userType:[
             { required: true, message: '请选择持卡者性质', trigger: 'change' }
        ],
        refillEnergy:[
           { required: true, message: '请输入本次充值电量', trigger: 'blur' }
        ],
        refillAmount:[
           { required: true, message: '请输入本次充值金额', trigger: 'blur' }
        ],
        remainEnergy:[
            { required: true, message: '请输入剩余充值电量', trigger: 'blur' }
        ],
        remainAmount:[
           { required: true, message: '请输入剩余充值金额', trigger: 'blur' }
        ],
        totalTimes:[
          { required: true, message: '请输入充值总次数', trigger: 'blur' }
        ],
        auditState:[
           { required: true, message: '请选择充值审核进度', trigger: 'cahnge' }
        ]
      },
      columns:[
        {prop:'cardNo',label:'充电卡号'},
        {prop:'usageMode',label:'充电卡性质'},
        {prop:'userNo',label:'持卡者编号'},
        {prop:'userName',label:'持卡者姓名'},
        {prop:'userType',label:'持卡者性质'},
        {prop:'refillEnergy',label:'本次充值电量(kwh)'},
        {prop:'refillAmount',label:'本次充值金额(元)'},
        {prop:'remainEnergy',label:'剩余充值电量(kwh)'},
        {prop:'remainAmount',label:'剩余充值金额(元)'},
        {prop:'totalTimes',label:'充值总次数(次)'},
        {prop:'totalEnerty',label:'充值总电量(kwh)'},
        {prop:'totalAmout',label:'充值总金额(元)'},
        {prop:'auditState',label:'充值审核进度'},
      ]
    };
  },
  created() {
    this.getChartgeInit()
    this.getrechargeListAll()
  },
  computed:{
       downloadList(){
       if(this.batchNum.length === 0){
        return this.rechargeList.map(item=>{
          item.usageMode = item.usageMode === '1'?'金额卡':'电量卡'
          item.userType = item.userType === 1?'客户':'会员'
          switch (item.auditState) {
            case 0:
              item.auditState =   '未注册'
              break;
            case 1:
              item.auditState =   '正常使用'
              break
            case 2:
              item.auditState =   '注销'
              break
            default:
              break;
          }
            return item
         })
       }else{
        return  this.batchNum.map(item=>{
          item.usageMode = item.usageMode === '1'?'金额卡':'电量卡'
          item.userType =  item.userType === 1?'客户':'会员'
           switch (item.auditState) {
            case 0:
              item.auditState =   '未注册'
              break;
            case 1:
              item.auditState =   '正常使用'
              break
            case 2:
              item.auditState =   '注销'
              break
            default:
              break;
          }
            return item
         })
       }
       }
  },
  methods: {
    //获取充电卡信息
    async getChartgeInit(){
      try {
        const data ={
          page:this.query.pageNum,
          size:this.query.pageSize
        }
       const res =await checkRecharPage(data)
       if(res.status !== 200)return this.$msg.error('获取充电卡信息失败!')
       this.tableData = res.data.data.content
       this.total = res.data.data.totalElements

      } catch (error) {
         console.log(error);
      }
    },
   async getrechargeListAll(){
       const res =await getrechargeAll()
      this.rechargeList = res.data.data
    },
    //选择充电卡
    batchList(list) {
      this.batchNum = list
    },
    //添加和修改充电卡
    addchangeList(){
     try {
       this.$refs.recarform.validate(async valid=>{
        if(!valid)return this.$msg.info('充电卡信息不能为空!')
        const data= JSON.parse(JSON.stringify(this.rechargeFrom))
        this.rechargeFrom.usageMode === '1'?(data.refillEnergy = 0):(data.refillAmount = 0)
        if(this.isRechart){
          console.log(data);
          const res =await addRecharge(data)
          if(res.status !== 200 && res.data.message === '已存在')return this.$msg.warning('充电卡信息已存在，请重新添加!')
          if(res.status !== 200)return this.$msg.error('新增充电卡失败!')
          this.$msg.success('新增充电卡成功!')
          this.addchange = false
          this.getChartgeInit()
        }else{
        const res =await changeRechar(data)
        if(res.status !== 200)return this.$msg.error('修改充电卡失败!')
         this.$msg.success('修改充电卡成功!')
        this.addchange = false
        this.getChartgeInit()

          }
       })

      } catch (e) {
        console.log(e);
      }

    },
     renIndex(index){
      return (this.query.pageNum -1 ) * this.query.pageSize + index +1
    },
    close(){
      const data ={
         usageMode:'1'
      }
      this.$refs.recarform.resetFields()
       this.rechargeFrom = data
       this.isdisabled = false
        this.isRechart = true
    },
    //修改数据填充
    compile(val){
       this.addchange = true
        this.isRechart = false
        this.isdisabled = true
        this.rechargeFrom = JSON.parse(JSON.stringify(val))
    },
    recharge(val){
       this.isrecharge = true
       this.istext = val.usageMode === '1'?true:false
        this.restFrom.id = val.id
        this.restFrom.isAmount = this.istext
    },
    async search(){
       if(
         this.plateValue === '' && this.magcardValue === '' &&
         this.modeValue === '' && this.rechargeValue === '' &&
         this.residueValue === '' && this.totalValue === '' && this.userValue === ''
         )return
     try {
      const data ={
        auditState:this.rechargeValue,
        remainAmount:this.residueValue,
        totalAmout:this.totalValue,
        usageMode:this.modeValue,
        userType:this.magcardValue,
        cardNo:this.plateValue,
        userName:this.userValue
        }
        this.storage('rechargeCard',data)
        data.page = this.query.resPageNum
        data.size = this.query.pageSize
      const res =await checkRecharPage(data)
      if(res.status !== 200)return this.$msg.error('查询信息失败!')
      this.tableData = res.data.data.content
       this.total = res.data.data.totalElements
       this.isPage = false
     } catch (error) {

     }
    },
    userNumber(){
         const newList = JSON.parse(getItem('cellInfo'))
         if(newList && newList[1].cardNo === this.plateValue && newList[0].index > 3){
            this.plateValue = newList[1].cardNo
            this.magcardValue = newList[1].userType
            this.modeValue = newList[1].usageMode
            this.rechargeValue = newList[1].auditState
            this.residueValue = newList[1].remainAmount
            this.totalValue = newList[1].totalAmout
            this.userValue = newList[1].userName
         }
    },
    resFuntion(){
       this.plateValue = ''
       this.magcardValue = ''
       this.modeValue = ''
       this.rechargeValue = ''
       this.residueValue = ''
       this.totalValue = ''
       this.userValue = ''
       this.isPage = true
       this.query ={
         pageNum:1,
         pageSize:20,
         resPageNum:1
       }
       this.getChartgeInit()
    },
    handleSizeChange(val){
      this.query.pageSize = val
      if(this.isPage){
           this.getChartgeInit()
      }else{
        this.search()
      }

    },
    handleCurrentChange(val){
     this.query.pageNum = val
     if(this.isPage){
           this.getChartgeInit()
      }else{
        this.query.resPageNum =val
        this.search()
      }
    },
      cleanList(){
      this.$refs.rechargeTable.clearSelection()
    },
  async  removeAll(){
         if(this.batchNum.length === 0)return this.$msg.info('请选择需要删除的项')
       const config = await this.$MessageBox.confirm(
        "此操作将永久删除选中项, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(e => e);
      if (config !== "confirm") return;
       try {
         const list = this.batchNum.map((item) => {
        return item.id;
      });
       const res =await removeRechar(list)
       if(res.status !== 200)return this.$msg.error('删除失败')
        this.tableData = this.tableData.filter((item,i)=>{
           return item.id !== list[i]
        })
       this.$refs.rechargeTable.clearSelection()
       if(this.query.pageNum > 1 && this.tableData.length === 0){
         this.query.pageNum = this.query.pageNum -1
       }
       this.$msg.success('删除成功')
       this.getChartgeInit()

       } catch (e) {
          console.log(e);
       }

    },
    rechargeClose(){
       this.isrecharge = false
        this.$refs.restform.resetFields()
        this.restFrom.updDate = null
    },
    addRestList(){
       try {
       this.$refs.restform.validate(async valid=>{
         if(!valid)return
           const res = await restCard(this.restFrom)
           if(res.status !== 200)return this.$msg.error('修改充值数据失败')
           this.$msg.success('修改充值数据成功')
           this.isrecharge = false
           this.getChartgeInit()

       })
        } catch (error) {

         }
    }
  }
};
</script>

<style lang='less' scoped>
.el-row {
  margin: 15px 0;
  .el-col {
    margin-right: 10px;

  }
}
.el-select{
  width: 100%;
}
.dowload{
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   >div{
     display: flex;
     align-items: center;
     &:first-child{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: flex-start;
       >span{
         font-size: 12px;
         color: #e10505;
       }
     }
     >button{
       margin-top: 0!important;
     }
   }
 }
</style>
