<template>
   <el-card>
    <el-row>
       <el-col :span="4">
         <el-cascader
        v-model="cascaderValue"
        :options="optionsList"
        @change="changeCascader"
        :props="{checkStrictly: true}"
        placeholder="请选择省市区"
        ></el-cascader>
      </el-col>
      <el-col :span="4">
       <el-select @change="changeSelect" v-model="stationType" placeholder="请选择换电站类型">
            <el-option
              v-for="item in stationTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="stationValue" placeholder="请选择换电站">
            <el-option
              v-for="item in stationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
      </el-col>
      <el-col :span="5">
        <el-button @click="checkCoging" type="primary">查询</el-button>
        <el-button @click="resFuntion">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
<!--      <el-button size="small" @click="codingRule =true" class="el-icon-plus" type="primary"> 新增</el-button>-->
<!--      <el-button size="small" @click="dialogImport = true" icon="el-icon-upload2" type="primary" >批量导入</el-button>-->
      <el-button size="small" icon="el-icon-download"  @click="excelAll" type="primary">批量导出</el-button>
      <el-button @click="removeAll" size="small" type="danger" icon="el-icon-delete">选中删除</el-button>
    </el-row>
    <el-row>
      <el-alert show-icon type="info">
        <span slot="title">
          已选择{{batchNum.length}}项
           <span @click="cleanList" style="margin-left:10px;color:#409eff;cursor:pointer;">清空</span>
        </span>
      </el-alert>
    </el-row>
    <el-row>
      <el-table ref="codingTable" id="codingRule" @selection-change="batchList" :data="tableData" style="width: 100%">
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="编号"  :index="renIndex" type="index" width="50"></el-table-column>
        <el-table-column label="设备编号" prop="eqmNo" min-width="100"></el-table-column>
        <el-table-column label="设备类型编号" prop="eqmmodeNo" min-width="110"></el-table-column>
        <el-table-column label="设备流水号" prop="eqmSrlNum" min-width="150"></el-table-column>
        <el-table-column label="设备编码前置标志字符" prop="preMarker" min-width="160"></el-table-column>
        <el-table-column label="换电站编码" prop="stationNo" min-width="170"></el-table-column>
        <el-table-column label="场站代码" prop="stationSn" min-width="100"></el-table-column>
<!--        <el-table-column label="操作"  >-->
<!--          <template slot-scope="scope">-->
<!--             <el-button @click="compileCoding(scope.row)" size="small" type="primary" icon="el-icon-edit">编辑</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
    </el-row>
    <el-row>
       <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNum"
      :page-sizes="[20, 25, 30]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </el-row>
    <!-- 新增与修改 -->
    <el-dialog
      :title="iscodingRule?'新增编码信息':'修改编码信息'"
      :visible.sync="codingRule"
      width="30%"
      @close="close">
      <el-form size="small" :rules="codingForm" ref="codingform" :model="codingRuleForm" label-width="170px">
      <el-row>
        <el-col :span="22">
          <el-form-item label="换电站编码:" prop="stationNo">
             <el-select v-model="codingRuleForm.stationNo" placeholder="请选择换电站名称">
          <el-option
            v-for="item in stationList1"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
          </el-form-item>
          <el-form-item label="设备编号:" prop="eqmNo">
            <el-input v-model="codingRuleForm.eqmNo"></el-input>
          </el-form-item>
          <el-form-item label="设备类型编号:" prop="eqmmodeNo">
            <el-input maxlength="4" @input="changeeqmmodeNo(codingRuleForm.eqmmodeNo)" v-model="codingRuleForm.eqmmodeNo"></el-input>
          </el-form-item>
          <el-form-item label="设备流水号:" prop="eqmSrlNum">
            <el-input maxlength="4" v-model.number="codingRuleForm.eqmSrlNum"></el-input>
          </el-form-item>
          <el-form-item label="场站代码:" prop="stationSn">
            <el-input v-model="codingRuleForm.stationSn"></el-input>
          </el-form-item>
          <el-form-item label="设备编码前置标志字符:" prop="preMarker">
            <el-input @input="changepreMarker(codingRuleForm.preMarker)" maxlength="2" v-model="codingRuleForm.preMarker"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="codingRule = false">取 消</el-button>
        <el-button type="primary" @click="newcodingRule">确 定</el-button>
      </span>
    </el-dialog>
     <!-- 批量导入 -->
    <el-dialog title="请选择导入文件" :visible.sync="dialogImport" width="30%" @close="closeLoad">
      <el-upload
        class="upload-demo"
        ref="upload"
        action
        :on-change="handleChange"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :before-upload="beforeUpload"
        :file-list="fileList"
        :limit="1"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        :auto-upload="false"
      >
        <el-button size="small" type="primary">请选择 xlsx / xls 文件</el-button>
      </el-upload>
      <span slot="footer" class="dowload dialog-footer">
         <div>
          <el-button @click="dowtemplate">下载模版</el-button>
          <span>请不要擅自修改或删除模版</span>
        </div>
        <div>
          <el-button @click="dialogImport = false">取消</el-button>
        <el-button type="primary" :disabled="uploadDisabled" style="margin-top: 16px" @click="handleUpload">开始上传</el-button>
        </div>
      </span>
    </el-dialog>
   </el-card>
</template>

<script>
import {
  addCodingRule,
  getCodingRule,
  setCodingRule,
  deleteCodingRule,
  getForceList,
  regionCodeSation,
  getCodingAll,
  CodingRuleFile } from '@/utils/station'
    import { regionData } from "element-china-area-data"
import * as myStore from "@/utils/stroage";

export default {
  name:'codingRule',
  data(){
   var validatereg = (rule, value, callback)=>{
       var regExg =/[a-z]|[0-9]/ig
       if(!value) return callback('请输入设备类型编号')
       if(!regExg.test(value))return callback('设备类型编号由4个字母数字组成')
       callback()
   }
var validateMarker = (rule, value, callback)=>{
       var regExg =/^[A-Za-z]+$/
       if(!value) return callback('前置标志字符不能为空')
       if(!regExg.test(value) || value.length < 2)return callback('前置标志字符由2个字母组成')
       callback()
   }
    return{
      optionsList: regionData,
       cascaderValue:[],
      stationType:'',
      stationValue:'',
       stationList:[],
       stationList1:[],
      stationTypeList:[
        {
          value: '0',
          label: "A类型",
        },
        {
          value: '1',
          label: "B类型",
        }
      ],
      query:{
        pageNum:1,
        pageSize:20,
        resPageNum:1
      },
      total:0,
      codingRule:false,
      iscodingRule:true,
      isPage:true,
      batchNum:[],
      tableData:[],
      condingList:[],
      codingRuleForm:{
           eqmNo:'',
           eqmmodeNo:'',
           eqmSrlNum:'',
           stationNo:'',
           stationSn:'',
           preMarker:''
      },
      codingForm:{
         eqmNo:[
            { required: true, message: '请输入设备编号', trigger: 'blur' }
         ],
         eqmmodeNo:[
             { required: true,validator: validatereg, trigger: 'blur' }
         ],
         eqmSrlNum:[
            { required: true, message: '请输入设备流水号', trigger: 'blur' }
         ],
         stationNo:[
            { required: true, message: '请输入换电站编码', trigger: 'blur' }
         ],
         stationSn:[
            { required: true, message: '请输入场站代码', trigger: 'blur' }
         ],
         preMarker:[
           { required: true, validator:validateMarker, trigger: 'blur' }
         ]
      },
      columns:[
        {prop:'preMarker',label:'设备编码前置标志字符'},
        {prop:'stationSn',label:'场站代码'},
        {prop:'eqmmodeNo',label:'设备类型编号'},
        {prop:'eqmSrlNum',label:'设备流水号'},
        {prop:'eqmNo',label:'设备编号'},
        {prop:'stationNo',label:'换电站编码'}

      ],
       dialogImport:false,
      fileList:[],//上传的文件列表
      uploadDisabled:true
    }
  },
  computed:{
     eqmSrl(){
       if(this.codingRuleForm.eqmSrlNum.length === 1){
         return '000'+ this.codingRuleForm.eqmSrlNum
       }else if(this.codingRuleForm.eqmSrlNum.length === 2){
          return '00'+ this.codingRuleForm.eqmSrlNum
       }else if(this.codingRuleForm.eqmSrlNum.length === 3){
         return '0'+ this.codingRuleForm.eqmSrlNum
       }else{
         return this.codingRuleForm.eqmSrlNum
       }

     },
     cascader(){
      if(this.cascaderValue.length === 1){
        return this.cascaderValue[0]
      }else if(this.cascaderValue.length === 2){
        return this.cascaderValue[1]
      }else if(this.cascaderValue.length === 3){
        return this.cascaderValue[2]
      }else{
        return ''
      }
    },
    downloadList(){
       if(this.batchNum.length === 0){
          return this.condingList.map(item=>{
             return item
           })
       }else {
        return this.batchNum.map(item=>{
             return item
           })
       }
    }
  },
  created() {
     this.getCodin()
     this.getCodingListAll()
     this.getStation()
  },
  methods: {
   async getCodin(){
     const data ={
       page:this.query.pageNum,
       size:this.query.pageSize
     }
      const res =await getCodingRule(data)
      if(res.status !== 200)return this.$msg.error('获取编码信息失败!')
       this.tableData = res.data.data.content
          this.total = res.data.data.totalElements
    },
   async getCodingListAll(){
      const res =await getCodingAll()
       this.condingList = res.data.data
    },
      async getStation(){
      const res =await getForceList()
      this.stationList = res.data.data.map(item=>{
        return {
          value:item.stationNo,
          label:item.stationName
        }
      })
      this.stationList1 = this.stationList
    },
     batchList(list){
         this.batchNum = list
     },
     close(){
       this.$refs.codingform.resetFields()
        this.codingRuleForm = {}
        this.iscodingRule = true
     },
     //新增与修改
     newcodingRule(){
       this.$refs.codingform.validate(async valid=>{
         if(!valid)return this.$msg.info('编码规则信息不能为空!')
         let data = JSON.parse(JSON.stringify(this.codingRuleForm))
         data.eqmSrlNum = +this.eqmSrl
         if(this.iscodingRule){
           const res=await addCodingRule(data)
           if(res.status !== 200)return this.$msg.error('新增编码规则失败!')
           this.$msg.success('新增编码规则成功!')
           this.codingRule = false
            this.getCodin()
         }else{
            const res=await setCodingRule(data)
           if(res.status !== 200)return this.$msg.error('修改编码规则失败!')
           this.$msg.success('修改编码规则成功!')
           this.codingRule = false
            this.getCodin()
         }
       })
     },
      renIndex(index){
      return (this.query.pageNum -1 ) * this.query.pageSize + index +1
    },
     //修改信息填充
     compileCoding(list){
            this.codingRule = true
            this.iscodingRule = false
            this.codingRuleForm = JSON.parse(JSON.stringify(list))
     },
     handleSizeChange(val){
        this.query.pageSize =val
        if(this.isPage){
          this.getCodin();
        }else{
          this.checkCoging()
        }

     },
     handleCurrentChange(val){
      this.query.pageNum =val
       if(this.isPage){
          this.getCodin();
        }else{
          this.query.resPageNum = val
          this.checkCoging()
        }
     },
     changepreMarker(val){
         this.codingRuleForm.preMarker = val.toLocaleUpperCase()
     },
     changeeqmmodeNo(val){
        this.codingRuleForm.eqmmodeNo = val.toLocaleUpperCase()
     },
      cleanList(){
      this.$refs.codingTable.clearSelection()
    },
     //选中删除
    async removeAll(){
         if(this.batchNum.length === 0)return this.$msg.info('请选择需要删除的项')
       const config = await this.$MessageBox.confirm(
        "此操作将永久删除选中项, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(e => e);
      if (config !== "confirm") return;
       const list = this.batchNum.map(item=>{
           return item.id
         })
        const res =await deleteCodingRule(list)
       if(res.status !== 200)return this.$msg.error('删除失败')
       this.tableData = this.tableData.filter((item,i)=>{
           return item.id !== list[i]
        })
        this.$refs.codingTable.clearSelection()
       if(this.query.pageNum > 1 && this.tableData.length === 0){
         this.query.pageNum = this.query.pageNum -1
       }
        this.$msg.success('删除成功')
       this.getCodin()
     },
    async checkCoging(){
         try {
           if(this.cascader === '' && this.stationValue === '' &&  this.stationType === '')return
           const data ={
             regionCode:this.cascader,
             stationNo:this.stationValue,
             stationType:this.stationType
           }
            this.storage('codingRule',data)
           data.page = this.query.resPageNum
           data.size = this.query.pageSize
           const res =await getCodingRule(data)
            if(res.status !== 200)return this.$msg.error('查询失败')
          this.tableData = res.data.data.content
          this.total = res.data.data.totalElements
           this.isPage = false
         } catch (error) {

         }
     },
     resFuntion(){
         this.cascaderValue = []
         this.stationValue = ''
         this.stationType = ''
         this.isPage = true
         this.query = {
           pageNum:1,
           pageSize:20,
           resPageNum:1
         }
         this.getCodin()
         this.getStation()
     },
      async changeCascader(){
       this.stationValue = ''
      const data ={
         regionCode:this.cascader!== ''?this.cascader:null,
         stationType:this.stationType !== ''?this.stationType:null
       }
      const res =await regionCodeSation(data)
       if(res.status !== 200)return this.$msg.error('换电站查询失败!')
      if(res.data.data.length !== 0){
          this.stationList = res.data.data.map(item=>{
         return {
           value:item.stationNo,
           label:item.stationName
         }
       })
      }else{
        this.stationList = []
      }
      if(this.stationList.length !== 0){
         const newList = JSON.parse(getItem('codingRule'))
        if(newList && newList[1].regionCode === this.cascader && newList[0].index > 3){
          this.cascaderValue =  this.changeDetSelect(newList[1].regionCode,this.optionsList)
          this.stationValue =  newList[1].stationNo
          this.stationType =  newList[1].stationType
        }
      }
    },
   async changeSelect(){
         this.stationValue = ''
      const data ={
         regionCode:this.cascader!== ''?this.cascader:null,
         stationType:this.stationType !== ''?this.stationType:null
       }
      const res =await regionCodeSation(data)
       if(res.status !== 200)return this.$msg.error('换电站查询失败!')
      if(res.data.data.length !== 0){
          this.stationList = res.data.data.map(item=>{
         return {
           value:item.stationNo,
           label:item.stationName
         }
       })
      }else{
        this.stationList = []
      }
    },
    async excelAll(){
        const confirm =await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e=>e)
        if(confirm !== 'confirm')return
        this.$download(this.downloadList,this.columns,'编码信息管理')

     },
         //下载模版
    dowtemplate(){
      const list =[
        {
          stationNo:'00000AST00000000',
          eqmmodeNo:'最大长度4位',
          eqmNo:'00000',
          eqmSrlNum:'最大长度4位',
          preMarker:'ER',
          stationSn:'ST00000000',
        }
      ]
      this.$download(list, this.columns, "编码信息管理")
    },
      //清空上传的文件
    closeLoad(){
        this.uploadDisabled = true
         this.$refs.upload.clearFiles();
    },
    //上传文件改变
    handleChange(file,fileList){
        if(fileList.length > 0){
          this.uploadDisabled = false
          this.fileList = fileList
        }
    },
     //上传文件之前
    beforeUpload(file){
        let fileTemp = file.raw
       if(fileTemp){
         if(fileTemp.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
         fileTemp.type === "application/vnd.ms-excel"){
        }else{
          this.$msg.warning('附件格式错误，请删除后重新上传!')
        }
       }
    },
    //上传文件移除
    handleRemove(file,fileList){
      this.fileList = fileList
      if(fileList.length < 1){
          this.uploadDisabled = true
      }
    },
   //开始上传
   async handleUpload(){
        if(!this.fileList.length === 0)return this.$msg.info('请选择要上传的文件')
        let formData = new FormData()
        formData.append('file',this.fileList[0].raw)
        const res =await CodingRuleFile(formData)
        if(res.status !== 200) return this.$msg.error('文件上传失败')
        if(res.data && res.data.slice(0,4) === '导入失败')return this.$msg.error('文件上传失败')
        this.$msg.success('文件上传成功')
        this.dialogImport = false
        this.getCodin()
    },
    //删除文件之前的钩子
   async beforeRemove(file){
       return this.$MessageBox.confirm(`确定移除 ${file.name}？`)
    }
  }
}
</script>

<style lang='less' scoped>
 .el-row{
   margin: 15px 0;
   .el-col{
     margin-right: 20px;
   }
 }
.el-select,.el-cascader{
  width: 100%;
}
.dowload{
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   >div{
     display: flex;
     align-items: center;
     &:first-child{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: flex-start;
       >span{
         font-size: 12px;
         color: #e10505;
       }
     }
     >button{
       margin-top: 0!important;
     }
   }
 }

</style>
