<template>
  <div class="content">
    <div class="content-title">
      <el-row >
      <el-col :span="4">
         <el-cascader
        v-model="cascaderValue"
        :options="optionsList"
        @change="changeCascader"
        :props="{checkStrictly: true}"
        placeholder="请选择省市区"
        ></el-cascader>
      </el-col>
       <el-col :span="4">
        <el-select @change="changeSelect" v-model="stationType" placeholder="请选择换电站类型">
            <el-option
              v-for="item in stationTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
      </el-col>
      <el-col :span="4">
         <el-select v-model="stationValue" placeholder="请选择换电站">
            <el-option
              v-for="item in stationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
      </el-col>
      <el-col :span="10">
         <el-button @click="getCellAll" type="primary">查询</el-button>
        <el-button @click="resFuntion">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
<!--      <el-button size="small"  @click="dialogImport = true" icon="el-icon-upload2" type="primary" >批量导入</el-button>-->
      <el-button size="small"  icon="el-icon-download" @click="excelAll" type="primary">批量导出</el-button>
<!--      <el-button size="small" @click="removeAll" type="danger" icon="el-icon-delete">选中删除</el-button>-->
      <el-button size="small" @click="checkAll">{{check?'全选':'反选'}}</el-button>
    </el-row>

    <el-row>
       <el-alert show-icon type="info" :closable="false">
        <span slot="title">
          已选择{{checkList.length}}项
        </span>
      </el-alert>
    </el-row>
    </div>
    <el-row>
       <el-checkbox-group v-model="checkList">
<!--        <div class="space-add"  @click="cellspace = true">-->
<!--          <div>-->
<!--            <i class="el-icon-plus"></i>-->
<!--            新增换电仓-->
<!--          </div>-->
<!--        </div>-->

        <div class="space-item" v-for="item in tableData" :key="item.id">
            <div class="space-title">
            <div>
              <img src="../../../assets/image/space.png" >
            </div>
            <div>
              <h3>{{item.binName}}</h3>
              <span>编号:<span>{{item.binCode}}</span></span>
<!--                <span>换电站编号：</span><span>{{item.stationNo}}</span>-->
            </div>
            <el-checkbox :label="item"></el-checkbox>
          </div>
          <div class="space-content">
            <ul class="space-ul">
              <li>
                <div>
                  <span>位置：</span>
                  <span class="loc">{{item.binLoc}}</span>
                </div>
                <div>
                  <span>状态：</span>
                  <span v-if="item.status === 1"><i class="state" style="background-color:#1890ff;"></i>启用</span>
                  <span v-else><i class="state" style="background-color:#d43030;"></i>禁用</span>
                </div>
              </li>
              <li>
                <div>
                  <span>仓位号：</span>
                  <span>{{item.binNo}}</span>
                </div>
                <div>
                  <span>充电架ID：</span>
                  <span>{{item.side}}</span>
                </div>
              </li>
              <li>
                <div>
                  <span>电插：</span>
                   <span v-if="item.electricPlugDone === 1">有</span>
                  <span v-else>没有</span>
                </div>
                <div>
                  <span>水插：</span>
                  <span v-if="item.waterPlugDone === 1">有</span>
                  <span v-else>没有</span>
                </div>
              </li>
              <li>
<!--                <div>-->
<!--                  <span>创建人：</span>-->
<!--                  <span>{{item.creator}}</span>-->
<!--                </div>-->
                <div>
                  <span>换电站编号：</span>
                  <span>{{item.stationNo}}</span>
                </div>
<!--                <div>-->
<!--                  <span>创建时间：</span>-->
<!--                  <span>{{dateFmt(item.createTime)}}</span>-->
<!--                </div>-->
              </li>

            </ul>
          </div>
<!--          <div class="space-bottom">-->
<!--            <el-button @click="compileCell(item)">修改</el-button>-->
<!--          </div>-->
      </div>
      </el-checkbox-group>
    </el-row>
     <el-row class="flooter">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNum"
      :page-sizes="[10,20, 25, 30]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </el-row>
    <el-dialog
      :title="iscellspace?'新增电池仓位信息':'修改电池仓位信息'"
      :visible.sync="cellspace"
      width="50%"
     @close="close"
    >
      <el-form size="small" ref="addorform" :rules="cellRules" :model="cellForm" label-width="150px">
        <el-row>
          <el-col style="margin-right:10px;" :span="11">

            <el-form-item label="换电站编码:" prop="stationNo">
               <el-select v-model="cellForm.stationNo" placeholder="请选择换电站编码">
                  <el-option
                    v-for="item in stationList1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="仓位编号:" prop="binCode">
              <el-input minlength="4" placeholder="最低输入四个字母" v-model="cellForm.binCode"></el-input>
            </el-form-item>
             <el-form-item label="仓位号:" prop="binNo">
              <el-input v-model="cellForm.binNo"></el-input>
            </el-form-item>
             <el-form-item label="仓位名称:" prop="binName">
              <el-input v-model="cellForm.binName"></el-input>
            </el-form-item>
             <el-form-item label="充电架ID:" prop="side">
              <el-input v-model="cellForm.side"></el-input>
            </el-form-item>
             <el-form-item label="充电仓排序号(1-100):" prop="binNumber">
              <el-input v-model="cellForm.binNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="是否有水插头:" prop="waterPlugDone">
               <el-select v-model="cellForm.waterPlugDone" placeholder="请选择">
                  <el-option
                    v-for="item in waterList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否有电插头:" prop="electricPlugDone">
                <el-select v-model="cellForm.electricPlugDone" placeholder="请选择">
                  <el-option
                    v-for="item in electricList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="通用状态:" prop="status">
            <el-select v-model="cellForm.status" placeholder="请选择电池仓位状态">
                  <el-option
                    v-for="item in stateList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="所在位置:" prop="binLoc">
              <el-input v-model="cellForm.binLoc"></el-input>
            </el-form-item>
            <el-form-item label="创建者:" >
              <span>{{cellForm.creator}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cellspace = false">取 消</el-button>
        <el-button type="primary" @click="newCellorset">确 定</el-button>
      </span>
    </el-dialog>
      <!-- 批量导入 -->
    <el-dialog title="请选择导入文件" :visible.sync="dialogImport" width="30%" @close="closeLoad">
      <el-upload
        class="upload-demo"
        ref="upload"
        action
        :on-change="handleChange"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :before-upload="beforeUpload"
        :file-list="fileList"
        :limit="1"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        :auto-upload="false"
      >
        <el-button size="small" type="primary">请选择 xlsx / xls 文件</el-button>
      </el-upload>
      <span slot="footer" class="dowload dialog-footer">
         <div>
          <el-button @click="dowtemplate">下载模版</el-button>
          <span>请不要擅自修改或删除模版</span>
        </div>
       <div>
          <el-button @click="dialogImport = false">取消</el-button>
        <el-button type="primary" :disabled="uploadDisabled" style="margin-top: 16px" @click="handleUpload">开始上传</el-button>
       </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSpaceAll,
  addSpace,
  getSpacePage,
  setSpace,
  getForceList,
  deleteSpace,
  regionCodeSation,
  sapaceLeadFile
  } from "@/utils/station";
import { regionData } from "element-china-area-data"
import * as myStore from "@/utils/stroage";
import moment from 'moment'
export default {
  name: "cellSpace",
  data() {
    return {
      query:{
         pageNum:1,
         pageSize:10,
         resPageNum:1
      },
      total:0,
      cascaderValue:[],
      stationType:'',
      optionsList: regionData,
       stationTypeList: [
        {
          value: '0',
          label: "A类型",
        },
        {
          value: '1',
          label: "B类型",
        },
      ],
       stationList:[],
       stationList1:[],
      stationValue:'',
       stateList:[
        {
          value:1,
          label:'启用'
        },
        {
          value:2,
          label:'禁用'
        }
      ],
      tableData: [],
      cellspace: false,
      iscellspace:true,
      waterList:[
      {
        value:1,
        label:'有'
      },
      {
        value:0,
        label:'没有'
      }
      ],
      electricList:[
      {
        value:1,
        label:'有'
      },
      {
        value:0,
        label:'没有'
      }
      ],
      cellForm: {
        binCode: "",
        binLoc: "",
        binName: "",
        binNo: null,
        binNumber: null,
        waterPlugDone:"",
        electricPlugDone:"",
        side: null,
        stationNo: "",
        status: null,
        creator:''
      },
      cellRules:{
         binCode:[
            { required: true, message: '请输入仓位编号', trigger: 'blur' }
         ],
         binName:[
            { required: true, message: '请输入仓位名称', trigger: 'blur' }
         ],
         binNo:[
            { required: true, message: '请输入仓位号', trigger: 'blur' }
         ],
         waterPlugDone:[
           { required: true, message: '请选择是否有水插头', trigger: 'change' }
         ],
         electricPlugDone:[
            { required: true, message: '请选择是否有电插头', trigger: 'change' }
         ],
         side:[
            { required: true, message: '请输入充电架ID', trigger: 'blur' }
         ],
         stationNo:[
           { required: true, message: '请输入换电站编码', trigger: 'blur' }
         ],
         status:[
            { required: true, message: '请选择通用状态', trigger: 'change' }
         ],
         binLoc:[
           { required: false, message: '', trigger: 'blur' }
         ],
         binNumber:[
            { required: false, message: '', trigger: 'blur' }
         ]
      },
      columns:[
        {prop:'binCode',label:'仓位编号'},
        {prop:'binName',label:'仓位名称'},
        {prop:'binLoc',label:'所在位置'},
        {prop:'binNo',label:'仓位号(序号)'},
        {prop:'side',label:'充电架ID'},
        {prop:'elecPlugDone',label:'是否有电插头'},
        {prop:'waterPlugDone',label:'是否有水插头'},
        {prop:'binNumber',label:'充电仓排序号'},
        {prop:'status',label:'通用状态'},
        {prop:'stationNo',label:'换电站编码'}
      ],
      checkList:[],
      cellspaceAll:[],
      check:true,
      isPage:true,
       dialogImport:false,
      fileList:[],//上传的文件列表
      uploadDisabled:true
    };
  },
  computed: {
    userName(){
      return JSON.parse(this.$store.state.user).username
    },
    cascader(){
      if(this.cascaderValue.length === 1){
        return this.cascaderValue[0]
      }else if(this.cascaderValue.length === 2){
        return this.cascaderValue[1]
      }else if(this.cascaderValue.length === 3){
        return this.cascaderValue[2]
      }else{
        return ''
      }
    },
    downloadList(){
     if(this.checkList.length === 0){
       return  this.cellspaceAll.map(item=>{
              item.status = item.status === 0?'禁用':'启用'
              item.elecPlugDone = item.elecPlugDone === 0?'没有':'有'
              item.waterPlugDone = item.waterPlugDone === 0?'没有':'有'
              return item
        })
     }else{
        return  this.checkList.map(item=>{
              item.status = item.status === 0?'禁用':'启用'
              item.elecPlugDone = item.elecPlugDone === 0?'没有':'有'
              item.waterPlugDone = item.waterPlugDone === 0?'没有':'有'
              return item
        })
     }
    }

  },
  created() {
    this.getCellspace()
    this.getSpaceListAll()
    this.getStation()
    this.cellForm.creator = this.userName
  },
  methods: {
    //分页获取
    async getCellspace() {
       const data ={
         page:this.query.pageNum,
         size:this.query.pageSize-1
       }
      const res = await getSpacePage(data);
       console.log(res)
      if (res.status !== 200) return this.$msg.error("获取电池仓位信息失败!");
         let newList = res.data.data.content
         this.tableData = newList
         this.total = res.data.data.totalElements
    },
    //获取所有
    async getSpaceListAll(){
      const res =await getSpaceAll()
       this.cellspaceAll = res.data.data
    },
      //获取换电站编号
   async getStation(){
       const res =await getForceList()
       this.stationList = res.data.data.map(item=>{
         return {
           value:item.stationNo,
           label:item.stationName
         }
       })
       this.stationList1 = this.stationList
    },
    //新增与修改
    newCellorset() {
        this.$refs.addorform.validate(async (valid) => {
          if (!valid) return this.$msg.info("仓位信息不能为空!");
          try {
            var data = JSON.parse(JSON.stringify(this.cellForm))
          if (this.iscellspace) {
            const res = await addSpace(data);
            if (res.status !== 200 && res.data.message === '电池仓位信息已存在') return this.$msg.warning("电池仓位信息已存在，请重新添加!")
            if (res.status !== 200 && res.data.message !== '电池仓位信息已存在') return this.$msg.error("新增仓位信息失败!");
            this.$msg.success("新增仓位信息成功!");
            this.cellspace = false;
            this.getCellspace();
          }else{
            const res = await setSpace(data);
            if (res.status !== 200) return this.$msg.error("修改仓位信息失败!");
            this.$msg.success("修改仓位信息成功!");
            this.cellspace = false;
            this.getCellspace();
          }
           } catch (e) {
          }
        });

    },
    //修改填充信息
    compileCell(list){
       this.cellspace = true
       this.iscellspace = false
       let data = JSON.parse(JSON.stringify(list))
        this.cellForm = data

    },
    close(){
       const data ={
        creator: this.userName,
       }
        this.$refs.addorform.resetFields()
       this.cellForm = data
    },
   async getCellAll(){
        if(this.cascaderValue === '' && this.stationType === '' && this.stationValue === '')return

         try {
        const data ={
        regionCode:this.cascader,
        stationNo:this.stationValue,
        stationType:this.stationType
      }
      this.storage('cellspace',data)
      data.page = this.query.resPageNum
      data.size = this.query.pageSize-1
      const res =await getSpacePage(data)
      if(res.status !== 200)return this.$msg.error('查询失败')
        let newList = res.data.data.content
         this.tableData = newList
         this.total = res.data.data.totalElements
       this.isPage = false
     } catch (e) {
        console.log(e);
     }
    },
    resFuntion(){
       this.cascaderValue = ''
       this.stationType = ''
       this.stationValue = ''
       this.isPage = true
       this.query ={
         pageNum:1,
         pageSize:20,
         resPageNum:1
       }
       this.getCellspace()
       this.getStation()

    },
    checkAll(){
       if(this.check){
         this.checkList = this.tableData.map(item=>{
          return item
        })
        this.check = false
       }else{
         this.checkList = []
          this.check =true
       }

    },
   async excelAll(){
        const confirm =await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e=>e)
        if(confirm !== 'confirm')return
        const data = JSON.parse(JSON.stringify(this.columns))
        data.creator = '创建者'
         this.$download(this.downloadList,data,'电池仓位信息')
    },
       //下载模版
    dowtemplate(){
      const list =[
        {
          binCode:'00000',
          binName:'0号充电仓',
          binLoc:'00000',
          binNo:'0',
          side:'0',
          elecPlugDone:'有1没有0',
          waterPlugDone:'有1没有0',
          binNumber:'0',
          status:'启用1禁用0',
          stationNo:'00000AST00000000'
        }
      ]
      this.$download(list, this.columns, "电池仓位信息")
    },
      handleSizeChange(val){
        this.query.pageSize = val
        if(this.isPage){
           this.getCellspace()
        }else{
          this.getCellAll()
        }

    },
    handleCurrentChange(val){
      this.query.pageNum = val
       if(this.isPage){
           this.getCellspace()
        }else{
          this.query.resPageNum = val
          this.getCellAll()
        }
    },
   async removeAll(){
         if(this.checkList.length === 0)return this.$msg.info('请选择需要删除的项')
       const config = await this.$MessageBox.confirm(
        "此操作将永久删除选中项, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(e => e);
      if (config !== "confirm") return;
       try {
         const list = this.checkList.map(item=>{
         return item.id
       })
       const res =await deleteSpace(list)
       if(res.status !== 200)return this.$msg.error('删除失败')
        this.checkList = []
       this.$msg.success('删除成功')
       this.getCellspace()

       } catch (e) {
          console.log(e);
       }
    },
    dateFmt(time){
       if(time){
         return moment(time).format('YYYY-MM-DD')
       }else{
         return ''
       }
    },
     async changeCascader(){
       this.stationValue = ''
       const data ={
         regionCode:this.cascader!== ''?this.cascader:null,
         stationType:this.stationType !== ''?this.stationType:null
       }
      const res =await regionCodeSation(data)
       if(res.status !== 200)return this.$msg.error('换电站查询失败!')
      if(res.data.data.length !== 0){
          this.stationList = res.data.data.map(item=>{
         return {
           value:item.stationNo,
           label:item.stationName
         }
       })
      }else{
        this.stationList = []
      }
      if(this.stationList.length !== 0){
         const newList = JSON.parse(getItem('cellspace'))
        if(newList && newList[1].regionCode === this.cascader && newList[0].index > 3){
          this.cascaderValue =  this.changeDetSelect(newList[1].regionCode,this.optionsList)
          this.stationValue =  newList[1].stationNo
          this.stationType =  newList[1].stationType
        }
      }
    },
   async changeSelect(){
       this.stationValue = ''
       const data ={
         regionCode:this.cascader!== ''?this.cascader:null,
         stationType:this.stationType !== ''?this.stationType:null
       }
      const res =await regionCodeSation(data)
       if(res.status !== 200)return this.$msg.error('换电站查询失败!')
      if(res.data.data.length !== 0){
          this.stationList = res.data.data.map(item=>{
         return {
           value:item.stationNo,
           label:item.stationName
         }
       })
      }else{
        this.stationList = []
      }
    },
     //清空上传的文件
    closeLoad(){
        this.uploadDisabled = true
         this.$refs.upload.clearFiles();
    },
    //上传文件改变
    handleChange(file,fileList){
        if(fileList.length > 0){
          this.uploadDisabled = false
          this.fileList = fileList
        }
    },
     //上传文件之前
    beforeUpload(file){
        let fileTemp = file.raw
       if(fileTemp){
         if(fileTemp.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
         fileTemp.type === "application/vnd.ms-excel"){
        }else{
          this.$msg.warning('附件格式错误，请删除后重新上传!')
        }
       }
    },
    //上传文件移除
    handleRemove(file,fileList){
      this.fileList = fileList
      if(fileList.length < 1){
          this.uploadDisabled = true
      }
    },
   //开始上传
   async handleUpload(){
        if(!this.fileList.length === 0)return this.$msg.info('请选择要上传的文件')
        let formData = new FormData()
        formData.append('file',this.fileList[0].raw)
        formData.append('creator',this.userName)
        const res =await sapaceLeadFile(formData)
       if(res.status !== 200)return this.$msg.error('文件上传失败')
        if(res.data && res.data.slice(0,4) === '导入失败')return this.$msg.error('文件上传失败')
        this.$msg.success('文件上传成功')
        this.dialogImport = false
        this.getCellspace()
    },
    //删除文件之前的钩子
   async beforeRemove(file){
       return this.$MessageBox.confirm(`确定移除 ${file.name}？`)
    }

  },
};
</script>

<style lang='less' scoped>
.el-row {
  margin: 15px 0;
  .el-col{
    margin-right: 20px;
  }
}
.el-select,.el-cascader{
  width: 100%;
}
.content{
  background-color:#f0f2f5;
}
.content-title{
   padding: 15px 25px 15px 15px;
   background-color: #fff;
}

li{
  list-style: none;
}
.el-checkbox-group{
   width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    .space-add{
      width: 300px;
      height: 300px;
      min-width: 300Px;
      min-height: 300Px;
      display: flex;
      align-items: center;
      justify-content: center;
      color:#aaabaa;
      background-color: #fff;
      margin-right: 30px;
      margin-bottom: 30px;
      cursor: pointer;
      font-size: 30px;
}
   .el-checkbox{
    width: 10%;
    /deep/ .el-checkbox__label{
     display: none;
   }
   }

}

.space-item{
    width: 300px;
   height: 300px;
   min-width: 300Px;
    min-height: 300Px;
    background-color: #fff;
     margin-right: 30px;
      margin-bottom: 30px;
   .space-title{
     height: 20%;
     display: flex;
     align-items: center;
     >div{
       &:nth-child(1){
         height: 100%;
         width: 25%;
         padding: 3px 7px;
         >img{
           width: 100%;
           height: 100%;
         }
       }
       &:nth-child(2){
         height: 100%;
         width: 75%;
         display: flex;
         flex-direction: column;
         justify-content: space-around;
         >span{
           font-size: 15px;
         }
       }

     }
   }
   .space-content{
     height: 60%;
     .space-ul{
       width: 100%;
       height: 100%;
       display: flex;
       flex-direction: column;
       li{
         display: flex;
         align-items: center;
         justify-content: space-between;
         width: 100%;
         height: 25%;
         padding:  0 15px;
         div{
           flex: 1;
           height: 100%;
           display: flex;
           align-items: center;
           justify-content: flex-start;

           span{
             font-size: 12px;
           }
           .loc{
             width: 65%;
           display: -webkit-box;
            -webkit-box-orient: vertical;
             -webkit-line-clamp: 2;
             overflow: hidden;
           }
           .state{
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #fff;
            margin-bottom: 3px;
            margin-right: 3px;
          }

         }
       }
     }
   }
   .space-bottom{
     height: 20%;
     .el-button{
       width: 100%;
       height: 100%;
       border-radius: 0;
       background-color: #2a82e4;
       color: #fff;
     }
   }
}
 .dowload{
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   >div{
     display: flex;
     align-items: center;
     &:first-child{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: flex-start;
       >span{
         font-size: 12px;
         color: #e10505;
       }
     }
     >button{
       margin-top: 0!important;
     }
   }
 }
 .flooter{
   background-color: #fff;
   padding: 15px 0;
   padding-left: 15px;
 }
</style>
