<template>
  <!-- 区域营收结果 -->
  <el-card>
    <el-row >
      <el-col :span="4">
        <el-cascader
          ref="elcascader"
          v-model="cascaderValue"
          :options="optionsList"
          @change="changeCascader"
          :props="{checkStrictly: true}"
          placeholder="请选择省市区"
        ></el-cascader>
      </el-col>
      <el-col :span="4">
        <el-select @change="changeSelect" v-model="dateValue" placeholder="请选择日期">
          <el-option
            v-for="item in dateList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <Quarter @quarter="quarterClick" v-if="dateValue === 4"></Quarter>
        <el-date-picker
          v-else
          v-model="timeValue"
          :type="timeType"
          :format="timeMat"
          :placeholder="timeholder"></el-date-picker>
      </el-col>
      <el-col :span="10">
        <el-button @click="search" type="primary">查询</el-button>
        <el-button @click="resFuntion">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <revenueCompoments :isShow="isShow" :newPie="newPie" :newObj="newObj" :classData="classData" :timeState="timeState"></revenueCompoments>
    </el-row>
    <el-row class="content-bottom">
      <div class="content-box-title">
        <span>{{stationName}}</span>
        <el-button type="primary" @click="excelAll" class="el-icon-download" size="small">批量导出</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="区域编码" prop="renginCode"></el-table-column>
        <el-table-column label="区域名称">
          <template slot-scope="scope">
            <span>{{playName(scope.row.renginCode)}}</span>
          </template>
        </el-table-column>
        <el-table-column :prop="AmountName" :label="AmountName=== 'monthAmount'?'区域每月营收':'区域每天营收'" :formatter="$toDecimal2"></el-table-column>
        <el-table-column prop="startTime" label="开始时间"></el-table-column>
        <el-table-column prop="endTime" label="结束时间"></el-table-column>
      </el-table>
    </el-row>
  </el-card>
</template>

<script>

  import {  searchDayArerRevenue,searchMonthArerRevenue,getArerStatis,getAmountCount } from "@/utils/revenue";
  import { regionData } from "element-china-area-data";
  import { getArerCodeAll } from "@/utils/basicInformation";
  import Quarter from '@/components/quarter/index'
  import * as myStore from "@/utils/stroage";
  import revenueCompoments from '@/components/revenueCompoments/index'
  import moment from 'moment'
  export default {
    name: "arerCodeName",
    components:{
      Quarter,
      revenueCompoments
    },
    data() {
      return {
        arerCode:'',
        arerName:'',
        stationName:'',
        optionsList: regionData,
        cascaderValue: [],
        dateList: [
          {
            value: 5,
            label: "年",
          },
          {
            value: 4,
            label: "季度",
          },
          {
            value: 3,
            label: "月",
          },
          {
            value: 2,
            label: "周",
          },
          {
            value: 1,
            label: "日",
          },
        ],
        dateValue: 5,
        week:'一',
        timeValue:'',
        tableData: [],
        total: 0,
        isShow:false,
        timeState:[],
        newObj:{
          item:[],
          list:[]
        },
        newPie:{
          list:[],
          title:''
        },
        arrList:['区域营收结果'],
        classData:{
          totalName:'区域营收结果',
          monthsName:'区域本月营收结果',
          daysName:'区域今日营收结果',
          unit:'元',
          total:0,
          currentMonth:0,
          currentday:0
        },
        AmountName:'monthAmount'
      };
    },
    computed:{
      timeType(){
        switch (this.dateValue) {
          case 5:
            return 'year'
          case 4:
            return 'week'
          case 3:
            return 'month'
          case 2:
            return 'week'
          case 1:
            return 'date'
          default:
            return	''
        }
      },
      timeMat(){
        let time = this.timeValue || new Date()
        let i = moment(time).month()+1
        let wekStr = this.getWeek(moment(time).year(), moment(time).month()+1,moment(time).date())
        switch (this.dateValue) {
          case 5:
            return moment(time).year()+'年'
          case 4:
            return moment(time).year()+'年第'+this.week+'季度'
          case 3:
            return moment(time).year()+'年'+i+'月'
          case 2:
            return wekStr
          case 1:
            return `${moment(time).year()}年${moment(time).month()+1}月${moment(time).date()}日`
          default:
            return	''

        }
      },
      timeholder(){
        switch (this.dateValue) {
          case 5:
            return '请选择年'
          case 4:
            return 'week'
          case 3:
            return '请选择月份'
          case 2:
            return '请选择周'
          case 1:
            return '请选择日期'
          default:
            return	'什么都不选'
        }
      },
      selectTime(){
        let time = this.timeValue ||new Date()
        let startTime = null
        let endTime = null
        switch (this.dateValue) {
          case 5:
            startTime =  moment(time).startOf('year').format('YYYY-MM-DD HH:mm:ss')
            endTime =  moment(time).endOf('year').format('YYYY-MM-DD HH:mm:ss')
            break;
          case 4:
            startTime =  moment(time).startOf('quarter').format('YYYY-MM-DD HH:mm:ss')
            endTime =  moment(time).endOf('quarter').format('YYYY-MM-DD HH:mm:ss')
            break
          case 3:
            startTime =  moment(time).startOf('month').format('YYYY-MM-DD HH:mm:ss')
            endTime =  moment(time).endOf('month').format('YYYY-MM-DD HH:mm:ss')
            break
          case 2:
            startTime =  moment(time).startOf('week').format('YYYY-MM-DD HH:mm:ss')
            endTime =  moment(time).endOf('week').format('YYYY-MM-DD HH:mm:ss')
            break
          case 1:
            startTime =  moment(time).startOf('date').format('YYYY-MM-DD HH:mm:ss')
            endTime =  moment(time).endOf('date').format('YYYY-MM-DD HH:mm:ss')
            break
          default:
            return ''
        }
        return {
          startTime,
          endTime
        }
      },
      dataList(){
        if(this.dateValue === 5 || this.dateValue === 4){
          return 'monthAmount'
        }else{
          return 'dayAmount'
        }
      },
      cascader() {
        return this.cascaderValue.join(',')
      },

    },
    created() {
      this.resFuntion();

    },
    methods: {
      //查询初始化
      async checkStation() {
        try {
          let index = this.dateValue
          let res = null
          let Newlist = null
          let newArr = null
          let code = this.cascader || this.arerCode
          const data = {
            regionCode:code,
            startTime: this.selectTime.startTime,
            endTime: this.selectTime.endTime,
          };
          let newData = JSON.parse(JSON.stringify(data))
          newData.dateValue = index
          newData.timeValue = this.timeValue || new Date()
          this.storage('arerCodeRevenue',newData)
          if(index === 5 || index === 4){
            res = await searchMonthArerRevenue(data);
            Newlist = res.data.data
            newArr = this.sort(Newlist.list)
            if (res.status !== 200) return this.$msg.error("获取换电站用电计费失败");
          }else if(index === 3 || index === 2  || index === 1){
            res =await searchDayArerRevenue(data)
            Newlist = res.data.data
            newArr = this.sort(Newlist.list)
            if (res.status !== 200) return this.$msg.error("获取换电站用电计费失败");
          }
          let list = this.reduces(newArr)
          this.tableData = list
          this.stationName =  `${this.arerName}${this.timeMat}营收结果`
          this.rendeerEcharts(JSON.parse(JSON.stringify(list)))

        } catch (error) {
          console.log(error.response);
        }
      },
      //获取总数量
      async getdayPage(value){
        try {
          const res =await getArerStatis(value)
          const result = await getAmountCount({regionCode:value,stationNo:''})
          if(res.status !== 200 || result.status !== 200)return this.$msg.error('获取区域营收总数失败')
          let newList = res.data.data
          this.classData.total = newList.year
          this.classData.currentMonth = newList.month
          this.classData.currentday = result.data.data?result.data.data.paymentAmountCount:0
        } catch (error) {

        }
      },
      //获取区域编码
      async getStation() {
        try {
          const res = await getArerCodeAll();
          if(res.status !== 200)return this.$msg.error('获取区域编码失败')
          let newList = res.data.data;
          this.arerCode = newList[0].areaCode
          this.arerName = this.playName(newList[0].areaCode)
          if(newList[0].areaCode.indexOf(',') !== -1){
            this.cascaderValue = [...newList[0].areaCode.split(',')]
          }else{
            this.cascaderValue = this.changeDetSelect(newList[0].areaCode,this.optionsList)
          }
          this.checkStation()
          this.getdayPage(this.arerCode)
        } catch (error) {

        }
      },
      search(){
        let value = this.cascader || this.arerCode
        if(this.dateValue === 5 || this.dateValue === 4){
          this.AmountName =  'monthAmount'
        }else{
          this.AmountName =  'dayAmount'
        }

        this.checkStation()
        this.getdayPage(value)
      },
      rendeerEcharts(Newlist){
        let time = this.timeValue || new Date()
        let monthTotalValue = []
        let newList = []
        let i = 0
        let dayList =[]
        switch (this.dateValue) {
          case 5://年
            dayList = this.dayLIST(12)
            dayList.forEach(item=>{
              newList.push(this.monthLIST(item))
              i = Newlist.findIndex(c=>moment(c.endTime).month()+1 === item)
              if(i === -1){
                monthTotalValue.push(0)
              }else if(i !== -1){
                monthTotalValue.push(Newlist.length === 0?0:Newlist[i][this.dataList])
              }
            })

            break;
          case 4://季度
            dayList = this.quarterList(this.week)
            dayList.forEach(item=>{
              newList.push(this.monthLIST(item))
              i = Newlist.findIndex(c=>moment(c.endTime).month()+1 === item)
              if(i === -1){
                monthTotalValue.push(0)
              }else if(i !== -1){
                monthTotalValue.push(Newlist.length === 0?0:Newlist[i][this.dataList])
              }
            })
            break
          case 3://月
            let day = moment(time).daysInMonth()
            dayList = this.dayLIST(day)
            dayList.forEach(item=>{
              newList.push(item)
              i = Newlist.findIndex(c=>moment(c.endTime).date() === item)
              if(i === -1){
                monthTotalValue.push(0)
              }else if(i !== -1){
                monthTotalValue.push(Newlist.length === 0?0:Newlist[i][this.dataList])
              }
            })
            break
          case 2://周
            dayList = this.dayLIST(7)
            dayList.forEach(item=>{
              newList.push(this.weekList(item))
              i = Newlist.findIndex(c=>moment(c.endTime).isoWeekday() === item)
              if(i === -1){
                monthTotalValue.push(0)
              }else{
                monthTotalValue.push(Newlist.length === 0?0:Newlist[i][this.dataList])
              }
            })
            break
          case 1://日
            let name = ''
            if(Newlist.length === 0){
              monthTotalValue.push(0)
            }else{
              name = Newlist[i].endTime.split(' ')[0]
              monthTotalValue.push(Newlist[i][this.dataList])
            }
            newList.push(name)
            break
          default:
            return false
        }
        let list = []
        let item = this.arrList
        item.forEach(item1=>{
          if(item1 === item[0]){
            list.push({name:item1,type:'bar',data:monthTotalValue,itemStyle:{color:'#1b80e1'}})
          }
        })
        this.timeState = newList
        this.newObj ={
          item,
          list,
          name:`${this.stationName}`
        }
      },
      //获取周
      getWeek(a, b, c) {
        /**
         * a = d = 当前日期
         * b = 6 - w = 当前周的还有几天过完(不算今天)
         * a + b 的和在除以7 就是当天是当前月份的第几周
         */
        var date = new Date(a, parseInt(b) - 1, c),
          w = date.getDay(),
          d = date.getDate();
        if(w==0){
          w=7;
        }
        var config={
          getMonth:date.getMonth()+1,
          getYear:date.getFullYear(),
          getWeek:Math.ceil((d + 6 - w) / 7),
        }

        return `${config.getYear}年${config.getMonth}月${config.getWeek}周`
      },
      //获取季度
      quarterClick(e){
        this.timeValue = moment(e.startTime).format('YYYY-MM-DD HH:mm:ss')
        if(e.week === 0){
          this.week = '一'
        }else if(e.week === 1){
          this.week = '二'
        } else if(e.week === 2){
          this.week = '三'
        } else if(e.week === 3){
          this.week = '四'
        }
      },
      resFuntion() {
        this.cascaderValue = []
        this.dateValue = 5
        this.timeValue = new Date();
        this.getStation();
      },
      //排序
      sort(item){
        if(item.length === 0) return []
        let list = item
        list.forEach(item1=>{
          item1.startTime = +moment(item1.startTime).format('x')
        })

        list = list.reduce((item,row)=>{
          if(this.dateValue === 5 || this.dateValue === 4){
            let i = item.findIndex(c=>moment(c.startTime).month()+1 === moment(row.startTime).month()+1)
            i === -1 && item.push(row)
            i !== -1 && (item[i][this.dayList] = row[this.dayList])
          }else{
            let i = item.findIndex(c=>moment(c.startTime).date() === moment(row.startTime).date())
            i === -1 && item.push(row)
            i !== -1 && (item[i][this.dayList] = row[this.dayList])
          }
          return item
        },[])
        list.sort((a,b)=>a.startTime-b.startTime)
        list.forEach(item1=>{
          item1.startTime = moment(item1.startTime).format('YYYY-MM-DD HH:mm:ss')
        })
        return list
      },
      //转换数组
      dayLIST(list){
        if(list.length === 0) return []
        let newArr = []
        if(this.dateValue !== 1){

          for (let i = 1; i < list+1; i++) {
            newArr.push(i)
          }
        }else{
          for (let i = 0; i < list; i++) {
            newArr.push(i)
          }
        }
        return newArr
      },
      changeSelect(){
        this.timeValue =''
      },
      //转换月
      monthLIST(list){
        switch (list) {
          case 1:
            return '一月'
          case 2:
            return '二月'
          case 3:
            return '三月'
          case 4:
            return '四月'
          case 5:
            return '五月'
          case 6:
            return '六月'
          case 7:
            return '七月'
          case 8:
            return '八月'
          case 9:
            return '九月'
          case 10:
            return '十月'
          case 11:
            return '十一月'
          case 12:
            return '十二月'
          default:
            break;
        }
      },
      //转换季度
      quarterList(index){
        if(index === '一'){
          return  [1,2,3]

        }else if(index === '二'){
          return  [4,5,6]
        }else if(index === '三'){
          return  [7,8,9]
        }else if(index === '四'){
          return  [10,11,12]
        }
      },
      //转换周
      weekList(list){
        switch (list) {
          case 1:
            return '周一'
          case 2:
            return '周二'
          case 3:
            return '周三'
          case 4:
            return '周四'
          case 5:
            return '周五'
          case 6:
            return '周六'
          case 7:
            return '周日'
          default:
            break;
        }
      },
      changeCascader(){
        let arerData = this.$refs.elcascader.getCheckedNodes()[0]
        this.arerName =arerData.label
        const newList = JSON.parse(getItem('arerCodeRevenue'))
        if(newList && newList[1].regionCode === this.cascader && newList[0].index > 3){
          this.cascaderValue = this.changeDetSelect(newList[1].regionCode,this.optionsList)
          this.timeValue =  moment(newList[1].timeValue).toString()
          this.dateValue =  newList[1].dateValue
        }
      },
      async excelAll(){
        let coulm = [
          {prop:'renginCode',label:'区域编码'},
          {prop:this.dataList,label:this.dataList=== 'monthAmount'?'区域每月营收':'区域每天营收'},
          {prop:'startTime',label:'开始时间'},
          {prop:'endTime',label:'结束时间'},
        ]
        const confirm =await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e=>e)
        if(confirm !== 'confirm')return
        this.$download(this.tableData,coulm,'区域营收结果')
      },
      playName(val){
        let code = val.split(',')
        let i = code.length
        if(i === 1){
          let obj = this.optionsList.find(c=>c.value === code[0])
          return obj.label
        }else if(i === 2){
          let obj = this.optionsList.find(c=>c.value === code[0])
          let obj1 = obj.children.find(c=>c.value === code[1])
          return  obj.label + obj1.label
        }else if(i === 3){
          let obj = this.optionsList.find(c=>c.value === code[0])
          let obj1 = obj.children.find(c=>c.value === code[1])
          let obj2 = obj1.children.find(c=>c.value === code[2])
          return obj.label + obj1.label + obj2.label
        }
      },
      reduces(val){
        var i = null;
        if(val.length === 0)return []
        return val.reduce((item,row)=>{
          if(this.dateValue === 5 || this.dateValue === 4){
            i = item.findIndex(c=>moment(c.endTime).month() + 1 === moment(row.endTime).month() + 1)
          }else if(this.dateValue === 3 || this.dateValue === 1){
            i = item.findIndex(c=>moment(c.endTime).date() === moment(row.endTime).date())
          }else if(this.dateValue === 2){
            i = item.findIndex(c=>moment(c.endTime).isoWeekday() === moment(row.endTime).isoWeekday())
          }
          row.renginCode = this.cascader || this.arerCode
          i === -1 && item.push(row)
          i !== -1 && (item[i][this.dataList] += row[this.dataList])
          return item
        },[])
      }
    },
  };
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;
    .el-col {
      margin-right: 20px;
    }
  }
  /deep/.el-cascader,
  .el-select,
  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
  }
  .content-bottom{
    margin-top: 25px;
    border-top: 1px solid #e8e8e8;
    padding-top: 10px;
  }
  .content-box-title{
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 15px;
    span{
      color: #5d5d5d;
    }
    .el-button{
      margin-left: 10px;
    }
  }
</style>
