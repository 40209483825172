<template>
  <el-card>
    <el-row>
      <el-col :span="4">
        <el-input @blur="loginNumber" placeholder="请输入退款单号" v-model.trim="informationVo.frefundId"></el-input>
      </el-col>
      <el-col :span="4">
        <el-input placeholder="请输入换电订单号" v-model.trim="informationVo.forderNo"></el-input>
      </el-col>
      <el-col :span="10">
        <el-button @click="submitClient()" type="primary">查询</el-button>
        <el-button @click="removeCheck">重置</el-button>
      </el-col>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-table
        @selection-change="batchList"
        ref="clientTabel"
        :data="tableData"
        style="width: 100%"
        height="500"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="编号" :index="renIndex" type="index" width="50"></el-table-column>
        <!--        <el-table-column label="序号" prop="fid"  width="50"></el-table-column>-->
        <el-table-column prop="frefundId" label="退款单号" min-width="320"></el-table-column>
        <el-table-column prop="frunNo" label="商户订单号" min-width="200"></el-table-column>
        <el-table-column prop="forderNo" label="换电订单号" min-width="220"></el-table-column>
        <el-table-column prop="frefundAmount" label="退款金额(元)" min-width="120"></el-table-column>
        <el-table-column prop="frefillPlatform" label="付款平台" min-width="130">
          <!--1:微信支付；2:支付宝支付-->
          <template slot-scope="scope">
            <span v-if="scope.row.frefillPlatform === 1">微信支付</span>
            <span v-else-if="scope.row.frefillPlatform === 2">支付宝支付</span>
          </template>
        </el-table-column>
<!--        <el-table-column prop="frefillAmount" label="付款账号" min-width="150"></el-table-column>-->
<!--        <el-table-column prop="frefillAmount" label="付款账号" min-width="150"></el-table-column>-->
        <el-table-column prop="floginNo" label="登录号码" min-width="150"></el-table-column>
<!--        <el-table-column prop="floginType" label="登录方式" min-width="100">-->
<!--          &lt;!&ndash;0:手机号码；1:微信号码;2:网站注册号码;3:支付宝号码&ndash;&gt;-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.floginType === 0">手机号码</span>-->
<!--            <span v-else-if="scope.row.floginType === 1">微信号码</span>-->
<!--            <span v-if="scope.row.floginType ===2">网站注册号码</span>-->
<!--            <span v-else-if="scope.row.floginType === 3">支付宝号码</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column prop="ftime" label="申请时间" min-width="170"></el-table-column>
        <el-table-column prop="fstate" label="申请状态" min-width="120">
          <!--0：退款失败；1：申请中；2：处理中；3：退款完成-->
          <template slot-scope="scope">
            <span v-if="scope.row.fstate === 0">退款失败</span>
            <span v-else-if="scope.row.fstate === 1">待审核</span>
            <span v-if="scope.row.fstate ===2">审核未通过</span>
            <span v-if="scope.row.fstate ===3">审核通过</span>
            <span v-else-if="scope.row.fstate === 5">退款完成</span>
          </template>
        </el-table-column>

        <el-table-column prop="fexecutor" label="处理人" min-width="90"></el-table-column>
        <el-table-column prop="fexecuteTime" label="处理时间" min-width="190"></el-table-column>
        <el-table-column fixed="right" label="操作" min-width="280">
          <template slot-scope="scope">
            <router-link :to="'/swapRefund/'+scope.row.fid">
              <el-button v-if="scope.row.fstate !==3 && scope.row.fstate !==5" size="small" type="warning"
                         class="el-icon-edit">退款审核
              </el-button>
            </router-link>
            <el-button  v-if="scope.row.fstate ===3" @click="compileEqu(scope.row)" size="small" type="primary"
                       class="el-icon-edit">确认退款
            </el-button>
          </template>

        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[20, 25, 30]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-row>


    <!-- 新增和修改 -->
    <el-dialog :title="isClent?'新增信息':'修改信息'" :visible.sync="client" width="50%" @close="close">
      <el-form size="small" ref="clientform" :model="swapInfo" label-width="100px">

        <el-col :span="12">
          <el-form-item label="退款订单:">
            <!--        <el-input v-model="swapInfo.frefundId"></el-input>-->
            <span style="color: #409EFF">{{swapInfo.frefundId}}</span>
          </el-form-item>
          <el-form-item label="换电订单号:">
            <span style="color: #409EFF">{{swapInfo.forderNo}}</span>
            <!--        <el-input v-model="swapInfo.forderNo"></el-input>-->
          </el-form-item>
          <el-form-item label="退款金额:">
            <span style="color: #409EFF">{{swapInfo.frefundAmount}}</span>
          </el-form-item>
          <el-form-item label="付款平台:">
            <span style="color: #409EFF" v-if="swapInfo.frefillPlatform===1">微信支付</span>
            <span style="color: #409EFF" v-if="swapInfo.frefillPlatform===2">支付宝支付</span>
          </el-form-item>
          <el-form-item label="付款账号:">
            <span style="color: #409EFF">{{swapInfo.frefillAmount}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="登录号码:">
            <span style="color: #409EFF">{{swapInfo.floginNo}}</span>
          </el-form-item>
          <el-form-item label="登录方式:">
            <!--0:手机号码；1:微信号码;2:网站注册号码;3:支付宝号码-->
            <span style="color: #409EFF" v-if="swapInfo.floginType===0">手机号码</span>
            <span style="color: #409EFF" v-if="swapInfo.floginType===1">微信号码</span>
            <span style="color: #409EFF" v-if="swapInfo.floginType===2">网站注册号码</span>
            <span style="color: #409EFF" v-if="swapInfo.floginType===3">支付宝号码</span>
          </el-form-item>
          <el-form-item label="申请时间:">
            <span style="color: #409EFF">{{swapInfo.ftime}}</span>
          </el-form-item>

          <el-form-item label="申请状态:">
            <!--          <span style="color: #409EFF">{{swapInfo.fstate}}</span>-->
            <template>
              <el-select v-model="swapInfo.fstate" placeholder="请选择" style="width: 200px">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-form-item>
          <el-form-item label="处理人:">
            <span style="color: #409EFF">{{swapInfo.fexecutor}}</span>
          </el-form-item>
          <el-form-item label="处理时间:">
            <span style="color: #409EFF">{{swapInfo.fexecuteTime}}</span>
          </el-form-item>
        </el-col>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="client = false">取 消</el-button>
        <el-button type="primary" @click="changeClient">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
  import {
    getClientPage,
    newAddClient,
    chanegClient,
    removeClientMsg,
    restWord,

  } from "@/utils/operation";

  import {
    ApSwapRefundPage,
    findApSwapRefundById,
    findApSwapRefundByOrderNo,
    updateApSwapRefund,
    aliRefund,
    WXRefund
  } from "@/utils/operation2";
  import moment from "moment";
  import * as myStore from "@/utils/stroage";

  export default {
    name: "clienName",
    data() {
      var proof = (rule, value, cb) => {
        const reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        if (!value) cb(new Error("身份证不能为空"));
        if (!reg.test(value)) {
          return cb(new Error("身份证输入错误"));
        }
        return cb();
      };

      var mobile = (rule, value, cb) => {
        const Reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
        if (value === '') return cb()
        if (!Reg.test(value)) return cb(new Error("手机格式错误"))
        cb();
      };
      var licence = (rule, value, cb) => {
        const Reg = /^([京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][1-9DF][1-9ABCDEFGHJKLMNPQRSTUVWXYZ]\d{3}[1-9DF]|[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][\dABCDEFGHJKLNMxPQRSTUVWXYZ]{5})$/;
        if (value === '') return cb()
        if (!Reg.test(value)) return cb(new Error("车牌号格式错误"))
        cb();
      };

      var emailReg = (rule, value, cb) => {
        const Reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
        if (value === '') return cb()
        if (!Reg.test(value)) return cb(new Error("邮箱格式错误"))
        cb();

      };
      return {
        swapInfo: {},
        options: [
          {
            value: 0,
            label: '退款失败'
          },
          {
            value: 3,
            label: '审核通过'
          },
          {
            value: 5,
            label: '退款完成'
          }

        ],
        active: 0,
        resgiterValue: "",
        weCatValue: '',
        modileValue: '',
        userValue: '',
        dentiValue: '',
        carValue: '',
        stateValue: "",
        query: {
          pageNum: 1,
          pageSize: 20,
          respageNum: 1
        },
        isPage: true,
        total: 0,
        tableData: [],

        informationVo: {},
        loginTypeList: [
          {
            value: 0,
            label: "手机号码",
          },
          {
            value: 1,
            label: "微信号码",
          },
          {
            value: 2,
            label: "网站注册账号",
          },
        ],
        gender: [
          {
            value: 1,
            label: '男'
          },
          {
            value: 2,
            label: '女'
          },
          {
            value: 3,
            label: '保密'
          }
        ],
        isClent: true,
        userAudit: false,
        client: false,
        isStatus: false,
        clientFrom: {
          loginNo: "",
          loginPsd: '',
          loginType: 3,
          name: "",
          identifyCode: "",
          gender: 3,
          email: "",
          iphoneNo: "",
          weChatNo: "",
          carNo: "",
          homeAddr: "",
          isMember: "",
          registerPlat: 3,
          state: null,
          work: "",
          vipDate: null,
          auditState: null,
          points: null,
        },
        nameList: [],
        batchNum: [],
        clientRules: {
          loginNo: [
            {required: true, message: "请输入登录帐号", trigger: "blur"},
          ],
          loginPsd: [
            {required: true, message: "请输入登录密码", trigger: "blur"},
            {min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur'}
          ],
          loginType: [
            {required: true, message: "请选择登录方式", trigger: "change"},
          ],
          name: [{required: true, message: "请输入姓名", trigger: "blur"}],
          identifyCode: [{required: true, validator: proof, trigger: "blur"}],
          iphoneNo: [{validator: mobile, trigger: "blur"}],
          carNo: [{validator: licence, trigger: "blur"}],
          email: [{validator: emailReg, trigger: "blur"}],
          gender: [{required: true, message: "请选择性别", trigger: "change"}],
          homeAddr: [
            {required: true, message: "请输入地址", trigger: "blur"},
          ],
          isMember: [
            {required: true, message: "请选择是否是会员", trigger: "change"},
          ],
          registerPlat: [
            {required: true, message: "请选择注册平台", trigger: "change"},
          ],
          state: [
            {required: true, message: "请选择使用状态", trigger: "change"},
          ],
          auditState: [
            {required: true, message: "请选择审核进度", trigger: "change"},
          ],
          points: [{required: true, message: "请输入积分", trigger: "blur"}],
        },
        registerList: [
          {
            value: 0,
            label: "微信小程序",
          },
          {
            value: 1,
            label: "安卓APP",
          },
          {
            value: 2,
            label: "苹果APP",
          },
          {
            value: 3,
            label: "pc平台",
          },
        ],
        stateList: [
          {
            value: 0,
            label: "未使用",
          },
          {
            value: 1,
            label: "正常",
          },
          {
            value: 2,
            label: "注销",
          },
          {
            value: 3,
            label: "黑名单",
          },
        ],
        auditStateList: [
          {
            value: 0,
            label: "未注册",
          },
          {
            value: 1,
            label: "审核中",
          },
          {
            value: 2,
            label: "审核通过",
          },
          {
            value: 3,
            label: "审核未通过",
          },
        ],
        isMemberList: [
          {
            value: 1,
            label: "VIP会员",
          },
          {
            value: 0,
            label: "普通会员",
          },
        ],
      };
    },
    computed: {
      userName() {
        return JSON.parse(this.$store.state.user).username
      },
      userId() {
        return JSON.parse(this.$store.state.user).id
      },
      userroleId() {
        return JSON.parse(this.$store.state.user).roleId
      },
    },
    created() {
      this.clientFrom.registerPlat = 3
      this.submitClient();
    },
    methods: {
      compileEqu(list) {
        this.isClent = false
        this.client = true
        var data = JSON.parse(JSON.stringify(list))
        this.swapInfo = data
      },
      next() {
        if (this.active++ > 2) this.active = 0;
      },
      batchList(list) {
        this.batchNum = list.map((item) => {
          return item.id;
        });
      },
      //每页数据条数
      handleSizeChange(val) {
        this.query.pageSize = val;
        if (this.isPage) {
          this.submitClient();
        } else {
          this.submitClient();
        }
      },
      //当前页
      handleCurrentChange() {
        this.query.pageNum = val;
        if (this.isPage) {
          this.submitClient();
        } else {
          this.query.respageNum = val
          this.submitClient();
        }
      },
      renIndex(index) {
        return (this.query.pageNum - 1) * this.query.pageSize + index + 1
      },
      //添加或修改
      changeClient() {
        this.$refs.clientform.validate(async (valid) => {
          if (!valid) return
          var data = JSON.parse(JSON.stringify(this.swapInfo));
          if (data.fstate === 5) {
            if (data.frefillPlatform === 2) {
              // 支付宝退款
              const res = await aliRefund(data)
              console.log(res)
              if (res.data.code != 20000) return this.$msg.error(res.data.data.row.subMsg)
              const result = await updateApSwapRefund(data)
              if (result.data.code != 20000) return this.$msg.error("操作失败")
              if (result.data.code === 20000 && res.data.code === 20000)
              this.$msg.success("支付宝退款成功")
              this.client = false;
              this.submitClient();

            }
            if (data.frefillPlatform === 1) {
              // 微信退款
              console.log(data)
              const res = await WXRefund(data)
              console.log(res)
              if (res.data.code != 20000) return this.$msg.error(res.data.data.row)
              const result = await updateApSwapRefund(data)
              if (result.data.code != 20000) return this.$msg.error("操作失败")
              if (result.data.code === 20000 && res.data.code === 20000)
                this.$msg.success("微信退款退款成功")
              this.client = false;
              this.submitClient();
            }
          }
          const result = await updateApSwapRefund(data)
          if (result.data.code != 20000) return this.$msg.error("操作失败")
          this.$msg.success("操作成功")
          this.client = false;
          this.submitClient();

        });
      },
      close() {
        this.$refs.clientform.resetFields();
        this.clientFrom = {}
        this.isClent = true;
      },
      closeUserAudit() {
        this.$refs.userAuditform.resetFields();
        this.clientFrom = {}
        this.isClent = true;
      },
      //修改信息填充
      // compile(data) {
      //   this.isStatus = data.auditState === 2?true:false
      //   this.client = true;
      //   this.isClent = false;
      //   this.clientFrom = JSON.parse(JSON.stringify(data));
      // },
      //用户审核
      compile(data) {
        // this.isStatus = data.auditState === 2?true:false
        this.userAudit = true;
        // this.isClent = false;
        // this.clientFrom = JSON.parse(JSON.stringify(data));
      },
      //查询客户信息
      async submitClient() {
        const res = await ApSwapRefundPage(this.query.pageNum, this.query.pageSize, this.informationVo);
        console.log(res)
        if (res.data.code !== 20000) return this.$msg.error("查询信息失败!");
        this.tableData = res.data.data.rows.records;
        this.total = res.data.data.total;
        // this.isPage = false;
      },
      cleanList() {
        this.$refs.clientTabel.clearSelection();
      },
      //批量删除
      async removeAll() {
        if (this.batchNum.length === 0)
          return this.$msg.info("请选择需要删除的项");
        const config = await this.$MessageBox
          .confirm("此操作将永久删除选中项, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .catch((e) => e);
        if (config !== "confirm") return;
        try {
          const list = this.batchNum;
          const res = await removeClientMsg(list);
          console.log(res);
          if (res.status !== 200) return this.$msg.error("删除失败");
          this.tableData = this.tableData.filter((item, i) => {
            return item.id !== list[i];
          });
          this.$refs.clientTabel.clearSelection();
          if (this.query.pageNum > 1 && this.tableData.length === 0) {
            this.query.pageNum = this.query.pageNum - 1;
          }
          this.$msg.success("删除成功");
          this.submitClient();
        } catch (e) {
          console.log(e);
        }
      },
      //重置密码
      async restPassword(id) {
        const config = await this.$MessageBox
          .confirm("确定要重置密码吗, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .catch((e) => e);
        if (config !== "confirm") return;
        const res = await restWord(id)
        if (res.status !== 200) return this.$msg.error('重置密码失败')
        this.$msg.success('重置密码成功')
        this.submitClient()
      },
      removeCheck() {
        this.informationVo = {}
        this.submitClient();
      },
      loginNumber() {
        const newList = JSON.parse(getItem('client'))
        if (newList && newList[1].loginNo === this.resgiterValue && newList[0].index > 3) {
          this.resgiterValue = newList[1].loginNo
          this.weCatValue = newList[1].weChatNo
          this.modileValue = newList[1].iphoneNo
          this.userValue = newList[1].name
          this.dentiValue = newList[1].identifyCode
          this.carValue = newList[1].carNo
          this.stateValue = newList[1].state
        }
      }
    },
  };
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 10px;
    }
  }

  ul {
    width: 0;
  }

  .el-select {
    width: 100%;
  }
</style>
