<template>
  <el-card>
    <el-row>
      <el-col :span="4">
        <el-cascader
          v-model="cascaderValue"
          :options="optionsList"
          @change="changeCascader"
          :props="{checkStrictly: true}"
          placeholder="请选择省市区"
        ></el-cascader>
      </el-col>
      <el-col :span="4">
        <el-select @change="changeSelect" v-model="stationType" placeholder="请选择换电站类型">
          <el-option
            v-for="item in stationTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="stationNoValue" placeholder="请选择换电站">
          <el-option
            v-for="item in stationList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-input placeholder="请输入设备编号" v-model.trim="maintainValue"></el-input>
      </el-col>
      <el-col :span="5">
        <el-button @click="checkMain" type="primary">查询</el-button>
        <el-button @click="resetValue">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <!--      <el-button size="small" @click="maintain =true" class="el-icon-plus" type="primary"> 新增</el-button>-->
      <el-button size="small" type="danger" icon="el-icon-delete" @click="removeAll">批量删除</el-button>
    </el-row>
    <el-row>
      <el-alert show-icon type="info">
        <span slot="title">
          已选择{{batchNum.length}}项
           <span @click="cleanList" style="margin-left:10px;color:#409eff;cursor:pointer;">清空</span>
        </span>
      </el-alert>
    </el-row>
    <el-row>
      <el-table
        id="maintion"
        ref="mainTable"
        @selection-change="batchList"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="编号" :index="renIndex" type="index"></el-table-column>
        <el-table-column prop="eqmCode" label="设备编号" min-width="110"></el-table-column>
        <el-table-column prop="eqmName" label="设备名称"></el-table-column>
        <el-table-column prop="eqmModeNo" label="设备类型编号" min-width="110"></el-table-column>
        <el-table-column prop="eqmModeName" label="设备类型名称" min-width="110"></el-table-column>
        <el-table-column prop="eqmUseDptName" label="设备使用部门名称" min-width="140"></el-table-column>
        <el-table-column prop="eqmMtnDptName" label="设备维保部门名称" min-width="140"></el-table-column>
        <el-table-column prop="stationNo" label="换电站编码" min-width="170"></el-table-column>
        <el-table-column prop="eqmSvcDate" label="投入启用日期" min-width="160"></el-table-column>
        <el-table-column prop="mtnPeriod" label="普通保养周期(天)" min-width="130"></el-table-column>
        <el-table-column prop="mtnContent" label="普通保养内容" min-width="110"></el-table-column>
        <el-table-column prop="mtnRemindDays" label="提前提醒天数(天)" min-width="130"></el-table-column>
        <el-table-column prop="mtnTime" label="本次维保时间(分钟)" min-width="145"></el-table-column>
        <el-table-column prop="dmdSpecMtn" label="是否需要指定保养" min-width="140">
          <template slot-scope="scope">
            <span v-if="scope.row.dmdSpecMtn === 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column prop="creator" label="创建者"></el-table-column>
        <!--        <el-table-column label="操作" min-width="120" fixed="right">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-button-->
        <!--              @click="compileInfo(scope.row)"-->
        <!--              size="small"-->
        <!--              type="primary"-->
        <!--              icon="el-icon-edit"-->
        <!--            >编辑</el-button>-->
        <!--            &lt;!&ndash; <el-button-->
        <!--              @click="conduct(scope.row)"-->
        <!--              size="small"-->
        <!--              type="warning"-->
        <!--            >维护</el-button> &ndash;&gt;-->
        <!--          </template>-->
        <!--        </el-table-column>-->
      </el-table>
    </el-row>
    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[20, 25, 30]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-row>
    <!-- 新增和修改 -->
    <el-dialog
      :title="ismaintain?'新增维护信息':'修改维护信息'"
      :visible.sync="maintain"
      width="50%"
      @close="close"
    >
      <el-form
        size="small"
        :rules="mainFormRules"
        ref="mainForm"
        :model="mainForm"
        label-width="150px"
      >
        <el-row>
          <el-col :span="11">

            <el-form-item label="换电站编码:" prop="stationNo">
              <el-select v-model="mainForm.stationNo" placeholder="请选择换电站名称">
                <el-option
                  v-for="item in stationList1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备类型编号:" prop="eqmModeNo">
              <el-input @blur="placeInput(mainForm.eqmModeNo)" v-model="mainForm.eqmModeNo" maxlength="4"
                        placeholder="4个字母组合"></el-input>
            </el-form-item>
            <el-form-item label="设备类型名称:" prop="eqmModeName">
              <el-input v-model="mainForm.eqmModeName"></el-input>
            </el-form-item>
            <el-form-item label="设备编号:" prop="eqmCode">
              <el-input v-model="mainForm.eqmCode"></el-input>
            </el-form-item>
            <el-form-item label="设备类型:" prop="eqmName">
              <el-input v-model="mainForm.eqmName"></el-input>
            </el-form-item>
            <el-form-item label="设备使用部门编码:">
              <el-input v-model="mainForm.eqmUseDptNo"></el-input>
            </el-form-item>
            <el-form-item label="设备使用部门名称:">
              <el-input v-model="mainForm.eqmUseDptName"></el-input>
            </el-form-item>
            <el-form-item label="投入启用日期:" prop="eqmSvcDate">
              <el-date-picker
                v-model="mainForm.eqmSvcDate"
                type="datetime"
                value-format="timestamp"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="设备维保部门编码:">
              <el-input v-model="mainForm.eqmMtnDptNo"></el-input>
            </el-form-item>
            <el-form-item label="设备维保部门名称:">
              <el-input v-model="mainForm.eqmMtnDptName"></el-input>
            </el-form-item>
            <el-form-item label="提前提醒天数(天):" prop="mtnRemindDays">
              <el-input v-model="mainForm.mtnRemindDays"></el-input>
            </el-form-item>
            <el-form-item label="本次维保时间(分钟):" prop="mtnTime">
              <el-input v-model="mainForm.mtnTime"></el-input>
            </el-form-item>

            <el-form-item label="普通保养周期(天)" prop="mtnPeriod">
              <el-input v-model="mainForm.mtnPeriod"></el-input>
            </el-form-item>
            <el-form-item label="普通保养内容:" prop="mtnContent">
              <el-input type="textarea" :rows="2" v-model="mainForm.mtnContent"></el-input>
            </el-form-item>
            <el-form-item label="是否需要指定保养:" prop="dmdSpecMtn">
              <el-radio-group v-model="mainForm.dmdSpecMtn">
                <el-radio-button label="1">是</el-radio-button>
                <el-radio-button label="0">否</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="创建者:">
              <span>{{mainForm.creator}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="maintain = false">取 消</el-button>
        <el-button type="primary" @click="changeMaintain">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 维护处理 -->
    <el-dialog title="维护处理" :visible.sync="isshow" width="50%">
      <el-row style="margin:0;">
        <div class="item">
          <h3>维护站点</h3>
          <div class="item-box">
            <el-col class="item-box-center" :span="4">
              <span class="text1">设备站点:</span>
              <span>{{manageForm.stationNo}}</span>
            </el-col>
            <el-col class="item-box-center" :span="6">
              <span class="text1">维护人员:</span>
              <span></span>
            </el-col>
            <el-col class="item-box-center" :span="4">
              <span class="text1">维护部门:</span>
              <span>{{manageForm.eqmMtnDptName}}</span>
            </el-col>
          </div>
        </div>
        <div class="item">
          <h3>维护信息</h3>
          <div class="item-box">
            <ul class="item-ul">
              <li>
                <div>
                  <span class="text1">维护状态:</span>
                  <span></span>
                </div>
                <div>
                  <span class="text1">上次维护:</span>
                  <span></span>

                </div>
                <div>
                  <span class="text1">到期时间</span>
                  <span>{{manageForm.mtnTime}}:</span>
                </div>
              </li>
              <li>
                <div>
                  <span class="text1">使用部门:</span>
                  <span>{{manageForm.eqmUseDptName}}</span>
                </div>
                <div>
                  <span class="text1">维护周期:</span>
                  <span>{{manageForm.mtnPeriod}}</span>
                </div>
                <div>
                  <span class="text1">到期提醒:</span>
                  <span>{{manageForm.mtnRemindDays}}</span>
                </div>
              </li>
              <li>
                <div>
                  <span class="text1">是否需要指定维护:</span>
                  <span>{{manageForm.dmdSpecMtn}}</span>
                </div>
                <div></div>
                <div></div>
              </li>
              <li>
                <div>
                  <span class="text1">维护内容:</span>

                </div>
                <div>
                  <p>{{manageForm.mtnContent}}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="item">
          <h3>维护设备</h3>
          <div class="item-box">
            <ul class="item-ul">
              <li>
                <div>
                  <span class="text1">设备名称:</span>
                  <span>{{manageForm.eqmModeName}}</span>
                </div>
                <div>
                  <span class="text1">投入时间:</span>
                  <span>{{manageForm.eqmSvcDate}}</span>
                </div>
                <div>
                  <span class="text1">设备类型编号:</span>
                  <span>{{manageForm.eqmModeNo}}</span>
                </div>
              </li>
              <li>
                <div>
                  <span class="text1">设备类型:</span>
                  <span>{{manageForm.eqmName}}</span>
                </div>
                <div></div>
                <div></div>
              </li>
              <li>
                <div>
                  <span class="text1">设备型号编号:</span>
                  <span>{{manageForm.eqmModeNo}}</span>
                </div>
                <div>
                  <span class="text1">设备位置:</span>
                  <span>{{manageForm.stationNo}}</span>
                </div>
                <div></div>
              </li>
            </ul>
          </div>
        </div>
        <div class="item">
          <h3>维护方式</h3>
          <div class="item-box">
            <div class="item-area">
              <span class="text1">维护描述:</span>
              <div>
                <span class="text1">维护状态:</span>
                <el-select v-model="mainValue" size="small" placeholder="请选择">
                  <el-option
                    v-for="item in stateOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <el-input class="item-top" type="textarea" :rows="3" placeholder="请输入内容" v-model="textarea"></el-input>
          </div>

        </div>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isshow = false">取 消</el-button>
        <el-button type="primary" @click="isshow = false">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
  import {
    addmain,
    getMainTainPage,
    compileMain,
    deleteMain,
    getForceList,
    regionCodeSation
  } from "@/utils/station";
  import {regionData} from "element-china-area-data"
  import * as myStore from "@/utils/stroage";
  import moment from 'moment';

  export default {
    name: "mainName",
    data() {
      var verify = (rule, value, cb) => {
        var reg = /^[A-Za-z]+$/
        if (value === '') return cb(new Error('设备类型编号不能为空'))
        if (!reg.test(value)) return cb(new Error('设备类型编号不能为非字母组合'))
        return cb()
      }
      return {
        optionsList: regionData,
        cascaderValue: [],
        stationType: '',
        stationNoValue: '',
        maintainValue: '',
        stationTypeList: [
          {
            value: '0',
            label: "A类型",
          },
          {
            value: '1',
            label: "B类型",
          },
        ],
        query: {
          pageNum: 1,
          pageSize: 20,
          resPageNum: 1
        },
        total: 0,
        maintain: false,
        ismaintain: true, //新增修改切换
        isshow: false,
        isPage: true,
        batchNum: [],
        tableData: [],
        stationList: [],
        stationList1: [],
        mainForm: {
          creator: '',
          stationNo: "",
          eqmModeNo: "",
          eqmModeName: "",
          eqmCode: "",
          eqmName: "",
          eqmUseDptNo: "",
          eqmUseDptName: "",
          eqmMtnDptNo: "",
          eqmMtnDptName: "",
          eqmSvcDate: "",
          mtnPeriod: null,
          mtnContent: "",
          mtnRemindDays: null,
          mtnTime: null,
          dmdSpecMtn: "1",
        },
        manageForm: {},
        mainFormRules: {
          stationNo: [
            {required: true, message: "请输入换电站编码", trigger: "blur"},
          ],
          eqmModeNo: [
            {required: true, validator: verify, trigger: "blur"},
          ],
          eqmModeName: [
            {required: true, message: "请输入换电站类型名称", trigger: "blur"},
          ],
          eqmCode: [
            {required: true, message: "请输入设备编号", trigger: "blur"},
          ],
          eqmName: [
            {required: true, message: "请输入设备类型", trigger: "blur"},
          ],
          eqmSvcDate: [
            {required: true, message: "请输入投入启用日期", trigger: "blur"},
          ],
          mtnPeriod: [
            {required: true, message: "请输入普通保养周期", trigger: "blur"},
          ],
          mtnRemindDays: [
            {required: true, message: "请输入提前提醒天数", trigger: "blur"},
          ],
          mtnTime: [
            {required: true, message: "请输入本次维保时间", trigger: "blur"},
          ],
          dmdSpecMtn: [
            {
              required: true,
              message: "请选择是否需要指定保养",
              trigger: "change",
            },
          ],
        },
        textarea: '',
        mainValue: '',
        stateOptions: [
          {
            label: '维护中',
            value: 1
          },
          {
            label: '正常使用',
            value: 2
          },
          {
            label: '维修中',
            value: 3
          }
        ]
      };
    },
    computed: {
      username() {
        return JSON.parse(this.$store.state.user).username

      },
      cascader() {
        if (this.cascaderValue.length === 1) {
          return this.cascaderValue[0]
        } else if (this.cascaderValue.length === 2) {
          return this.cascaderValue[1]
        } else if (this.cascaderValue.length === 3) {
          return this.cascaderValue[2]
        } else {
          return ''
        }
      }
    },
    created() {
      this.getmain();
      this.getStation()
      this.mainForm.creator = this.username
    },
    methods: {
      async getmain() {
        try {
          const data = {
            page: this.query.pageNum,
            size: this.query.pageSize
          }
          const res = await getMainTainPage(data);
          if (res.status !== 200) return this.$msg.error("获取维护基础信息失败!");
          this.tableData = res.data.data.content
          this.total = res.data.data.totalElements

        } catch (e) {
          console.log(e);
        }
      },
      async getStation() {
        const res = await getForceList()
        this.stationList = res.data.data.map(item => {
          return {
            value: item.stationNo,
            label: item.stationName
          }
        })
        this.stationList1 = this.stationList
      },
      batchList(list) {
        this.batchNum = list.map(item => {
          return item.id
        })
      },
      renIndex(index) {
        return (this.query.pageNum - 1) * this.query.pageSize + index + 1
      },
      //修改信息填充
      compileInfo(data) {
        this.maintain = true
        this.ismaintain = false
        var list = JSON.parse(JSON.stringify(data))
        list.eqmSvcDate = moment(list.eqmSvcDate).utc()
        this.mainForm = list
      },
      cleanList() {
        this.$refs.mainTable.clearSelection()
      },
      async removeAll() {
        if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
        const config = await this.$MessageBox.confirm(
          "此操作将永久删除选中项, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        ).catch(e => e);
        if (config !== "confirm") return;
        try {
          const list = this.batchNum
          const res = await deleteMain(list)
          if (res.status !== 200) return this.$msg.error('删除失败')
          this.tableData = this.tableData.filter((item, i) => {
            return item.id !== list[i]
          })
          this.$refs.mainTable.clearSelection()
          if (this.query.pageNum > 1 && this.tableData.length === 0) {
            this.query.pageNum = this.query.pageNum - 1
          }
          this.$msg.success('删除成功')
          this.getmain()

        } catch (e) {
          console.log(e);
        }
      },
      handleSizeChange(val) {
        this.query.pageSize = val
        if (this.isPage) {
          this.getmain()
        } else {
          this.checkMain()
        }

      },
      handleCurrentChange(val) {
        this.query.pageNum = val
        if (this.isPage) {
          this.getmain()
        } else {
          this.query.resPageNum = val
          this.checkMain()
        }
      },
      close() {
        const data =
          {
            dmdSpecMtn: "1",
            creator: this.username
          }
        this.$refs.mainForm.resetFields()
        this.mainForm = data
        this.ismaintain = true
      },
      //新增与修改
      changeMaintain() {
        this.$refs.mainForm.validate(async (valid) => {
          if (!valid) return this.$msg.info("维护基础信息不能为空!");
          var data = JSON.parse(JSON.stringify(this.mainForm));
          data.dmdSpecMtn = +data.dmdSpecMtn;
          data.eqmSvcDate = moment(data.eqmSvcDate).format('YYYY-MM-DD HH:mm:ss')
          try {
            if (this.ismaintain) {
              const res = await addmain(data);
              if (res.status !== 200)
                return this.$msg.error("新增维护基础信息失败!");
              this.$msg.success("新增维护基础信息成功!");
              this.maintain = false;
              this.getmain();
            } else {
              const res = await compileMain(data);
              if (res.status !== 200)
                return this.$msg.error("修改维护基础信息失败!");
              this.$msg.success("修改维护基础信息成功!");
              this.maintain = false;
              this.getmain();

            }

          } catch (e) {
            console.log(e);
          }
        });
      },
      async checkMain() {
        try {
          if (this.cascader === '' && this.stationType === '' &&
            this.stationNoValue === '' && this.maintainValue === '') return
          const data = {
            regionCode: this.cascader,
            stationType: this.stationType,
            stationNo: this.stationNoValue,
            eqmCode: this.maintainValue,
            page: this.query.resPageNum,
            size: this.query.pageSize
          }
          const res = await getMainTainPage(data)
          if (res.status !== 200) return this.$msg.error('查询失败')
          this.tableData = res.data.data.content
          this.total = res.data.data.totalElements
          this.isPage = false
        } catch (e) {
          console.log(e);
        }

      },
      resetValue() {
        this.cascaderValue = []
        this.stationType = ''
        this.stationNoValue = ''
        this.maintainValue = ''
        this.isPage = true
        this.query = {
          pageNum: 1,
          pageSize: 20,
          resPageNum: 1
        }
        this.getmain()
      },
      //维护处理
      conduct(data) {
        this.isshow = true;
        this.manageForm = JSON.parse(JSON.stringify(data))
      },
      async changeCascader() {
        this.stationValue = ''
        const data = {
          regionCode: this.cascader,
          stationType: this.stationType
        }
        const res = await regionCodeSation(data)
        if (res.status !== 200) return this.$msg.error('换电站查询失败!')
        if (res.data.data.length !== 0) {
          this.stationList = res.data.data.map(item => {
            return {
              value: item.stationNo,
              label: item.stationName
            }
          })
        } else {
          this.stationList = []
        }
        if (this.stationList.length !== 0) {
          const newList = JSON.parse(getItem('maintain'))
          if (newList && newList[1].regionCode === this.cascader && newList[0].index > 3) {
            this.cascaderValue = this.changeDetSelect(newList[1].regionCode, this.optionsList)
            this.stationValue = newList[1].stationNo
            this.stationType = newList[1].stationType
          }
        }
      },
      async changeSelect() {
        this.stationValue = ''
        const data = {
          regionCode: this.cascader !== '' ? this.cascader : null,
          stationType: this.stationType !== '' ? this.stationType : null
        }
        const res = await regionCodeSation(data)
        if (res.status !== 200) return this.$msg.error('换电站查询失败!')
        if (res.data.data.length !== 0) {
          this.stationList = res.data.data.map(item => {
            return {
              value: item.stationNo,
              label: item.stationName
            }
          })
        } else {
          this.stationList = []
        }
      },
      placeInput(value) {
        this.mainForm.eqmModeNo = value.toUpperCase()
      }
    }
  };
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 20px;
    }
  }

  .el-select, .el-cascader {
    width: 100%;
  }

  li {
    list-style: none;
  }

  .clean {
    color: #1b80e1;
    margin-left: 10px;
    cursor: pointer;
  }

  .item {
    > h3 {
      font-size: 15px;
      border-left: 3px solid #2a82e4;
      padding-left: 12px;
    }

    .item-box {
      display: flex;
      width: 90%;
      padding-left: 12px;

      .item-box-center {
        flex: 1;
        margin: 0;
        border: 1px solid #ece8e8;
        padding: 6px 0 6px 5px;

      }

      .item-box-center:nth-child(2) {
        border-left: 0;
        border-right: 0;
      }
    }
  }

  .item:nth-child(1) {
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .item:nth-child(2) {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;

    > .item-box {
      height: 74%;

      .item-ul {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        li {
          flex: 1;
          list-style: none;
          display: flex;
          align-items: center;

          div {
            flex: 1;
            border: 1px solid #ece8e8;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 10px;
            height: 100%;

            &:nth-child(2) {
              border-left: 0;
              border-right: 0;
            }
          }

          &:nth-child(2) {
            div {
              border-top: 0;
              border-bottom: 0;
            }
          }

          &:nth-child(3) {
            div {
              border-bottom: 0;
            }
          }

          &:nth-child(4) {
            div {
              &:nth-child(1) {
                padding-left: 5px;

                span {
                  padding-left: 5px;
                }

              }

              &:nth-child(2) {
                flex: 2;
                border-right: 1px solid #ece8e8;

                p {
                  display: flex;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

        }

      }
    }
  }

  .item:nth-child(3) {
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;

    .item-box {
      height: 70%;

      .item-ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        li {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex: 1;
          width: 100%;

          div {
            flex: 1;
            height: 100%;
            border: 1px solid #ece8e8;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 10px;

            &:nth-child(2) {
              border-left: 0;
              border-right: 0;
            }
          }

          &:nth-child(2) {
            div {
              border-top: 0;
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  .item:nth-child(4) {
    .item-box {
      display: flex;
      flex-direction: column;

      .item-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .el-select--small {
          margin-left: 5px;
        }
      }
    }
  }

  .text1 {
    margin-right: 10px;
  }
</style>
