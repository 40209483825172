<template>
  <!-- 电能表原始数据 -->
  <div class="content">
    <div class="content-title">
      <el-row>
<!--        <el-col :span="4">-->
<!--          <el-cascader-->
<!--            v-model="cascaderValue"-->
<!--            @change="changeCascader"-->
<!--            :options="options"-->
<!--            :props="{checkStrictly: true}"-->
<!--            placeholder="请选择省市区"-->
<!--          ></el-cascader>-->
<!--        </el-col>-->
<!--        <el-col :span="4">-->
<!--          <el-select @change="changeSelect" v-model="stationType" placeholder="请选择换电站类型">-->
<!--            <el-option-->
<!--              v-for="item in stationTypeList"-->
<!--              :key="item.value"-->
<!--              :label="item.label"-->
<!--              :value="item.value"-->
<!--            ></el-option>-->
<!--          </el-select>-->
<!--        </el-col>-->
        <el-col :span="4">
          <el-select v-model="stationValue" placeholder="请选择换电站">
            <el-option
              v-for="item in stationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
<!--      </el-row>-->
<!--      <el-row>-->
        <el-col :span="2">
          <el-select @change="changeTime" v-model="dateValue" placeholder="请选择">
            <el-option
              v-for="item in dateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-date-picker
            v-model="TimeValue"
            :type="dateValue === 0?'date':'month'"
            :format="dateValue === 0?'yyyy年MM月dd日':'yyyy年MM月'"
            :placeholder="dateValue === 0?'选择日期':'选择月份'"
          ></el-date-picker>
        </el-col>
        <el-col :span="6">
          <el-button @click="search" type="primary">查询</el-button>
          <el-button @click="resFuntion">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="content-content">
      <el-tabs v-model="activeName" @tab-click="changeTabs">
        <el-tab-pane label="电能表累计值" name="0">
          <div>
            <el-row class="original">
              <oringinal-Line v-if="activeName === '0'" :newArray="newArry"></oringinal-Line>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="电能表变化值" name="1">
          <div>
            <el-row class="original">
              <i id="changeI" :class="isEcharts?'iconfont icon-zhexiantu':'iconfont icon-zhuzhuangtu' " @click="changeEcharts"></i>
              <oringinal-bar v-if="activeName === '1'" :newArray="newArray"></oringinal-bar>
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-row class="content-bottom">
      <div class="content-box-title">
        <span>{{titleName}}</span>
        <el-button type="primary" @click="excelAll" class="el-icon-download" size="small">批量导出</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column :prop="activeName === '0'?'updateTime':'startTime'" :label="activeName === '0'?'采集时刻':'开始时间'"></el-table-column>
        <el-table-column :prop="activeName === '0'?'value':'endTime'" :label="activeName === '0'?'累计值':'结束时间'" ></el-table-column>
        <el-table-column :formatter="$toDecimal2" v-if="activeName === '1'" :prop="activeName === '0'?'':'realValue'" :label="activeName === '0'?'':'变化值'" ></el-table-column>
      </el-table>
      <div style="margin-top:20px;">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 15, 20, 30]"
          :page-size="size"
          :current-page="page"
          layout="total,sizes, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </el-row>
  </div>
</template>

<script>
  import { searchTotalTime,getChangeTime } from "@/utils/energyMSApi";
  import { getForceList,regionCodeSation } from "@/utils/station";
  import * as myStore from "@/utils/stroage";
  import { regionData } from "element-china-area-data";
  import oringinalLine from "@/components/original-data/originalLine.vue";
  import oringinalBar from "@/components/original-data/originalBar.vue";
  import moment from "moment";
  export default {
    name: "basisName",
    components: {
      oringinalLine,
      oringinalBar,
    },
    data() {
      return {
        page:1,
        size:10,
        total:0,
        tableAll:[],
        resultListAll:[],
        cascaderValue: [],
        activeName: "0",
        tableData: [],
        options: regionData,
        stationTypeList: [
          {
            value: 0,
            label: "A类型",
          },
          {
            value: 1,
            label: "B类型",
          },
        ],
        dateList: [
          {
            value: 0,
            label: "按天",
          },
          {
            value: 1,
            label: "按月",
          },
        ],
        dateValue: 0,
        stationValue:'',
        stationNo:'',
        stationList: [],
        stationType: null,
        TimeValue: "",
        newArry: {},
        newArray:{},
        isEcharts:true,
        tabChange:[]
      };
    },
    computed: {
      titleName() {
        let time = this.TimeValue || new Date()
        if (this.activeName === "0") {
          if (this.dateValue === 0) {
            return moment(time).format("YYYY[年]MM[月]DD[日]") + "累计值";
          } else {
            return moment(time).format("YYYY[年]MM[月]") + "累计值";
          }
        } else {
          if (this.dateValue === 0) {
            return moment(time).format("YYYY[年]MM[月]DD[日]") + "变化值";
          } else {
            return moment(time).format("YYYY[年]MM[月]") + "变化值";
          }
        }
      },
      cascader() {
        return this.cascaderValue.join(',')
      },
      timeDefault(){
        let year = this.TimeValue || new Date()
        let timeStr = this.dateValue === 0?'day':'month'
        let startTime = moment(year).startOf(timeStr).format("YYYY-MM-DD HH:mm:ss")
        let endTime = moment(year).endOf(timeStr).format("YYYY-MM-DD HH:mm:ss")
        return {
          startTime,
          endTime
        }
      },
      dayTime(){
        let time = this.TimeValue || new Date()
        if(this.dateValue === 0){
          return 24
        }else {
          return moment(time).daysInMonth()
        }
      },
      dayTimeList(){
        let list = []
        for (let i = 0; i < 24; i++) {
          list.push(`${i < 10?'0'+i:i}:00`)
        }
        return list
      }
    },
    created() {
      this.getStation();

    },
    mounted() {},
    methods: {
      async getStation() {
        try {
          const res = await getForceList();
          this.stationList = res.data.data.map((item) => {
            return {
              value: item.stationNo,
              label: item.stationName,
            };
          });
          this.stationNo = this.stationList[0].value
          if(this.activeName === '0'){
            this.checkOriginTotal()
          }else{
            this.checkOrigin()
          }
        } catch (error) {
          console.log(error);
        }

      },
      //电能表变化值
      async checkOrigin() {
        try {
          this.tableData = []
          let station = this.stationValue || this.stationNo
          const data = {
            regionCode:this.cascader,
            stationNo:station,
            stationType:this.stationType,
            startTime:this.timeDefault.startTime,
            endTime:this.timeDefault.endTime
          }
          const res =await getChangeTime(data)
          let newList = res.data.data
          if(newList){
            newList.result = this.sort(newList.result)
            this.tabChange = newList.result
            this.changeForm(newList.result)
          }
        } catch (error) {
          console.log(error);
        }

      },
      dayLIST(list) {
        let newArr = []
        if (list.length === 0) return [];
        for (let i = 0; i < list ; i++) {
          newArr.push(i);
        }
        return newArr;
      },
      sort(list) {
        if (list.length === 0) return [];
        list.forEach((item) => {
          item.startTime = +moment(item.startTime).format("x");
        });
        list = list.sort((a, b) => a.startTime - b.startTime);
        list.forEach((item) => {
          item.startTime = moment(item.startTime).format("YYYY-MM-DD HH:mm:ss");
        });
        return list;
      },
      //累计值
      tranfromData(list) {
        let monthList = this.dayLIST(moment().daysInMonth())
        let arr = this.dayTimeList
        let state = true
        let mometime = null
        let start = null
        let end = null
        let time1 = null
        let i = null
        let newList = []
        let dataList = []
        let totalList = []
        let linkarr = []
        if(list.length !== 0){
          if(this.dateValue === 0){
            list.forEach(item=>{
              if(state){
                state = false
                start = item.updateTime
                end = moment(item.updateTime).add(15,'m').format('YYYY-MM-DD HH:mm:ss')
                mometime = moment(item.updateTime).add(15,'m').format('x')
              }
              time1 = moment(item.updateTime).format('x')
              if(time1 <= mometime){
                i = newList.findIndex(c=>c.updateTime === start)
                i === -1 && newList.push({updateTime:start,value:item.totalValue })
                i !== -1 && (newList[i].value += item.totalValue )
              }else{
                state = true
                newList[i] !== undefined && (newList[i].value += item.totalValue)
              }
            })

            linkarr = newList.map(item=>{
              let time = moment(item.updateTime).format('HH:mm')
              totalList.push(time)
              dataList.push({name:time,value:Math.floor(item.value).toFixed(2)})

              return {
                updateTime:item.updateTime,
                value:Math.floor(item.value).toFixed(2)
              }
            })
          }else{
            let map = {}
            let dest = []
            list.forEach(item=>{
              let i = moment(item.updateTime).date()
              start = moment(item.updateTime).format('YYYY-MM-DD HH:mm:ss')
              end = moment(item.updateTime).add(15,'m').format('YYYY-MM-DD HH:mm:ss')
              if(!map[i]){
                dest.push({startTime:start,endTime:end,updateTime:item.updateTime,value:item.totalValue })
                map[i] = item
              }else{
                let i = dest.findIndex(c=>moment(c.updateTime).date() === moment(item.updateTime).date())
                dest[i].value += item.totalValue
              }
            })
            monthList.forEach(item=>{
              item = item + 1
              totalList.push(item)
              let i = dest.findIndex(c=>moment(c.updateTime).date() === item)
              i === -1 && dataList.push({name:item,value:0})
              i !== -1 && dataList.push({name:item,value:dest[i].value.toFixed(2)})
            })
            linkarr = dest.map(c=>{
              return {
                updateTime:c.updateTime,
                value:c.value.toFixed(2)
              }
            })

          }
        }else{
          if(this.dateValue === 0){
            arr.forEach(item=>{
              totalList.push(item)
              dataList.push({name:item,value:0})
            })
          }else{
            monthList.forEach(item=>{
              totalList.push(item +1)
              dataList.push({name:item + 1,value:0})
            })
          }

        }
        this.tableAll = linkarr
        let obj = this.getTableData(this.page,this.size,linkarr)
        this.tableData = obj.data
        this.total = obj.length
        this.newArry = {
          totalList,
          seriesList:[{name:'电能表累计值',type: 'line',data:dataList}]
        }
      },
      changeTabs() {
        this.dateValue = 0
        this.TimeValue = ''
        if (this.activeName === "0") {
          this.checkOriginTotal();
        } else {
          this.checkOrigin();
        }
      },
      merge(list){
        return  list.reduce((item,row)=>{
          let i = item.findIndex(c=>moment(c.startTime).hours() === moment(row.startTime).hours())
          i === -1 && item.push(row)
          i !== -1 && (item[i].hourValue+row.hourValue)
          return item
        },[])

      },
      //累计值获取
      async checkOriginTotal() {
        try {
          this.tableData = []
          let station = this.stationValue || this.stationNo
          const data = {
            regionCode:this.cascader,
            stationNo:station,
            stationType:this.stationType,
            startTime:this.timeDefault.startTime,
            endTime:this.timeDefault.endTime
          }
          const res =await searchTotalTime(data)
          let newList = res.data.data
          if(newList){
            let list = this.sort(newList.result)
            this.tranfromData(list)
          }

        } catch (error) {
        }


      },
      resFuntion(){
        this.cascaderValue = []
        this.stationValue = ''
        this.stationType = null
        this.dateValue = 0
        this.TimeValue = ''
        this.getStation();
      },
      search(){
        if (this.activeName === "0") {
          const objList = {
            regionCode:this.cascader,
            stationNo:this.stationValue,
            stationType:this.stationType,
            time:moment(this.TimeValue).format('x'),
            dateValue:this.dateValue
          }
          this.storage('originalData1',objList)
          this.checkOriginTotal();
        } else {
          const objList = {
            regionCode:this.cascader,
            stationNo:this.stationValue,
            stationType:this.stationType,
            time:moment(this.TimeValue).format('x'),
            dateValue:this.dateValue
          }
          this.storage('originalData2',objList)
          this.checkOrigin();

        }
      },
      async changeCascader(){
        this.stationValue = ''
        const data ={
          regionCode:this.cascader,
          stationType:this.stationType
        }
        const res =await regionCodeSation(data)
        if(res.status !== 200)return this.$msg.error('换电站查询失败!')
        if(res.data.data.length !== 0){
          this.stationList = res.data.data.map(item=>{
            return {
              value:item.stationNo,
              label:item.stationName
            }
          })
        }else{
          this.stationList = []
        }
        if(this.stationList.length !== 0){
          if(this.activeName === '0'){
            const newList = JSON.parse(getItem('originalData1'))
            if(newList && newList[1].regionCode === this.cascader && newList[0].index > 3){
              this.cascaderValue = this.changeDetSelect(newList[1].regionCode,this.options)
              this.stationValue =  newList[1].stationNo
              this.stationType =  newList[1].stationType
              this.TimeValue =  moment(+newList[1].time).toString()
              this.dateValue =  newList[1].dateValue
            }
          }else{
            const newList = JSON.parse(getItem('originalData2'))
            if(newList && newList[1].regionCode === this.cascader && newList[0].index > 3){
              this.cascaderValue = this.changeDetSelect(newList[1].regionCode,this.options)
              this.stationValue =  newList[1].stationNo
              this.stationType =  newList[1].stationType
              this.TimeValue =  moment(+newList[1].time).toString()
              this.dateValue =  newList[1].dateValue
            }

          }
        }
      },
      async changeSelect(){
        this.stationValue = ''
        const data ={
          regionCode:this.cascader,
          stationType:this.stationType
        }
        const res =await regionCodeSation(data)
        if(res.status !== 200)return this.$msg.error('换电站查询失败!')
        if(res.data.data.length !== 0){
          this.stationList = res.data.data.map(item=>{
            return {
              value:item.stationNo,
              label:item.stationName
            }
          })
        }else{
          this.stationList = []
        }
      },
      changeEcharts(){
        this.isEcharts = !this.isEcharts
        this.activeName === '1' && this.changeForm(this.tabChange)
      },
      async excelAll(){
        let coulm = []
        let str1 = this.activeName === '0'?'updateTime':'startTime'
        let str2 = this.activeName === '0'?'value':'endTime'
        let str3 = this.activeName === '0'?'采集时刻':'开始时间'
        let str4 = this.activeName === '0'?'累计值':'结束时间'
        let str5 = this.activeName === '0'?'':'realValue'
        let str6 = this.activeName === '0'?'':'变化值'
        coulm = [
          {prop:str1,label:str3},
          {prop:str2,label:str4},
        ]
        this.activeName === '1' && coulm.push({prop:str5,label:str6})
        const confirm =await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e=>e)
        if(confirm !== 'confirm')return
        this.$download(this.activeName === '0'?this.tableAll:this.resultListAll,coulm,this.activeName === '0'?'电能表累计值':'电能表变化值')
      },
      //变化值
      changeForm(list){
        let newList = []
        let dataList = []
        let totalList = []
        let seriesList = []
        let newTime = null
        let time = this.TimeValue || new Date()
        let yeas = moment(time).format('YYYY-MM')
        let month = this.dayLIST(moment(time).daysInMonth())
        let houre = this.houres(24*4)
        if(this.dateValue === 0){
          houre.forEach((item,i)=>{
            let time = moment(item).format('HH:mm')
            let start = JSON.parse(JSON.stringify(moment(houre[i]).format('YYYY-MM-DD HH:mm:ss')))
            let end = JSON.parse(JSON.stringify(moment(houre[i+1]).format('YYYY-MM-DD HH:mm:ss')))
            totalList.push(time)
            if(list.length === 0){
              newList.push({startTime:start,endTime:end,realValue:0})
            }else{
              list.forEach(item1=>{
                let updateTime = +moment(item1.startTime).format('x')
                var index =  newList.findIndex(c=>c.startTime === start)
                if(houre[i] <= updateTime && houre[i+1] >= updateTime){
                  index === -1 && newList.push({startTime:start,endTime:end,realValue:item1.realValue})
                  index !== -1 && (newList[index].realValue += item1.realValue)
                }else{
                  index === -1 &&  newList.push({startTime:start,endTime:end,realValue:0})
                }
              })
            }

          })
        }else{
          month.forEach((item,i)=>{
            let it = `${yeas}-${item +1 < 10?'0'+(item +1):item +1}`
            let str = `${yeas}-${item+1 < 10?'0'+(item+1):item+1}`
            totalList.push(item+1)
            if(list.length === 0){
              newList.push({startTime:it,endTime:str,month:item+1,realValue:0})
            }else{
              list.forEach(item1=>{
                let updateTime = moment(item1.startTime).date()
                var index =  newList.findIndex(c=>c.startTime === it)
                if(item === updateTime){
                  index === -1 && newList.push({startTime:it,endTime:str,month:item+1,realValue:item1.realValue})
                  index !== -1 && (newList[index].realValue += item1.realValue)
                }else{
                  index === -1 && newList.push({startTime:it,endTime:str,month:item+1,realValue:0})
                }
              })
            }
          })
        }
        newList.forEach(item=>{
          newTime = this.dateValue === 0?moment(item.startTime).format('HH:mm'):item.month
          this.isEcharts?dataList.push({name:newTime,value:item.realValue.toFixed(2)}):dataList.push(item.realValue.toFixed(2))

        })
        if(this.isEcharts){
          seriesList.push({name:'电能表变化值',type: 'bar',data:dataList})
        }else{
          seriesList.push({name:'电能表变化值趋势',type: 'line',data:dataList})
        }
        if(list.length === 0){
          this.tableData = []
        }else{
          var arr = newList.map(item=>{
            if(this.dateValue === 0){
              item.startTime = moment(item.startTime).format('HH:mm:ss')
              item.endTime = moment(item.endTime).format('HH:mm:ss')
            }else{
              item.startTime = item.startTime +' 00:00:00'
              item.endTime = item.endTime+' 23:59:59'
            }
            item.realValue = Math.round(item.realValue).toFixed(2)
            return item
          })
        }
        this.resultListAll = arr
        let obj = this.getTableData(this.page,this.size,arr)
        this.tableData = obj.data
        this.total = obj.length
        this.newArray = {
          totalList,
          seriesList
        }
      },
      changeTime(){
        this.TimeValue = ''
      },
      houres(value){
        let  arr = this.dayLIST(value+1)
        let list = []
        let i = 0
        let time = this.TimeValue || new Date()
        let timeStr = this.dateValue === 0?'day':'month'
        let start = +moment(time).startOf(timeStr).format('x')

        arr.forEach(item=>{
          let matX = +moment(start).add(i,'m').format('x')
          list.push(matX)
          i += 15
        })
        return list
      },
      handleSizeChange(size){
        this.size = size
        if(this.activeName === '0'){
          let data = this.getTableData(this.page,this.size,this.tableAll)
          this.tableData = data.data
          this.total = data.length
        }else{
          let data = this.getTableData(this.page,this.size,this.resultListAll)
          this.tableData = data.data
          this.total = data.length
        }

      },
      handleCurrentChange(page){
        this.page = page
        if(this.activeName === '0'){
          let data = this.getTableData(this.page,this.size,this.tableAll)
          this.tableData = data.data
          this.total = data.length
        }else{
          let data = this.getTableData(this.page,this.size,this.resultListAll)
          this.tableData = data.data
          this.total = data.length
        }
      }
    }
  };
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;
    .el-col {
      position: relative;
      margin-right: 20px;
    }
  }
  .content {
    border: 0;
    box-shadow: none;
    background-color:#f0f2f5;

  }
  .content-title{
    background-color: #fff;
    padding: 15px;
  }
  .content-content{
    margin: 15px 0;
    padding: 15px;
    background-color: #fff;

  }
  .content-bottom{
    padding: 10px;
    background-color: #fff;
  }
  .content-box-title{
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 15px;
    span{
      color: #5d5d5d;
    }
    .el-button{
      margin-left: 10px;
    }
  }
  .el-select,
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
  .el-cascader {
    width: 100%;
  }
  .original {
    height: 300px;
    margin-top: 0;
  }
  /deep/.el-date-editor .el-range-separator {
    width: 17px;
  }
  #changeI{
    position: absolute;
    top: 0;
    right: 0%;
    cursor: pointer;
    color: #666;
    margin-right: 50px;
    z-index: 999;
  }
  #changeI:hover{
    color: #2a81e3;
  }
</style>
