<template>
  <el-card>
    <h2 style="text-align: center;">客户信息显示</h2>
    <el-steps :active="1" finish-status="success" process-status="wait" align-center style="margin-bottom: 40px;">
      <el-step title="客户信息显示"></el-step>
      <el-step title="客户汽车信息"></el-step>
      <el-step title="客户信息审核"></el-step>
    </el-steps>

    <el-divider></el-divider>


    <el-form ref="form" :model="swapInfo" label-width="90px">
      <el-col :span="12">

<!--        <el-image-->
<!--          style="width: 100px; height: 100px"-->
<!--          :src="swapInfo.fcarApc"-->
<!--        ></el-image>-->

        <el-form-item label="登录号码:">
          <!--        <el-input v-model="swapInfo.frefundId"></el-input>-->
          <span style="color: #409EFF">{{swapInfo.floginNo}}</span>
        </el-form-item>

        <el-form-item label="用户姓名:">
          <span style="color: #409EFF">{{swapInfo.fuserName}}</span>
          <!--        <el-input v-model="swapInfo.forderNo"></el-input>-->
        </el-form-item>
        <el-form-item label="身份证号码:">
          <span style="color: #409EFF">{{swapInfo.fidentifyCode}}</span>
        </el-form-item>
        <el-form-item label="登录方式:">
          <span style="color: #409EFF" v-if="swapInfo.floginType===1">微信支付</span>
          <span style="color: #409EFF" v-if="swapInfo.floginType===2">支付宝支付</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="车牌号:">
          <span style="color: #409EFF">{{swapInfo.fcarNo}}</span>
        </el-form-item>
        <el-form-item label="注册时间:">
          <span style="color: #409EFF">{{swapInfo.fvipDate}}</span>
        </el-form-item>
        <el-form-item label="审核进度:">
          <!--0:手机号码；1:微信号码;2:网站注册号码;3:支付宝号码-->
          <span style="color: #409EFF" v-if="swapInfo.fauditState===0">未注册</span>
          <span style="color: #409EFF" v-if="swapInfo.fauditState===1">审核中</span>
          <span style="color: #409EFF" v-if="swapInfo.fauditState===2">审核通过</span>
          <span style="color: #409EFF" v-if="swapInfo.fauditState===3">审核未通过</span>
        </el-form-item>
        <el-form-item label="注册平台:">
          <span style="color: #409EFF" v-if="swapInfo.fregisterPlat===0">微信小程序</span>
          <span style="color: #409EFF" v-if="swapInfo.fregisterPlat===1">安卓APP</span>
          <span style="color: #409EFF" v-if="swapInfo.fregisterPlat===2">苹果APP</span>
          <span style="color: #409EFF" v-if="swapInfo.fregisterPlat===3">pc平台</span>
        </el-form-item>
      </el-col>
    </el-form>


    <el-button style="margin-top: 12px;" @click="next">下一步</el-button>

  </el-card>
</template>

<script>
  import {
    getClientPage,
    newAddClient,
    chanegClient,
    removeClientMsg,
    restWord
  } from "@/utils/operation";
  import {
    CarBaseByCarNo,
    getCustomerRegisterByCarNo
  } from "@/utils/operation2";
  import moment from "moment";
  import * as myStore from "@/utils/stroage";

  export default {
    name: "clienName",
    data() {
      return {
        swapInfo:{},
        active: 0,
      };
    },
    computed: {
      userId(){
        return JSON.parse(this.$store.state.user).id
      }
    },
    created() {
      this.init()
    },
    methods: {

      //页面初始化方法
      async init() {
        if (this.$route.params && this.$route.params.fcarNo) {
          this.fCarNo = this.$route.params.fcarNo
          const result = await getCustomerRegisterByCarNo(this.fCarNo)
          console.log(result)
          this.swapInfo=result.data.data.rows
        }
        else {

        }
      },
      next() {
        this.$router.push({path: '/carInfo/' + this.swapInfo.fcarNo})
      },

      //根据用户id查询用户信息


    },
  };
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;
    .el-col {
      margin-right: 10px;
    }
  }
  ul {
    width: 0;
  }
  .el-select{
    width: 100%;
  }
</style>
