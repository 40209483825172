<template>
  <el-card>
    <el-row>
      <el-col :span="4">
        <el-cascader
          filterable
          v-model="cascaderValue"
          :options="optionsList"
          @change="changeCascader"
          :props="{checkStrictly: true}"
          placeholder="请选择省市区"
        ></el-cascader>
      </el-col>
      <el-col :span="4">
        <el-select @change="changeSelect" v-model="stationType" placeholder="请选择换电站类型">
          <el-option
            v-for="item in stationTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="stationValue" filterable  placeholder="请选择换电站">
          <el-option

            v-for="item in stationList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-input placeholder="请输入设备类型编号" v-model.trim="inputValue"></el-input>
      </el-col>
      <el-col :span="4">
        <el-button @click="checkBack" type="primary">查询</el-button>
        <el-button @click="resFuntion">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-button size="small" @click="backAard = true" class="el-icon-plus" type="primary"> 新建</el-button>
      <el-button size="small" @click="removeAll" type="danger" icon="el-icon-delete">选中删除</el-button>
    </el-row>
    <el-row>
      <el-alert show-icon type="info">
        <span slot="title">
          已选择{{batchNum.length}}项
          <span @click="cleanList" style="margin-left:10px;color:#409eff;cursor:pointer;">清空</span>
        </span>
      </el-alert>
    </el-row>
    <el-row>
      <el-table ref="backTable" @selection-change="batchList" :data="tableData" style="width: 100%" height="500">
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="编号" :index="renIndex" type="index" width="50"></el-table-column>
        <el-table-column prop="eqmModeNo" label="设备类型代码" min-width="110"></el-table-column>
        <el-table-column prop="faultCode" label="故障码" min-width="110"></el-table-column>
        <el-table-column prop="faultLevel" label="故障等级" min-width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.faultLevel === 1" type="danger">一级故障</span>
            <span v-else-if="scope.row.faultLevel === 2">二级故障</span>
            <span v-else type="success">三级故障</span>
          </template>
        </el-table-column>
        <el-table-column prop="faultContent" label="故障内容" :show-overflow-tooltip="true"
                         min-width="200"></el-table-column>
        <el-table-column prop="repairMethod" label="处理方法" min-width="110"></el-table-column>
        <el-table-column prop="eqmTypeCode" label="设备型号编号" min-width="110"></el-table-column>
        <el-table-column prop="eqmModeName" label="设备类型名称" min-width="110"></el-table-column>
        <el-table-column prop="stationNo" label="换电站编号" min-width="170"></el-table-column>
        <el-table-column prop="creator" label="创建者" min-width="120"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button @click="compileFault(scope.row)" size="small" type="primary" icon="el-icon-edit">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[20, 25, 30]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-row>
    <!-- 添加和修改故障信息 -->
    <el-dialog
      :title="isbackAard?'新增报警信息':'修改报警信息'"
      :visible.sync="backAard"
      @close="close"
      width="40%">
      <el-form size="small" :rules="backRules" ref="backform" label-width="120px" :model="FaultFrom">
        <el-row>
          <el-col style="margin:0;" :span="12">
            <el-form-item label="换电站编码:" prop="stationNo">
              <el-select v-model="FaultFrom.stationNo" placeholder="请选择换电站编码">
                <el-option
                  v-for="item in stationList1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备型号编号:" prop="eqmTypeCode">
              <el-input v-model="FaultFrom.eqmTypeCode"></el-input>
            </el-form-item>
            <el-form-item label="设备类型编号:" prop="eqmModeNo">
              <el-input @input="changeEqmModeNo(FaultFrom.eqmModeNo)" maxlength="4"
                        v-model="FaultFrom.eqmModeNo"></el-input>
            </el-form-item>
            <el-form-item label="设备类型名称:" prop="eqmModeName">
              <el-input v-model="FaultFrom.eqmModeName"></el-input>
            </el-form-item>

          </el-col>
          <el-col style="margin:0;" :span="12">
            <el-form-item label="故障等级:">
              <el-select v-model="FaultFrom.faultLevel" placeholder="请选择">
                <el-option
                  v-for="item in levelList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="故障码:">
              <el-input v-model="FaultFrom.faultCode"></el-input>
            </el-form-item>
            <el-form-item label="处理方法:">
              <el-input v-model="FaultFrom.repairMethod"></el-input>
            </el-form-item>
            <el-form-item label="故障内容:" prop="faultContent">
              <el-input type="textarea" v-model="FaultFrom.faultContent"></el-input>
            </el-form-item>
<!--            <el-form-item label="创建者:">-->
<!--              <span>{{FaultFrom.creator}}</span>-->
<!--            </el-form-item>-->
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="backAard = false">取 消</el-button>
          <el-button type="primary" @click="addFault">确 定</el-button>
        </span>
    </el-dialog>
  </el-card>
</template>

<script>
  import {
    getFault,
    addFaulting,
    removeFault,
    compileMsg,
    getForceList,
    regionCodeSation
  } from '@/utils/station'
  import {regionData} from "element-china-area-data"
  import * as myStore from "@/utils/stroage";
  import {mapState} from 'vuex'

  export default {
    name: 'faultMsg',
    data() {
      return {
        inputValue: '',
        cascaderValue: [],
        stationType: '',
        stationTypeList: [
          {
            value: '0',
            label: "A类型",
          },
          {
            value: '1',
            label: "B类型",
          },
        ],
        stationValue: '',
        optionsList: regionData,
        query: {
          pageNum: 1,
          pageSize: 20,
          resPageNum: 1
        },
        total: 0,
        tableData: [],
        stationList: [],
        stationList1: [],
        backAard: false,
        isbackAard: true,//控制添加与修改
        isPage: true,
        levelList: [
          {
            id: 1,
            name: '一级'
          },
          {
            id: 2,
            name: '二级'
          },
          {
            id: 3,
            name: '三级'
          }
        ],
        FaultFrom: {
          creator: '',
          eqmModeName: '',
          eqmModeNo: '',
          eqmTypeCode: '',
          faultCode: '',
          faultContent: '',
          repairMethod: '',
          faultLevel: null,
          stationNo: ''
        },
        backRules: {
          eqmModeNo: [
            {required: true, message: '请输入设备类型编号', trigger: 'blur'}
          ],
          eqmModeName: [
            {required: true, message: '请输入设备类型名称', trigger: 'blur'}
          ],
          eqmTypeCode: [
            {required: true, message: '请输入设备型号编号', trigger: 'blur'}
          ],
          stationNo: [
            {required: true, message: '请输入换电站编码', trigger: 'blur'}
          ],
          faultContent: [
            {required: true, message: '请输入故障描述', trigger: 'blur'}
          ]
        },
        batchNum: [],
        policeAll: []
      }
    },
    computed: {
      ...mapState(['user']),
      username() {
        return JSON.parse(this.$store.state.user).username

      },
      cascader() {
        if (this.cascaderValue.length === 1) {
          return this.cascaderValue[0]
        } else if (this.cascaderValue.length === 2) {
          return this.cascaderValue[1]
        } else if (this.cascaderValue.length === 3) {
          return this.cascaderValue[2]
        } else {
          return ''
        }
      }

    },
    created() {
      this.getFaultList()
      this.getStation()
      this.FaultFrom.creator = this.username
    },
    methods: {
      async getFaultList() {
        const data = {
          page: this.query.pageNum,
          size: this.query.pageSize
        }
        const res = await getFault(data)
        if (res.status !== 200) return this.$msg.error('获取换电故障信息失败!')
        this.tableData = res.data.data.content
        this.total = res.data.data.totalElements
      },
      async getStation() {
        const res = await getForceList()
        this.stationList = res.data.data.map(item => {
          return {
            value: item.stationNo,
            label: item.stationName
          }
        })
        this.stationList1 = this.stationList
      },
      batchList(list) {
        this.batchNum = list
      },
      //打开修改换电站信息
      compileFault(data) {
        this.backAard = true
        this.isbackAard = false
        this.FaultFrom = JSON.parse(JSON.stringify(data))
      },
      close() {
        const data = {
          creator: this.username
        }
        this.$refs.backform.resetFields()
        this.FaultFrom = data
        this.isbackAard = true
      },
      addFault() {
        this.$refs.backform.validate(async valid => {
          if (!valid) return this.$msg.info('报警信息不能为空!')
          try {
            const data = this.FaultFrom
            if (this.isbackAard) {
              const res = await addFaulting(data)
              console.log(res);
              if (res.status !== 200) return this.$msg.error('新增故障信息失败！')
              this.backAard = false
              this.$msg.success('新增故障信息成功！')
              this.getFaultList()

            } else {
              //修改
              const res = await compileMsg(data)
              if (res.status !== 200) return this.$msg.error('修改故障信息失败！')
              this.backAard = false
              this.$msg.success('修改故障信息成功！')
              this.getFaultList()

            }

          } catch (error) {

          }
        })

      },
      cleanList() {
        this.$refs.backTable.clearSelection()
      },
      //选中删除
      async removeAll() {
        if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
        const config = await this.$MessageBox.confirm(
          "此操作将永久删除选中项, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        ).catch(e => e);
        if (config !== "confirm") return;
        try {
          const list = this.batchNum.map(item => {
            return item.id
          })
          const res = await removeFault(list)
          if (res.status !== 200) return this.$msg.error('删除失败')
          this.tableData = this.tableData.filter((item, i) => {
            return item.id !== list[i]
          })
          this.$refs.backTable.clearSelection()
          if (this.query.pageNum > 1 && this.tableData.length === 0) {
            this.query.pageNum = this.query.pageNum - 1
          }
          this.$msg.success('删除成功')
          this.getFaultList()
        } catch (error) {

        }
      },
      renIndex(index) {
        return (this.query.pageNum - 1) * this.query.pageSize + index + 1
      },
      handleSizeChange(val) {
        this.query.pageSize = val
        if (this.isPage) {
          this.getFaultList()
        } else {
          this.checkBack()
        }

      },
      handleCurrentChange(val) {
        this.query.pageNum = val
        if (this.isPage) {
          this.getFaultList()
        } else {
          this.query.resPageNum = val
          this.checkBack()
        }
      },
      async checkBack() {
        try {
          if (this.cascader === '' && this.stationType && this.stationValue && this.inputValue) return
          const data = {
            regionCode: this.cascader,
            stationType: this.stationType,
            stationNo: this.stationValue,
            eqmModeNo: this.inputValue
          }
          this.storage('backward', data)
          data.page = this.query.resPageNum
          data.size = this.query.pageSize
          const res = await getFault(data)
          if (res.status !== 200) return this.$msg.error('查询失败')
          this.tableData = res.data.data.content
          this.total = res.data.data.totalElements
          this.isPage = false

        } catch (error) {

        }
      },
      async changeCascader() {
        this.stationValue = ''
        const data = {
          regionCode: this.cascader,
          stationType: this.stationType
        }
        const res = await regionCodeSation(data)
        if (res.status !== 200) return this.$msg.error('换电站查询失败!')
        if (res.data.data.length !== 0) {
          this.stationList = res.data.data.map(item => {
            return {
              value: item.stationNo,
              label: item.stationName
            }
          })
        } else {
          this.stationList = []
        }
        if (this.stationList.length !== 0) {
          const newList = JSON.parse(getItem('backward'))
          if (newList && newList[1].regionCode === this.cascader && newList[0].index > 3) {
            this.cascaderValue = this.changeDetSelect(newList[1].regionCode, this.optionsList)
            this.stationValue = newList[1].stationNo
            this.stationType = newList[1].stationType
            this.inputValue = newList[1].eqmModeNo
          }
        }
      },
      async changeSelect() {
        this.stationValue = ''
        const data = {
          regionCode: this.cascader,
          stationType: this.stationType
        }
        const res = await regionCodeSation(data)
        if (res.status !== 200) return this.$msg.error('换电站查询失败!')
        if (res.data.data.length !== 0) {
          this.stationList = res.data.data.map(item => {
            return {
              value: item.stationNo,
              label: item.stationName
            }
          })
        } else {
          this.stationList = []
        }
      },
      resFuntion() {
        this.cascaderValue = []
        this.stationValue = ''
        this.stationType = ''
        this.inputValue = ''
        this.isPage = true
        this.query = {
          pageNum: 1,
          pageSize: 20,
          resPageNum: 1
        }
        this.getFaultList()
        this.getStation()
      },
      changeEqmModeNo(val) {
        this.FaultFrom.eqmModeNo = val.toLocaleUpperCase()
      }
    }
  }
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 20px;
    }
  }

  .el-select, .el-cascader {
    width: 100%;
  }
</style>
