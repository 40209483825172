<template>
    <div class="app-container">
        <el-row class="main-flex">

            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="参数名称：">
                                <el-input  placeholder="请输入参数名称" clearable
                                           v-model.trim="query.name"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="参数代码：">
                                <el-input placeholder="请输入参数代码" clearable v-model.trim="query.code"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="状态：">
                                <el-select v-model="query.status" placeholder="请选择状态">
                                    <el-option
                                        v-for="item in statusList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                        @change="$forceUpdate()"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="4">
                        <div class="btn-end">
                            <span class="btn-rest">
                         <el-button @click="resetSearch" plain >重置</el-button>

                            </span>
                            <span class="btn-submit">
                           <el-button @click="getConfigPage" type="primary" plain >查询</el-button>

                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            参数列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                          已选择{{ batchList.length }}项
                            <span @click="cleanList" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                              <el-button @click="batchDelete" size="small" plain
                                         v-hasCode="['system:config:delete']"> 删除
                    </el-button>
                            </span>
                            <span class="add-btn">
                               <el-button size="small" @click="addConfig" type="primary" plain
                                          v-hasCode="['system:config:add']"> 新增
                    </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table ref="configTable" @selection-change="batchListChange" :data="tableData"
                                       :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo" height="100%">

                                <el-table-column align="center" type="selection" min-width="40" fixed></el-table-column>
                                <el-table-column align="center" label="编号" :index="renIndex" min-width="80" type="index"></el-table-column>
                                <el-table-column align="center" prop="name" label="参数名称" min-width="180"  show-overflow-tooltip></el-table-column>
                                <el-table-column align="center" prop="code" label="参数代码" min-width="200" show-overflow-tooltip></el-table-column>
                                <el-table-column align="center" prop="value" label="参数值"  min-width="180" show-overflow-tooltip></el-table-column>

                                <el-table-column align="center" prop="status" label="当前状态" min-width="80">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.status === 1"><i class="start" style="background-color: #1890ff;"></i> 已启用</span>
                                        <span v-else-if="scope.row.status === 0"><i class="start" style="background-color:#8c939d;"></i> 已禁用</span>
                                        <span v-else><i class="start" style="background-color:#E6A23C;"></i>未知</span>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" label="服务状态设置" width="110">
                                    <template slot-scope="scope">
                                        <el-switch
                                            @change="updateStatus(scope.row)"
                                            v-model="scope.row.status"
                                            v-hasCode="['system:config:statusSet']"
                                            :active-value=1
                                            :inactive-value=0
                                            active-color="#0D6CE4"
                                            inactive-color="#FF6165">
                                        </el-switch>
                                    </template>
                                </el-table-column>


                                <el-table-column align="center" prop="createTime" label="创建时间" min-width="180"></el-table-column>
                                <el-table-column align="center" prop="updateTime" label="更新时间" min-width="180"></el-table-column>

                                <el-table-column align="center" label="操作" min-width="180" fixed="right">
                                    <template slot-scope="scope">
                                        <span @click="handleEdit(scope.row)"
                                              class="edit-style"
                                              v-hasCode="['system:config:update']"> 编辑
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>

                        </el-row>
                        <el-col :span="24">
                            <el-row class="pagin">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="query.pageNum"
                                    :page-sizes="[10, 30, 50,100]"
                                    :page-size="query.pageSize"
                                    layout="total, sizes, prev, pager, next"
                                    :total="total"
                                ></el-pagination>
                            </el-row>
                        </el-col>

                    </el-col>
                </div>
            </el-col>
          </el-row>

        <!-- 添加参数 -->
        <el-dialog :title="isAdd?'添加参数信息':'修改参数信息'" @close="close"
                   :visible.sync="addDialog" width="60%">
            <el-form size="middle" ref="addForm" :rules="addRules" :model="addForm" label-width="100px">
                <el-row>
                    <el-col>

                        <el-form-item label="参数名称:" prop="name">
                            <el-input v-model="addForm.name" placeholder="请输入参数名称"></el-input>
                        </el-form-item>
                        <el-form-item label="参数代码:" prop="code">
                            <el-input v-model="addForm.code" placeholder="请输入参数名称" :disabled="!isAdd"></el-input>
                        </el-form-item>

                        <el-form-item label="参数值:" prop="value">
                            <el-input type="textarea" :rows="3"  v-model="addForm.value" placeholder="请输入参数值" maxlength="200" show-word-limit></el-input>
                        </el-form-item>

                        <el-form-item label="是否启用" prop="status">
                            <el-radio-group v-model="addForm.status">
                                <el-radio v-for="item in statusList" :key="item.value" :label="item.value">
                                    {{ item.label }}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="备注信息:" prop="remark">
                            <el-input type="textarea" :rows="6"  v-model="addForm.remark" placeholder="备注信息" maxlength="200" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="addFunction">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    sysConfigPage,
    sysConfigAdd,
    sysConfigModify,
    sysConfigBatchDel, sysConfigStatusSet
} from "@/utils/user";
export default {
    name: "sysConfig",
    components: {},
    data() {
        return {
            tableData: [],
            query: {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1,
                name:"",
                code:"",
                status:null
            },

            total: 0,
            isPage: true,
            clientHeight:0,
            statusList: [
                {
                    value: 0,
                    label: '禁用'
                },
                {
                    value: 1,
                    label: '启用'
                }
            ],

            addForm: {
                id:"",
                code:"",
                name:"",
                value:"",
                remark:"",
                status:null
            },

            addRules: {
                code: [
                    {required: true, message: '请输入参数代码', trigger: 'blur'},
                    {min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur'}
                ],
                name: [
                    {required: true, message: '请输入参数名称', trigger: 'blur'},
                    {min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur'}
                ],
                value: [
                    {required: true, message: '请输入参数值', trigger: 'change'},
                ],
                status: [
                    {required: true, message: '请选择启用状态', trigger: 'change'},
                ],
                remark: [
                    {required: false, message: '请输入参数名称', trigger: 'blur'},
                    {max: 200, message: '长度在 2 到 30 个字符', trigger: 'blur'}
                ],
            },

            batchList:[],
            isDisabled: false,
            isAdd: false, //是否新增标志
            addDialog:false

        };
    },
    computed: {
    },

    created() {
        this.getConfigPage();
    },
    mounted() {
    },
    // 组件方法
    methods: {
        headerStyleEvent({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row){
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        //获取配置信息
        async getConfigPage(){
            try {
                const data = {
                    page: this.query.pageNum,
                    size: this.query.pageSize
                };
                if(this.query.name !== ""){
                    data.name = this.query.name;
                }
                if(this.query.code !== ""){
                    data.code = this.query.code;
                }
                if(this.query.status !== "" && this.query.status !== null){
                    data.status = this.query.status;
                }

                const res = await sysConfigPage(data)
                if (res.data.code !== 200) return this.$msg.error("获取参数列表失败");
                this.tableData = res.data.data.list;
                this.total = res.data.data.total;
            } catch (e) {
            }
        },

        //新增与修改
        addFunction() {
            this.$refs.addForm.validate(async (valid) => {
                if (!valid) return this.$msg.info("参数信息不能为空");
                try {
                    let data = JSON.parse(JSON.stringify(this.addForm))
                    if (this.isAdd) {
                        let res = await sysConfigAdd(data);
                        if (res.data.code != 200) return this.$msg.error("参数信息添加失败!" + res.data.msg);
                        this.$msg.success("参数信息添加成功");
                        this.addDialog = false;
                        this.getConfigPage();
                    } else {
                        let res = await sysConfigModify(data);
                        if (res.status != 200) return this.$msg.error("参数信息修改失败");
                        this.$msg.success("参数信息修改成功");
                        this.addDialog = false;
                        this.getConfigPage();
                    }
                } catch (e) {
                }
            });
        },

        close(){
            this.addDialog = false;
            this.isAdd = false;
            this.resetForm()
        },

        addConfig(){
            this.resetForm();
            this.isAdd = true;
            this.addDialog = true;
        },

        //修改信息填充
        handleEdit(row) {
            this.addDialog = true;
            this.isAdd = false;
            let rowData = this.copyDeep(row);
            this.addForm = rowData;
        },

        batchListChange(rows){
            this.batchList = rows;
        },

         updateStatus(row){
            let res =  sysConfigStatusSet(row.id);
            if (res.data.code != 200){
                this.$msg.error("参数状态修改失败");
            }else{
                this.$msg.success("参数状态修改成功");
            }
            this.getConfigPage();
        },

        copyDeep(templateData) {
            // templateData 是要复制的数组或对象，这样的数组或者对象就是指向新的地址的
            return JSON.parse(JSON.stringify(templateData));
        },

        //删除参数信息
        async batchDelete() {
            if (this.batchList.length === 0) return this.$msg.info('请选择需要删除的账号')
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除配置信息, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const list = this.batchList.map(item => {
                    return item.id
                })
                const res = await sysConfigBatchDel(list)
                if (res.status !== 200) return this.$msg.error('删除参数信息失败!')
                this.getConfigPage()
            } catch (error) {

            }
        },

        resetSearch() {
            this.query = {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1,
                name:"",
                code:"",
                status:null
            };
            this.resetForm();
            this.getConfigPage()
        },

        resetForm() {
            this.addForm = {
                id:"",
                code:"",
                name:"",
                value:"",
                remark:"",
                status:null
            }
        },

        //几条每页
        handleSizeChange(val) {
            this.query.pageSize = val
            this.getConfigPage();
        },

        //当前选中页
        handleCurrentChange(val) {
            this.query.pageNum = val
            this.getConfigPage();
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        cleanList() {
            this.$refs.configTable.clearSelection()
        },


    },


};
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}
.el-table {
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 10px; /*滚动条宽度*/
      height: 10px; /*滚动条高度*/
  }
  /*定义滚动条轨道 内阴影+圆角*/
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
    //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
      border-radius: 10px; /*滚动条的背景区域的圆角*/
      background-color: #fff; /*滚动条的背景颜色*/
  }
  /*定义滑块 内阴影+圆角*/
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
      border-radius: 10px; /*滚动条的圆角*/
      background-color: #dddee0; /*滚动条的背景颜色*/
  }
}


//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor:pointer;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>

