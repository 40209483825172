<template>
  <el-card>
      <el-row>

      <el-col  :span="4">
        <el-input placeholder="请输入会员登录号码"  v-model.trim="inputValue"></el-input>
      </el-col>
      <el-col :span="10">
        <el-button @click="submitClient" type="primary">查询</el-button>
        <el-button @click="resFuntion" >重置</el-button>
      </el-col>
    </el-row>
    <!-- 操作区域 -->
    <el-row>
      <el-button size="small" @click="member = true" class="el-icon-plus" type="primary"> 新增</el-button>
      <el-button @click="removeAll"  size="small" class="el-icon-delete" type="danger"> 选中删除</el-button>
    </el-row>
    <el-row>
       <el-alert show-icon type="info">
        <span slot="title">
          已选择{{batchNum.length}}项
           <span @click="cleanList" style="margin-left:10px;color:#409eff;cursor:pointer;">清空</span>
        </span>
      </el-alert>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-table ref="memberTable" @selection-change="batchList" :data="tableData" style="width: 100%">
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="编号"  :index="renIndex" type="index" width="50"></el-table-column>
        <el-table-column prop="loginNo" label="会员登录号码"></el-table-column>
        <el-table-column prop="level" label="会员等级" >
          <template slot-scope="scope">
            <span v-if="scope.row.level === 0">普通会员</span>
            <span  v-else>VIP会员</span>
          </template>
        </el-table-column>
        <el-table-column prop="points" label="积分"></el-table-column>
         <el-table-column prop="vipDate" label="注册时间"  :formatter="$dateFormat"></el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button @click="compile(scope.row)" size="small" type="primary" class="el-icon-edit">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
       <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNum"
      :page-sizes="[20, 25, 30]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </el-row>
    <!-- 新增和修改 -->
    <el-dialog
        :title="ismember?'新增会员信息':'修改会员信息'"
        :visible.sync="member"
        width="30%"
        @close="close">
         <el-row>
           <el-col :span="20">
              <el-form size="small" :rules="memberRules" ref="memberForm" :model="clientFrom" label-width="120px">
                <el-form-item label="会员登录号码:" prop="loginNo">
                    <el-input v-model="clientFrom.loginNo"></el-input>
                  </el-form-item>
                  <el-form-item label="积分:" prop="points">
                  <el-input class="points-input" v-model.number="clientFrom.points"></el-input>
                </el-form-item>
                <el-form-item label="会员等级:" prop="level">
                    <el-select v-model="clientFrom.level" placeholder="请选择">
                            <el-option
                              v-for="item in levelist"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                  </el-form-item>
          </el-form>
           </el-col>
         </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="member = false">取 消</el-button>
          <el-button type="primary" @click="changeClient">确 定</el-button>
        </span>
      </el-dialog>
  </el-card>
</template>

<script>
import {
  getMemberPage,
  addmember,
  changeMember,
  removeMember,
   } from '@/utils/operation2'
   import moment from 'moment'

export default {
  name:'memberName',
    data(){
      return{
        inputValue:'',
        query:{
          pageNum:1,
          pageSize:20,
          resPageNum:1
        },
        total:0,
        tableData:[],
        ismember:true,
        member:false,
        isPage:true,
        clientFrom:{
              loginNo:'',
              level:null,
              points:null,
        },
        batchNum:[],
        memberRules:{
            loginNo:[
                { required: true, message: '请输入登录帐号', trigger: 'blur' }
            ],
            level:[
               { required: true, message: '请选择会员等级', trigger: 'change' }
            ],
            points:[
               { required: true, message: '请输入会员积分', trigger: 'blur' }
            ]
        },
        levelist:[
          {
            value:0,
            label:'普通会员'
          },
          {
            value:1,
            label:'VIP会员'
          }
        ],
        cascaderValue:''
      }
    },
    created() {
      this.getClient()
    },
    methods:{
     async getClient(){
      try {
         const data ={
         page:this.query.pageNum,
         size:this.query.pageSize
       }
        const res =await getMemberPage(data)
         if(res.status !== 200)return this.$msg.error('获取会员信息失败!')
         this.tableData = res.data.data.content
         this.total = res.data.data.totalElements

      } catch (error) {

      }
      },
      batchList(list){
        this.batchNum = list.map(item=>{
          return item.id
        })
      },
      //每页数据条数
      handleSizeChange(val){
           this.query.pageSize = val
           if(this.isPage){
            this.getClient()
           }else{
             this.submitClient()
           }

      },
      //当前页
      handleCurrentChange(){
            this.query.pageNum = val
            if(this.isPage){
            this.getClient()
           }else{
             this.query.resPageNum = val
             this.submitClient()
           }
      },
       renIndex(index){
      return (this.query.pageNum -1 ) * this.query.pageSize + index +1
    },
      //添加或修改
      changeClient(){
       this.$refs.memberForm.validate(async valid=>{
         if(!valid)return this.$msg.error('新增会员信息不能为空!')
        try {
           const data = JSON.parse(JSON.stringify(this.clientFrom))
         data.vipDate = moment().format('YYYY-MM-DD HH:mm:ss')
         if(this.ismember){
               const res =await addmember(data)
                 if(res.status !== 200 && res.data.message === '已存在')return this.$msg.warning('会员信息已存在，请重新添加!')
                 if(res.status !== 200 && res.data.message !== '已存在')return this.$msg.error('新增会员信息失败!')
                 this.$msg.success('新增会员信息成功!')
                 this.member = false
                 this.getClient()

             }else{
               const res =await changeMember(data)
                if(res.status !== 200)return this.$msg.error('修改会员信息失败!')
                this.$msg.success('修改会员信息成功!')
                 this.member = false
                 this.getClient()
             }

        } catch (error) {

        }
       })

      },
      close(){
        this.$refs.memberForm.resetFields()
         this.clientFrom = {}
         this.ismember = true
      },
      //修改信息填充
      compile(data){
         this.member = true
         this.ismember = false
         this.clientFrom = JSON.parse(JSON.stringify(data))
      },
      //查询会员信息
     async submitClient(){
         try {
            if(this.inputValue === '') return
          const data = {
             loginNo:this.inputValue,
             page:this.query.resPageNum,
             size:this.query.pageSize
          }
          const res =await getMemberPage(data)
          if(res.status !== 200)return this.$msg.error('查询会员信息失败!')
            this.tableData = res.data.data.content
            this.total = res.data.data.totalElements
            this.isPage = false

         } catch (error) {

         }
      },
      resFuntion(){
        this.inputValue = ''
        this.isPage = true
        this.query ={
          pageNum:1,
          pageSize:20,
          resPageNum:1
        }
        this.getClient()
      },
      cleanList(){
      this.$refs.memberTable.clearSelection()
    },
     async removeAll(){
        if(this.batchNum.length === 0)return this.$msg.info('请选择需要删除的项')
       const config = await this.$MessageBox.confirm(
        "此操作将永久删除选中项, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(e => e);
      if (config !== "confirm") return;
       try {
         const list = this.batchNum
       const res =await removeMember(list)
       if(res.status !== 200)return this.$msg.error('删除失败')
        this.tableData = this.tableData.filter((item,i)=>{
           return item.id !== list[i]
        })
       this.$refs.memberTable.clearSelection()
       if(this.query.pageNum > 1 && this.tableData.length === 0){
         this.query.pageNum = this.query.pageNum -1
       }
       this.$msg.success('删除成功')
       this.getClient()

       } catch (e) {
          console.log(e);
       }
     }

    }
  }
</script>

<style lang='less' scoped>
.el-row{
  margin: 15px 0;
  .el-col{
    margin-right: 10px;
  }
}
.el-select{
  width: 100%;
}
</style>
