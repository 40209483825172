<template>
  <el-card>
    <el-row style="margin:0;">
      <div class="auth-title">车队绑定<i @click="goClick" class="iconfont icon-fanhui"></i></div>
    </el-row>


    <div class="auth-content-name">
      <span>运营商名称：</span>
      <span>{{operatorData.name}}</span>
    </div>

    <!--
 <el-row>
   <el-col :span="4">
     <el-input placeholder="请输入车队名称" v-model.trim="vehicleValue" clearable></el-input>
   </el-col>

   <el-col :span="10">
     <el-button type="primary" @click="queryFunction">查询</el-button>
     <el-button @click="restFunction">重置</el-button>
   </el-col>
 </el-row>
 -->


    <el-row>
      <el-button type="primary" size="small" class="el-icon-plus" @click="addDialogFunction">新增绑定</el-button>
    </el-row>

    <el-table
      :data="tableData"
      style="width: 100%"
      ref="multipleTable"
      height="500"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column type="index"  :index="renIndex" label="编号" min-width="50"></el-table-column>
      <el-table-column prop="teamName" label="车队名称" min-width="100"></el-table-column>
      <el-table-column prop="principal" label="负责人" min-width="110"></el-table-column>
      <el-table-column prop="contactWay" label="联系方式"  min-width="180"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="110" :formatter="$dateFormat"></el-table-column>
      <el-table-column label="操作" min-width="180" fixed="right">
        <template slot-scope="scope">
          <el-button size="middle"  @click="unBinds(scope.row)" type="primary" icon="el-icon-setting">解除绑定</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--
    <el-row style="margin:0;">
      <div class="information" v-for="item in tableList" :key="item.id">
          <el-checkbox  v-model="item.checkAll"  class="parentLable" @change="handleCheckAllChange(item)" >{{item.menuName}}</el-checkbox>
         <el-checkbox-group  v-model="checkList" >
             <el-checkbox  @change="handleCheckedCitiesChange(item,item1)" v-for="item1 in item.children" :key="item1.id" :label="item1.id">{{item1.menuName}}</el-checkbox>
         </el-checkbox-group>
      </div>
    </el-row>
    <el-row class="auth-buttom">
      <el-button @click="authBinding" size="small" type="primary">提交</el-button>
      <el-button @click="resFuntion" size="small">重置</el-button>
    </el-row>
    -->

    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[10,20, 25, 30]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPages"
      ></el-pagination>
    </el-row>

    <el-dialog :title="'新增车队绑定'" :visible.sync="addDialog" @close="close" width="50%">
      <el-form size="middle" ref="addForm"  :model="addForm" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="11">
            <el-form-item label="车队名称:" prop="teamName">
              <el-input v-model="addForm.teamName"  @input ="inputFunc($event)"></el-input>
            </el-form-item>
          </el-col>

          <br>
          <ul class="selectLike">
            <li v-for="(item,i) in searchResult" :key= "i"  style="border-bottom: solid #ccc 1px;margin-left: 25px;" class="liBind" @click="selectName($event)">
              <span></span>
              <span class="spanBind2"  v-if="item.id === 0" >{{item.teamName}}</span>
              <span class="spanBind"  v-else>{{item.teamName}}</span>
              <span></span>
            </li>
          </ul>

        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="addFunction">确 定</el-button>
      </span>
    </el-dialog>

  </el-card>
</template>

<script>
import {bindOperatorTeams, operatorTeamBindsPage, unBindOperatorTeams,} from "@/utils/operatorApi";
import {getTeamList} from "@/utils/vehiclApi";
export default {
  name:'teamBinds',
  data(){
    return{
      vehicleValue:null,
      vehicleTeamNameValue:"",
      addDialog: false,
      searchResult:[],//搜索列表的数组
      index: 0,
      time: null,
      operatorData:null,
      tips:"未查询到相关车队信息，请重新输入车队名称!",

      query: {
        pageNum: 1,
        pageSize: 10,
        resPageNum:1
      },
      tableData: [],
      totalPages: 0,

      addForm: {
        teamName:"",
      },//编辑或者提交的表格信息
      addForm2:{
        teamIds:[],
        operatorId:null,
      },
      delForm:{
        teamIds:[],
        operatorId:null,
      },

      rules: {
        teamName: [
          { required: true, message: "请输入车队名称", trigger: "blur" },
        ],
      },
    }
  },
  created(){
    //接收页面跳转参数
    this.operatorData = JSON.parse(this.$route.query.data)
    this.addForm2.operatorId = this.operatorData.id
    this.delForm.operatorId = this.operatorData.id
    //console.log("------页面传递进来的参数信息----",this.operatorData)
    this.getPage()

  },
  computed:{
  },
  methods: {

    //分页查询
    async getPage() {
      try {
        let data = { page: this.query.pageNum, size: this.query.pageSize,operatorId:this.addForm2.operatorId};
        let res = await operatorTeamBindsPage(data);
        if (res.status !== 200) return tis.$msg.error("获取车队信息失败");
        let datas = res.data.data.content;
        this.totalPages = res.data.data.totalElements;
        this.tableData = datas;
      } catch (error) {
      }
    },


    async queryFunction() {
      try {
        let data = { page: this.query.pageNum, size: this.query.pageSize };
        data.name = this.vehicleTeamNameValue

        let res = await this.getPage(data);
        if (res.status != 200) return this.$msg.error("查询失败");
        this.tableData = res.data.data.content;
        this.totalPages = res.data.data.totalElements;
        this.isPage = false;
      } catch (error) {}
    },

    //重置
    restFunction() {
      this.vehicleTeamNameValue = "";
      this.isPage = true;
      this.query ={
        pageNum:1,
        pageSize:10,
        resPageNum:1
      }
      this.getPage();
    },

    addDialogFunction() {
      this.searchResult = [];
      this.addForm2.teamIds=[];
      clearTimeout(this.time);
      this.addDialog = true;
    },
    // 分页事件
    handleSizeChange(val) {
      this.query.pageSize = val;
      if (this.isPage) {
        this.getPage();
      } else {
        this.queryFunction();
      }
    },


    //新增与修改
    addFunction() {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.$refs.addForm.validate(async (valid) => {
        if (!valid) return this.$msg.info("车队信息不能为空");

        /**
        this.searchResult.forEach(item => {
          if (item.teamName.trim() === this.addForm.teamName.trim()){
            this.addForm2.teamIds.push(item.id)
          }
        })**/

        this.searchResult.some(item=>{
          if(item.teamName.trim() === this.addForm.teamName.trim()){
            this.addForm2.teamIds.push(item.id)
            return true;
          }
          })

        //循环遍历searchResult时匹配id和teamName失败，未正确获取到编号
        if (this.addForm2.teamIds.length < 1) return this.$msg.info("获取车队编号失败！");
        try {
            let data = JSON.parse(JSON.stringify(this.addForm2))
            let res = await bindOperatorTeams(data);
            if (res.data.code != 200) return this.$msg.error("绑定车队失败！");
            this.$msg.success("绑定车队成功！");
            this.addDialog = false;
            this.getPage();

        } catch (e) {
        }
      });
    },

    async inputFunc(event) {
      let name = event.trim();
      if(name === "" || name === null || name === undefined){
        return
      }
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(async () => {
        let newList = [];
        data.url = "?teamName=" + name;
        const result = await getTeamList(data)
        if (result.data.code == 200) {
          newList= result.data.data;
          if(newList.length >0){
            this.searchResult =newList;
          }else{
            let context = this.tips
            this.searchResult =[{id:0,teamName: context}];
          }
        }
      }, 1000);
    },

    selectName(event) {
      console.log(event)
      this.addForm2.teamIds=[];
      //console.log(event.target.innerText)
      let teamName = event.target.innerText.trim()
      if(teamName === this.tips){
        return;
      }
      this.addForm.teamName = teamName
    },

    async unBinds(row){
      this.delForm.teamIds=[];
      const config = await this.$MessageBox.confirm(
        "此操作将取消车队绑定,是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((e) => e);
      if (config !== "confirm") return;
      this.delForm.teamIds.push(row.id)

      let data = JSON.parse(JSON.stringify(this.delForm))
      const result = await  unBindOperatorTeams(data)
      if (result.data.code != 200) return this.$msg.error("解绑车队失败！");
      this.$msg.success("解绑车队成功！");
      this.getPage()

    },



    handleCurrentChange(val) {
      this.query.pageNum = val;
      if (this.isPage) {
        this.getPage();
      } else {
        this.query.resPageNum = val
        this.queryFunction();
      }
    },

    close() {
      const data = {
      };
      this.$refs.addForm.resetFields();
      this.addForm = data;
      this.isAdd = true;
      this.addDialog = false
    },

    renIndex(index){
      return (this.query.pageNum -1 ) * this.query.pageSize + index +1
    },

    goClick(){
      this.$router.go(-1)
    }
  }
}
</script>

<style lang='less' scoped>
.el-row{
  margin: 15px 0;

}
.spanBind{
  margin: 15px 0;
}

.spanBind2{
  margin: 15px 0;
  align-content: center;
  color: red;
}

.liBind:hover{
  background-color: #f0f2f5;

  font-size: larger;
  margin: 15px 0;
}

.auth-title{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  border-left: 4px solid #1b80e1;
  font-weight: 700;
  .icon-fanhui{
    font-size: 20px;
    margin-left: 10px;
    color: #1b80e1;
    cursor: pointer;
  }
}
.auth-content-name{
  height: 30px;
  margin: 15px 0;
  span{
    font-weight: 700;
    font-size: 18px;
  }
}
.information{
  margin: 10px 0;
  >h3{
    font-size: 18px;
  }
  /deep/ .el-checkbox-group{
    margin: 10px 0;
    .el-checkbox{
      margin: 5px 10px;
    }
    .el-checkbox__inner{
      border-radius: 50%;
    }
  }
}

.selectLike{
  width: 100%;
  height: 100%;
  padding: 20px 0 10px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  li{
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20%;
    padding: 0 20px;
    .text3{
      width: 20px;
      height: 20px;
      background-color:#43cf7c;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 12px;
    }
    span{
      font-size: 16px;
    }
  }
  li:nth-child(1){
    .text3{
      background-color: #d43030;
    }
  }
  li:nth-child(2){
    .text3{
      background-color: #ff8d1a;
    }
  }
  li:nth-child(3){
    .text3{
      background-color: #ff8d1a;
    }
  }
}

.auth-buttom{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
  height: 40px;
  .el-button{
    margin: 0 20px;
  }
}
/deep/.parentLable{
  >.el-checkbox__label{
    font-weight: 700;
    font-size: 16px;
    color: #333;
  }
  >.el-checkbox__input{
    .el-checkbox__inner{
      border-radius: 0;
    }
  }
}
</style>
