<template>
  <div class="app-container">
    <el-row class="main-flex">
        <el-col :span="3" class="side-search" v-if="isopenShow">
            <operatorStationTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorStationTreeVue>
        </el-col>

        <el-col v-if="!isopenShow" class="small-side">
            <div class="change-in" @click="isopenShow = !  isopenShow "></div>
        </el-col>


        <!-- 搜索区域 -->
        <el-col :span="21" class="mian-content">
<!--          <el-input placeholder="请从左侧选择运营商" v-model.trim="treeData.operatorStationNameTree" disabled></el-input>-->
            <div class="head-section">
                <el-col :span="7">
                    <el-form :inline="true">
                        <el-form-item label="换电站编号：">
                            <el-input placeholder="请输入换电站编号" v-model.trim="stationNoValue" clearable></el-input>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="17">
                    <div class="btn-end">
                            <span class="btn-rest">
                              <el-button @click="resFuntion" plain>重置</el-button>
                            </span>
                        <span class="btn-submit">
                                  <el-button @click="getAllPage()" type="primary" plain>查询</el-button>

                            </span>
                    </div>
                </el-col>
            </div>
            <!--  表格部分-->
            <div class="section-main">
                <el-col class="choose-mian">
                    <div class="section-title">
                        故障列表
                    </div>
                </el-col>
                <el-col :span="24">
                    <el-row class="table-box">
                        <el-table ref="equipTable" id="equipment" @selection-change="batchList" :data="tableData"
                                   :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo">

                            <el-table-column align="center" type="index" :index="renIndex" label="编号"></el-table-column>
                            <el-table-column align="center" prop="sn" label="换电站编码" min-width="130"></el-table-column>
                            <el-table-column align="center" prop="en" label="设备编号" min-width="130"></el-table-column>
                            <el-table-column align="center" prop="fc" label="故障码" min-width="130"></el-table-column>
                            <el-table-column align="center" prop="ft" label="故障内容" min-width="130"></el-table-column>
                            <el-table-column align="center" prop="fl" label="故障等级" min-width="100"></el-table-column>

                            <el-table-column align="center" prop="status" label="故障标志" width="80">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.fg === 1" class="states"> 发生</span>
                                    <span v-else class="states">消失</span>
                                </template>
                            </el-table-column>

                            <el-table-column align="center" prop="cm" label="采集模式" min-width="130">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.cm === 1" class="states"> 自动</span>
                                    <span v-else class="states">人工录入</span>
                                </template>

                            </el-table-column>

                            <el-table-column align="center" prop="bt" label="发生时间" width="160"></el-table-column>

                            <!--
                            <el-table-column label="操作" min-width="160">
                             <template slot-scope="scope">
                               <el-button @click="compileEqu(scope.row)" size="small" type="primary" icon="el-icon-edit">详情
                               </el-button>
                             </template>
                           </el-table-column>
                            -->


                        </el-table>
                    </el-row>
                </el-col>

                <el-col :span="24">
                    <el-row class="pagin">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="query.pageNum"
                            :page-sizes="[10, 30, 50,100]"
                            :page-size="query.pageSize"
                            layout="total, sizes, prev, pager, next"
                            :total="total">
                        </el-pagination>
                    </el-row>
                </el-col>
            </div>


        </el-col>




    </el-row>

  </div>
</template>

<script>
import '@/assets/font/font.css';
import {faultReport} from '@/utils/operation'
import moment from 'moment'
import operatorStationTreeVue from "@/components/tree/operatorStationTree"
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {operatorTree} from "@/utils/operatorApi";

export default {
  name: 'setOpModel',
  components: {operatorStationTreeVue},
  data() {
    return {
      isopenShow: true,
      idValue: null,
      stationNoValue: "",
      endTime: '',
      isPage: true,//查询
      query: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      isequipemt: true,
      tableData: [],
      stationCode: [],
      informationForm: {
        operatorId: null,
        stationId: null,
      },

      //左侧 运营商-场站树
      treeData: {
        operatorStationOptions: undefined,  //运营商-场站树选项
        defaultProps: {
          children: "children",
          label: "label"
        },
        operatorStationNameTree: "",
        operatorStationIdTree: null,
        defaultExpandAll: true,
      },

    }
  },
  computed: {},
  created() {
    this.getOperatorTree();
    this.getAllPage()

  },
  methods: {
      headerStyleEvent({ row, column, rowIndex, columnIndex }) {
          if (columnIndex == 1) {
              return 'padding:0px;'
          }
      },
      goodsInfo(row){
          if (row.columnIndex == 1) {
              return 'goodsInfo'
          }
      },
      isHidden(message) {
          this.isopenShow = message
      },
    resetForm() {
      this.isEdit = false;
      this.informationForm = {
        operatorId: null,
        stationId: null,
      }

    },

    renIndex(index) {
      return (this.query.pageNum - 1) * this.query.pageSize + index + 1
    },


    dateForma: function (row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return ''
      }
      ;
      return moment(date).format("HH:mm:ss")
    },


    //条件分页查询
    async getAllPage() {
      const data = {
        page: this.query.pageNum,
        size: this.query.pageSize,
      }
      var url = "?page=" + this.query.pageNum + "&size=" + this.query.pageSize;
      var id = this.treeData.operatorStationIdTree;
      if (id != "" && id != null) {
        if (id.indexOf("operator") != -1) {
          id = id.slice(9);
          url += "&operatorId=" + Number(id)
        } else {
          id = id.slice(8);
          url += "&stationId=" + Number(id)
        }
      }
      if (this.stationNoValue != "" && this.stationNoValue != null) {
        data.sn = this.stationNoValue;
      }
      data.url = url;
      const result = await faultReport(data)
      if (result.data.code !== 200) return this.$msg.error('获取信息失败!')

      this.tableData = result.data.data.content
      this.total = result.data.data.totalElements;
      this.idValue = null
    },


    //重置
    resFuntion() {
      this.stationNoValue = ""
      this.isEdit = false
      this.treeData.operatorStationNameTree = ""
      this.treeData.operatorStationIdTree = null
      this.getAllPage()
    },
    batchList(list) {

    },
    close() {
      const data = {
        fcreator: this.userName,
      }

      this.isequipemt = true
    },

    handleSizeChange(val) {
      this.query.pageSize = val
      if (this.isPage) {
        this.getAllPage()
      } else {
        this.checkEqui()
      }
    },
    handleCurrentChange(val) {
      this.query.pageNum = val
      if (this.isPage) {
        this.getAllPage()
      } else {
        this.checkEqui()
      }
    },
    cleanList() {
      this.$refs.equipTable.clearSelection()
    },

    /** 查询运营商下拉树结构 */
    async getOperatorTree() {
      try {
        this.operatorTreeData.operatorOptions = [];
        let res = await operatorTree();
        if (res.data.code !== 200) return this.$msg.error("获取运营商信息失败");
        this.operatorTreeData.operatorOptions.push(res.data.data)
        this.operatorTreeData.operatorIdTree = res.data.data.id
        this.operatorTreeData.operatorNameTree = res.data.data.label
      } catch (error) {
      }
    },

    getTreeData(data) {
      this.treeData = data
    },

  },

  watch: {
  },


}
</script>

<style lang='less' scoped>
.el-table {
  scrollbar-width: thin;  /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 10px; /*滚动条宽度*/
    height: 10px; /*滚动条高度*/
  }

  /*定义滚动条轨道 内阴影+圆角*/

  /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
    //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: #fff; /*滚动条的背景颜色*/
  }

  /*定义滑块 内阴影+圆角*/

  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
    border-radius: 10px; /*滚动条的圆角*/
    background-color: #dddee0; /*滚动条的背景颜色*/
  }
}
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}
.inner-container {
    width:  95%;
    height: var(--tableHeight);;
    overflow: hidden;
}

.out-container {
    width: 100%;
     height: var(--tableHeight);

    overflow: auto;
}
//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}
.edit-style{
    color: #0D6CE4;
    font-size: 14px;
    cursor:pointer;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>

