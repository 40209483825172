<template>
    <div class="app-container">
        <el-row class="main-flex">

            <!--运营商场站树-->
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorStationTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorStationTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!--                    <el-input placeholder="请从左侧选择运营商" v-model.trim="treeData.operatorStationNameTree"-->
            <!--                              disabled></el-input>-->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="换电站编号：">
                                <el-input placeholder="请输入换电站编号" v-model.trim="stationNoValue"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="17">
                        <div class="btn-end">
                    <span class="btn-rest">
                            <el-button @click="resFuntion">重置</el-button>
                            </span>
                            <span class="btn-submit">
                       <el-button @click="getSearch" type="primary" plain>查询
                    </el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            场站运营时段列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                          已选择{{ batchNum.length }}项
                            <span @click="cleanList" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                                 <el-button @click="removeAll"
                                            plain
                                            v-hasCode="['operation:runmodel:delete']">删除
                        </el-button>
                            </span>
                            <span class="add-btn">
                               <el-button @click="addStationRunModel"
                                          type="primary"
                                          v-hasCode="['operation:runmodel:add']" plain> 新增
                        </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table ref="equipTable" id="equipment" @selection-change="batchList" :data="tableData"
                                      :header-cell-style="headerStyleEvent"
                                      :cell-class-name="goodsInfo" height="100%">
                                <el-table-column align="center" type="selection" width="46"></el-table-column>
                                <el-table-column align="center" type="index" :index="renIndex"
                                                 label="编号"></el-table-column>
                                <el-table-column align="center" prop="stationNo" label="换电站编号"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" label="换电站名称" prop="stationName"
                                                 min-width="120"></el-table-column>
                                <!--        <el-table-column prop="stationName" label="换电站名称">-->
                                <!--          <template slot-scope="scope">-->
                                <!--            <span @click="getStationById(scope.row.fstationId)"></span>-->

                                <!--          </template>-->
                                <!--        </el-table-column>-->
                                <el-table-column align="center" prop="startTime" label="开始时刻"></el-table-column>
                                <el-table-column align="center" prop="endTime" label="结束时刻"></el-table-column>
                                <!--        <el-table-column prop="felecVal" label="电价"></el-table-column>-->
                                <!--        <el-table-column prop="fstate" label="状态"></el-table-column>-->
                                <!--        <el-table-column prop="fstate" label="运营状态">-->
                                <!--          <template slot-scope="scope">-->
                                <!--            <span v-if="scope.row.fstate === 1" style="color:#67C23A"><i class="status"-->
                                <!--                                                                         style="background-color:#409eff;"></i>营业时间</span>-->
                                <!--            <span v-if="scope.row.fstate === 2" style="color:#F56C6C"><i class="status"-->
                                <!--                                                                         style="background-color:#409eff;"></i>歇业时间</span>-->
                                <!--            <span v-if="scope.row.fstate === 3" style="color:#E6A23C"><i class="status" style="background-color:#409eff;"></i>维护时间</span>-->

                                <!--          </template>-->
                                <!--        </el-table-column>-->

                                <el-table-column align="center" label="操作" fixed="right" min-width="160">
                                    <template slot-scope="scope">
                                            <span @click="compileEqu(scope.row)" class="edit-style edit-margin"
                                                  v-hasCode="['operation:runmodel:update']">编辑
                                            </span>
                                        <span @click="compileRun(scope.row)" class="edit-style"
                                              v-hasCode="['operation:runmodel:send']">发送
                                            </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>

                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10, 30, 50,100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>
                </div>
            </el-col>
        </el-row>
        <el-dialog
            :title="isequipemt?'新增场站运营模型信息':'修改场站运营模型信息'"
            :visible.sync="addOrUpdate"
            width="40%"
            @close="close"
        >
            <el-form size="middle" ref="equipform" :rules="equipmentRules" :model="informationForm" label-width="100px">
                <el-row>
                    <el-col :span="24">

                        <el-form-item label="归属运营商" prop="operatorId">
                            <TreeSelect v-model="informationForm.operatorId" :options="operatorTreeData.operatorOptions"
                                        placeholder="请选择运营商"/>
                        </el-form-item>

                        <el-form-item label="换电站:" prop="stationId">
                            <el-select v-model="informationForm.stationId" placeholder="请选择换电站">
                                <el-option
                                    v-for="item in stationList"
                                    :key="item.id"
                                    :label="item.stationName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="开始时刻" prop="startTime">
                            <el-time-picker
                                value-format="HH:mm:ss"
                                v-model="informationForm.startTime"
                                :picker-options="{ selectableRange: '00:00:00 - 23:59:59'}"
                                placeholder="选择时间">
                            </el-time-picker>
                            <!--                            <el-time-select-->
                            <!--                                value-format="HH:mm:ss"-->
                            <!--                                v-model="informationForm.startTime"-->
                            <!--                                :picker-options="{start: '00:00',step: '00:01',end: '24:00'}"-->
                            <!--                                placeholder="选择时间">-->
                            <!--                            </el-time-select>-->
                        </el-form-item>

                        <el-form-item label="结束时刻" prop="endTime">
                            <el-time-picker
                                value-format="HH:mm:ss"
                                v-model="informationForm.endTime"
                                :picker-options="{  selectableRange: `${informationForm.startTime?informationForm.startTime:'00:00:00'} - 23:59:59`}"
                                placeholder="选择时间">
                            </el-time-picker>
                            <!--                            <el-time-select-->
                            <!--                                value-format="HH:mm:ss"-->
                            <!--                                v-model="informationForm.endTime"-->
                            <!--                                :picker-options="{start: '00:00',step: '00:01',end: '24:00',minTime:informationForm.startTime}"-->
                            <!--                                placeholder="选择时间">-->
                            <!--                            </el-time-select>-->
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="addOrUpdate = false">取 消</el-button>
        <el-button type="primary" @click="newequipemt">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    checkStationRunList,
    addStationRun,
    setStationRun,
    deleteStationRun, setStationRunMqtt,
} from '@/utils/operation'
import {getForceList, getOperatorStationTree, getStationInfoList} from '@/utils/station'
import moment from 'moment'
import {mapState} from "vuex";

import operatorStationTreeVue from "@/components/tree/operatorStationTree"
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {handleTree} from "@/utils/tree";
import {operatorTree} from "@/utils/operatorApi";

export default {
    name: 'stationRun',
    components: {TreeSelect, operatorStationTreeVue},
    data() {
        return {
            isopenShow: true,
            stationNoValue: "",

            startTime: '',
            endTime: '',
            informationVo: {},
            stationList: [],
            isPage: true,//查询
            query: {
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            addOrUpdate: false,
            isequipemt: true,
            batchNum: [],
            tableData: [],
            stationInfo: [],
            stationCode: [],
            allList: [],
            stationInfoList: [],

            informationForm: {
                operatorId: null,
                stationId: null,
                startTime: null,
                endTime: null
            },

            equipmentRules: {
                operatorId: [
                    {required: true, message: '请选择归属运营商', trigger: 'blur'}
                ],
                stationId: [
                    {required: true, message: '请选择换电站', trigger: 'blur'}
                ],
                startTime: [
                    {required: true, message: '请选择开始时间', trigger: 'blur'}
                ],
                endTime: [
                    {required: true, message: '请选择结束时间', trigger: 'blur'}
                ]
            },
            brandList: [],
            unitTypeList: [],
            newTime: '',

            isEdit: false,
            treeData: {
                operatorStationOptions: undefined,  //左侧运营商-场站树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorStationNameTree: "",
                operatorStationIdTree: null,
                defaultExpandAll: true,
            },

            //新增、修改时运营商树选择
            operatorTreeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },

        }
    },
    computed: {},
    created() {
        this.getOperatorTree();

        this.getAllPage()
        this.getCurrentTime()
    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        getCurrentTime() {
            //获取当前时间并打印
            var _this = this;
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            let dd = new Date().getDate();
            let hh = new Date().getHours();
            let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
            let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
            _this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
            console.log(_this.gettime)
            this.newTime = _this.gettime
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        dateForma: function (row, column) {
            var date = row[column.property];
            if (date == undefined) {
                return ''
            }
            ;
            return moment(date).format("HH:mm:ss")
        },

        //条件分页查询
        async getAllPage() {
            const data = {
                page: this.query.pageNum,
                size: this.query.pageSize,
            }
            var url = "?page=" + this.query.pageNum + "&size=" + this.query.pageSize;
            var id = this.treeData.operatorStationIdTree;
            if (id != "" && id != null) {
                if (id.indexOf("operator") != -1) {
                    id = id.slice(9);
                    url += "&operatorId=" + Number(id)
                } else {
                    id = id.slice(8);
                    url += "&stationId=" + Number(id)
                }
            }
            if (this.stationNoValue != "" && this.stationNoValue != null) {
                url += "&stationNo=" + this.stationNoValue;
            }

            data.url = url;
            const result = await checkStationRunList(data)
            if (result.data.code !== 200) return this.$msg.error('获取场站运营模型信息失败!')
            this.tableData = result.data.data.content
            this.total = result.data.data.totalElements;
        },
        getSearch() {
            this.query.pageNum = 1
            this.getAllPage()
        },

        async getStationById(stationId) {
            const result = await getStationById(stationId)
            if (result.data.code !== 200) return this.$msg.error('获取信息失败!')
            this.stationInfoList = result.data.data.rows
        },
        //重置
        resFuntion() {
            this.informationVo = {}
            this.stationNoValue = ""
            this.isEdit = false
            this.treeData.operatorStationNameTree = ""
            this.treeData.operatorStationIdTree = null
            this.getAllPage()
        },
        batchList(list) {
            this.batchNum = list
        },
        close() {
            // this.$refs.equipform.resetFields()
            this.isequipemt = true
            this.resetForm()
        },

        resetForm() {
            this.isEdit = false
            this.informationForm = {
                operatorId: null,
                stationId: null,
                startTime: null,
                endTime: null
            }

        },


        //新增与修改
        newequipemt() {
            this.$refs.equipform.validate(async valid => {
                if (!valid) return this.$msg.info('信息不能为空!')
                try {
                    let data = JSON.parse(JSON.stringify(this.informationForm))
                    console.log("==============data=============", data)
                    if (this.isequipemt) {
                        const res = await addStationRun(data)
                        if (res.data.code !== 200) return this.$msg.error('新增场站运营模型失败!')
                        this.$msg.success('新增场站运营模型成功!')
                        this.addOrUpdate = false
                        this.getAllPage()
                    } else {
                        const res = await setStationRun(data)
                        if (res.data.code !== 200) return this.$msg.error('修改场站运营模型失败!')
                        this.$msg.success('修改场站运营模型成功!')
                        this.addOrUpdate = false
                        this.getAllPage()
                    }
                } catch (error) {
                }
            })

        },

        //修改信息填充
        async compileEqu(row) {
            const res = await getForceList();
            if (res.data.code != 200) {
                return this.$msg.error('查询场站信息失败!')
            } else {
                this.isEdit = true;
                this.stationList = res.data.data
                this.addOrUpdate = true
                this.isequipemt = false
                var data = JSON.parse(JSON.stringify(row))
                this.informationForm = data
            }
        },

        async compileRun(list) {
            const config = await this.$MessageBox.confirm(
                // "向"+list.fstationId+"下发换电运营模型信息, 是否继续?",
                "下发场站运营时段模型信息, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            var data = JSON.parse(JSON.stringify(list))
            const info = {
                "stationCode": data.stationNo,
                "ut": moment(new Date()).format('YYYY-MM-DD HH:mm')
            }
            const result = await setStationRunMqtt(JSON.parse(JSON.stringify(info)))
            if (result.data.code !== 200) return this.$msg.error("操作失败")
            this.addOrUpdate = false
            this.$msg.success("操作成功")
            this.close()
        },

        //时间格式化
        dateFormat(row, column, cellValue) {
            return cellValue ? fecha.format(new Date(cellValue), 'yyyy-MM-dd ') : '';
        },

        handleSizeChange(val) {
            this.query.pageSize = val
            if (this.isPage) {
                this.getAllPage()
            } else {
                this.checkEqui()
            }
        },
        handleCurrentChange(val) {
            this.query.pageNum = val
            if (this.isPage) {
                this.getAllPage()
            } else {
                this.checkEqui()
            }
        },
        async excelAll() {
            const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(e => e)
            if (confirm !== 'confirm') return
            if (this.batchNum.length === 0) {
                this.$download(this.allList, this.coulm, '换电辅助设备管理')
            } else {
                this.$download(this.batchNum, this.coulm, '换电辅助设备管理')
            }
        },
        cleanList() {
            this.$refs.equipTable.clearSelection()
        },

        addStationRunModel() {
            this.addOrUpdate = true
            this.resetForm()
        },
        //批量删除
        async removeAll() {
            if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除选中项, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const list = this.batchNum.map(item => {
                    return item.id
                })
                const res = await deleteStationRun(list)
                if (res.status !== 200) return this.$msg.error('删除失败')
                this.tableData = this.tableData.filter((item, i) => {
                    return item.id !== list[i]
                })
                this.$refs.equipTable.clearSelection()
                if (this.query.pageNum > 1 && this.tableData.length === 0) {
                    this.query.pageNum = this.query.pageNum - 1
                }
                this.$msg.success('删除成功')
                this.getAllPage()
            } catch (e) {
                console.log(e);
            }
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.operatorTreeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.data.code !== 200) return this.$msg.error("获取运营商信息失败");
                this.operatorTreeData.operatorOptions.push(res.data.data)
                this.operatorTreeData.operatorIdTree = res.data.data.id
                this.operatorTreeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },

        getTreeData(data) {
            this.treeData = data
        },
    },

    watch: {
        'informationForm.operatorId': {
            async handler(newName, oldName) {
                //修改
                if (this.isEdit == true && newName != null && newName != undefined && (oldName == undefined || oldName == null)) {
                    return;
                }
                //新增、修改时改动过运营商
                if (newName !== null && newName != undefined) {
                    this.informationForm.stationId = null;
                    let data = {}
                    data.url = "?operatorId=" + newName;
                    const res = await getStationInfoList(data);
                    this.stationList = res.data.data
                    return;
                }
            },
            deep: true,
            immediate: false
        },
    },

}
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

::v-deep .fixstyle {
    padding-left: 0 !important;
}

.inner-container {
    width: 1300px;
    height: var(--tableHeight);
    overflow: hidden;
}

.el-select {
    width: 100%;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}
.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

.more-search {
    display: inline-block;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    cursor: pointer;
    position: relative;
    left: -15px;
}

.edit-margin {
    margin-right: 16px;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
