<template>
    <div class="app-container">
        <div class="page-container">
        <div class="page-title">交易统计</div>
            <div class="page-board">
                <div class="bg-all board">
                    <span>累计充值金额（元）</span>
                    <div class="page-num">{{ transData.totalReCharge }}</div>
                </div>
                <div class="bg-cost board">
                    <span>累计消费金额（元）</span>
                    <div class="page-num">{{ transData.totalConsume }}</div>
                </div>
                <div class="bg-add board">
                    <span>当天充值金额（元）</span>
                    <div class="page-num">
                        <div>{{ transDataOnDate.dayTotalReCharge }}</div>
                    <div class="page-change">{{ transDataOnDate.dayReChargeIncrease }}%↑</div>
                    </div>
                </div>
                <div class="bg-all board">
                    <span>当天消费金额（元）</span>
                    <div class="page-num">
                    <div>{{ transDataOnDate.dayTotalConsume }}</div>
                    <div class="page-change">{{ transDataOnDate.dayConsumeIncrease }}%↓</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-echart">
            <div class="echart-title">
                运营商-{{currentMonth}}月交易统计
            </div>
            <div class="echart-content" id="order-bar">
            </div>
        </div>

    </div>
</template>
<script>
import '@/assets/font/font.css';
import * as echarts from 'echarts';
import {dayTransStatistics, transStatistics, transStatisticsDateRange} from "@/utils/order";
import {getBeforeDayYMD} from "@/utils/dateUtil";

export default {
    data() {
        return {
            transData: {
                totalReCharge: 0,
                totalConsume: 0
            },
            transDataOnDate: {
                dayTotalReCharge: 0,
                dayTotalConsume: 0,
                dayReChargeIncrease: 0,
                dayConsumeIncrease: 0
            },
            dayList: [],
            reChargeOrderList: [],
            consumeOrderList: [],
            orderData: [],
            currentMonth:null

        };
    },

    mounted() {
        this.createBar();
        const chartDom = document.getElementById('order-bar');
        let myChart = echarts.init(chartDom);
        // 若dom尺寸变化，则resize
        const chartObserver = new ResizeObserver(() => {
            myChart.resize();
        });
        chartObserver.observe(chartDom);
    },

    created() {
        this.initData();
        this.getTransStatistics();
        this.getDayTransStatistics();
        this.getTransStatisticsDateRange();
        this.getCurrentMonth();
    },

    methods: {
        //交易统计
        async getTransStatistics() {
            const res = await transStatistics();
            if (res.data.data.code != 200) {
                return that.$msg.error("获取累计交易信息失败！" + res.data.data.msg);
            }
            if (res.data.data.data != null) {
                this.transData = res.data.data.data;
            }
        },

        //今日交易统计
        async getDayTransStatistics() {
            const res = await dayTransStatistics();
            if (res.data.data.code != 200) {
                return that.$msg.error("获取当日交易信息失败！" + res.data.data.msg);
            }
            if (res.data.data.data != null) {
                this.transDataOnDate = res.data.data.data;
            }
        },

        async getTransStatisticsDateRange() {
            const res = await transStatisticsDateRange();
            if (res.data.data.code != 200) {
                return that.$msg.error("获取区间交易信息失败！" + res.data.data.msg);
            }
            this.orderData = res.data.data.data;
            console.log("------this.orderData-----------[[", this.orderData, "]]-------------------------");
            var reChargeOrderList = [];
            var consumeOrderList = [];
            var dayList = this.dayList;
            console.log("------this.dayList-----------[[", this.dayList, "]]-------------------------");
            dayList.forEach(item => {
                let reChargeOrder = {};
                let consumeOrder = {};
                reChargeOrder.name = item;
                reChargeOrder.value = 0;
                consumeOrder.name = item;
                consumeOrder.value = 0;
                this.orderData.some(obj => {
                    if (item === obj.createTime) {
                        /*console.log("------item--------[[[[[[[",item,"]]-----------totalReCharge[[",obj.dayTotalReCharge,"]]]----" +
                            "totalConsume[[",obj.dayTotalConsume,"]]]--------")*/
                        reChargeOrder.value = obj.dayTotalReCharge;
                        consumeOrder.value = obj.dayTotalConsume;
                        return true;
                    }
                });
                reChargeOrderList.push(reChargeOrder);
                consumeOrderList.push(consumeOrder);
            })

            this.reChargeOrderList = reChargeOrderList;
            this.consumeOrderList = consumeOrderList;
            //console.log("----------this.reChargeOrderList-------------[[",this.reChargeOrderList,"]]----------------")
            //console.log("----------this.consumeOrderList--------------[[",this.consumeOrderList,"]]----------------")
            this.createBar();
        },

        getCurrentMonth() {
            var nowdate = new Date();
            var month = nowdate.getMonth() + 1;
            var newMonth = (month < 10 ? "0" + (month) : month); //单月补0
            this.currentMonth = newMonth;
        },

        initData() {
            var dayList = [];
            var t1 = this.getTime(6);
            var t2 = this.getTime(5);
            var t3 = this.getTime(4);
            var t4 = this.getTime(3);
            var t5 = this.getTime(2);
            var t6 = this.getTime(1);
            var t7 = this.getTime(0)
            dayList.push(t1);
            dayList.push(t2);
            dayList.push(t3);
            dayList.push(t4);
            dayList.push(t5);
            dayList.push(t6);
            dayList.push(t7);
            this.dayList = dayList;
            var dataList = [];
            dataList[0] = {name: t1, value: 0};
            dataList[1] = {name: t2, value: 0};
            dataList[2] = {name: t3, value: 0};
            dataList[3] = {name: t4, value: 0};
            dataList[4] = {name: t5, value: 0};
            dataList[5] = {name: t6, value: 0};
            dataList[6] = {name: t7, value: 0};
            this.reChargeOrderList = dataList;
            this.consumeOrderList = dataList;
        },

        getTime(total) {
            var dd = new Date();
            return getBeforeDayYMD(dd, total);
        },

        createBar() {
            var chartDom = document.getElementById('order-bar');
            var myChart = echarts.init(chartDom);
            var option;
            option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                toolbox: {
                    feature: {
                        // dataView: {show: true, readOnly: false},
                        magicType: {show: true, type: ['line', 'bar'],title:{ line:'切换为折线图',bar:'切换为柱状图'}},
                        // saveAsImage: {show: true}
                    }
                },
                grid: {
                    top:'10%',
                    left: '1%',
                    right: '1%',
                    bottom: '1%',
                    containLabel: true
                },
                legend: {
                    top:'1%',
                    left:'80%',
                    itemGap: 16,
                    data: ['消费', '充值']
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.dayList,

                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '金额（元）',
                        //interval: 50,//间隔
                        axisLabel: {
                            formatter: '{value} 元'
                        }
                    },
                ],
                series: [
                    {
                        name: '消费',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' 元';
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 0, color: 'rgba(13, 108, 228, 1)'},
                                        {offset: 1, color: 'rgba(13, 108, 228, 0.60)'},
                                    ]
                                )
                            }
                            },
                        data: this.consumeOrderList
                    },
                    {
                        name: '充值',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' 元';
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 0, color: 'rgba(250, 150, 0, 1)'},
                                        {offset: 1, color: 'rgba(250, 150, 0, 0.6)'},
                                    ]
                                )
                            }
                        },
                        data: this.reChargeOrderList
                    },
                ],
            };
            option && myChart.setOption(option);
        }
    },

};
</script>
<style scoped lang="less">
.app-container {
    background-color: rgba(255,255,255,0);
    padding-left: 0px;
}
    .page-container {
        padding:24px 16px;
        background-color: rgba(255, 255, 255, 1);
        .page-title{
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 16px;
        }
        .page-board{
            display: flex;
        }
        .board{
            width: 100%;
            margin-right: 16px;
        }
        div{
            span{
                display: inline-block;
                color: #FFF;
                font-size: 14px;
                font-weight: 400;
                margin: 20px 0 0 24px;
                font-family: PingFang SC;
            }
            .page-num{
                color: #FFF;
                font-size: 24px;
                font-style: italic;
                font-weight: 700;
                margin: 16px 0 20px 24px;
                display: flex;
                justify-content: space-between;
            }
            .page-change{
                margin-right: 24px;
            }
        }
        .board:last-child{
            margin-right: 0px;
        }
        .bg-all{
            border-radius: 4px;
            background: linear-gradient(135deg, #55A0FF 0%, #0D6CE4 100%);
        }
        .bg-cost{
            border-radius: 4px;
            background: linear-gradient(136deg, rgba(250, 150, 0, 0.60) 0%, #F1AF4B 0.01%, #FA9600 100%);
        }
        .bg-add{
            border-radius: 4px;
            background: linear-gradient(135deg, #50CDCA 0%, #00BBB7 100%);
        }
    }
    .page-echart{
        background: #fff;
        margin-top: 20px;
        padding: 24px 16px;
        .echart-title{
            color: #2F3133;
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 400;
        }
            .echart-content {
                //margin: 25px 0 0 0;
                width: 100%;
                height: 500px;
            }

    }

</style>
