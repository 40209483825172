<template>
    <el-container>
        <el-container>
            <el-header>
                <el-row type="flex" justify="start" class="hander">
                    <el-col>
                        <div class="logo">
                            <div class="describle"></div>
                        </div>
                        <!-- <img src="../assets/image/webcome.png" alt/>-->
                        <div class="hander-left">
                            <!--  <i @click="iscollapse = !iscollapse" class="el-icon-s-fold"></i>-->
                            <div><h3>秦欧换电云平台管理系统</h3></div>
                        </div>
                    </el-col>
                    <el-col class="hander-right">
                        <div @click="userClick" class="header-user">
                            <el-image
                                :src="userList.avatar"
                                fit="fit"></el-image>
                            <span>欢 迎 , {{ userList.username }}</span>
                            <img src="~@/assets/image/arrow_down.png" class="arrow_down flex-col"/>
                        </div>
                        <div style="margin-left: 5px" class="header-user">
                            <span @click="loginEnd"><i class="el-icon-switch-button"></i>注销</span>
                        </div>

                        <div id="user-box">
                            <div class="user-pisition">
                                <div class="user-content">
                                    <span @click="userInfo"><i class="el-icon-user"></i> 个人信息</span>
                                    <span @click="pwdShow"><i></i> 修改密码</span>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-header>
            <el-main>
                <!--        logo-->
                <el-aside :class="{'coll-small':iscollapse==true,'coll-large':iscollapse==false}">

                    <!--左侧菜单区-->
                    <div :class="iscollapse==true?'sidebar':'sidebar-xs'">
                        <div>
                            <div :class="{'in-open':iscollapse==true,'in-close':iscollapse!=true}">
                                <div :class="{'btn-img':iscollapse!=true,'btn-img-small':iscollapse==true}">
                                    <div @click="iscollapse = !iscollapse"
                                         :class="{'icon-s-fold':iscollapse== false,'icon-s-unfold':iscollapse== true}">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--               搜索框-->
                        <div class="search-title" v-if="!iscollapse">
                            <el-input placeholder="搜索菜单" v-if="!iscollapse" @input="inputNull"
                                      @keyup.enter.native="inputChange" v-model="toinp" clearable>
                                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                            </el-input>
                            <!--                    <el-input clearable v-model="toinp" @input="inputNull"-->
                            <!--                              @keyup.enter.native="inputChange" class="inpt2"-->
                            <!--                              style="width: 168px;height: 36px;position: relative"/>-->
                        </div>
                        <div class="search-small" v-if="iscollapse">
                            <div class="icon-search"></div>
                        </div>
                        <!-- 侧边导航-->
                        <el-menu class="el-menu-vertical-demo"
                                 ref="menu"
                                 :default-active="$route.path"
                                 :collapse="iscollapse"
                                 :collapse-transition="false"
                                 router
                                 :unique-opened="true"
                                 style="border: none"
                                 :default-openeds="openeds"
                        >
                            <el-submenu v-for="item in list" :key="item.id" :index="item.id+''">
                                <template slot="title">
                                    <div :class="iscollapse==true?'menu-itemsmall':'menu-item'">
                                        <svg-icon :icon-class="item.menuImage"/>
                                        <span :class="iscollapse==true?'menu-InfSmall':'menu-Inf'"
                                              v-html="item.menuName"></span>
                                    </div>
                                </template>

                                <el-menu-item
                                    @click="addLabel(count)"
                                    v-for="count in item.children"
                                    :key="count.id"
                                    :index="'/'+count.path"
                                    v-html="count.menuName"
                                >
                                </el-menu-item>
                            </el-submenu>
                        </el-menu>
                    </div>
                </el-aside>
                <div class="main-content">
                    <div  @contextmenu.prevent="openMenu($event)">
                        <!--  页签-->
                        <el-tabs
                            class="root"
                            v-model="editableTabsValue"
                            type="card"
                            @tab-click="pushTabs"
                            @tab-remove="removeTab">
                            <el-tab-pane
                                :closable="item.name=='首页'?false:true"
                                v-for="item in optionTab"
                                :key="item.id"
                                :index="item.id"
                                :label="item.name.replace(/(<\/?span.*?>)/gi,'')"
                                :name="item.path"
                            ></el-tab-pane>
                        </el-tabs>
                        <!-- 右击-->
                        <div class="toptab" v-if="tabmakr"
                             :style="{'padding-top': '6px','left':`${this.tabsx}px`,'top':'120px' }">
                            <div :class="indextas==5?'activegb':'gb'" style="font-size: 14px;padding-left: 20px"
                                 @mouseover="indextas=5" @mouseout="indextas=0" @click="gbtabindex">关闭
                            </div>
                            <div :class="indextas==6?'activegb':'gb'" style="font-size: 14px;padding-left: 20px"
                                 @mouseover="indextas=6" @mouseout="indextas=0" @click="gbleft">关闭左侧
                            </div>
                            <div :class="indextas==7?'activegb':'gb'" style="font-size: 14px;padding-left: 20px"
                                 @mouseover="indextas=7" @mouseout="indextas=0" @click="gbqt">关闭其他
                            </div>
                            <!--              <div class="inmark">-->
                            <!--&lt;!&ndash;              <div v-for="(item,index) in options" :key="index" class="makr"><span @click="topachs(item.path)">{{item.name}}</span>  <span style="color: #585858" @click="tabdel(item.id)">x</span></div>&ndash;&gt;-->
                            <!--              </div>-->
                        </div>
                    </div>
                    <el-scrollbar class="scroll-reses">
                        <router-view/>
                    </el-scrollbar>
                </div>
            </el-main>
        </el-container>


        <!-- 修改用户信息 -->
        <el-dialog title="个人信息"
                   @close="close"
                   :visible.sync="userShow" width="50%">
            <el-form size="small" ref="addRefFrom" :rules="addrules" :model="addFrom" label-width="100px">
                <el-row>
                    <el-col style="margin-right:10px;" :span="11">
                        <el-form-item label="登录账号:" prop="username">
                            <el-input v-model="addFrom.username" show-word-limit maxlength="20"
                                      :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item label="真实姓名:" prop="realName">
                            <el-input v-model="addFrom.realName" show-word-limit maxlength="20"></el-input>
                        </el-form-item>
                        <el-form-item label="员工工号:" prop="employeeId">
                            <el-input v-model="addFrom.employeeId"></el-input>
                        </el-form-item>
                        <el-form-item class="Form-item" label="头像:">
                            <template>
                                <Avater @on-avater="onAvater" @on-imgchange="imgchange" :uploadImg="uploadImg"
                                        :isAvater="isAvater"/>
                            </template>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="电话:" prop="mobile">
                            <el-input v-model="addFrom.mobile" show-word-limit maxlength="11"></el-input>
                        </el-form-item>
                        <el-form-item label="身份证号:" prop="identifyCode">
                            <el-input v-model="addFrom.identifyCode"></el-input>
                        </el-form-item>
                        <el-form-item label="性别:" prop="gender">
                            <el-select v-model="addFrom.gender" placeholder="请选择性别">
                                <el-option
                                    v-for="item in genderList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="邮箱:" prop="email">
                            <el-input v-model="addFrom.email"></el-input>
                        </el-form-item>
                        <el-form-item label="地址:">
                            <el-input v-model="addFrom.homeAddress"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="userShow = false">取 消</el-button>
        <el-button type="primary" @click="setUser">确 定</el-button>
      </span>
        </el-dialog>

        <!-- 修改密码 -->
        <el-dialog title="密码修改"
                   @close="pwdclose"
                   :visible.sync="isPwd" width="30%">
            <el-form ref="pwdform" :model="form" :rules="rulesPWD" label-width="150px">
                <el-form-item label="请输入原密码：" prop="password">
                    <el-input type="password" v-model="form.password" placeholder="请输入原密码"></el-input>
                </el-form-item>
                <el-form-item label="请输入新密码：" prop="password1">
                    <el-input type="password" v-model="form.password1" placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="请再次确认新密码：" prop="password2">
                    <el-input type="password" v-model="form.password2" placeholder="请再次确认新密码"></el-input>
                </el-form-item>
                <el-form-item>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="isPwd = false">取 消</el-button>
        <el-button type="primary" @click="sunmitpwd">确 定</el-button>
      </span>
        </el-dialog>
    </el-container>
</template>
<script>
import {mapState} from "vuex";
import {userIdMenuList, security, setUser, getRolesAll, getUsername, getAuthAll} from '@/utils/user'
import Avater from '@/components/avater/index'
import * as myStore from "@/utils/stroage";
import {myconfig} from "@/utils/config";

export default {
    name: 'webCome',
    components: {
        Avater
    },
    data() {
        var pwd = (rule, value, cb) => {
            if (value === '') return cb(new Error('密码不能为空'))
            if (this.form.password1 !== value) return cb(new Error('两次密码不一致'))
            return cb()
        }
        var proof = (rule, value, cb) => {
            const reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
            if (!value) cb(new Error('身份证不能为空'))
            if (!reg.test(value)) {
                return cb(new Error('身份证输入错误'))
            }
            return cb()
        }

        var mobileReg = (rule, value, cb) => {
            const reg = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;
            if (!value) cb(new Error("手机号不能为空"));
            if (!reg.test(value)) {
                return cb(new Error("手机号输入错误"));
            }
            return cb();
        };

        var emailReg = (rule, value, cb) => {
            const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if (value === "") return cb(new Error("邮箱不能为空"));
            if (!reg.test(value)) {
                return cb(new Error("邮箱输入错误"));
            }
            return cb();
        };

        var username = (rule, value, cb) => {
            const reg = /(?!^\d+$)(?!^[a-zA-Z]+$)[0-9a-zA-Z]{1,30}/
            if (value === '') return cb()
            if (!reg.test(value)) return cb(new Error("格式错误,格式为字母加数字"))
            return cb()
        }
        return {
            // subindex:'0',
            openeds: [],
            toinp: '',
            list: [],
            options: [],
            iscollapse: false,
            editableTabsValue: "/index",
            disabled: true,
            visible: false,
            imgAvatar: '',
            genderList: [
                {
                    value: 1,
                    label: "男"
                },
                {
                    value: 2,
                    label: "女"
                },
                {
                    value: 3,
                    label: "保密"
                }
            ],
            userShow: false,
            addFrom: {//添加用户
                employeeId: '',
                username: '',
                realName: '',
                avatar: '',
                gender: 3,
                identifyCode: null,
                mobile: null,
                email: '',
                homeAddress: ''
            },
            addrules: {
                /**
                 realName: [
                 {required: true, message: '请输入真实姓名', trigger: 'blur'},
                 {min: 1, max: 18, message: '长度在 2 到 20 个字符', trigger: 'blur'}
                 ],
                 gender: [
                 {required: true, message: '请选择性别', trigger: 'change'},
                 ],
                 */
                // usedstatus:[
                //    { required: true , message: '请选择员工状态', trigger: 'change'},
                // ],
                /*
                identifyCode: [
                    {required: true, validator: proof, trigger: 'blur'}
                ],
                */
                username: [
                    {required: false, validator: username, trigger: 'blur'}
                ],
                mobile: [
                    {required: true, validator: mobileReg, trigger: 'blur'}
                ],
                /*
                email: [
                    {required: true, validator: emailReg, trigger: 'blur'}
                ]
                */
            },
            isdisabled: false,
            isPwd: false,
            form: {
                password: '',
                password1: '',
                password2: ''
            },
            rulesPWD: {
                password: [
                    {required: true, message: '请输入原密码', trigger: 'blur'}
                ],
                password1: [
                    {required: true, message: '请输入新密码', trigger: 'blur'},
                    {min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur'}
                ],
                password2: [
                    {required: true, validator: pwd, trigger: 'blur'}
                ]
            },
            fileAvater: null,
            isAvater: true,
            uploadImg: null,
            optionTab: [{
                id: 5056,
                name: '首页',
                path: '/index',
            }],
            tabmakr: false,
            tabsx: "",
            tabsy: "",
            tabindex: 0,
            indextas: 0,
        };
    },
    computed: {
        ...mapState(["tags", "token", "activeName", 'rolesStoreList']),
        userList() {
            if (typeof this.$store.state.user === 'string' && this.$store.state.user != "" && this.$store.state.user != null) {
                return JSON.parse(this.$store.state.user)
            } else {
                return this.$store.state.user
            }

        }
    },
    created() {
        window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
        this.getAuthInfo()

    },
    destroyed() {
        window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
    },
    mounted() {
        this.docinit()
        if (typeof this.tags === 'string') {
            this.options = JSON.parse(this.tags)
        } else {
            this.options = this.tags
        }
        this.editableTabsValue = this.$route.path;
        if (sessionStorage.getItem('tabs')) {
            let tabList = sessionStorage.getItem('tabs')
            this.optionTab = JSON.parse(tabList)
            let routePath = this.$route.path.substring(1)
            let falgTab = this.optionTab.some((item) => {
                return item.path == routePath
            })
            if (falgTab) {
                this.editableTabsValue = routePath;
            } else {
                let pathState=this.optionTab.some((item)=>{
                    return item.path=='force'
                })
                if (!pathState){
                    this.optionTab.push({
                        id: 205,
                        name: '场站列表',
                        path: 'force',
                    })
                }

                console.log('vvvvvvvvvvvvvvvvv',this.optionTab,pathState)
                this.editableTabsValue = 'force';
            }
            //console.log(this.optionTab, 'tabList', falgTab, routePath)
        } else {
            this.optionTab.push({
                id: 205,
                name: '场站列表',
                path: 'force',
            })
            this.editableTabsValue = 'force';
        }
        this.addEventListenerFun()
    },
    methods: {
        addEventListenerFun() {
            document.addEventListener('mouseup', (e) => {
                let divSearchs = document.querySelector('.toptab')

                if (divSearchs) {
                    if (!divSearchs.contains(event.target)) {
                        this.tabmakr = false
                    }
                }
            })
        },
        gbtabindex() {
            this.tabmakr = false
            let tabPath = this.editableTabsValue
            this.removeTab(tabPath)
        },
        gbleft() {
            this.tabmakr = false
            console.log(this.optionTab,'this.optionTab',this.tabindex)
            this.optionTab.splice(1, this.tabindex - 1).splice(this.tabindex, this.optionTab.length)
            sessionStorage.setItem("tabs", JSON.stringify(this.optionTab))

        },
        gbqt() {
            this.tabmakr = false
            this.optionTab.splice(1, this.tabindex - 1)
            this.optionTab.splice(2, this.optionTab.length)
            sessionStorage.setItem("tabs", JSON.stringify(this.optionTab))
        },
        openMenu(e) {
            this.tabsx = e.clientX
            this.tabsy = e.clientY
            this.tabmakr = true
        },

        // subcheck(id) {
        //     console.log('clickclick', id, this.subindex)
        //     this.subindex = id
        //     console.log('clickedclicked', this.subindex)
        // },
        inputNull() {
            if (!this.toinp) {
                this.getAuthInfo();
                this.openeds = [];
            }
        },
        // 模糊查询
        async inputChange() {
            await this.getAuthInfo()
            this.openeds = [];
            let dataList = [];
            if (this.toinp) {
                for (let item of this.list) {
                    // if(item.menuname.indexOf(this.toinp) > -1){
                    for (let ye of item.children) {
                        if (ye.menuName.indexOf(this.toinp) > -1) {
                            this.openeds.push(String(item.id));
                            console.log(this.openeds, 'this.openeds')
                        }

                        const sonReg = new RegExp(this.toinp, "ig");
                        ye.menuName = ye.menuName.replace(sonReg, (val) =>
                            `<span style="color:#585858;background-color: #FFFF00;position: relative;top: -2px;">${val}</span>`)
                    }
                    const reg = new RegExp(this.toinp, "ig");
                    let obj = {
                        id: item.id,
                        menuName: item.menuName.replace(reg, (val) =>
                            `<span style="color:#585858;background-color: #FFFF00;">${val}</span>`),
                        createId: item.createId,
                        createName: item.createName,
                        createTime: item.createTime,
                        delFlg: item.delFlg,
                        functionCode: item.functionCode,
                        menuCaption: item.menuCaption,
                        menuImage: item.menuImage,
                        menuLevel: item.menuLevel,
                        menuOrder: item.menuOrder,
                        parentId: item.parentId,
                        path: item.path,
                        remark: item.remark,
                        updateId: item.updateId,
                        updateName: item.updateName,
                        updateTime: item.updateTime,
                        children: item.children
                    }
                    dataList.push(obj);
                }
                // }
                this.list = dataList;
            } else {
                this.getAuthInfo()
                this.openeds = []
            }
        },
        setStorage() {
            window.sessionStorage.setItem('tabs', JSON.stringify(this.optionTab))
        },
        //点击导航栏跳转对应tabs
        addLabel(e) {
            if (e.path !== "/") {
                const result = this.optionTab.some((item) => {
                    return item.id === e.id;
                });
                const path = this.optionTab.find((item) => {
                    return item.path == e.path;
                });
                if (result) {
                    // 有 v-model实现侧边与页签选中统一
                    this.editableTabsValue = path.path;
                } else {
                    //   //没有
                    this.optionTab.push({
                        id: e.id,
                        name: e.menuName,
                        path: e.path,
                    });
                    this.editableTabsValue = e.path;
                }
                this.setStorage()
            }
            this.optionTab.findIndex((item,index) => {
                if (item.path === e.path) {
                    this.tabindex = index
                }
            })
        },
        removeTab(e) {
            console.log('target', e)
            let tabs = this.optionTab;

            let activeName;
            console.log('target', tabs)
            tabs.forEach((tab, index) => {
                if (tab.path === e) {
                    let nextTab
                    if (tabs[index + 1]) {
                        nextTab = tabs[index + 1]
                    } else {
                        nextTab = tabs[index - 1]
                    }
                    if (nextTab) {
                        console.log('target', nextTab)
                        activeName = nextTab.path;
                        this.$router.push(activeName);
                    }
                }
            });
            this.editableTabsValue = activeName;
            this.optionTab = tabs.filter((tab) => {
                return tab.path !== e;
            });
            console.log('target', this.optionTab)
            this.setStorage()
        },
        pushTabs(e) {
            console.log('push', e)
            this.tabindex = e.index
            this.optionTab.forEach((item) => {
                if (item.path === e.name) {
                    return false;
                } else {
                    this.editableTabsValue = e.name;
                    this.$router.push(e.name);
                }
            });
        },
        beforeunloadFn(e) {
            console.log('welcome刷新或关闭');

        },
        onload(e) {
            console.log('welcome刷新');
            this.getAuthInfo()
        },
        async getAuthInfo() {
            //console.log('welcome->getAuthInfo');
            try {
                let userId = this.userList.id
                const res = await userIdMenuList(userId)
                //console.log('welcome->userIdMenuList,res:',JSON.stringify(res));
                if (res.data.code !== 200) {
                    return this.$msg.error('获取用户菜单列表失败')
                }

                const result = await getRolesAll()
                //-console.log('welcome->getRolesAll,result:',JSON.stringify(result));
                // if (res.status !== 200 && result.status !== 200) return this.$msg.error('获取用户权限列表失败')
                if (result.data.code !== 200) {
                    return this.$msg.error('获取角色列表失败')
                }
                this.$store.commit('getRoles', result.data.data)

                let newList = res.data.data;
                if (newList.length !== 0) {
                    let newArr = newList.reduce((item, row) => {
                        if (row.menuLevel === 0) {
                            let i = item.findIndex(c => c.id === row.id)
                            i === -1 && item.push(row)
                            i !== -1 && (item[i] = row)
                        }
                        return item
                    }, [])
                    // newArr = newArr.sort((a, b) => a.id - b.id);
                    let newItem = newList.reduce((item, row) => {
                        if (row.menuLevel === 1) {
                            let i = item.findIndex(c => c.id === row.id)
                            i === -1 && item.push(row)
                            i !== -1 && (item[i] = row)
                        }
                        return item
                    }, [])
                    newArr.forEach(item => {
                        newItem.forEach(item1 => {
                            if (item.id === item1.parentId) {
                                if ('children' in item) {
                                    item.children.push(item1)
                                } else {
                                    item.children = []
                                    item.children.push(item1)
                                }
                            }
                        })
                    })

                    this.list = newArr;

                }
            } catch (e) {
            }
        },
        onAvater(file) {
            this.fileAvater = file
        },
        // goHome(path) {
        //   this.editableTabsValue = path;
        // },
        userClick() {

            const box = document.querySelector('#user-box')
            box.style.display = 'block'
        },
        docinit() {
            document.onclick = function (e) {
                const box = document.getElementById('user-box')
                const header = document.querySelector('.header-user')
                const elHeader = document.querySelector('.el-header')
                if (elHeader && window.location.href.split('#')[1] !== '/login' && !header.contains(e.target) && !box.contains(e.target)) {
                    box.style.display = 'none'
                }

            }
        },
        imgchange(val) {
            this.isAvater = val
        },
        userInfo() {
            this.userShow = true
            this.addFrom = this.userList
            this.uploadImg = this.userList.avatar
        },
        loginEnd() {
            myStore.sessionRemoveItem("electric-token");
            myStore.sessionRemoveItem("electric-user");
            // removeItem("electric-token");
            // removeItem("electric-user");
            myStore.sessionRemoveItem("function-code");
            this.$store.commit('removeState', true)
            this.visible = false
            this.$router.push("/login");
        },
        setUser() {
            this.$refs.addRefFrom.validate(async valid => {
                if (!valid) return this.$msg('用户信息不能为空')
                try {
                    let data = JSON.parse(JSON.stringify(this.addFrom))
                    //头像更改成功，则提交更改过后的地址
                    this.fileAvater && (data.avatar = this.fileAvater.url)
                    const result = await setUser(data)
                    if (result.data.code !== 200) return this.$msg.error('修改用户信息失败!')
                    this.$msg.success('修改用户信息成功!')
                    const res = await getUsername(this.addFrom.username)
                    this.$store.commit('userInfo', JSON.stringify(res.data.data))
                    this.userShow = false
                } catch (e) {
                }
            })


        },
        close() {
            const data = {
                gender: 3
            }
            this.addFrom = data
            this.isdisabled = false
            this.isAvater = false
            this.uploadImg = null
            this.userShow = false
        },
        pwdShow() {
            const box = document.getElementById('user-box')
            box.style.display = 'none'
            this.isPwd = true
        },
        pwdclose() {
            this.$refs.pwdform.resetFields()
            this.form = {}
        },
        sunmitpwd() {
            this.$refs.pwdform.validate(async valid => {
                if (!valid) return this.$msg.error('密码不能为空！')
                const data = {
                    id: this.userList.id + '',
                    password: this.form.password,
                    newPassword: this.form.password2
                }
                const res = await security(data)
                if (res.status !== 200 && !res.data) return this.$msg.error('重置密码失败!')
                this.$msg.success('重置密码成功!')
                this.form = {}
                setTimeout(() => {
                    this.$router.push('/login')
                }, 1500)
            })
        }
    },
};
</script>

<style lang="less" scoped>
.el-container {
    width: 100%;
    height: 100%;
    font-size: 24px;

    .menu-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        flex-basis: auto;
        box-sizing: border-box;
        overflow: hidden;
    }

    .sidebar {
        width: 100%;
        height: calc(~'100vh - 64px');
        background: #0D6CE4;
        //background: url('~@/assets/image/menuBg.png') 100% no-repeat;
        //background-size: 100% 100%;
        border-right: #0D6CE4 solid 1px;

    }

    .sidebar-xs {
        width: 100%;
        height: calc(~'100vh - 64px');
        background: #0D6CE4;
        //background: url('~@/assets/image/menuBg.png') 100% no-repeat;//原来的背景图
        //background-size: 100% 100%;
        border-right: #0D6CE4 solid 1px;
        overflow: hidden;
        overflow-y: auto;
    }

    .logo {
        width: 180px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .describle {
        width: 100%;
        height: 40px;
        background: url('~@/assets/image/webcome.png') no-repeat;
        background-size: 100% 100%;

    }

    .el-header {
        //background-color: #2a82e4;
        background-color: #ffffff;
        height: 64px !important;
        border-bottom: 1px solid #eee;

        .hander {
            width: 100%;
            height: 100%;
            border: 0.5px solid #ffffff;

            .el-col {
                &:nth-child(1) {
                    float: left;
                    display: flex;
                    align-items: center;

                    .hander-left {
                        display: flex;
                        align-items: center;
                        margin-left: 20px;
                        color: rgba(13, 108, 228, 0.90);
                        font-size: 20px;
                        font-weight: 500;

                        > i {
                            cursor: pointer;
                        }

                        h3 {
                            margin-left: 5px;

                        }
                    }
                }

                &:nth-child(2) {
                    position: relative;
                    float: right;
                    color: #0c0c0c;;
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 2%;

                    .header-user {
                        height: 100%;
                        padding: 0 2%;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        .el-image {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;

                            .el-image__error {
                                font-size: 12px;
                            }
                        }

                        > span {
                            font-size: 16px;
                            margin-left: 10px;
                        }
                    }

                    .header-user:hover {
                        background-color: #f0f2f5;
                        color: #2a82e4;
                    }

                    .arrow_down {
                        width: 20px;
                        height: 20px;
                        margin: 8px 20px 0 13px;
                    }

                    #user-box {
                        position: absolute;
                        padding: 0 4%;
                        top: 107%;
                        right: 12%;
                        display: none;
                        background-color: #f0f2f5;
                        color: #2a82e4;
                        z-index: 999;
                        border: 2px solid #f0f2f5;
                        border-radius: 6px;

                        .user-pisition {
                            display: flex;
                            flex-direction: column;
                            padding: 6px 15px;

                            .user-img {
                                display: flex;
                                align-items: center;
                                padding: 6px 0 6px 20px;
                                border-bottom: 1px solid #ccc;

                                .el-image {
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 50%;

                                    .el-image__error {
                                        font-size: 12px;
                                    }
                                }

                                > span {
                                    font-size: 16px;
                                    margin-left: 10px;
                                }
                            }

                            .user-content {
                                //padding: 6px 0;
                                padding: 6px 0;
                                float: left;
                                margin-left: 5px;
                                background-color: #ffffff;
                                border: 1px solid #2a82e4;

                                span {
                                    font-size: 16px;
                                    padding: 0 8px;
                                    cursor: pointer;
                                }
                            }

                            .user-end {
                                text-align: center;
                                font-size: 16px;
                                padding: 6px 0;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }


    //左侧导航栏
    /deep/ .el-menu {
        background-color: #FFFFFF1A !important;
        color: rgba(255, 255, 255, 0.7);
        font-size: 24px;
    }

    //.el-menu:hover {
    //    width: 100%;
    //    color: #2a82e4;
    //}

    .el-submenu {
        //background: #2a82e4 !important;
        //background: linear-gradient(90deg, #2692E5, #0046AD) !important;
        background: #0D6CE4;
        color: rgba(255, 255, 255, 0.7);
        height: auto;
    }

    //.el-submenu:hover {
    //    //background: #2a82e4 !important;
    //    //background: linear-gradient(90deg, #2692E5, #0046AD) !important;
    //    background: linear-gradient(90deg, #2692E5, #0046AD) !important;
    //    color: #ffffff;
    //    height: auto;
    //}


    .el-menu-item {
        //background-color: #0D6CE4;
        height: 40px;
        line-height: 40px;
        color: rgba(255, 255, 255, 0.7);
    }

    .el-menu-item:hover {
        background-color: rgba(255, 255, 255, 0.2) !important;
        //color: #FFFFFF !important;
    }

    .menu-Inf {
        display: inline-block;
        margin-left: 10px;
    }

    .menu-InfSmall {
        display: none;
    }

    .btn-img {
        //width: 20px;
        color: #FFFFFF;
        margin-right: 16px;
        display: flex;
        align-items: center;
    }

    .btn-img-small {
        //width: 20px;
        color: #FFFFFF;
    }


    .in-open {
        width: 100%;
        background-color: #0D6CE4;
        display: flex;
        justify-content: center;
        color: #FFFFFF;
        height: 40px;
        align-items: center;
    }

    .in-close {
        width: 200px;
        background-color: #0D6CE4;
        display: flex;
        justify-content: flex-end;
        color: #FFFFFF;
        height: 40px;
        //line-height: 40px;
    }

    .menu-item {
        width: 100%;
        color: rgba(255, 255, 255, 0.7);
    }

    .menu-itemsmall {
        color: #FFFFFF;
        //width: 64px;
        display: flex;
        justify-content: center;
        line-height: 56px;
    }

    /deep/ .menu-item:hover {
        width: 100%;
        color: rgba(255, 255, 255, 0.1);
        //background-color:  rgba(255, 255, 255, 0.2) !important;
    }

    //.menu-item2 {
    //    background-color: #0D6CE4;
    //    color: rgba(255, 255, 255, 0.7);
    //}
    //
    //.menu-item2:hover {
    //    background-color: rgba(255, 255, 255, 0.2);
    //    width: 100%;
    //    //background: url('~@/assets/image/menuItem.png');
    //    //color: rgba(255, 255, 255, 0.7);
    //}

    .el-menu-item.is-active { //二级选中的样式
        //background: url('~@/assets/image/menuItem.png') 100% no-repeat;
        color: #0D6CE4;
        background-color: #FFFFFF !important;

    }

    /deep/ .el-submenu__title { //一级菜单样式
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        line-height: 48px;
    }

    .el-submenu__title.is-active {
        //background: #2a82e4 !important;
        color: rgba(255, 255, 255, 0.2);
    }


    /deep/ .el-submenu__title:hover { //一级菜单
        background-color: rgba(255, 255, 255, 0.2) !important;
        color: rgba(255, 255, 255, 1) !important;

        .menu-item {
            color: #fff;
        }
    }

    /deep/ .el-submenu__title i { //侧边箭头
        color: #FFFFFFB2;

    }

    //右侧内容区域
    .el-main {
        padding: 0;
        position: relative;
        display: flex;

        .main-hander {
            position: absolute;
            top: 0;
            width: 100%;
            height: 0px;
            display: flex;
            align-items: center;
            background-color: #f3f3f3;
            color: #1b80e1;
            z-index: 1;
            overflow: auto;
        }

        .main-hander::-webkit-scrollbar {
            width: 0;
            height: 6px;
            /**/
        }

        .main-hander::-webkit-scrollbar-track {
            background: rgb(239, 239, 239);
            border-radius: 2px;
        }

        .main-hander::-webkit-scrollbar-thumb {
            background: #bfbfbf;
            border-radius: 10px;
        }

        .main-hander::-webkit-scrollbar-thumb:hover {
            background: #1b80e1;
        }

        .main-hander::-webkit-scrollbar-corner {
            background: #179a16;
        }

        .main-content {
            width: 100%;
            height: 100%;
            //padding-top: 55px;
            background: #F5F7FA;
            //padding: 65px 1px 1px;
            //padding: 2px 1px 1px;
            overflow: hidden;
        }
    }
}

.el-popover__reference-wrapper {
    > .el-button {
        border: 0;
        background-color: #1b80e1;
        color: #fff;
    }
}

.el-select {
    width: 100%;
}

.avatar-box {
    width: 100%;
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    .avatar-item {
        width: 150px;
        height: 150px;
        border: 1px dashed #666;
        position: relative;
        border-radius: 10px;
        margin-right: 10px;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: #666;
        }
    }

    .avatar-item:hover {
        border: 1px dashed #409eff;

        .icon {
            color: #409eff;
        }
    }
}

.contnent-popover {
    > p {
        text-align: center;
        margin-bottom: 10px;
    }

    .popover-div {

        display: flex;
        align-items: center;
        justify-content: space-around;

    }
}

.el-tabs--card > .el-tabs__header {
    border: 0;
    height: 20px;
    margin: 0;
}

.el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0;
}

.el-tabs--card > .el-tabs__header .el-tabs__item {
    border: 0;
    color: #2a82e4;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    border-bottom: 1px solid #409eff;
}

.el-scrollbar__view {
    width: 100%;
    height: 100%;
}

.el-scrollbar__wrap {
    overflow-x: hidden;
}

.is-horizontal {
    display: none;
}

.iconfont {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 18px;
}

.icon-che {
    font-size: 14px;
}

.scroll-reses {
    height: 100%;

    > .el-scrollbar__wrap {
        overflow-x: auto;
    }

    > .el-scrollbar__wrap::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        /**/
    }

    > .el-scrollbar__wrap::-webkit-scrollbar-track {
        background: rgb(239, 239, 239);
        border-radius: 2px;
    }

    > .el-scrollbar__wrap::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 10px;
    }

    > .el-scrollbar__wrap::-webkit-scrollbar-thumb:hover {
        background: #4a637c;
    }

    > .el-scrollbar__wrap::-webkit-scrollbar-corner {
        background: #179a16;
    }
}

.el-tabs__nav {
    /deep/ .el-tabs__item:nth-child(1) {
        span {
            display: none;
        }
    }
}

.el-aside {
    transition: width 0.25s;
}

.search-title { //侧边搜索的样式
    padding: 8px 16px;

    /deep/ .el-input__inner {
        height: 32px;
        line-height: 32px;
        background: #0D6CE4;
        color: #FFFFFF;
        border: 1px solid rgba(255, 255, 255, 0.7);
    }

    /deep/ .el-input__inner::placeholder {
        color: rgba(255, 255, 255, 0.7);
    }

    /deep/ .el-input__icon {
        color: #FFFFFFB2;
        line-height: 32px;
    }
}

.search-small {
    height: 48px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-s-fold {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/icon-s-fold.svg") no-repeat;
    background-size: 100% 100%;
}

.icon-s-unfold {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/icon-s-unfold.svg") no-repeat;
    background-size: 100% 100%;
}

.icon-search {
    width: 16px;
    height: 16px;
    background: url("~@/assets/image/search.svg") no-repeat;
    background-size: 100% 100%;
}

::-webkit-scrollbar {
    width: 0px; /*滚动条宽度*/
    height: 10px; /*滚动条高度*/
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: #0D6CE4; /*滚动条的背景颜色*/
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px; /*滚动条的圆角*/
    background-color: rgba(255, 255, 255, 0.7); /*滚动条的背景颜色*/
}

.title-child {
    display: inline-block;
    margin-left: 10px;
}

/deep/ .el-tabs__nav-scroll {
    height: 48px;
    background: #fff;
}

/deep/ .el-tabs {
    height: 48px;
}

/deep/ .el-tabs__item {
    height: 48px;
    line-height: 48px;
    background: #fff;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0px;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__item.is-active.is-closable {
    background: #F5F7FA;

}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__item {
    border: 0px;
}

/deep/ .el-tabs__nav-wrap.is-scrollable {
    background: #FFFFFF;
}

/deep/ .el-tabs__nav-prev, /deep/ .el-tabs__nav-next {
    line-height: 48px;
    font-size: 20px;
}

/deep/ .el-tabs__item.is-active {
    color: #0D6CE4;
}

/deep/ .el-tabs__item:hover {
    color: #0D6CE4;
}

/deep/ .el-tabs__item .el-icon-close:hover { //页签悬浮的叉
    color: #0D6CE4;
    background: #FFFFFF;
}

/deep/ .app-container {
    margin: 0px;
}

/deep/ .el-scrollbar__view {
    padding: 16px;
}

.coll-small {
    width: 64px !important;
}

.coll-large {
    width: 200px !important;
}

.dashboard {

}
//右侧内容区域
.toptab {
    position: fixed;
    //padding: 21px;
    box-sizing: border-box;
    z-index: 1;

    width: 200px;
    height: 114px;
    background-color: #fff;
    box-shadow: 0px 2.66667px 16px rgba(0, 0, 0, 0.2);

    .gb {
        width: 200px;
        height: 34px;
        //font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #585858;
        box-sizing: border-box;
        cursor: pointer;
    }

    .activegb {
        width: 200px;
        height: 34px;
        //font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #585858;
        box-sizing: border-box;
        background: #F5F7FA;
        color: #4C91FF;
        cursor: pointer;
    }
}
</style>
<style lang="less">

</style>
