<template>
  <el-card>
    <el-row>
      <el-form :inline="true">
        <el-col :span="3">
          <el-input placeholder="请输入轮播图名称" v-model=" informationVo.fnode"></el-input>
        </el-col>
      </el-form>
      <el-col :span="3">
        <el-select v-model="informationVo.fstatus">
          <el-option
            v-for="item in selectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-button @click="getAllPage()" type="primary" icon="el-icon-search">查询</el-button>
        <el-button @click="resFuntion">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-button size="small" @click="addOrUpdate =true" class="el-icon-plus" type="primary"> 新增</el-button>
      <!--      <el-button type="primary" icon="el-icon-upload2" size="small" @click="importExcel">批量导入</el-button>-->
      <!--      <el-button size="small" icon="el-icon-download" @click="excelAll" type="primary">批量导出</el-button>-->
      <el-button size="small" @click="removeAll" type="danger" icon="el-icon-delete">选中删除</el-button>
    </el-row>
    <el-row>
      <el-alert show-icon type="info">
        <span slot="title">
          已选择{{batchNum.length}}项
           <span @click="cleanList" style="margin-left:10px;color:#409eff;cursor:pointer;">清空</span>
        </span>
      </el-alert>
    </el-row>
    <el-row>
      <el-table ref="equipTable" id="equipment" @selection-change="batchList" :data="tableData" style="width: 100%">
        <el-table-column type="selection"></el-table-column>
        <!--        <el-table-column label="序号" prop="fid"></el-table-column>-->
        <el-table-column label="编码" type="index" width="80" :index="renIndex"></el-table-column>
        <el-table-column label="图片编号" prop="fpicId"></el-table-column>
        <el-table-column label="图片名称" prop="fpicName"></el-table-column>
        <el-table-column label="轮播顺序" prop="fcyclicOrder"></el-table-column>
        <el-table-column label="图片规格" prop="fpicType"></el-table-column>
        <el-table-column label="URL地址" prop="furl" min-width="150"></el-table-column>
        <el-table-column label="图片" prop="ffilePath" min-width="150">
          <template slot-scope="scope">
          <el-image
            :src="scope.row.ffilePath"
            lazy
           ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="是否启用" prop="fisActived">
          <template slot-scope="scope">
            <span v-if="scope.row.fisActived===1">启用</span>
            <span v-if="scope.row.fisActived===0">禁用</span>
          </template>

        </el-table-column>
        <el-table-column label="创建时间" prop="fcreateTime" min-width="150"></el-table-column>
        <el-table-column label="修改时间" prop="fmodifyTime" min-width="150"></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button @click="compileEqu(scope.row)" size="small" type="primary" icon="el-icon-edit">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[5, 10, 15]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-row>

    <el-dialog :title="isequipemt?'新增信息':'修改信息'" :visible.sync="addOrUpdate" width="40%" @close="close">
      <el-form size="small" ref="equipform" :rules="equipmentRules" :model="informationForm" label-width="130px">

        <el-row>
          <el-col :span="11">
            <el-form-item label="图片编号:" prop="fpicId">
              <el-input v-model="informationForm.fpicId"></el-input>
            </el-form-item>

            <el-form-item label="图片名称:" prop="fpicName">
              <el-input v-model="informationForm.fpicName"></el-input>
            </el-form-item>

            <el-form-item class="Form-item" label="图片:" prop="ffilePath">
              <template>
                <Avater @on-avater="onAvater" @on-imgchange="imgchange" :uploadImg="uploadImg" :isAvater="isAvater"/>
              </template>
            </el-form-item>

          </el-col>
          <el-col :span="11">
            <el-form-item label="轮播顺序:" prop="fcyclicOrder">
              <el-input v-model="informationForm.fcyclicOrder"></el-input>
            </el-form-item>

            <el-form-item label="是否启用:" prop="fisActived">
              <el-select v-model="informationForm.fisActived">
                <el-option
                  v-for="item in selectOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <!--              <el-input v-model="informationForm.fstatus"></el-input>-->
            </el-form-item>
            <el-form-item label="图片规格:" prop="fpicType">
              <el-input v-model="informationForm.fpicType"></el-input>
            </el-form-item>
            <el-form-item label="URL地址:" prop="furl">
              <el-input v-model="informationForm.furl"></el-input>
            </el-form-item>

          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addOrUpdate = false">取 消</el-button>
        <el-button type="primary" @click="newequipemt">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
  import {
    getNodePage,
    getNode,
    addNode,
    updateNode,
    deleteNode
  } from '@/utils/station2'
  import {
    CyclicPicInfoPage,
    addCyclicPic,
    updateCyclicPic,
    deleteCyclicPic
  } from '@/utils/operation2'
  import {getForceList} from '@/utils/station'
  import moment from 'moment'
  import {createAPI} from "../../../utils/request";
  import {mapState} from "vuex";
  import Avater from '@/components/avater'
  export default {
    components: {
      Avater
    },
    // name: 'recharge',
    data() {
      return {
        uploadImg: null,
        isAvater: true,
        fileAvater: null,

        selectOptions: [
          {
            value: 1,
            label: "启用",
          },
          {
            value: 0,
            label: "禁用",
          },
        ],
        coulm: [
          // {prop:'fid',label:'序号'},
          {prop: 'fthValNo', label: '报警阀值编号'},
          {prop: 'fthValName', label: '报警阀值名称'},
          {prop: 'feqmNo', label: '设备编号'},
          {prop: 'feqmNo', label: '设备编号'},
          {prop: 'feqmName', label: '设备名称'},
          {prop: 'feqmModeNo', label: '设备类型编号'},
          {prop: 'feqmModeName', label: '设备类型名称'},
          {prop: 'fthValue', label: '报警值'},
          {prop: 'fthValType', label: '报警值类别'},
          {prop: 'fthValType', label: '报警阈值类别'},
          {prop: 'fremark', label: '备注'},
          {prop: 'fcreateTime', label: '创建时间'},
          {prop: 'fmodifyTime', label: '修改时间'}
        ],
        informationVo: {},
        isPage: true,//查询
        query: {
          pageNum: 1,
          pageSize: 5
        },
        total: 0,
        addOrUpdate: false,
        isequipemt: true,
        batchNum: [],
        tableData: [],
        srcList:[],
        allList: [],
        stateList: [
          {
            value: 1,
            label: '在役'
          },
          {
            value: 0,
            label: '退役'
          }
        ],
        informationForm: {},
        equipmentRules: {

          fnode: [
            {required: true, message: '请输入云平台区域编号', trigger: 'blur'}
          ],
          fstatus: [
            {required: true, message: '请选择状态', trigger: 'blur'}
          ],
          fdescription: [
            {required: true, message: '请输入云平台区域描述', trigger: 'blur'}
          ],

        },
        brandList: [],
        unitTypeList: [],
      }
    },
    computed: {
      ...mapState(['user']),
      username() {
        return JSON.parse(this.user).name
      }

    },
    created() {
      this.informationForm.fcreator = this.username
      this.getAllPage()
      // this. getAllList()
    },
    methods: {
      onAvater(file) {
        this.fileAvater = file
      },
      imgchange(val) {
        this.isAvater = val
      },

      renIndex(index) {
        return (this.query.pageNum - 1) * this.query.pageSize + index + 1
      },
      async getAllPage() {
        const result = await CyclicPicInfoPage(this.query.pageNum, this.query.pageSize, this.informationVo)
        console.log(result)
        if (result.data.code !== 20000)
          return this.$msg.error('获取能耗设备型号信息失败!')
        this.tableData = result.data.data.rows.records
        this.total = result.data.data.rows.total
        this.srcList= this.tableData.ffilePath

      },
      //重置
      resFuntion() {
        this.informationVo = {}
        this.getAllPage()
      },
      batchList(list) {
        this.batchNum = list
      },
      close() {
        const data = {
          fcreator: this.username,
        }

        this.$refs.equipform.resetFields()
        this.isAvater = false
        this.uploadImg = null
        this.fileAvater = null
        this.informationForm = data
        this.isequipemt = true
      },

      //新增与修改
      newequipemt() {
        this.$refs.equipform.validate(async valid => {
          if (!valid) return this.$msg.info('信息不能为空!')
          const data = JSON.parse(JSON.stringify(this.informationForm))
          // data.releaseDate = moment(data.releaseDate).format('x')
          this.fileAvater && (data.ffilePath = this.fileAvater)
          try {
            if (this.isequipemt) {
              // console.log(data)
              const res = await addCyclicPic(data)
              // console.log(res)
              if (res.data.code !== 20000) return this.$msg.error('新增信息失败!')
              this.$msg.success('新增信息成功!')
              this.addOrUpdate = false
              this.getAllPage()
            } else {
              // console.log(data)
              const res = await updateCyclicPic(data)
              // console.log(res)
              if (res.data.code !== 20000) return this.$msg.error('修改信息失败!')
              this.$msg.success('修改换信息成功!')
              this.addOrUpdate = false
              this.getAllPage()
            }
          } catch (error) {
          }
        })

      },
      //修改信息填充
      compileEqu(list) {
        this.addOrUpdate = true
        this.isequipemt = false
        var data = JSON.parse(JSON.stringify(list))
        this.informationForm = data
        this.uploadImg = data.ffilePath
      },

      handleSizeChange(val) {
        this.query.pageSize = val
        if (this.isPage) {
          this.getAllPage()
        } else {
          this.getAllPage()
        }
      },
      handleCurrentChange(val) {
        this.query.pageNum = val
        if (this.isPage) {
          this.getAllPage()
        } else {
          this.getAllPage()
        }
      },
      // //获取所有电池型号信息
      // async getAllList(){
      //   const res =await getVehicleModelsAll()
      //   this.allList = res.data.data
      //   console.log(res)
      // },
      async excelAll() {
        const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e => e)
        if (confirm !== 'confirm') return
        if (this.batchNum.length === 0) {
          this.$download(this.allList, this.coulm, '报警阈值管理')
        } else {
          this.$download(this.batchNum, this.coulm, '报警阈值管理')
        }
      },
      cleanList() {
        this.$refs.equipTable.clearSelection()
      },
      //批量删除
      async removeAll() {
        if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
        const config = await this.$MessageBox.confirm(
          "此操作将永久删除选中项, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        ).catch(e => e);
        if (config !== "confirm") return;
        try {
          const list = this.batchNum.map(item => {
            return item.fid
          })
          const res = await deleteCyclicPic(list)
          if (res.data.code !== 20000) return this.$msg.error('删除失败')
          this.tableData = this.tableData.filter((item, i) => {
            return item.id !== list[i]
          })
          this.$refs.equipTable.clearSelection()
          if (this.query.pageNum > 1 && this.tableData.length === 0) {
            this.query.pageNum = this.query.pageNum - 1
          }
          this.$msg.success('删除成功')
          this.getAllPage()
        } catch (e) {
          console.log(e);
        }
      }
    },
  }
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 20px;
    }
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
  }

</style>
