<template>
  <el-card>
    <h2 style="text-align: center;">退款订单信息</h2>
    <el-steps :active="1" finish-status="success" process-status="wait" align-center style="margin-bottom: 40px;">
      <el-step title="退款订单信息"></el-step>
      <el-step title="换电订单信息"></el-step>
      <el-step title="退款审核"></el-step>
    </el-steps>
    <el-divider></el-divider>

    <el-form ref="form" :model="swapInfo" label-width="90px">
      <el-col :span="12">
        <el-form-item label="退款订单:">
          <!--        <el-input v-model="swapInfo.frefundId"></el-input>-->
          <span style="color: #409EFF">{{swapInfo.frefundId}}</span>
        </el-form-item>
        <el-form-item label="换电订单号:">
          <span style="color: #409EFF">{{swapInfo.forderNo}}</span>
          <!--        <el-input v-model="swapInfo.forderNo"></el-input>-->
        </el-form-item>
        <el-form-item label="退款金额:">
          <span style="color: #409EFF">{{swapInfo.frefundAmount}}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">

        <el-form-item label="付款平台:">
          <span style="color: #409EFF" v-if="swapInfo.frefillPlatform===1">微信支付</span>
          <span style="color: #409EFF" v-if="swapInfo.frefillPlatform===2">支付宝支付</span>
        </el-form-item>
<!--        <el-form-item label="付款账号:">-->
<!--          <span style="color: #409EFF">{{swapInfo.frefillAmount}}</span>-->
<!--        </el-form-item>-->
        <el-form-item label="登录号码:">
          <span style="color: #409EFF">{{swapInfo.floginNo}}</span>
        </el-form-item>
<!--        <el-form-item label="登录方式:">-->
<!--          &lt;!&ndash;0:手机号码；1:微信号码;2:网站注册号码;3:支付宝号码&ndash;&gt;-->
<!--          <span style="color: #409EFF" v-if="swapInfo.floginType===0">手机号码</span>-->
<!--          <span style="color: #409EFF" v-if="swapInfo.floginType===1">微信号码</span>-->
<!--          <span style="color: #409EFF" v-if="swapInfo.floginType===2">网站注册号码</span>-->
<!--          <span style="color: #409EFF" v-if="swapInfo.floginType===3">支付宝号码</span>-->
<!--        </el-form-item>-->
        <el-form-item label="申请时间:">
          <span style="color: #409EFF">{{swapInfo.ftime}}</span>
        </el-form-item>
      </el-col>
      <el-button @click="compileEqu(swapInfo.forderNo)" size="small" type="primary" icon="el-icon-edit">查看换电订单信息</el-button>
    </el-form>
<!--    <el-button style="margin-top: 12px;" @click="next">下一步</el-button>-->


    <el-dialog :title="isequipemt?'换电订单信息':'换电订单信息'" :visible.sync="addOrUpdate" width="80%" @close="close">

        <el-form ref="equipform" :model="clientFrom" label-width="170px">
          <h2 style="text-align: center;">换电订单信息</h2>
          <el-steps :active="2" finish-status="success" process-status="wait" align-center style="margin-bottom: 40px;">
            <el-step title="退款订单信息"></el-step>
            <el-step title="换电订单信息"></el-step>
            <el-step title="退款审核"></el-step>
          </el-steps>
          <el-divider></el-divider>

          <el-col :span="10">
            <el-form-item label="换电订单号:">
              <span  style="color: #409EFF">{{clientFrom.forderNo}}</span>
              <!--        <el-input v-model="swapInfo.forderNo"></el-input>-->
            </el-form-item>

            <el-form-item label="车辆VIN码:">
              <span style="color: #409EFF">{{clientFrom.fcarVin}}</span>
            </el-form-item>

            <el-form-item label="拆下电池序列号:">
              <span style="color: #409EFF">{{clientFrom.fdownBatteryNo}}</span>
            </el-form-item>
            <el-form-item label="装上电池序列号:">
              <span style="color: #409EFF">{{clientFrom.fupBatteryNo}}</span>
            </el-form-item>

            <el-form-item label="车牌识别时间:">
              <span style="color: #409EFF">{{clientFrom.fcardidtTimeNum}}</span>
            </el-form-item>
            <el-form-item label="换电开始时刻:">
              <span style="color: #409EFF">{{clientFrom.fswapStartTime}}</span>
            </el-form-item>
            <el-form-item label="换电结束时刻:">
              <span style="color: #409EFF">{{clientFrom.fswapEndTime}}</span>
            </el-form-item>
            <el-form-item label="订单换电日期:">
              <span style="color: #409EFF">{{clientFrom.fdate}}</span>
            </el-form-item>
            <el-form-item label="车辆离开时间:">
              <span style="color: #409EFF">{{clientFrom.fcarDepartureTime}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="汽车车牌号:">
              <span style="color: #409EFF">{{clientFrom.fcarNo}}</span>
            </el-form-item>
            <el-form-item label="换电站编码:">
              <span style="color: #409EFF">{{clientFrom.fstationNo}}</span>
            </el-form-item>


            <el-form-item label="拆下电池SOC:">
              <span style="color: #409EFF">{{clientFrom.fdownBatterySoc}}</span>
            </el-form-item>
            <el-form-item label="装上电池SOC:">
              <span style="color: #409EFF">{{clientFrom.fupBatterySoc}}</span>
            </el-form-item>
            <el-form-item label="装上电池SOE:">
              <span style="color: #409EFF">{{clientFrom.fupBatterySoe}}</span>
            </el-form-item>
            <el-form-item label="拆下电池行驶里程:">
              <span style="color: #409EFF">{{clientFrom.fdownTotalOdometer}}</span>
            </el-form-item>
            <el-form-item label="装上电池行驶里程:">
              <span style="color: #409EFF">{{clientFrom.fupTotalOdometer}}</span>
            </el-form-item>
            <el-form-item label="拆下电池外接充电次数:">
              <span style="color: #409EFF">{{clientFrom.fdownExtChargeCount}}</span>
            </el-form-item>
            <el-form-item label="拆下电池外接充电能量:">
              <span style="color: #409EFF">{{clientFrom.fdownExtChargeEnergy}}</span>
            </el-form-item>
            <!--        <el-form-item label="拆下电池上次换电完成出站时SOC:">-->
            <!--          <span style="color: #409EFF">{{swapInfo.fdownBatteryLastSoc}}</span>-->
            <!--        </el-form-item>-->
            <!--        <el-form-item label="拆下电池上次换电完成出站时SOE:">-->
            <!--          <span style="color: #409EFF">{{swapInfo.fdownBatteryLastSoe}}</span>-->
            <!--        </el-form-item>-->
          </el-col>
          <el-col :span="7">
            <el-form-item label="车辆总里程:">
              <span style="color: #409EFF">{{clientFrom.fvehicleMileage}}</span>
            </el-form-item>
            <el-form-item label="此次换电次序:">
              <span style="color: #409EFF">{{clientFrom.fserialNo}}</span>
            </el-form-item>

            <el-form-item label="取电池仓位号:">
              <span style="color: #409EFF">{{clientFrom.finBinNo}}</span>
            </el-form-item>
            <el-form-item label="放电池仓位号:">
              <span style="color: #409EFF">{{clientFrom.foutBinNo}}</span>
            </el-form-item>

            <el-form-item label="是否成功换电:">
              <!--0:手机号码；1:微信号码;2:网站注册号码;3:支付宝号码-->
              <span style="color: #409EFF" v-if="clientFrom.fisSuccess===0">成功</span>
              <span style="color: #F56C6C" v-if="clientFrom.fisSuccess===1">失败</span>
            </el-form-item>
            <el-form-item label="本次换电次数:">
              <span style="color: #409EFF">{{clientFrom.fswapCount}}</span>
            </el-form-item>
            <el-form-item label="本次换电时间(min):">
              <span style="color: #409EFF">{{clientFrom.fswapTimeCount}}</span>
            </el-form-item>
            <el-form-item label="订单是否取消:">
              <span style="color: #409EFF" v-if="clientFrom.forderIsCancel===0">正常</span>
              <span style="color: #F56C6C" v-if="clientFrom.forderIsCancel===1">取消</span>
            </el-form-item>
            <el-form-item label="换电结果:">
              <span style="color: #409EFF" v-if="clientFrom.fresult===0">正常</span>
              <span style="color: #F56C6C" v-if="clientFrom.fresult===1">失败</span>
            </el-form-item>
            <el-form-item label="失败原因:">
              <span style="color: #409EFF">{{clientFrom.ffailReason}}</span>
            </el-form-item>
          </el-col>
        </el-form>

      <span slot="footer" class="dialog-footer">
<!--        <el-button type="danger" @click="close()">关闭</el-button>-->
        <el-button type="primary" @click="next1">下一步</el-button>
      </span>
    </el-dialog>

  </el-card>
</template>

<script>
  import {
    getClientPage,
    newAddClient,
    chanegClient,
    removeClientMsg,
    restWord
  } from "@/utils/operation";
  import {
    findApSwapRefundById,
    StationOrderFinishedByOrderNo,
    findApSwapRefundByOrderNo
  } from "@/utils/operation2";
  import moment from "moment";
  import * as myStore from "@/utils/stroage";

  export default {
    name: "clienName",
    data() {
      return {
        isequipemt: true,
        addOrUpdate: false,
        swapInfo: {},
        clientFrom: {},
        active: 0,
      };
    },
    computed: {
      userId() {
        return JSON.parse(this.$store.state.user).id
      }
    },
    created() {
      this.init()
    },
    methods: {
      close() {
        const data = {
          fcreator: this.username,
        }
        this.$refs.equipform.resetFields()
        this.informationForm = data
        this.isequipemt = false
      },
      //修改信息填充
      async compileEqu(fOrderNo) {
        this.addOrUpdate = true
        this.isequipemt = false
        const result = await StationOrderFinishedByOrderNo(fOrderNo)
        this.clientFrom =result.data.data.rows
        if(result.data.data.rows==null){
          this.$msg.error("此换电订单没有换电记录")
          this.clientFrom={}
        }
      },
      //页面初始化方法
      async init() {
        if (this.$route.params && this.$route.params.fid) {
          this.id = this.$route.params.fid
          const result = await findApSwapRefundById(this.id)
          console.log(result)
          this.swapInfo = result.data.data.rows
        } else {
        }
      },
      next() {
        this.$router.push({path: '/swapRefundInfo/' + this.swapInfo.forderNo})
      },

      next1() {
        this.$router.push({path: '/RefundInfo/' + this.swapInfo.fid})
      },
      //根据用户id查询用户信息
    },
  };
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 10px;
    }
  }

  ul {
    width: 0;
  }

  .el-select {
    width: 100%;
  }
</style>
