<template>
  <el-card>
    <h2 style="text-align: center;">退款审核</h2>
    <el-steps :active="3" finish-status="success" process-status="wait" align-center style="margin-bottom: 40px;">
      <el-step title="退款订单信息"></el-step>
      <el-step title="换电订单信息"></el-step>
      <el-step title="退款审核"></el-step>
    </el-steps>

    <el-form ref="form" :model="swapInfo" label-width="90px">
      <el-col :span="12">
        <el-form-item label="退款订单:">
          <!--        <el-input v-model="swapInfo.frefundId"></el-input>-->
          <span style="color: #409EFF">{{swapInfo.frefundId}}</span>
        </el-form-item>
        <el-form-item label="换电订单号:">
          <span style="color: #409EFF">{{swapInfo.forderNo}}</span>
          <!--        <el-input v-model="swapInfo.forderNo"></el-input>-->
        </el-form-item>
        <el-form-item label="退款金额:">
          <span style="color: #409EFF">{{swapInfo.frefundAmount}}</span>
        </el-form-item>
        <el-form-item label="付款平台:">
          <span style="color: #409EFF" v-if="swapInfo.frefillPlatform===1">微信支付</span>
          <span style="color: #409EFF" v-if="swapInfo.frefillPlatform===2">支付宝支付</span>
        </el-form-item>
<!--        <el-form-item label="付款账号:">-->
<!--          <span style="color: #409EFF">{{swapInfo.frefillAmount}}</span>-->
<!--        </el-form-item>-->
      </el-col>
      <el-col :span="12">
        <el-form-item label="登录号码:">
          <span style="color: #409EFF">{{swapInfo.floginNo}}</span>
        </el-form-item>
<!--        <el-form-item label="登录方式:">-->
<!--          &lt;!&ndash;0:手机号码；1:微信号码;2:网站注册号码;3:支付宝号码&ndash;&gt;-->
<!--          <span style="color: #409EFF" v-if="swapInfo.floginType===0">手机号码</span>-->
<!--          <span style="color: #409EFF" v-if="swapInfo.floginType===1">微信号码</span>-->
<!--          <span style="color: #409EFF" v-if="swapInfo.floginType===2">网站注册号码</span>-->
<!--          <span style="color: #409EFF" v-if="swapInfo.floginType===3">支付宝号码</span>-->
<!--        </el-form-item>-->
        <el-form-item label="申请时间:">
          <span style="color: #409EFF">{{swapInfo.ftime}}</span>
        </el-form-item>

        <el-form-item label="申请状态:">
          <!--          <span style="color: #409EFF">{{swapInfo.fstate}}</span>-->
          <template>
            <el-select v-model="swapInfo.fstate" placeholder="请选择" style="width: 200px">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="处理人:">
          <span style="color: #409EFF">{{swapInfo.fexecutor}}</span>
        </el-form-item>
        <el-form-item label="处理时间:">
          <span style="color: #409EFF">{{swapInfo.fexecuteTime}}</span>
        </el-form-item>
      </el-col>
    </el-form>


    <el-button style="margin-top: 12px;" @click="next">上一步</el-button>
    <el-button style="margin-top: 12px;" @click="save">提交审核结果</el-button>

  </el-card>
</template>

<script>
  import {
    getClientPage,
    newAddClient,
    chanegClient,
    removeClientMsg,
    restWord
  } from "@/utils/operation";
  import {
    findApSwapRefundById,
    findApSwapRefundByOrderNo,
    updateApSwapRefund,
    aliRefund,
    WXRefund
  } from "@/utils/operation2";
  import moment from "moment";
  import * as myStore from "@/utils/stroage";

  export default {
    name: "clienName",
    data() {
      return {
        options: [
          {
          value: 0,
          label: '退款失败'
        }, {
          value: 1,
          label: '待审核'
        }, {
          value: 2,
          label: '审核未通过'
        }, {
          value: 3,
          label: '审核通过'
        },
          // {
          //   value: 5,
          //   label: '退款完成'
          // }

        ],
        swapInfo: {},
        active: 0,
      };
    },
    computed: {
      userName() {
        return JSON.parse(this.$store.state.user).username
      },
      userId() {
        return JSON.parse(this.$store.state.user).id
      }
    },
    created() {
      this.init()
      this.getCurrentTime()
    },
    methods: {
      getCurrentTime() {
        //获取当前时间并打印
        var _this = this;
        let yy = new Date().getFullYear();
        let mm = new Date().getMonth() + 1;
        let dd = new Date().getDate();
        let hh = new Date().getHours();
        let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
        let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
        _this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
        // console.log(_this.gettime)
        this.newTime = _this.gettime
      },
      //页面初始化方法
      async init() {
        if (this.$route.params && this.$route.params.fid) {
          this.id = this.$route.params.fid
          const result = await findApSwapRefundById(this.id)
          // console.log(result)
          this.swapInfo = result.data.data.rows
          this.swapInfo.fexecuteTime = this.newTime
          this.swapInfo.fexecutor = this.userName;
        } else {

        }
      },
      next() {
        this.$router.push({path: '/swapRefund/' + this.swapInfo.fid})
      },
      async save() {
        let data = JSON.parse(JSON.stringify(this.swapInfo))
        const result = await updateApSwapRefund(data)
        console.log(result)
        if (result.data.code != 20000) return this.$msg.error("操作失败");
        this.$msg.success("操作成功")
        this.$router.push({path: '/swapRefund'})

      },
    },
  };
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 10px;
    }
  }

  ul {
    width: 0;
  }

  .el-select {
    width: 100%;
  }
</style>
