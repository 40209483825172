<template>
    <div class="app-container">
        <el-row class="main-flex">

            <!--运营商数据--><!--
    <el-col :span="3" :xs="24" style="border-right: darkgrey solid 1px">
      <div class="head-container">
        <el-input
          v-model="operationName"
          placeholder="请输入运营商名称"
          clearable
          size="small"
          prefix-icon="el-icon-search"
          style="margin-bottom: 20px"
        />
      </div>
      <div class="head-container2">
        <el-tree
          :data="operatorOptions"
          :props="defaultProps"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          ref="tree"
          show-checkbox
          default-expand-all
          highlight-current
          @node-click="handleNodeClick"
        />
      </div>
    </el-col>
    -->
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <!--  <el-input placeholder="请从左侧选择运营商" v-model.trim="treeData.operatorNameTree"
                disabled></el-input>-->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="登录用户名：">
                                <el-input placeholder="请输入登录用户名" clearable
                                          v-model.trim="resCheck.userValue"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="电话：">
                                <el-input placeholder="请输入电话" clearable
                                          v-model.trim="resCheck.iponeValue"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="状态：">
                                <!--                                <el-input placeholder="请输入身份证号码" clearable v-model.trim="resCheck.identValue"></el-input>-->
                                <el-select v-model="resCheck.stateValue" placeholder="请选择状态">
                                    <el-option
                                        v-for="item in stateList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                        @change="$forceUpdate()"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="3">
                        <div class="btn-end">
                            <span class="btn-rest">
                            <el-button @click="cancelSubmit" plain>重置</el-button>
                            </span>
                            <span class="btn-submit">
                            <el-button @click="submitInquire" type="primary" plain>查询</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            用户列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                          已选择{{ batchnum.length }}项
                            <span @click="cleanList" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                                <el-button @click="removeBatch" plain
                                           v-hasCode="['system:user:delete']"> 删除
                        </el-button>
                            </span>
                            <span class="add-btn">
                               <el-button @click="addUser" type="primary" plain
                                          v-hasCode="['system:user:add']"> 新增
                        </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <!-- 内容区域 -->
                        <el-row class="table-box">
                            <el-table ref="userTable" @selection-change="batchList" :data="tableData"
                                       :header-cell-style="headerStyleEvent"
                                      :cell-class-name="goodsInfo" height="100%">
                                <el-table-column align="center" type="selection" min-width="40" fixed></el-table-column>
                                <el-table-column align="center" label="编号" :index="renIndex" min-width="80"
                                                 type="index"></el-table-column>
                                <!--        <el-table-column prop="employeeid" label="员工ID" min-width="80"></el-table-column>-->
                                <el-table-column align="center" prop="username" label="登录用户名"
                                                 min-width="110"></el-table-column>
                                <el-table-column align="center" prop="operatorName" label="归属运营商" min-width="130"
                                                 :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column align="center" prop="roleName" label="角色名称"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="realName" label="真实姓名"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="employeeId" label="员工工号"
                                                 min-width="110"></el-table-column>
                                <!--        <el-table-column prop="isroot" label="是否是超级用户" min-width="120">-->
                                <!--          <template slot-scope="scope">-->
                                <!--            <span v-if="scope.row.isroot === '1'">是</span>-->
                                <!--            <span v-else>否</span>-->
                                <!--          </template>-->
                                <!--        </el-table-column>-->
                                <el-table-column align="center" prop="gender" label="性别" min-width="50">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.gender === 1">男</span>
                                        <span v-else-if="scope.row.gender === 2">女</span>
                                        <span v-else>保密</span>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" prop="avater" label="用户头像" min-width="80">
                                    <template slot-scope="scope">
                                        <el-avatar :src="scope.row.avatar" icon="el-icon-user-solid"></el-avatar>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" prop="mobile" label="电话"
                                                 min-width="120"></el-table-column>
                                <el-table-column align="center" prop="userStatus" label="状态" min-width="80">
                                    <template slot-scope="scope">
                                    <span v-if="scope.row.userStatus"><i class="start"
                                                                         style="background-color: #1890ff;"></i> 启用</span>
                                        <span v-else><i class="start" style="background-color:#d43030;"></i>禁用</span>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="identifyCode" label="身份证号码"
                                                 min-width="180"></el-table-column>
                                <el-table-column align="center" prop="email" label="邮箱"
                                                 min-width="170"></el-table-column>
                                <el-table-column align="center" prop="homeAddress" label="地址"
                                                 :show-overflow-tooltip="true"
                                                 min-width="160"></el-table-column>
                                <!-- <el-table-column align="center" prop="creationdate" label="注册时间" min-width="160">
                                    <template slot-scope="scope">
                                        {{ formatting(scope.row.creationdate) }}
                                    </template>
                                </el-table-column>-->
                                <!--        <el-table-column fixed="right" label="操作" min-width="180">-->
                                <el-table-column align="center" label="操作" min-width="180" fixed="right">
                                    <template slot-scope="scope">

                                        <span class="edit-style edit-margin" @click="compile(scope.row)"
                                                   v-hasCode="['system:user:update']"> 编辑
                                        </span>
<!--                                        :icon="scope.row.userStatus ?'el-icon-remove-outline':'el-icon-circle-check'" :type="scope.row.userStatus ?'danger':'success'"-->
                                        <span class="edit-style" v-show="scope.row.roleLevel !== 1" @click="userUsing(scope.row.id)"
                                                   v-hasCode="['system:user:enable']">
                                            {{ scope.row.userStatus ? '禁用' : '启用' }}
                                        </span>

                                        <!--
                                        <el-button  @click="$router.push({path:'/userOperator',query:{data:JSON.stringify(scope.row)}})" size="small" type="warning" icon="el-icon-setting">运营商绑定</el-button>
                                        -->

                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>

                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10, 30, 50,100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total"
                            ></el-pagination>
                        </el-row>
                    </el-col>
                </div>


            </el-col>
            <!--                <el-col :span="5" class="fixstyle">
                                <el-select v-model="resCheck.sexValue" placeholder="请选择性别">
                                    <el-option
                                        v-for="item in genderList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                        @change="$forceUpdate()"
                                    ></el-option>
                                </el-select>
                            </el-col>-->

            <!--
                            <el-col :span="5">
                                <el-row>
                                    <el-button size="small" @click="addUser" class="el-icon-plus" type="primary"
                                               v-hasCode="['system:user:add']"> 新增
                                    </el-button>
                                    <el-button @click="removeBatch" class="el-icon-delete" type="danger" size="small"
                                               v-hasCode="['system:user:delete']"> 选中删除
                                    </el-button>
                                </el-row>
                            </el-col> -->

        </el-row>

        <!-- 添加角户 -->
        <el-dialog :title="ischangeUser?'添加用户信息':'修改用户信息'"
                   @close="close"
                   :visible.sync="isAddUser" width="60%">
            <el-form size="middle" ref="addRefForm" :rules="addrules" :model="addForm" label-width="100px">
                <el-row>
                    <el-col :span="11">

                        <el-form-item label="归属运营商" prop="operatorId">
                            <TreeSelect v-model="addForm.operatorId" :options="treeData.operatorOptions"
                                        placeholder="请选择运营商"/>
                            <!-- <TreeSelect v-model="addForm.operatorId" :options="operatorOptions" placeholder="选择上级目录" @select="change(node)"/>-->
                        </el-form-item>

                        <el-form-item label="登录用户名:" prop="username">
                            <el-input v-model="addForm.username" placeholder="作为登录帐号" show-word-limit maxlength="20"></el-input>
                        </el-form-item>
                        <el-form-item label="真实姓名:" prop="realName">
                            <el-input v-model="addForm.realName" show-word-limit maxlength="20"></el-input>
                        </el-form-item>

                        <el-form-item label="员工工号:" prop="employeeId">
                            <el-input v-model="addForm.employeeId" placeholder="员工工号为数字类型"
                                      type="number"></el-input>
                        </el-form-item>
                        <!--            <el-form-item label="员工工号:" prop="employeeid">-->
                        <!--              <el-input v-model="addForm.employeeid"></el-input>-->
                        <!--            </el-form-item>-->
                        <el-form-item label="选择角色:" prop="roleId">
                            <el-select v-model="addForm.roleId" placeholder="请选择角色">
                                <el-option
                                    v-for="item in rolesList"
                                    :key="item.id"
                                    :label="item.roleName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item class="Form-item" label="头像:" prop="avatar">
                            <template>
                                <Avater @on-avater="onAvater" @on-imgchange="imgchange" :uploadImg="uploadImg"
                                        :isAvater="isAvater"/>
                            </template>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="身份证号:" prop="identifyCode">
                            <el-input v-model="addForm.identifyCode" show-word-limit maxlength="18"></el-input>
                        </el-form-item>
                        <el-form-item label="性别:" prop="gender">
                            <el-select v-model="addForm.gender" placeholder="请选择性别">
                                <el-option
                                    v-for="item in genderList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    @change="$forceUpdate()"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="电话:" prop="mobile">
                            <el-input v-model="addForm.mobile" show-word-limit maxlength="11"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱:" prop="email">
                            <el-input v-model="addForm.email" show-word-limit maxlength="50"></el-input>
                        </el-form-item>
                        <el-form-item label="地址:">
                            <el-input v-model="addForm.homeAddress" show-word-limit maxlength="50"></el-input>
                        </el-form-item>
                        <el-form-item v-if="ischangeUser" label="密码:" prop="password">
                            <el-input type="text" style="opacity: 0;width:0;height:0;"/>
                            <el-input type="password" v-model="addForm.password" maxlength="30" show-word-limit
                                      autocomplete="new-password"></el-input>
                        </el-form-item>
                        <el-form-item v-if="ischangeUser" label="确认密码:" prop="password1">
                            <el-input type="password" v-model="addForm.password1" maxlength="30" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="isAddUser = false">取 消</el-button>
        <el-button type="primary" @click="addRegister">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    setUser,
    adduserRole, batchUser,
    setStatus, getRoleByOperatorId, getUsersPage, getUsername
} from "@/utils/user";
import * as myStore from "@/utils/stroage";
import {mapState} from "vuex";
import moment from 'moment'
import {mapMutations} from 'vuex'
import Avater from '@/components/avater'

import operatorTreeVue from "@/components/tree/operatorTree"
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {operatorTree} from "@/utils/operatorApi";


export default {
    name: "username",
    components: {
        Avater, TreeSelect, operatorTreeVue
    },
    data() {
        //身份证校验
        var proof = (rule, value, cb) => {
            const reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
            if (!value) {
                // cb(new Error('身份证不能为空'))
                return cb()
            }
            if (!reg.test(value)) {
                return cb(new Error('身份证输入错误'))
            }
            return cb()
        }

        var mobileReg = (rule, value, cb) => {
            const reg = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;
            if (!value) cb(new Error("手机号不能为空"));
            if (!reg.test(value)) {
                return cb(new Error("手机号输入错误"));
            }
            return cb();
        };

        var emailReg = (rule, value, cb) => {
            const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if (value === "") {
                // return cb(new Error("邮箱不能为空"));
                return cb();
            }
            if (!reg.test(value)) {
                return cb(new Error("邮箱输入错误"));
            }
            return cb();
        };

        var pwd = (rule, value, cb) => {
            if (value === '') return cb(new Error('密码不能为空'))
            if (this.addForm.password !== value) return cb(new Error('两次密码不一致'))
            return cb()
        }

        var username = (rule, value, cb) => {
            const reg = /(?!^\d+$)(?!^[a-zA-Z]+$)[0-9a-zA-Z]{1,30}/
            if (value === '') return cb()
            if (!reg.test(value)) return cb(new Error("格式错误,格式为字母加数字"))
            return cb()
        }

        return {
            isopenShow: true,
            resCheck: {
                userValue: "",
                iponeValue: "",
                identValue: "",
                edition: "",
                sexValue: null,
                stateValue: null
            },
            tableData: [], //用户表格
            ischangeUser: true,
            currentPage4: 1,
            query: {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1
            },
            total: 0,
            isPage: true,
            clientHeight: 0,
            genderList: [
                {
                    value: 1,
                    label: "男"
                },
                {
                    value: 2,
                    label: "女"
                },
                {
                    value: 3,
                    label: "保密"
                }
            ],
            stateList: [
                {
                    value: 1,
                    label: '启用'
                },
                {
                    value: 0,
                    label: '禁用'
                }
            ],
            editionList: [
                {
                    value: 0,
                    label: '测试版本'
                },
                {
                    value: 1,
                    label: '成熟版本'
                }
            ],
            value: true,
            isAddUser: false,
            addForm: {//添加用户
                employeeId: null,
                username: '',
                realName: '',
                // useraccount: ''
                avatar: '',
                gender: 3,
                roleId: null,
                identifyCode: null,
                mobile: null,
                email: '',
                homeAddress: '',
                operatorId: null,
                password: null,
                password1: null

            },
            uploadImg: null,
            addrules: {
                username: [
                    {required: true, message: '请输入登录用户名', trigger: 'blur'},
                    {min: 2, max: 12, message: '长度在 2 到 12 个字符', trigger: 'blur'}
                ],
                realName: [
                    {required: false, message: '请输入中文名', trigger: 'blur'},
                    {min: 2, max: 12, message: '长度在 2 到 12 个字符', trigger: 'blur'}
                ],
                gender: [
                    {required: true, message: '请选择性别', trigger: 'change'},
                ],
                userStatus: [
                    {required: true, message: '请选择员工状态', trigger: 'change'},
                ],
                identifyCode: [
                    {required: false, validator: proof, trigger: 'blur'}
                ],
                mobile: [
                    {required: true, validator: mobileReg, trigger: 'blur'}
                ],
                email: [
                    {required: false, validator: emailReg, trigger: 'blur'}
                ],
                // deptno:[
                //   {min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur'}
                // ],
                // deptname: [
                //   {min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur'}
                // ],
                // useraccount: [
                //   {min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur'}
                // ],
                homeAddress: [
                    {min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur'}
                ],
                roleId: [
                    {required: true, message: '请选择角色', trigger: 'change'}
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur'}
                ],
                password1: [
                    {required: true, validator: pwd, trigger: 'blur'}
                ],
                operatorId: [
                    {required: true, message: '请选择运营商', trigger: 'change'}
                ],
            },

            batchnum: [],
            rolesList: [],
            userValue: [],
            isdisabled: false,
            isEdit: false, //是否编辑标志

            fileAvater: null,
            isAvater: true,

            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            }

        };
    },
    computed: {
        ...mapState(["imgURL", "token"]),
        userName() {
            return JSON.parse(this.$store.state.user).username
        },
        userId() {
            return JSON.parse(this.$store.state.user).id
        },
        userroleId() {
            return JSON.parse(this.$store.state.user).roleLevel
        },
        imgURL() {
            return this.$store.state.imgURL
        },
        rolesStore() {
            return this.$store.state.rolesStoreList.map(item => {
                return {
                    value: item.id,
                    label: item.roleName
                }
            })
        }
    },
    created() {
        this.getOperatorTree();
        this.getUserInfo();
        this.clientHeight = document.body.clientHeight - 280;
    },
    mounted() {
    },
    // 组件方法
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        ...mapMutations(['userInfo']),
        //获取用户信息
        async getUserInfo() {
            try {
                var data = {}
                data.page = this.query.resPageNum
                data.size = this.query.pageSize
                if (this.treeData.operatorNameTree != "" && this.treeData.operatorNameTree != null) {
                    data.operatorId = this.treeData.operatorIdTree
                }
                var url = "?page=" + this.query.pageNum + "&size=" + this.query.pageSize;
                data.url = url;
                const res = await getUsersPage(data)
                if (res.data.code !== 200) return this.$msg.error("获取用户列表失败");
                this.tableData = res.data.data.data.list;
                this.total = res.data.data.total;
            } catch (e) {
            }
        },
        //提交查询
        async submitInquire() {
            try {
                const query = this.resCheck

                const data = {
                    username: query.userValue,
                    mobile: query.iponeValue,
                    identifyCode: query.identValue,
                    gender: query.sexValue,
                    userStatus: query.stateValue,
                    version: query.edition,
                }
                var url = "?page=" + this.query.pageNum + "&size=" + this.query.pageSize;
                data.url = url;

                if (this.resCheck.userValue != "") {
                    data.username = this.resCheck.userValue;
                }
                if (this.resCheck.iponeValue != "") {
                    data.mobile = this.resCheck.iponeValue;
                }

                if (this.resCheck.edition != "") {
                    data.version = this.resCheck.edition;
                }
                if (this.resCheck.sexValue != null) {
                    data.gender = this.resCheck.sexValue;
                }
                if (this.resCheck.stateValue != null) {
                    data.userStatus = this.resCheck.stateValue;
                }
                if (this.treeData.operatorNameTree != "" && this.treeData.operatorNameTree != null) {
                    data.operatorId = this.treeData.operatorIdTree;
                } else if (this.treeData.operatorOptions.length > 0) {
                    data.operatorId = this.treeData.operatorOptions[0].id;
                }
                const res = await getUsersPage(data)
                if (res.status !== 200) return this.$msg.error('查询失败!')
                this.tableData = res.data.data.data.list;
                this.total = res.data.data.total;
                this.isPage = false
            } catch (error) {

            }
        },
        //取消清空表单
        cancelSubmit() {
            this.resCheck.userValue = ''
            this.resCheck.iponeValue = ''
            this.resCheck.identValue = ''
            this.resCheck.sexValue = null
            this.resCheck.stateValue = null
            this.resCheck.edition = ''
            this.isPage = true
            this.treeData.operatorNameTree = ""
            this.treeData.operatorIdTree = null
            this.query = {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1
            }
            this.resetForm()
            this.getUserInfo()
        },
        //几条每页
        handleSizeChange(val) {
            this.query.pageSize = val
            if (this.isPage) {
                this.getUserInfo()
            } else {
                this.submitInquire()
            }


        },
        //当前选中页
        handleCurrentChange(val) {
            this.query.pageNum = val
            if (this.isPage) {
                this.getUserInfo()
            } else {
                this.query.resPageNum = val
                this.submitInquire()
            }
        },
        addUser() {
            this.rolesList = [];
            this.isAddUser = true;
            this.resetForm()
        },
        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },
        //编辑用户信息
        async compile(data) {
            this.rolesList = [];
            this.isEdit = true;

            let params = {}
            params.operatorId = data.operatorId;
            let result = await getRoleByOperatorId(params)
            if (result.data.code !== 200) {
                return this.$msg.error('获取角色列表失败')
            } else {
                this.rolesList = result.data.data;
                this.isAddUser = true;
                this.ischangeUser = false
                var list = JSON.parse(JSON.stringify(data))
                this.uploadImg = data.avatar
                this.addForm = list;
            }

            /*    const res = await getUsername(data.username)
                if (res.data.code !== 200) return this.$msg.error('修改用户信息失败')
                var list = JSON.parse(JSON.stringify(res.data.data))
          */

        },
        async userUsing(id) {
            try {
                const res = await setStatus(id)
                if (res.data.code !== 200) return this.$msg.error('修改用户状态失败')
                this.$msg.success('修改用户状成功')
                this.getUserInfo()

            } catch (error) {

            }
        },
        //获取选中的用户
        batchList(list) {
            this.batchnum = list.filter(item => {
                return item

            })
        },
        //删除用户账号
        async removeBatch() {
            if (this.batchnum.length === 0) return this.$msg.info('请选择需要删除的账号')
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除选中账号, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const list = this.batchnum.map(item => {
                    return item.id
                })
                const res = await batchUser(list)
                if (res.status !== 200) return this.$msg.error('删除账号失败!')
                this.tableData = this.tableData.filter(item => {
                    return item.id
                })
                this.$refs.userTable.clearSelection()
                if (this.query.pageNum > 1 && this.tableData.length === 0) {
                    this.query.pageNum = this.query.pageNum - 1
                }
                this.$msg.success('删除账号成功!')
                this.getUserInfo()

            } catch (error) {

            }
        },
        cleanList() {
            this.$refs.userTable.clearSelection()
        },
        //添加用户
        addRegister() {
            this.$refs.addRefForm.validate(async valid => {
                if (!valid) {
                    return
                }
                try {
                    //去掉多余数据
                    this.$delete(this.addForm, "password1")
                    let data = JSON.parse(JSON.stringify(this.addForm))
                    this.fileAvater && (data.avatar = this.fileAvater.url)
                    if (this.ischangeUser) {
                        const res = await adduserRole(data)
                        if (res.data.code !== 200) {
                            this.$msg.error('新增用户信息失败!' + res.data.msg)
                        } else {
                            this.$msg.success('新增用户信息成功')
                            this.isAddUser = false
                            this.getUserInfo()
                        }
                    } else {
                        const result = await setUser(data)
                        if (result.data.code !== 200) {
                            this.$msg.error('修改用户信息失败!' + result.data.msg)
                        } else {
                            this.$msg.success('修改用户信息成功!')
                            if (this.userId === result.data.data.id) {
                                this.userInfo(JSON.stringify(result.data.data))
                            }
                            this.ischangeUser = true
                            this.isAddUser = false
                            this.getUserInfo()
                        }
                    }
                } catch (e) {

                }
            })
        },
        onAvater(file) {
            this.fileAvater = file
        },
        imgchange(val) {
            this.isAvater = val
        },
        close() {
            this.isdisabled = false
            this.isAvater = false
            this.uploadImg = null
            this.fileAvater = null
            this.ischangeUser = true
            this.resetForm()
        },

        resetForm() {
            this.isEdit = false;
            this.addForm = {
                employeeId: null,
                username: '',
                realName: '',
                // useraccount: ''
                avatar: '',
                gender: 3,
                roleId: null,
                identifyCode: null,
                mobile: null,
                email: '',
                homeAddress: '',
                operatorId: null,
                password: null,
                password1: null
            }
        },

        formatting(time) {
            return moment(time).format('YYYY-MM-DD')
        },

        getTreeData(data) {
            this.treeData = data
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.treeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.status !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorOptions.push(res.data.data)
                this.treeData.operatorIdTree = res.data.data.id
                this.treeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },
    },

    watch: {
        'addForm.operatorId': {
            async handler(newName, oldName) {
                if (this.isEdit == true && newName != null && newName != undefined && (oldName == null || oldName == undefined)) {
                    return;
                }

                //新增、修改时改动过运营商
                if (newName !== null && newName != undefined) {
                    this.addForm.roleId = null;
                    let params = {}
                    params.operatorId = newName;
                    let result = await getRoleByOperatorId(params)
                    this.rolesList = result.data.data;
                }

            },
            deep: true,
            immediate: false
        }

    },

};
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #dddee0; /*滚动条的背景颜色*/
    }
}


::v-deep .fixstyle {
    padding-left: 0 !important;
}

.inner-container {
    // min-width: 2500px;
    height: var(--tableHeight);;
    overflow: hidden;
}


//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor:pointer;
    display: inline-block;
}
.edit-margin{
    margin-right: 16px;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>

