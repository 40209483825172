<template>
  <div class="content">
    <div class="content-title">
      <el-row class="head-section ">

        <el-col :span="6">
            <el-form :inline="true">
                <el-form-item label="换电站：">
                    <el-select  @change="stationChange" v-model="stationNo" placeholder="请选择换电站">
                        <el-option
                            v-for="item in stationList"
                            :key="item.id"
                            :label="item.stationName"
                            :value="item.stationNo"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>

        </el-col>

        <el-col :span="7">
            <el-form :inline="true">
                <el-form-item label="日期：">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="monthValue" type="month" placeholder="选择月" clearable></el-date-picker>
                </el-form-item>
            </el-form>
        </el-col>

        <el-col :span="11">
            <div class="btn-end">
                    <span class="btn-rest">
                  <el-button @click="resFuntion" plain >重置</el-button>
                     </span>
                <span class="btn-submit">
                <el-button @click="search" type="primary" plain>查询</el-button>
                        </span>
            </div>
        </el-col>
      </el-row>
    </div>

    <el-row class="content-content">
      <div>
        <span class="content-content_pos">{{stationStr}}月用电量环比</span>
        <elecEnergyPie :newPie="newPie"></elecEnergyPie>
      </div>

      <div>
        <span class="content-content_pos">{{stationStr}}月每日用电量环比</span>
        <elecEnergyBar :newBar="newBar"></elecEnergyBar>
      </div>
    </el-row>

    <el-row class="content-bottom">
      <div class="content-box-title">
        <span class="content-content_pos"  style="padding-left:5px">{{stationStr}}月用电量详情</span>
      </div>
        <div class="table-box mar-table">
            <el-table :data="list" style="width: 100%" :header-cell-style="{'text-align':'center'}"
                      :cell-style="{'text-align':'center'}" >
                <el-table-column prop="name" label="时间"></el-table-column>
                <el-table-column prop="stationNo" label="场站编码"></el-table-column>
                <el-table-column prop="stationName" label="场站名称"></el-table-column>
                <el-table-column prop="value" label="总能耗值(kw/h)"></el-table-column>
                <el-table-column prop="chargeCost" label="充电能耗值(kw/h)"></el-table-column>
                <el-table-column prop="changeCost" label="换电能耗值(kw/h)"></el-table-column>
                <el-table-column prop="otherCost" label="其它能耗值(kw/h)"></el-table-column>
            </el-table>
        </div>
    </el-row>
  </div>
</template>

<script>
import '@/assets/font/font.css';
import moment from "moment";
import {getStaEnergyValOnMonth} from '@/utils/statistics'
import elecEnergyPie from "@/components/elec-energy-link/elec-energy-pie";
import elecEnergyBar from "@/components/elec-energy-link/elec-energy-bar";
import {getForceList, regionCodeSation} from '@/utils/station'
export default {
  name: "elec-energy-link",
  components: {
    elecEnergyPie,
    elecEnergyBar
  },
  data() {
    return {
      yDataAgo:[],
      yDataNow:[],
      stationNo: null,
      stationName: null,
      stationStr:"",
      tempValue: 3,
      monthValue: "",
      stationList: [],
      tableData: [],
      newBar: {},
      newPie: {},
      list: [],
      week: '一',
      coulm: [],
      isEcharts: true,
      isClick: true
    };
  },
  computed: {
    selectTime() {
      let time = this.monthValue || new Date();
      let startTime = null;
      let endTime = null;
      let newName = null;
      let lastName= null;
      switch (this.tempValue) {
        case 5:
          startTime = moment(time)
            .startOf("year")
            .format("YYYY-MM-DD HH:mm:ss");
          endTime = moment(time).endOf("year").format("YYYY-MM-DD HH:mm:ss");
          break;
        case 4:
          startTime = moment(time)
            .startOf("quarter")
            .format("YYYY-MM-DD HH:mm:ss");
          endTime = moment(time).endOf("quarter").format("YYYY-MM-DD HH:mm:ss");
          break;
        case 3:
          startTime = moment(time)
            .startOf("month")
            .format("YYYY-MM-DD HH:mm:ss");
          endTime = moment(time).endOf("month").format("YYYY-MM-DD HH:mm:ss");
          newName = moment(time).endOf("month").format("YYYY-MM")
          lastName = moment(time).endOf("month").add(-1, 'month').format("YYYY-MM")
          break;
        case 2:
          startTime = moment(time)
            .startOf("week")
            .format("YYYY-MM-DD HH:mm:ss");
          endTime = moment(time).endOf("week").format("YYYY-MM-DD HH:mm:ss");
          break;
        case 1:
          startTime = moment(time)
            .startOf("date")
            .format("YYYY-MM-DD HH:mm:ss");
          endTime = moment(time).endOf("date").format("YYYY-MM-DD HH:mm:ss");
          break;
        default:
          return "";
      }
      return {
        startTime,
        endTime,
        lastName,
        newName
      };
    },
    changeCount() {
      let str = null
      if (this.tempValue === 5 || this.tempValue === 4) {
        str = 'monthSwapCount'
      } else if (this.tempValue === 3 || this.tempValue === 2 || this.tempValue === 1) {
        str = 'daySwapAmount'
      }
      return str
    }
  },
  created() {
    this.resFuntion()
    //this.getStaEnergyValByMonth()
  },
  mounted() {
  },
  methods: {

    resFuntion() {
      this.stationNo = null;
      this.stationName = null;
      this.tempValue = 3;
      this.monthValue = new Date();
      this.isClick = true;
      this.getStation()
    },

    async getStation() {
      const res = await getForceList();
      this.stationList = res.data.data;
      this.stationNo = this.stationList.length>0?this.stationList[0].stationNo:"";
      this.stationName = this.stationList.length>0?this.stationList[0].stationName:"";
      this.getStaEnergyValByMonth()
    },

    stationChange(e) {
      let value = this.stationList.filter(c => c.stationNo === e);
      this.stationName = value[0].stationName;
      this.stationNo = value[0].stationNo;
    },

    async getStaEnergyValByMonth() {
      try {
        let month = this.monthValue
        month = month !== ''?moment(month).format('YYYY-MM-DD'):moment().month('YYYY-MM-DD')
        var thisMonthNme = `${this.selectTime.newName}`
        var lastMonthNme = `${this.selectTime.lastName}`
        const data = {
          stationNo: this.stationNo,
          startTime:moment(month).format('YYYY-MM-DD HH:mm:ss'),
        }

        //const res = await staElecDayStatisticsTwoInfo(JSON.parse(JSON.stringify(data)))
        const res = await getStaEnergyValOnMonth(data)

        if (res.data.code !== 200) return this.$msg.error('获取信息失败')
        var newTotalCons =  (res.data.data.thisMonthTotal.mt).toFixed(2)
        var agoTotalCons =  (res.data.data.lastMonthTotal.mt).toFixed(2)
        this.list = [
          {value: newTotalCons, name: thisMonthNme,
            stationNo:this.stationNo,stationName:this.stationName,
            chargeCost:res.data.data.thisMonthTotal.mc,changeCost:res.data.data.thisMonthTotal.ms,otherCost:res.data.data.thisMonthTotal.mv},
          {value: agoTotalCons, name: lastMonthNme,
            stationNo:this.stationNo,stationName:this.stationName,
            chargeCost:res.data.data.lastMonthTotal.mc,changeCost:res.data.data.lastMonthTotal.ms,otherCost:res.data.data.lastMonthTotal.mv}
        ]
        this.newPie = {
          list: this.list,
          name: `场站电量结果环比`
        }

        this.yDataNow = res.data.data.thisMonth
        this.yDataAgo = res.data.data.lastMonth

        this.stationStr = "" + this.stationName　+ "-" + moment(month).format('YYYY-MM')
        //柱图、折线图数据格式化
        this.tranfromBar(this.yDataNow,this.yDataAgo ,month);

      } catch (error) {

      }
    },

    async excelAll() {
      let months = this.changeCount === 'monthSwapCount' ? '每月换电次数' : '每日换电次数'
      let coulm = [
        {prop: 'carNo', label: '车牌号'},
        {prop: this.changeCount, label: months},
        {prop: 'startTime', label: '开始时间'},
        {prop: 'endTime', label: '结束时间'},
      ]
      const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(e => e)
      if (confirm !== 'confirm') return
      this.$download(this.tableData, coulm, '车辆换电记录')
    },

    search() {
      this.getStaEnergyValByMonth()
    },

    changeEcharts() {
      this.isEcharts = !this.isEcharts
      this.getStaEnergyValByMonth()
    },

    //换电量环比
    tranfromBar(list1,list2,month){
      let arrAll = []
      let dayList = this.dayLIST(moment(month).daysInMonth())

      //echart数据生成
      let data1 = this.monthListD(list1,dayList)
      let data2 =  this.monthListD(list2,dayList)
      this.isEcharts? arrAll.push({name:this.selectTime.newName,type:'bar',data:data1.newArr}):arrAll.push({name:this.selectTime.newName,type:'line',data:data1.newArr})
      this.isEcharts? arrAll.push({name:this.selectTime.lastName,type:'bar',data:data2.newArr}):arrAll.push({name:this.selectTime.lastName,type:'line',data:data2.newArr})
      /**
      let count = this.countPercentage([data1.count,data2.count])
      this.newPie ={
        list:[{name:this.selectTime.newName,value:count[0]},{name:this.selectTime.lastName,value:count[1]}]
      }
      **/
      this.newBar ={
        arrAll,
        monthList:dayList,
        yearList:[this.selectTime.newName,this.selectTime.lastName]
      }
    },

    countPercentage(countArray){
      var j = eval(countArray.join('+'));
      var resultArray = [];
      for (var i = 0 ; i < countArray.length ; i++){
        if(countArray[i] === 0){
          resultArray.push(0);
        }else{
          var k = Math.floor((countArray[i]/j)*100)
          resultArray.push(k);
        }
      }
      return resultArray;
    },


    //格式化当前月份每天的用电量数据
    monthListD(list,dayList){
      let newArr = []
      let count = 0
      dayList.forEach(item=>{
        let i = list.findIndex(c=>moment(c.st).date() === item) //场站用电日期等于当前日历中的日期
        i === -1 && newArr.push({name:item,value:0})
        i !== -1 && newArr.push({name:item,value:list[i].dt})  //场站用电量的日期加入到对应的当前日历中
      })
      list.forEach(item=>{
        count += item.dt
      })
      return {
        newArr,
        count
      }
    },

    dayLIST(list) {
      let newArr = []
      if (list.length === 0) return [];
      for (let i = 1; i < list+1 ; i++) {
        newArr.push(i);
      }
      return newArr;
    },

    redcueList(val,dayList){
      let newArr = []
      let monthList = []
      dayList.forEach(item=>{
        let index = monthList.findIndex(c=>c === item)
        index === -1 && monthList.push(item)
        let i = val.findIndex(c=>moment(c.st).date() === item) //判断返回数组里面的换电日期是否等于当前月份里的日期
        if(i === -1){
          newArr.push({name:item,value:0})
        }else{
          newArr.push({name:item,value:val[i].dt}) //每天的用电量
        }
      })
      return {
        newArr,
        monthList
      }
    },


  },
};
</script>

<style lang='less' scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.el-col {
  position: relative;
}

.content {
  border: 0;
  box-shadow: none;
  background-color: #f0f2f5;

}

.content-title {
    background-color: #fff;
    margin: 0;
    padding: 16px 12px;
}

.content-content {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    div {
        position: relative;
        background-color: #fff;

        &:nth-child(1) {
            width: 28%;
            margin-right: 15px;
        }

        &:nth-child(2) {
            width: 71%;
        }

        .content-content_pos {
            display: inline-block;
            font-weight: 400;
            color: #2F3133;
            font-size: 16px;
            padding: 24px 0 0 16px;
            font-family: PingFang SC;
        }
    }
}

.content-bottom {
    background-color: #fff;
    padding: 24px 16px 16px 16px;
    margin-bottom: 30px;
}

.electric-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  padding-left: 20px;
  font-weight: 700;
  border-left: 6px solid #2a82e4;
}

#changeI {
  position: absolute;
  top: 15px;
  right: 0%;
  cursor: pointer;
  color: #666;
  margin-right: 50px;
  z-index: 999;
}

#changeI:hover {
  color: #2a81e3;
}

.content-box-title {
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 16px;
    color: #2F3133;
    font-family: PingFang SC;

    span {
        color: #2F3133;
    }
}
.btn-end {
    display: flex;
    justify-content: flex-end;
}
.mar-table{
    margin-top: 16px;
}
.page-num{
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
.open-btn{
    .el-button {
        border: 1px solid #0D6CE4;
        color: #0D6CE4;
        background: #fff;
        padding: 8px 16px;
        line-height: 20px;
        font-size: 14px;
        width: 88px;
    }
}
</style>
