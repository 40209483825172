<template>
  <div class="content">
    <div class="content-title">
      <el-row style="margin:15px 0;">
        <el-col style="margin-right:20px;" :span="4">
          <el-select @change="carConsume" v-model.trim="carValue" placeholder="请选择车牌号">
            <el-option
              v-for="item in carList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col style="margin-right:20px;" :span="2">
          <el-select v-model="tempValue" @change="dateValue = ''" placeholder="请选择">
            <el-option
              v-for="item in timeoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col style="margin-right:20px;" :span="4">
          <Quarter @quarter="quarterClick" ref="child" v-if="tempValue === 4"></Quarter>
          <el-date-picker
            v-else
            firstDayOfWeek="1"
            v-model="dateValue"
            :type="timeType"
            :format="timeMat"
            :placeholder="timeholder"
          ></el-date-picker>
        </el-col>
        <el-col :span="6">
          <el-button @click="search" type="primary">查询</el-button>
          <el-button @click="resFuntion">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row class="content-content">
      <div >
        <vehiclePie :newPie="newPie" :namePie="namePie"></vehiclePie>
      </div>
      <div >
        <i id="changeI" :class="isEcharts?'iconfont icon-zhexiantu':'iconfont icon-zhuzhuangtu' " @click="changeEcharts"></i>
        <vehicleBar :newObj="newObj" ></vehicleBar>
      </div>
    </el-row>
    <el-row class="content-bottom">
      <div class="content-box-title">
        <span>{{titleStr}}</span>
        <el-button type="primary" @click="excelAll" class="el-icon-download" size="small">批量导出</el-button>
      </div>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="carNo" label="车牌号" ></el-table-column>
        <el-table-column :formatter="$toDecimal2" :prop="amount" :label="amount === 'monthSwapAmount'?'每月消费金额':'每日消费金额'" ></el-table-column>
        <el-table-column prop="startTime" label="开始时间" ></el-table-column>
        <el-table-column prop="endTime" label="结束时间" ></el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
  import moment from "moment";
  import Quarter from "@/components/quarter/index";
  import vehiclePie from "@/components/vehicle/vehiclePie";
  import vehicleBar from "@/components/vehicle/vehicleBar";
  import * as myStore from "@/utils/stroage";
  import {
    searchCarDayElectr,
    searchCarMonthElectr,
    getCarBaseAll
  } from '@/utils/vehiclApi'
  export default {
    name: "vehicleConsume",
    components: {
      Quarter,
      vehiclePie,
      vehicleBar,
    },
    data() {
      return {
        vehicle: "",
        carValue: "",
        tempValue: 5,
        titleStr:'',
        dateValue: "",
        timeoptions: [
          {
            value: 5,
            label: "年",
          },
          {
            value: 4,
            label: "季度",
          },
          {
            value: 3,
            label: "月",
          },
          {
            value: 2,
            label: "周",
          }
        ],
        tableData:[],
        arrList:['车辆消费记录','车辆消费记录趋势'],
        newObj:{},
        newPie:[],
        week:'一',
        isEcharts:true,
        isClick:true,
        namePie:'',
        carList:[],
        amount:'monthSwapAmount'
      };
    },
    computed: {
      timeType() {
        switch (this.tempValue) {
          case 5:
            return "year";
          case 4:
            return "week";
          case 3:
            return "month";
          case 2:
            return "week";
          case 1:
            return "date";
          default:
            return "";
        }
      },
      timeMat() {
        let time = this.dateValue || new Date();
        let i = moment(time).month() + 1;
        switch (this.tempValue) {
          case 5:
            return moment(time).year() + "年";
          case 4:
            return moment(time).year() + "年第" + this.week + "季度";
          case 3:
            return moment(time).year() + "年" + i + "月";
          case 2:
            return this.getWeek(
              moment(time).year(),
              moment(time).month() + 1,
              moment(time).date()
            )
          default:
            return "";
        }
      },
      timeholder() {
        switch (this.tempValue) {
          case 5:
            return "请选择年";
          case 4:
            return "week";
          case 3:
            return "请选择月份";
          case 2:
            return "请选择周";
          case 1:
            return "请选择日期";
          default:
            return "什么都不选";
        }
      },
      quarterList() {
        switch (this.week) {
          case '一':
            return [1, 2, 3];
          case '二':
            return [4, 5, 6];
          case '三':
            return [7, 8, 9];
          case '四':
            return [10, 11, 12];
          default:
            return [];
        }
      },
      selectTime() {
        let time = this.dateValue || new Date();
        let startTime = null;
        let endTime = null;
        switch (this.tempValue) {
          case 5:
            startTime = moment(time)
              .startOf("year")
              .format("YYYY-MM-DD HH:mm:ss");
            endTime = moment(time).endOf("year").format("YYYY-MM-DD HH:mm:ss");
            break;
          case 4:
            startTime = moment(time)
              .startOf("quarter")
              .format("YYYY-MM-DD HH:mm:ss");
            endTime = moment(time).endOf("quarter").format("YYYY-MM-DD HH:mm:ss");
            break;
          case 3:
            startTime = moment(time)
              .startOf("month")
              .format("YYYY-MM-DD HH:mm:ss");
            endTime = moment(time).endOf("month").format("YYYY-MM-DD HH:mm:ss");
            break;
          case 2:
            startTime = moment(time)
              .startOf("week")
              .format("YYYY-MM-DD HH:mm:ss");
            endTime = moment(time).endOf("week").format("YYYY-MM-DD HH:mm:ss");
            break;
          case 1:
            startTime = moment(time)
              .startOf("date")
              .format("YYYY-MM-DD HH:mm:ss");
            endTime = moment(time).endOf("date").format("YYYY-MM-DD HH:mm:ss");
            break;
          default:
            return "";
        }
        return {
          startTime,
          endTime,
        };
      },
      changeCount(){
        if(this.tempValue === 5 || this.tempValue === 4){
          return'monthSwapAmount'
        }else{
          return 'daySwapAmount'
        }
      }
    },
    created() {
      this.getVar()
    },
    mounted() {
    },
    methods: {
      async changeVehicle() {
        try {
          const data ={
            carNo:this.carValue || this.vehicle,
            startTime:this.selectTime.startTime,
            endTime:this.selectTime.endTime
          }
          let newList = null
          if(this.tempValue === 5 || this.tempValue === 4){
            const res =await searchCarMonthElectr(data)
            if(res.status !== 200)return this.$msg.error('获取车辆换电记录失败')
            newList = this.sort(res.data.data)
          }else if(this.tempValue === 3 || this.tempValue === 2 || this.tempValue === 1){
            const res =await searchCarDayElectr(data)
            if(res.status !== 200)return this.$msg.error('获取车辆换电记录失败')
            newList = this.sort(res.data.data)
          }
          let vehice = this.carValue || this.vehicle
          this.titleStr = `${vehice}-${this.timeMat}消费记录报表`
          this.rendeerEcharts(newList)
          if(this.isClick){
            this.redule(newList)
          }
          this.tableData = newList
        } catch (error) {

        }
      },
      async getVar(){
        try {
          const res = await getCarBaseAll()
          if(res.status !== 200)return this.$msg.error('获取车牌信息失败')
          this.carList = res.data.data.map(item=>{
            return {
              value:item.carNo,
              label:item.carNo
            }
          })
          this.vehicle = this.carList[0].value
          this.carValue = this.carList[0].value
          this.changeVehicle()
        } catch (error) {

        }
      },
      rendeerEcharts(Newlist){
        let time = this.dateValue || new Date()
        let monthAll = this.dayLIST(moment(time).daysInMonth())
        let newArr = []
        let newList = []
        let newPieList =[]
        let i = 0
        let dayList =[]
        let objData = []
        switch (this.tempValue) {
          case 5://年
            dayList = this.dayLIST(12)
            dayList.forEach(item=>{
              newList.push(this.monthLIST(item))
              i = Newlist.findIndex(c=>moment(c.startTime).month()+1 === item)
              if(i === -1){
                newArr.push(0)
                !this.isClick && newPieList.push({name:this.monthLIST(item),value:0})
              }else if(i !== -1){
                newArr.push(Newlist.length === 0?0:Newlist[i][this.changeCount])
                !this.isClick && newPieList.push({name:this.monthLIST(item),value:Newlist.length === 0?0:Newlist[i][this.changeCount]})
              }
            })
            break;
          case 4://季度
            dayList = this.quarterList
            dayList.forEach(item=>{
              newList.push(this.monthLIST(item))
              i = Newlist.findIndex(c=>moment(c.startTime).month()+1 === item)
              if(i === -1){
                newArr.push(0)
                !this.isClick && newPieList.push({name:this.monthLIST(item),value:0})
              }else if(i !== -1){
                newArr.push(Newlist.length === 0?0:Newlist[i][this.changeCount])
                !this.isClick && newPieList.push({name:this.monthLIST(item),value:Newlist.length === 0?0:Newlist[i][this.changeCount]})
              }
            })
            break
          case 3://月
            monthAll.forEach(item=>{
              newList.push(item)
              let i = Newlist.findIndex(c=>moment(c.startTime).date() === item)
              i === -1 && newArr.push(0)
              i !== -1 && newArr.push(Newlist.length === 0?0:Newlist[i][this.changeCount])
              i !== -1 && !this.isClick && newPieList.push({name:item,value:Newlist.length === 0?0:Newlist[i][this.changeCount]})
            })
            Newlist.length === 0 && !this.isClick && newPieList.push({name:1,value:0})
            break
          case 2://周
            dayList = this.dayLIST(7)
            dayList.forEach(item=>{
              newList.push(this.weekLIST(item))
              i = Newlist.findIndex(c=> moment(c.startTime).isoWeekday() === item)
              i === -1 &&  newArr.push(0)
              i === -1 && !this.isClick && newPieList.push({name:this.weekLIST(item),value:0})
              i !== -1 && newArr.push(Newlist.length === 0?0:Newlist[i][this.changeCount])
              i !== -1 && !this.isClick && newPieList.push({name:this.weekLIST(item),value:Newlist.length === 0?0:Newlist[i][this.changeCount]})
            })
            break
          default:
            return false
        }
        let titleList = []
        if(this.isEcharts){
          objData.push({name:this.arrList[0],type: 'bar',barWidth: '60%', color:'#2a82e4',data:newArr})
          titleList.push(this.arrList[0])
        }else{
          objData.push({name:this.arrList[1],type: 'line',barWidth: '60%', color:'#f37c16',data:newArr})
          titleList.push(this.arrList[1])
        }
        let vehice = this.carValue || this.vehicle
        this.newObj ={
          item:newList,
          list:objData,
          name:`${vehice}-${this.timeMat}消费记录`,
          titleList,
          unit:'消费记录'
        }
        !this.isClick && (this.newPie = newPieList)
        !this.isClick && (this.namePie = '车辆消费记录')
      },
      //合并结果
      redule(list){
        let arrList = JSON.parse(JSON.stringify(list))
        let yearArr = {}
        let querterArr = {}
        let monthArr = {}
        let weekArr = {}
        let yearStr = 0
        let querterStr = 0
        let monthStr = 0
        let weekStr = 0
        let year = moment().year()
        let querter = moment().quarter()
        let month = moment().month()+1
        let week = this.getWeek(year,month,moment().date())
        if(arrList.length === 0){
          yearArr.name = year+'年'
          yearArr.value = 0
          querterArr.name = '本季度'
          querterArr.value = 0
          monthArr.name = `本月`
          monthArr.value = 0
          weekArr.name = '本周'
          weekArr.value = 0
        }else{
          arrList.forEach(item=>{
            let yearIndex = moment(item.startTime).year()
            let quarterIndex = moment(item.startTime).quarter()
            let monthIndex = moment(item.startTime).month()+1
            let weekIndex = this.getWeek(moment(item.startTime).year(),moment(item.startTime).month()+1,moment(item.startTime).date())
            //年
            if(yearIndex === year){
              yearStr += item.monthSwapAmount
              yearArr.name = year+'年'
              yearArr.value = yearStr
            }else{
              yearArr.name = year+'年'
              yearArr.value = 0
            }
            //季度
            if(quarterIndex === querter){
              querterStr += item.monthSwapAmount
              querterArr.name = '本季度'
              querterArr.value = querterStr
            }else{
              querterArr.name = '本季度'
              querterArr.value = 0
            }
            //月
            if(monthIndex === month){
              monthStr += item.monthSwapAmount
              monthArr.name = `本月`
              monthArr.value = monthStr
            }else{
              monthArr.name = `本月`
              monthArr.value = 0
            }
            //周
            if(weekIndex === week){
              weekStr += item.monthSwapAmount
              weekArr.name = '本周'
              weekArr.value = weekStr
            }else{
              weekArr.name = '本周'
              weekArr.value = 0
            }
          })
        }

        this.newPie = [yearArr,querterArr,monthArr,weekArr]
        this.namePie = '车辆消费记录'
      },
      //获取季度
      quarterClick(e){
        this.dateValue = moment(e.startTime).format('YYYY-MM-DD HH:mm:ss')
        if(e.week === 0){
          this.week = '一'
        }else if(e.week === 1){
          this.week = '二'
        } else if(e.week === 2){
          this.week = '三'
        } else if(e.week === 3){
          this.week = '四'
        }
      },
      weekLIST(list) {
        switch (list) {
          case 1:
            return "星期一";
          case 2:
            return "星期二";
          case 3:
            return "星期三";
          case 4:
            return "星期四";
          case 5:
            return "星期五";
          case 6:
            return "星期六";
          case 7:
            return "星期天";
          default:
            break;
        }
      },
      dayLIST(list) {
        if (list.length === 0) return [];
        let newArr = [];
        for (let i = 1; i < list + 1; i++) {
          newArr.push(i);
        }
        return newArr;
      },
      monthLIST(list) {
        switch (list) {
          case 1:
            return "一月";
          case 2:
            return "二月";
          case 3:
            return "三月";
          case 4:
            return "四月";
          case 5:
            return "五月";
          case 6:
            return "六月";
          case 7:
            return "七月";
          case 8:
            return "八月";
          case 9:
            return "九月";
          case 10:
            return "十月";
          case 11:
            return "十一月";
          case 12:
            return "十二月";
          default:
            break;
        }
      },
      //排序
      sort(item) {
        if (item.length === 0) return [];
        let list = item;
        list.forEach((item1) => {
          item1.startTime = +moment(item1.startTime).format("x");
        });
        list.sort((a, b) => a.startTime - b.startTime);
        list.forEach((item1) => {
          item1.startTime = moment(item1.startTime).format("YYYY-MM-DD HH:mm:ss");
        });
        return list;
      },
      getWeek(a, b, c) {
        /**
         * a = d = 当前日期
         * b = 6 - w = 当前周的还有几天过完(不算今天)
         * a + b 的和在除以7 就是当天是当前月份的第几周
         */
        var date = new Date(a, parseInt(b) - 1, c),
          w = date.getDay(),
          d = date.getDate();
        if (w == 0) {
          w = 7;
        }
        var config = {
          getMonth: date.getMonth() + 1,
          getYear: date.getFullYear(),
          getWeek: Math.ceil((d + 6 - w) / 7),
        };

        return `${config.getYear}年${config.getMonth}月${config.getWeek}周`;
      },
      resFuntion() {
        this.carValue = ''
        this.tempValue = 5
        this.dateValue = ''
        this.isClick = true
        this.changeVehicle()
      },
      async excelAll(){
        let months = this.changeCount === 'monthSwapAmount'?'每月消费金额':'每日消费金额'
        let coulm = [
          {prop:'carNo',label:'车牌号'},
          {prop:this.changeCount,label:months},
          {prop:'startTime',label:'开始时间'},
          {prop:'endTime',label:'结束时间'},
        ]
        const confirm =await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e=>e)
        if(confirm !== 'confirm')return
        this.$download(this.tableData,coulm,'车辆消费记录')
      },
      search(){
        this.amount = this.changeCount
        this.storage('vehicleElectric',{carNo:this.vehicle,i:this.tempValue,time:moment(this.dateValue).format('x')})
        this.isClick = false
        this.changeVehicle()
      },
      carConsume(){
        const newList = JSON.parse(getItem('vehicleElectric'))
        if(newList && newList[1].carNo === this.vehicle && newList[0].index > 3){
          this.vehicle = newList[1].carNo
          if(newList[1].i === 5){
            this.tempValue = 5
            this.dateValue = newList[1].time !== 'Invalid date'?moment(+newList[1].time).toString():''
          }else if(newList[1].i === 4){
            this.tempValue = 4
            this.$nextTick(()=>{
              this.$refs.child.selectSeason(this.timeInit(moment(+newList[1].time).month()+1),moment(+newList[1].time).year())
            })

          }else if(newList[1].i === 3){
            this.tempValue = 3
            this.dateValue = newList[1].time !== 'Invalid date'?moment(+newList[1].time).toString():''
          }else if(newList[1].i === 2){
            this.tempValue = 2
            this.dateValue = newList[1].time !== 'Invalid date'?moment(+newList[1].time).toString():''
          }else if(newList[1].i === 1){
            this.tempValue = 1
            this.dateValue = newList[1].time !== 'Invalid date'?moment(+newList[1].time).toString():''
          }

        }
      },
      timeInit(time){
        switch (time) {
          case 1 || 2 || 3:
            return 0
          case 4 || 5 || 6:
            return 1
          case 7 || 8 || 9:
            return 2
          case 10 || 11 || 12:
            return 3
          default:
            return ''
        }
      },
      changeEcharts(){
        this.isEcharts = !this.isEcharts
        this.changeVehicle()
      }
    },
  };
</script>

<style lang='less' scoped>
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
  .el-col{
    position: relative;
  }
  .content {
    border: 0;
    box-shadow: none;
    background-color:#f0f2f5;

  }
  .content-title{
    background-color: #fff;
    padding: 15px 0 15px 15px;
  }
  .content-content{
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    div{
      background-color: #fff;
      &:nth-child(1){
        width: 28%;
        margin-right: 15px;
      }
      &:nth-child(2){
        width: 71%;
        padding: 5px;
      }
    }
  }
  .content-bottom{
    background-color: #fff;
    padding: 20px 10px 10px 10px;
  }

  .electric-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 30px;
    padding-left: 20px;
    font-weight: 700;
    border-left: 6px solid #2a82e4;
  }
  #changeI{
    position: absolute;
    top: 15px;
    right: 0%;
    cursor: pointer;
    color: #666;
    margin-right: 50px;
    z-index: 999;
  }
  #changeI:hover{
    color: #2a81e3;
  }
  .content-box-title{
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
    span{
      color: #5d5d5d;
    }
    .el-button{
      margin-left: 10px;
    }
  }
</style>
