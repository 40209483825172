<template>

  <el-card>
    <h2 style="text-align: center;">换电订单信息</h2>
    <el-steps :active="2" finish-status="success" process-status="wait" align-center style="margin-bottom: 40px;">
      <el-step title="退款订单信息"></el-step>
      <el-step title="换电订单信息"></el-step>
      <el-step title="退款审核"></el-step>
    </el-steps>
    <el-divider></el-divider>
    <el-form ref="form" :model="swapInfo" label-width="290px">
      <el-col :span="8">
        <el-form-item label="换电订单号:">
          <span style="color: #409EFF">{{swapInfo.forderNo}}</span>
          <!--        <el-input v-model="swapInfo.forderNo"></el-input>-->
        </el-form-item>

        <el-form-item label="车辆VIN码:">
          <span style="color: #409EFF">{{swapInfo.fcarVin}}</span>
        </el-form-item>

        <el-form-item label="拆下电池序列号:">
          <span style="color: #409EFF">{{swapInfo.fdownBatteryNo}}</span>
        </el-form-item>
        <el-form-item label="装上电池序列号:">
          <span style="color: #409EFF">{{swapInfo.fupBatteryNo}}</span>
        </el-form-item>

        <el-form-item label="车牌识别时间:">
          <span style="color: #409EFF">{{swapInfo.fcardidtTimeNum}}</span>
        </el-form-item>
        <el-form-item label="换电开始时刻:">
          <span style="color: #409EFF">{{swapInfo.fswapStartTime}}</span>
        </el-form-item>
        <el-form-item label="换电结束时刻:">
          <span style="color: #409EFF">{{swapInfo.fswapEndTime}}</span>
        </el-form-item>
        <el-form-item label="订单换电日期:">
          <span style="color: #409EFF">{{swapInfo.fdate}}</span>
        </el-form-item>
        <el-form-item label="车辆离开时间:">
          <span style="color: #409EFF">{{swapInfo.fcarDepartureTime}}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="汽车车牌号:">
          <span style="color: #409EFF">{{swapInfo.fcarNo}}</span>
        </el-form-item>
        <el-form-item label="换电站编码:">
          <span style="color: #409EFF">{{swapInfo.fstationNo}}</span>
        </el-form-item>


        <el-form-item label="拆下电池SOC:">
          <span style="color: #409EFF">{{swapInfo.fdownBatterySoc}}</span>
        </el-form-item>
        <el-form-item label="装上电池SOC:">
          <span style="color: #409EFF">{{swapInfo.fupBatterySoc}}</span>
        </el-form-item>
        <el-form-item label="装上电池SOE:">
          <span style="color: #409EFF">{{swapInfo.fupBatterySoe}}</span>
        </el-form-item>
        <el-form-item label="拆下电池行驶里程:">
          <span style="color: #409EFF">{{swapInfo.fdownTotalOdometer}}</span>
        </el-form-item>
        <el-form-item label="装上电池行驶里程:">
          <span style="color: #409EFF">{{swapInfo.fupTotalOdometer}}</span>
        </el-form-item>
        <el-form-item label="拆下电池外接充电次数:">
          <span style="color: #409EFF">{{swapInfo.fdownExtChargeCount}}</span>
        </el-form-item>
        <el-form-item label="拆下电池外接充电能量:">
          <span style="color: #409EFF">{{swapInfo.fdownExtChargeEnergy}}</span>
        </el-form-item>
<!--        <el-form-item label="拆下电池上次换电完成出站时SOC:">-->
<!--          <span style="color: #409EFF">{{swapInfo.fdownBatteryLastSoc}}</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="拆下电池上次换电完成出站时SOE:">-->
<!--          <span style="color: #409EFF">{{swapInfo.fdownBatteryLastSoe}}</span>-->
<!--        </el-form-item>-->
      </el-col>
      <el-col :span="8">
        <el-form-item label="车辆总里程:">
          <span style="color: #409EFF">{{swapInfo.fvehicleMileage}}</span>
        </el-form-item>
        <el-form-item label="此次换电次序:">
          <span style="color: #409EFF">{{swapInfo.fserialNo}}</span>
        </el-form-item>

        <el-form-item label="取电池仓位号:">
          <span style="color: #409EFF">{{swapInfo.finBinNo}}</span>
        </el-form-item>
        <el-form-item label="放电池仓位号:">
          <span style="color: #409EFF">{{swapInfo.foutBinNo}}</span>
        </el-form-item>

        <el-form-item label="是否成功换电:">
          <!--0:手机号码；1:微信号码;2:网站注册号码;3:支付宝号码-->
          <span style="color: #409EFF" v-if="swapInfo.fisSuccess===0">成功</span>
          <span style="color: #F56C6C" v-if="swapInfo.fisSuccess===1">失败</span>
        </el-form-item>
        <el-form-item label="本次换电次数:">
          <span style="color: #409EFF">{{swapInfo.fswapCount}}</span>
        </el-form-item>
        <el-form-item label="本次换电时间(min):">
          <span style="color: #409EFF">{{swapInfo.fswapTimeCount}}</span>
        </el-form-item>
        <el-form-item label="订单是否取消:">
          <span style="color: #409EFF" v-if="swapInfo.forderIsCancel===0">正常</span>
          <span style="color: #F56C6C" v-if="swapInfo.forderIsCancel===1">取消</span>
        </el-form-item>
        <el-form-item label="换电结果:">
          <span style="color: #409EFF" v-if="swapInfo.fresult===0">正常</span>
          <span style="color: #F56C6C" v-if="swapInfo.fresult===1">失败</span>
        </el-form-item>
        <el-form-item label="失败原因:">
          <span style="color: #409EFF">{{swapInfo.ffailReason}}</span>
        </el-form-item>
      </el-col>
    </el-form>
<!--    <div slot="footer">-->
<!--      <el-button @click="close">取消</el-button>-->
<!--      <el-button type="primary" @click="handelConfirm">确定</el-button>-->
<!--    </div>-->
    <el-button @click="previous">上一步</el-button>
    <el-button style="margin-top: 12px;" @click="next">下一步</el-button>
  </el-card>
</template>

<script>
  import {
    getClientPage,
    newAddClient,
    chanegClient,
    removeClientMsg,
    restWord
  } from "@/utils/operation";
  import {
    StationOrderFinishedByOrderNo,
    findApSwapRefundByOrderNo
  } from "@/utils/operation2";
  import moment from "moment";
  import * as myStore from "@/utils/stroage";

  export default {
    name: "clienName",
    data() {
      return {
        active: 0,
        swapInfo: {}

      };
    },
    computed: {
      userId() {
        return JSON.parse(this.$store.state.user).id
      }
    },
    created() {
      this.init()
    },
    methods: {
      onClose() {
        this.$refs['elForm'].resetFields()
      },
      //获取换电信息
      async init() {
        if (this.$route.params && this.$route.params.fOrderNo) {
          this.fOrderNo = this.$route.params.fOrderNo
          console.log(this.fOrderNo)
          const result = await StationOrderFinishedByOrderNo(this.fOrderNo)

          if(result.data.data.rows==null){
            this.$msg.error("此换电订单没有换电记录")

            this.swapInfo.fOrderNo=this.fOrderNo
            this.$router.push({path: '/RefundInfo/' + this.swapInfo.fOrderNo})


            const result = await findApSwapRefundByOrderNo(this.swapInfo.fOrderNo)
          }
          console.log(result)
          this.swapInfo = result.data.data.rows

        } else {

        }
      },
      //上一步
      previous() {
        this.$router.push({path: '/swapRefund/' + this.swapInfo.forderNo})
      },
      next() {
        this.$router.push({path: '/RefundInfo/' + this.swapInfo.fid})
      },

    },
  };
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 10px;
    }
  }

  ul {
    width: 0;
  }

  .el-select {
    width: 100%;
  }
</style>
