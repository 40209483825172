<template>
    <el-card>
        <el-row style="margin:0;">
            <div class="auth-title">权限分配<i @click="goClick" class="iconfont icon-fanhui"></i></div>
        </el-row>
        <div class="auth-content-name">
            <span>角色名称：</span>
            <span>{{ authList.roleName }}</span>
        </div>
        <el-row style="margin:0;">
            <div class="information" v-for="item in tableList" :key="item.id">
                <el-checkbox v-model="item.checkAll" class="parentLable" @change="handleCheckAllChange(item)">
                    {{ item.menuName }}
                </el-checkbox>
                <el-checkbox-group v-model="checkList">
                    <el-checkbox @change="handleCheckedCitiesChange(item,item1)" v-for="item1 in item.children"
                                 :key="item1.id" :label="item1.id">{{ item1.menuName }}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
        </el-row>
        <el-row class="auth-buttom">
            <el-button @click="authBinding" size="small" type="primary">提交</el-button>
            <el-button @click="resFuntion" size="small">重置</el-button>
        </el-row>
    </el-card>
</template>

<script>
import { getAuthAll, AuthBindin, rolesIdAuth } from "@/utils/user";
import * as myStore from "@/utils/stroage";

export default {
    name: "authority",
    data() {
        return {
            ischeckBox: false,
            authList: {},
            checkList: [],
            parentList: [],
            tableList: []
        };
    },
    created() {
        this.authList = JSON.parse(this.$route.query.data);
        this.getAuthList();
    },
    computed: {
        authId() {
            return this.authList.id;
        },
        userInfo() {
            return JSON.parse(this.$store.state.user);
        }
    },
    methods: {
        //获取权限列表
        async getAuthList() {
            try {
                const res = await getAuthAll();
                if (res.status !== 200) return this.$msg.error("获取权限列表失败");
                let newList = res.data.data;
                this.total = newList;
                this.tableList = this.authRander(newList);
                this.getrolesIdAuth();
            } catch (e) {
                console.log(e);
            }
        },
        //获取角色的权限
        async getrolesIdAuth() {
            try {
                const res = await rolesIdAuth(this.authId);
                if (res.status !== 200) return this.$msg.error("获取角色权限失败");
                let newList = res.data.data;
                let newArr = [];
                let Arr = newList.reduce((item, row) => {
                    if (row.menuLevel === 0) {
                        let i = item.findIndex(c => c === row.id);
                        i === -1 && item.push(row.id);
                        i !== -1 && (item[i] = row.id);
                        this.tableList.forEach(item1 => {
                            if (row.id === item1.id) {
                                item1.checkAll = true;
                            }
                        });
                    } else if (row.menuLevel === 1) {
                        let index = newArr.findIndex(c => c === row.id);
                        index === -1 && newArr.push(row.id);
                        index !== -1 && (newArr[index] = row.id);
                        this.tableList.forEach(item1 => {
                            if ("children" in item1) {
                                item1.children.forEach(item2 => {
                                    if (item2.id === row.id) {
                                        item2.checkAll = true;
                                    }
                                });
                            }

                        });
                    }
                    return item;
                }, []);
                this.parentList = Arr;
                this.checkList = newArr;
                const list = {
                    tableList: this.tableList,
                    checkList: newArr,
                    parentList: Arr
                };
                myStore.sessionSetItem("authority", JSON.stringify(list));
            } catch (error) {

            }
        },
        authRander(list) {
            if (list.length === 0) return [];
            let newArr = [];
            let temArr = list.filter(c => {
                if (c.menuLevel === 0) {
                    c.checkAll = false;
                    newArr.push(c);
                } else {
                    return c;
                }
            });
            newArr.forEach(item => {
                temArr.forEach(item2 => {
                    if (item.id === item2.parentId) {
                        if ("children" in item) {
                            item.children.push(item2);
                        } else {
                            item.children = [];
                            item2.checkAll = false;
                            item.children.push(item2);
                        }
                    }
                });
            });
            return newArr;
        },
        //权限赋予
        async authBinding() {
            try {
                let formFata = {};
                formFata.roleId = this.authList.id;
                formFata.menuInfoIdList = [...this.checkList, ...this.parentList];
                let data = JSON.parse(JSON.stringify(formFata));
                const res = await AuthBindin(data);
                if (res.status !== 200) return this.$msg.error("权限赋予失败");
                this.$msg.success("权限赋予成功");
                this.getAuthList();
            } catch (e) {
                console.log(e);
            }
        },
        handleCheckAllChange(val) {
            if (val.checkAll) {
                val.children.forEach(item => {
                    item.checkAll = true;
                });
                let list = val.children.map(item => {
                    return item.id;
                });
                this.checkList.push(...list);
                this.parentList.push(val.id);
            } else {
                val.children.forEach(item => {
                    item.checkAll = false;
                });
                let list = val.children.map(item => {
                    return item.id;
                });
                let arr = this.checkList;
                list.forEach(item => {
                    let i = arr.findIndex(c => c === item);
                    i !== -1 && arr.splice(i, 1);
                });
                let index = this.parentList.findIndex(c => c === val.id);
                index !== -1 && this.parentList.splice(index, 1);
                this.checkList = arr;
            }
            val.isIndeterminate = false;
        },
        handleCheckedCitiesChange(val, val1) {
            let children = val.children;
            try {
                val1.checkAll = !val1.checkAll;
                if (val1.checkAll) {
                    val.checkAll = val1.checkAll;
                    children.forEach(item => {
                        let state = this.checkList.some(c => item.id === c);
                        if (state) {
                            let i = this.parentList.findIndex(c => c === val1.parent);
                            i === -1 && this.parentList.push(val1.parent);
                            i !== -1 && (this.parentList[i] = val1.parent);
                            throw Error();
                        }
                    });

                } else {
                    let newArr = this.checkList;
                    let Arr = [];
                    let list = children.map(item => {
                        return item.id;
                    });
                    for (let i = 0; i < list.length; i++) {
                        for (let j = 0; j < newArr.length; j++) {
                            if (list[i] === newArr[j]) {
                                Arr.push(newArr[j]);
                            }
                        }
                    }
                    if (Arr.length === 0) {
                        let i = this.parentList.findIndex(c => c === val.id);
                        this.parentList.splice(i, 1);
                        val.checkAll = false;
                    }
                }
            } catch (error) {

            }

        },
        resFuntion() {
            let newList = myStore.sessionGetItem("authority");
            if (newList) {
                this.tableList = newList.tableList;
                this.checkList = newList.checkList;
                this.parentList = newList.parentList;
            }
        },
        goClick() {
            myStore.sessionRemoveItem("authority");
            this.$router.go(-1);
        }
    }
};
</script>

<style lang="less" scoped>
.el-row {
    margin: 15px 0;

}

.auth-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    border-left: 4px solid #1b80e1;
    font-weight: 700;

    .icon-fanhui {
        font-size: 20px;
        margin-left: 10px;
        color: #1b80e1;
        cursor: pointer;
    }
}

.auth-content-name {
    height: 30px;
    margin: 15px 0;

    span {
        font-weight: 700;
        font-size: 18px;
    }
}

.information {
    margin: 10px 0;

    > h3 {
        font-size: 18px;
    }

    /deep/ .el-checkbox-group {
        margin: 10px 0;

        .el-checkbox {
            margin: 5px 10px;
        }

        .el-checkbox__inner {
            border-radius: 50%;
        }
    }
}

.auth-buttom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    width: 100%;
    height: 40px;

    .el-button {
        margin: 0 20px;
    }
}

/deep/ .parentLable {
    > .el-checkbox__label {
        font-weight: 700;
        font-size: 16px;
        color: #333;
    }

    > .el-checkbox__input {
        .el-checkbox__inner {
            border-radius: 0;
        }
    }
}
</style>
