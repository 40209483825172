<template>
  <el-card>
    <el-row>
      <el-col :span="4">
        <el-input @blur="loginNumber" placeholder="请输入登录账号" v-model.trim="informationVo.floginNo"></el-input>
      </el-col>
      <el-col :span="4">
        <el-input placeholder="请输入车牌号" v-model.trim="informationVo.fcarNo"></el-input>
      </el-col>
      <el-col :span="10">
        <el-button @click="submitClient()" type="primary">查询</el-button>
        <el-button @click="removeCheck">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-table
        @selection-change="batchList"
        ref="clientTabel"
        :data="tableData"
        style="width: 100%"
        height="500"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="编号"  :index="renIndex" type="index" width="50"></el-table-column>
        <el-table-column prop="floginNo" label="登录帐号" min-width="120"></el-table-column>
        <el-table-column prop="fweChatNo" label="用户昵称" min-width="120"></el-table-column>
        <el-table-column prop="fuserName" label="姓名" min-width="120"></el-table-column>
        <el-table-column prop="fuserAvatar" label="用户头像" width="120"><template slot-scope="scope">
          <el-avatar :src="scope.row.fuserAvatar" icon="el-icon-user-solid"></el-avatar>
        </template></el-table-column>
<!--        <el-table-column prop="floginType" label="登录方式">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.floginType === 0">手机号码</span>-->
<!--            <span v-else-if="scope.row.floginType === 1">微信号码</span>-->
<!--            <span v-else>网站注册号码</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="fuserName" label="姓名"></el-table-column>-->
        <el-table-column prop="fidentifyCode" label="身份证号码" width="170"></el-table-column>
        <el-table-column prop="fgender" label="性别" width="60">
          <template slot-scope="scope">
            <span v-if="scope.row.fgender === 1">男</span>
            <span v-else-if="scope.row.fgender === 2">女</span>
            <span v-else>保密</span>
          </template>
        </el-table-column>
        <el-table-column prop="femail" label="邮箱" min-width="150"></el-table-column>
        <el-table-column prop="fiphoneNo" label="电话" min-width="120"></el-table-column>
<!--        <el-table-column prop="weChatNo" label="微信号码" width="110"></el-table-column>-->
        <el-table-column prop="fcarNo" label="车牌号" min-width="90"></el-table-column>
        <el-table-column prop="fremainAmount" label="剩余充值金额" min-width="190"></el-table-column>
        <el-table-column prop="fcardNum" label="卡卷数量" min-width="90"></el-table-column>
<!--        <el-table-column prop="fpoints" label="积分数" min-width="90"></el-table-column>-->
<!--        <el-table-column prop="fwork" label="从事工作" min-width="90"></el-table-column>-->

        <el-table-column prop="fvipDate" label="注册时间" min-width="190"></el-table-column>
        <el-table-column prop="fisMember" label="是否是会员" min-width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.fisMember === 1">会员</span>
            <span v-else>非会员</span>
          </template>
        </el-table-column>
        <el-table-column prop="fregisterPlat" label="注册平台" min-width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.fregisterPlat === 0">微信小程序</span>
            <span v-else-if="scope.row.fregisterPlat === 1">安卓APP</span>
            <span v-else-if="scope.row.fregisterPlat === 2">苹果APP</span>
            <span v-else>PC平台</span>
          </template>
        </el-table-column>
        <el-table-column prop="fhomeAddr" :show-overflow-tooltip="true" label="地址" min-width="150"></el-table-column>
<!--        <el-table-column prop="fvipDate" label="注册时间" min-widt="160"></el-table-column>-->
<!--        <el-table-column prop="fstate" label="状态" min-widt="100">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.fstate === 0">未使用</span>-->
<!--            <span v-else-if="scope.row.fstate === 1">正常</span>-->
<!--            <span v-else-if="scope.row.fstate === 2">注销</span>-->
<!--            <span v-else>黑名单</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="fwork" label="从事工作"></el-table-column>-->
<!--        <el-table-column prop="fpoints" label="积分"></el-table-column>-->
<!--        <el-table-column prop="fauditState" label="审核进度" width="120">-->
<!--          <template slot-scope="scope">-->
<!--            <el-tag v-if="scope.row.fauditState === 0">未注册</el-tag>-->
<!--            <el-tag v-else-if="scope.row.fauditState === 1" type="info">审核中</el-tag>-->
<!--            <el-tag v-else-if="scope.row.fauditState === 2" type="success">审核通过</el-tag>-->
<!--            <el-tag v-else type="danger">审核未通过</el-tag>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column fixed="right" label="操作" min-width="180">-->
<!--          <template slot-scope="scope">-->
<!--            <router-link :to="'/client/'+scope.row.fcarNo">-->
<!--              <el-button  size="small" type="primary" class="el-icon-edit">用户审核</el-button>-->
<!--            </router-link>-->
<!--&lt;!&ndash;            <el-button&ndash;&gt;-->
<!--&lt;!&ndash;            :disabled="userId !== 3"&ndash;&gt;-->
<!--&lt;!&ndash;            @click="restPassword(scope.row.id)"&ndash;&gt;-->
<!--&lt;!&ndash;              size="small"&ndash;&gt;-->
<!--&lt;!&ndash;              type="warning"&ndash;&gt;-->
<!--&lt;!&ndash;            >密码重置</el-button>&ndash;&gt;-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
    </el-row>
    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[20, 25, 30]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-row>


    <!-- 新增和修改 -->
    <el-dialog :title="isClent?'新增客户信息':'修改客户信息'" :visible.sync="client" width="50%" @close="close">
      <el-form
        size="small"
        ref="clientform"
        :rules="clientRules"
        :model="clientFrom"
        label-width="100px"
      >
        <el-row>
          <el-col style="margin-right:0;" :span="12">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="clientFrom.name"></el-input>
            </el-form-item>
            <el-form-item label="身份证号码" prop="identifyCode">
              <el-input v-model="clientFrom.identifyCode"></el-input>
            </el-form-item>
            <el-form-item label="登录帐号" prop="loginNo">
              <el-input  v-model="clientFrom.loginNo"></el-input>
            </el-form-item>
            <el-form-item label="登录密码" prop="loginPsd">
              <el-input :disabled="isClent" v-model="clientFrom.loginPsd"></el-input>
            </el-form-item>
            <el-form-item label="地址" prop="homeAddr">
              <el-input v-model="clientFrom.homeAddr"></el-input>
            </el-form-item>
            <el-form-item label="会员等级" prop="isMember">
              <el-select v-model="clientFrom.isMember" placeholder="请选择是否是会员">
                <el-option
                  v-for="item in isMemberList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
             <el-form-item label="积分" prop="points">
              <el-input v-model="clientFrom.points"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="gender">
              <el-select v-model="clientFrom.gender" placeholder="请选择性别">
                <el-option
                  v-for="item in gender"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col style="margin-right:0;" :span="12">
             <el-form-item label="登录方式" prop="loginType">
              <el-select v-model="clientFrom.loginType" placeholder="请选择登录方式" disabled>
                <el-option
                  v-for="item in loginTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="注册平台" prop="registerPlat" >
              <el-select v-model="clientFrom.registerPlat" placeholder="请选择注册平台" disabled>
                <el-option
                  v-for="item in registerList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="审核进度" prop="auditState">
              <el-select v-model="clientFrom.auditState" placeholder="请选择审核进度" disabled>
                <el-option
                  v-for="item in auditStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
             <el-form-item v-if="isStatus" label="使用状态" prop="state">
              <el-select v-model="clientFrom.state" placeholder="请选择使用状态">
                <el-option
                  v-for="item in stateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="clientFrom.email"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="iphoneNo">
              <el-input maxlength="11" v-model="clientFrom.iphoneNo"></el-input>
            </el-form-item>
            <el-form-item label="微信号码">
              <el-input v-model="clientFrom.weChatNo"></el-input>
            </el-form-item>
            <el-form-item label="车牌号" prop="carNo">
              <el-input v-model="clientFrom.carNo"></el-input>
            </el-form-item>
            <el-form-item label="从事工作">
              <el-input v-model="clientFrom.work"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="client = false">取 消</el-button>
        <el-button type="primary" @click="changeClient">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  getClientPage,
  newAddClient,
  chanegClient,
  removeClientMsg,
  restWord
} from "@/utils/operation";
import {
  getCustomerRegister
} from "@/utils/operation2";
import moment from "moment";
import * as myStore from "@/utils/stroage";

export default {
  name: "clienName",
  data() {
    var proof = (rule, value, cb) => {
      const reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      if (!value) cb(new Error("身份证不能为空"));
      if (!reg.test(value)) {
        return cb(new Error("身份证输入错误"));
      }
      return cb();
    };

    var mobile = (rule, value, cb) => {
      const Reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
      if(value === '')return cb()
      if(!Reg.test(value))return  cb(new Error("手机格式错误"))
       cb();
    };
    var licence = (rule, value, cb) => {
      const Reg = /^([京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][1-9DF][1-9ABCDEFGHJKLMNPQRSTUVWXYZ]\d{3}[1-9DF]|[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][\dABCDEFGHJKLNMxPQRSTUVWXYZ]{5})$/;
     if(value === '')return cb()
      if(!Reg.test(value))return  cb(new Error("车牌号格式错误"))
     cb();
    };

    var emailReg = (rule, value, cb) => {
      const Reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
      if(value === '')return cb()
      if(!Reg.test(value))return  cb(new Error("邮箱格式错误"))
      cb();

    };
    return {
      active: 0,
      resgiterValue: "",
      weCatValue:'',
      modileValue:'',
      userValue:'',
      dentiValue:'',
      carValue:'',
      stateValue: "",
      query: {
        pageNum: 1,
        pageSize: 20,
        respageNum:1
      },
      isPage: true,
      total: 0,
      tableData: [],

      informationVo:{},
      loginTypeList: [
        {
          value: 0,
          label: "手机号码",
        },
        {
          value: 1,
          label: "微信号码",
        },
        {
          value: 2,
          label: "网站注册账号",
        },
      ],
      gender:[
        {
          value:1,
          label:'男'
        },
        {
          value:2,
          label:'女'
        },
        {
          value:3,
          label:'保密'
        }
      ],
      isClent: true,
      userAudit:false,
      client: false,
      isStatus:false,
      clientFrom: {
        loginNo: "",
        loginPsd:'',
        loginType: 3,
        name: "",
        identifyCode: "",
        gender: 3,
        email: "",
        iphoneNo: "",
        weChatNo: "",
        carNo: "",
        homeAddr: "",
        isMember: "",
        registerPlat: 3,
        state: null,
        work: "",
        vipDate: null,
        auditState: null,
        points: null,
      },
      nameList: [],
      batchNum: [],
      clientRules: {
        loginNo: [
          { required: true, message: "请输入登录帐号", trigger: "blur" },
        ],
        loginPsd: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          { min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur' }
        ],
        loginType: [
          { required: true, message: "请选择登录方式", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        identifyCode: [{ required: true, validator: proof, trigger: "blur" }],
        iphoneNo: [{ validator: mobile, trigger: "blur" }],
        carNo: [{ validator: licence, trigger: "blur" }],
        email: [{validator: emailReg, trigger: "blur" }],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        homeAddr: [
          { required: true, message: "请输入地址", trigger: "blur" },
        ],
        isMember: [
          { required: true, message: "请选择是否是会员", trigger: "change" },
        ],
        registerPlat: [
          { required: true, message: "请选择注册平台", trigger: "change" },
        ],
        state: [
          { required: true, message: "请选择使用状态", trigger: "change" },
        ],
        auditState: [
          { required: true, message: "请选择审核进度", trigger: "change" },
        ],
        points: [{ required: true, message: "请输入积分", trigger: "blur" }],
      },
      registerList: [
        {
          value: 0,
          label: "微信小程序",
        },
        {
          value: 1,
          label: "安卓APP",
        },
        {
          value: 2,
          label: "苹果APP",
        },
        {
          value: 3,
          label: "pc平台",
        },
      ],
      stateList: [
        {
          value: 0,
          label: "未使用",
        },
        {
          value: 1,
          label: "正常",
        },
        {
          value: 2,
          label: "注销",
        },
        {
          value: 3,
          label: "黑名单",
        },
      ],
      auditStateList: [
        {
          value: 0,
          label: "未注册",
        },
        {
          value: 1,
          label: "审核中",
        },
        {
          value: 2,
          label: "审核通过",
        },
        {
          value: 3,
          label: "审核未通过",
        },
      ],
      isMemberList: [
        {
          value: 1,
          label: "VIP会员",
        },
        {
          value: 0,
          label: "普通会员",
        },
      ],
    };
  },
  computed: {
    userId(){
      return JSON.parse(this.$store.state.user).id
    }
  },
  created() {
    this.clientFrom.registerPlat=3
    this.submitClient();
  },
  methods: {

    next() {
      if (this.active++ > 2) this.active = 0;
  },

    batchList(list) {
      this.batchNum = list.map((item) => {
        return item.id;
      });
    },
    //每页数据条数
    handleSizeChange(val) {
      this.query.pageSize = val;
      if (this.isPage) {
        this.submitClient();
      } else {
        this.submitClient();
      }
    },
    //当前页
    handleCurrentChange() {
      this.query.pageNum = val;
      if (this.isPage) {
        this.submitClient();
      } else {
        this.query.respageNum = val
        this.submitClient();
      }
    },
     renIndex(index){
      return (this.query.pageNum -1 ) * this.query.pageSize + index +1
    },
    //添加或修改
    changeClient() {
      this.$refs.clientform.validate(async (valid) => {
        if (!valid) return
        var data = JSON.parse(JSON.stringify(this.clientFrom));
        data.loginType = +data.loginType;
        data.isMember = +data.isMember;
        data.vipDate = moment().format("YYYY-MM-DD HH:mm:ss");
        data.state = !this.isStatus?1: data.state
        if (this.isClent) {
          const res = await newAddClient(data);
           if (res.status !== 200 && res.data.message === '已存在') return this.$msg.warning("客户信息已存在，请重新添加!")
          if (res.status !== 200 && res.data.message !== '已存在') return this.$msg.error("新增客户信息失败!")
          this.$msg.success("新增客户信息成功!");
          this.client = false;
          this.submitClient();
        } else {
          const res = await chanegClient(data);
          if (res.status !== 200) return this.$msg.error("修改客户信息失败!");
          this.$msg.success("修改客户信息成功!");
          this.client = false;

          this.submitClient();
        }
      });
    },
    close() {
      this.$refs.clientform.resetFields();
      this.clientFrom = {}
      this.isClent = true;
    },
    closeUserAudit(){
      this.$refs.userAuditform.resetFields();
      this.clientFrom = {}
      this.isClent = true;
    },
    //修改信息填充
    // compile(data) {
    //   this.isStatus = data.auditState === 2?true:false
    //   this.client = true;
    //   this.isClent = false;
    //   this.clientFrom = JSON.parse(JSON.stringify(data));
    // },
    //用户审核
    compile(data) {
      // this.isStatus = data.auditState === 2?true:false
      this.userAudit = true;
      // this.isClent = false;
      // this.clientFrom = JSON.parse(JSON.stringify(data));
    },
    //查询客户信息
    async submitClient() {
        const res = await getCustomerRegister(this.query.pageNum, this.query.pageSize, this.informationVo);
        console.log(res)
        if (res.data.code !== 20000) return this.$msg.error("查询客户信息失败!");
        this.tableData = res.data.data.rows.records;
        this.total = res.data.data.total;
        // this.isPage = false;
    },
    cleanList() {
      this.$refs.clientTabel.clearSelection();
    },
    //批量删除
    async removeAll() {
      if (this.batchNum.length === 0)
        return this.$msg.info("请选择需要删除的项");
      const config = await this.$MessageBox
        .confirm("此操作将永久删除选中项, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((e) => e);
      if (config !== "confirm") return;
      try {
        const list = this.batchNum;
        const res = await removeClientMsg(list);
        console.log(res);
        if (res.status !== 200) return this.$msg.error("删除失败");
        this.tableData = this.tableData.filter((item, i) => {
          return item.id !== list[i];
        });
        this.$refs.clientTabel.clearSelection();
        if (this.query.pageNum > 1 && this.tableData.length === 0) {
          this.query.pageNum = this.query.pageNum - 1;
        }
        this.$msg.success("删除成功");
        this.submitClient();
      } catch (e) {
        console.log(e);
      }
    },
    //重置密码
   async restPassword(id){
        const config = await this.$MessageBox
        .confirm("确定要重置密码吗, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((e) => e);
      if (config !== "confirm") return;
       const res =await restWord(id)
       if(res.status !== 200)return this.$msg.error('重置密码失败')
       this.$msg.success('重置密码成功')
       this.submitClient()
    },
    removeCheck() {
      this.informationVo={}
      this.submitClient();
    },
    loginNumber(){
        const newList = JSON.parse(getItem('client'))
        if(newList && newList[1].loginNo === this.resgiterValue && newList[0].index > 3){
              this.resgiterValue = newList[1].loginNo
              this.weCatValue = newList[1].weChatNo
              this.modileValue = newList[1].iphoneNo
              this.userValue = newList[1].name
              this.dentiValue = newList[1].identifyCode
              this.carValue = newList[1].carNo
              this.stateValue = newList[1].state
        }
    }
  },
};
</script>

<style lang='less' scoped>
.el-row {
  margin: 15px 0;
  .el-col {
    margin-right: 10px;
  }
}
ul {
  width: 0;
}
.el-select{
  width: 100%;
}
</style>
