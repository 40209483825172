<template>
  <!-- 换电站消费环比 -->
  <div class="content">
    <el-row class="content-title">
      <el-row class="head-section ">
        <el-col :span="6">
            <el-form :inline="true">
                <el-form-item label="换电站：">
                    <el-select @change="stationChange" v-model="stationNo" placeholder="请选择换电站">
                        <el-option
                            v-for="item in stationList"
                            :key="item.id"
                            :label="item.stationName"
                            :value="item.stationNo"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>

        </el-col>
        <el-col :span="7">
            <el-form :inline="true">
                <el-form-item label="日期：">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="monthValue" type="month" placeholder="选择月"></el-date-picker>
                </el-form-item>
            </el-form>

        </el-col>
        <el-col :span="11">
            <div class="btn-end">
                    <span class="btn-rest">
                    <el-button @click="resFuntion" plain >重置</el-button>
                     </span>
                <span class="btn-submit">
                         <el-button @click="renderPie" type="primary" plain >查询</el-button>
                        </span>
            </div>


        </el-col>
      </el-row>
    </el-row>
    <el-row class="content-content">
      <div >
        <span class="content-content_pos"  style="padding-left:10px">{{stationStr}}</span>
        <station-pie :newPie="newPie"></station-pie>
      </div>
      <div >
        <span class="content-content_pos"  style="padding-left:10px">{{stationStr}}</span>
<!--        <i id="changeI" :class="isEcharts?'iconfont icon-zhexiantu':'iconfont icon-zhuzhuangtu' " @click="changeEcharts"></i>-->
        <station-bar :newBar="newBar"></station-bar>
      </div>
    </el-row>
    <el-row class="content-bottom">
      <div class="content-box-title">
        <span  style="padding-left:5px">{{stationStr}}</span>
          <div class="open-btn">
              <el-button @click="excelAll" size="small" plain>批量导出</el-button>
          </div>
      </div>
        <div class="table-box mar-table">
            <el-table :data="tableData" style="width: 100%" :header-cell-style="{'text-align':'center'}"
                      :cell-style="{'text-align':'center'}" >
                <el-table-column prop="date" :formatter="chanRender" label="日期" width="180"></el-table-column>
                <el-table-column prop="startTime" label="运行开始时间" width="180"></el-table-column>
                <el-table-column prop="endTime" label="运行结束时间"></el-table-column>
                <el-table-column prop="time" :label="currentMonth"></el-table-column>
                <el-table-column prop="lastTime" :label="lastMonth"></el-table-column>
            </el-table>
        </div>
        <el-col :span="24">
            <el-row class="pagin page-num">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 15, 20, 30]"
                    :page-size="size"
                    :current-page="page"
                    layout="total,sizes, prev, pager, next"
                    :total="total">
                </el-pagination>
            </el-row></el-col>
    </el-row>
  </div>
</template>

<script>
import '@/assets/font/font.css';
  import { getForceList } from '@/utils/station'
  import { regionData } from "element-china-area-data";
  import stationPie from '@/components/statsiscComponents/station-pie'
  import stationBar from '@/components/statsiscComponents/station-bar'
  import moment from "moment";
  import {getStaDaySwapOnMonth} from "@/utils/statistics";
  export default {
    name: "basisLink",
    components:{
      stationPie,
      stationBar
    },
    data() {
      return {
        page:1,
        size:10,
        total:0,
        cascaderValue:[],
        tableData: [],
        tableAll:[],
        options: regionData,
        stationList: [],
        stationType: null,
        stationNo: null,
        monthValue: "",
        stationName:'',
        stationStr:'',
        stationId:'',
        newPie:{},
        newBar:{},
        yearList:[],
        isEcharts:true,
        linkAll:{}
      };
    },
    created() {
      this.resFuntion();

    },
    computed:{
      cascader(){
        return this.cascaderValue.join(',')
      },
      currentMonth(){
        let time = this.monthValue || new Date()
        return moment(time).format('YYYY-MM')
      },
      lastMonth(){
        let time = this.monthValue || new Date()
        return moment(time).add(-1, 'M').format("YYYY-MM")
      },
      monthTime(){
        let time = this.monthValue
        if(time === ''){
          return moment().month()+1
        }else{
          return moment(time).month()+1
        }
      }
    },
    methods: {
      async getStation(){
        const res =await getForceList()
        this.stationList = res.data.data;
        this.stationNo = this.stationList.length>0?this.stationList[0].stationNo:"";
        this.stationName = this.stationList.length>0?this.stationList[0].stationName:"";
        this.renderInit();
      },
      resFuntion() {
        this.cascaderValue = []
        this.stationNo = ''
        this.stationType = ''
        this.monthValue = new Date()
        this.getStation()
      },
      renderPie(){
        this.renderInit()
      },
      async renderInit(){
        try {
          let month = this.monthValue
          month = month !== ''?moment(month).format('YYYY-MM-DD'):moment().month('YYYY-MM-DD')
          let stationNo = this.stationNo || this.stationId
          const data ={
            regionCode:this.cascader,
            stationNo:stationNo,
            stationType:this.stationType,
            startTime:moment(month).format('YYYY-MM-DD HH:mm:ss'),
            month:moment(month).month()+1
          }
          const objdata ={
            regionCode:this.cascader,
            stationNo:stationNo,
            stationType:this.stationType,
            month:moment(month).format('x')
          }

          const res = await getStaDaySwapOnMonth(data)
          let newList = res.data.data
          let newArr1 = newList.thisMonth
          let newArr2 = newList.lastMonth
          this.linkAll = {
            newArr1,
            newArr2,
            month
          }

          //分页表格查询
          this.tableList(newArr1,newArr2)
          this.stationStr = `${this.stationName}-${this.currentMonth}月换电站次数环比`
          //格式化柱图数据
          this.tranfromBar(newArr1,newArr2,month)

        } catch (error) {
          console.log(error);
        }
      },
      //换电消费同比
      tranfromBar(list1,list2,month){
        let arrAll = []
        let dayList = this.dayLIST(moment(month).daysInMonth())

        //echart数据生成
        let data1 = this.monthListD(list1,dayList)
        let data2 =  this.monthListD(list2,dayList)
        let count = this.countPercentage([data1.count,data2.count])
        this.isEcharts? arrAll.push({name:this.currentMonth,type:'bar',data:data1.newArr}):arrAll.push({name:this.currentMonth,type:'line',data:data1.newArr})
        this.isEcharts? arrAll.push({name:this.lastMonth,type:'bar',data:data2.newArr}):arrAll.push({name:this.lastMonth,type:'line',data:data2.newArr})
        this.newPie ={
          list:[{name:this.currentMonth,value:count[0]},{name:this.lastMonth,value:count[1]}]
        }
        this.newBar ={
          arrAll,
          monthList:dayList,
          yearList:[this.currentMonth,this.lastMonth]
        }

      },
      dayLIST(list) {
        let newArr = []
        if (list.length === 0) return [];
        for (let i = 1; i < list+1 ; i++) {
          newArr.push(i);
        }
        return newArr;
      },
      monthListD(list,dayList){
        let newArr = []
        let count = 0
        dayList.forEach(item=>{
          let i = list.findIndex(c=>moment(c.dt).date() === item)
          i === -1 && newArr.push({name:item,value:0})
          i !== -1 && newArr.push({name:item,value:list[i].tc})
        })
        list.forEach(item=>{
          count += item.tc
        })
        return {
          newArr,
          count
        }
      },
      dateList(list){
        let arr = []
        list.forEach(item=>{
          arr.push(item)
        })
        return arr
      },

      tableList(list1,list2){
        let newArr = []
        if(list1.length !== 0){
          list1.forEach(item=>{
            const data ={
              date:moment(item.dt).date(),
              startTime:item.st,
              endTime:item.et,
              time:item.tc,
              lastTime:0
            }
            newArr.push(data)
          })
          if(list2.length !== 0){
            list2.forEach(item=>{
              let i = newArr.findIndex(c=>c.date === moment(item.dt).date())
              if(i === -1){
                const data ={
                  date:moment(item.dt).date(),
                  startTime:item.st,
                  endTime:item.et,
                  time:0,
                  lastTime:item.tc
                }
                newArr.push(data)
              }else{
                newArr[i].lastTime += item.tc
              }
            })
          }
        }else{
          if(list2.length !== 0){
            list2.forEach(item=>{
              const data ={
                date:moment(item.dt).date(),
                startTime:item.st,
                endTime:item.et,
                time:0,
                lastTime:item.tc
              }
              newArr.push(data)
            })
          }
        }
        this.tableAll = JSON.parse(JSON.stringify(newArr))
        let data = this.getTableData(this.page,this.size,newArr)
        this.tableData = data.data
        this.total = data.length
      },

      changeEcharts(){
        this.isEcharts = !this.isEcharts
        this.tranfromBar(this.linkAll.newArr1,this.linkAll.newArr2,this.linkAll.month)
      },
      stationChange(e){
        let value = this.stationList.filter(c=>c.stationNo === e);
        this.stationName = value[0].stationName;
        this.stationNo = value[0].stationNo;
      },
      async excelAll(){
        const coulm =[
          {prop:'date',label:'日期'},
          {prop:'startTime',label:'运行开始时间'},
          {prop:'endTime',label:'运行结束时间'},
          {prop:'time',label:this.currentMonth},
          {prop:'lastTime',label:this.lastMonth},
        ]
        const confirm =await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e=>e)
        if(confirm !== 'confirm')return
        this.$download(this.tableData,coulm,this.stationStr)
      },
      countPercentage(countArray){
        var j = eval(countArray.join('+'));
        var resultArray = [];
        for (var i = 0 ; i < countArray.length ; i++){
          if(countArray[i] === 0){
            resultArray.push(0);
          }else{
            var k = Math.floor((countArray[i]/j)*100)
            resultArray.push(k);
          }
        }
        return resultArray;
      },
      handleSizeChange(size){
        this.size = size
        let data = this.getTableData(this.page,this.size,this.tableAll)
        this.tableData = data.data
        this.total = data.length
      },
      handleCurrentChange(page){
        this.page = page
        let data = this.getTableData(this.page,this.size,this.tableAll)
        this.tableData = data.data
        this.total = data.length
      },
      chanRender(row, column){
        return row[column.property] + '号'
      }
    },
  };
</script>

<style lang='less' scoped>
  .content {
    border: 0;
    box-shadow: none;
    background-color:#f0f2f5;
    width: 100%;
    height: 100%;
  }

  .el-select,.el-cascader {
    width: 100%;
  }
  .content-title {
    background-color: #fff;
    margin: 0;
    padding: 15px;
  }
  .content-bottom{
      background-color: #fff;
      padding: 24px 16px 16px 16px;
      margin-bottom: 30px;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
  .content-content {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
      div {
          position: relative;
          background-color: #fff;

          &:nth-child(1) {
              width: 28%;
              margin-right: 15px;
          }

          &:nth-child(2) {
              width: 71%;
          }

          .content-content_pos {
              display: inline-block;
              font-weight: 400;
              color: #2F3133;
              font-size: 16px;
              padding: 24px 0 0 16px;
              font-family: PingFang SC;
          }
      }
  }
  #changeI{
    position: absolute;
      top: 13%;
    right: 0%;
    cursor: pointer;
    color: #666;
    margin-right: 50px;
    z-index: 999;
  }
  #changeI:hover{
    color: #2a81e3;
  }
  .content-box-title {
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 16px;
      color: #2F3133;
      font-family: PingFang SC;

      span {
          color: #2F3133;
      }
  }
  .btn-end {
      display: flex;
      justify-content: flex-end;
  }
  .mar-table{
      margin-top: 16px;
  }
  .page-num{
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
  }
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
.open-btn{
    .el-button {
        border: 1px solid #0D6CE4;
        color: #0D6CE4;
        background: #fff;
        padding: 8px 16px;
        line-height: 20px;
        font-size: 14px;
        width: 88px;
    }
}
</style>

