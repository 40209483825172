<template>
    <div class="app-container">
        <el-row class="main-flex">
<!--            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>
            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>-->
            <!-- 搜索区域 -->
            <el-col :span="24" class="main-content">
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="换电站：">
                                <el-select v-model="query.name" placeholder="请选择换电站" clearable>
                                    <!--                                <el-option-->
                                    <!--                                    v-for="item in billingEnumList"-->
                                    <!--                                    :key="item.k"-->
                                    <!--                                    :label="item.v"-->
                                    <!--                                    :value="item.k"-->
                                    <!--                                ></el-option>-->
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label=" 年月：">
                                <el-input v-model.trim="query.name" placeholder="请选择年月"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="日期：">
                                <el-date-picker
                                    v-model="query.dateVal"
                                    type="date"
                                    placeholder="请选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="5">
                        <div class="btn-end">
                        <span class="btn-rest export-btn">
                                <el-button @click="resFuntion" plain>
                                导出
                                </el-button>
                        </span>
                            <span class="btn-rest">
                                <el-button @click="resFuntion" plain>
                                重置
                                </el-button>
                            </span>
                            <span class="btn-submit">
                                <el-button type="primary" @click="getSearch" plain>查询
                                </el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  main部分-->
                <div class="section-main">
                    <div class="round-plant">
                        <div class="round-title">换电次数同环比</div>
                        <div class="round-num">
                            <div class="round-num-inf">4454</div>
                            <div class="round-num-unit">换电次数（次）</div>
                        </div>
                        <div class="pie-plant">
                            <div class="pie-plant-inf">
                                <div class="pie-plant-date">22年11月</div>
                                <div class="pie-plant-num">6936<span>次</span></div>
                                <elePie :pieName="pieLeft" :pieColor="pieColorLeft" style="width:96px;height: 96px"></elePie>
                            </div>
                            <div class="pie-plant-inf">
                                <div class="pie-plant-date">23年11月</div>
                                <div class="pie-plant-num">936<span>次</span></div>
                                <elePie :pieName="pieRight" :pieColor="pieColorRight" style="width:96px;height: 96px"></elePie>
                            </div>
                        </div>
                    </div>
                    <div class="power-changes">
                        <div class="round-title">换电次数统计</div>
                        <bar :barId="barNumSta" :barColor="barColorFir" :yVal="yFir" style="height: 100%"></bar>
                    </div>
                </div>
                <!-- -->
                <div class="section-main statistics">
                    <div class="statistics-plant">时段换电次数统计</div>
                    <bar :barId="bsiTimeSta" :barColor="barColorSec" :yVal="yFir" style="height: 360px"></bar>
                </div>
            </el-col>

        </el-row>

    </div>
</template>

<script>
// import operatorTreeVue from "@/components/tree/operatorTree.vue";
import elePie from './pieGroup.vue';
import bar from './barGroup.vue';

export default {
    name: "index",
    components: {elePie, bar},
    data() {
        return {
            isopenShow: true,
            //左侧运营商树
            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },
            query: {
                name: '',
                dateVal: ''
            },
            newPie: [
                {
                    name: '装备制造',
                    value: 54
                }],
            newBar: {},
            pieLeft:'barLeft',
            pieRight:'barRight',
            barNumSta:'barNumSta',
            bsiTimeSta:'bsiTimeSta',
            pieColorLeft:'#FF6165',
            pieColorRight:'#66BB83',
            barColorFir:'#0D6CE4',
            barColorSec:'#66BB83',
            yFir:'换电次数（次）',
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        getTreeData(data) {
            this.treeData = data
        },
        isHidden(message) {
            this.isopenShow = message
        },
        resFuntion() {

        },
        getSearch() {

        }
    }
}
</script>

<style lang="less" scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.main-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    height: 68px;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    //background: #FFFFFF;
    margin-top: 16px;
    //padding: 0px 0px 0px 16px;
    display: flex;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

.round-plant {
    width: 360px;
    background: #FFFFFF;
    padding: 24px 16px;

    .round-num {
        margin-top: 16px;
        padding-bottom: 8px;
        border-bottom: 2px solid #0D6CE4;
        border-radius: 4px;
    }

    .round-num-inf {
        font-family: Arial;
        font-size: 24px;
        font-style: italic;
        font-weight: 700;
        letter-spacing: 0em;
        color: #0D6CE4;
    }

    .round-num-unit {
        font-size: 14px;
        font-weight: 400;
        color: #0D6CE4;
    }
}

.round-title {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    color: #2F3133;
}

.export-btn {
    margin-right: 16px;
}

.power-changes {
    flex: 1;
    background: #FFFFFF;
    margin-left: 16px;
    padding: 24px 16px;
}

.pie-plant {
    display: flex;
    justify-content: space-between;
    padding:0 26px;

}

.statistics {
    background: #FFFFFF;
    padding: 24px 16px;
    display: block;
    margin-bottom: 20px
}

.statistics-plant {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    color: #2F3133;

}

.pie-plant-inf {
    text-align: center;
    color: #2F3133;
    font-weight: 400;

    .pie-plant-date {
        font-size: 14px;
        margin-top: 18px;
        margin-bottom: 8px;
    }

    .pie-plant-num {
        font-family: PingFang SC;
        font-size: 18px;
        margin-bottom: 16px;
        span {
            font-size: 14px;
        }
    }

}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
