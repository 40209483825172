<template>
    <div class="app-container">
        <el-row class="main-flex">

            <!-- 搜索区域 -->
            <el-col :span="24" class="mian-content">

                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="模型名称：">
                                <el-input v-model.trim="query.name" placeholder="请输入模型名称"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>

                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="模型序列：">
                                <el-select v-model="query.no" placeholder="请选择模型序列" clearable>
                                    <el-option clearable v-for="item in noList" :key="item.value" :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>


                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="开始日期：">
                                <el-date-picker
                                    v-model="query.startDate"
                                    type="date"
                                    value-format="MM-dd"
                                    format="MM-dd"
                                    popper-class="picker-date"
                                    placeholder="选择开始日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                    </el-col>

                    <el-col :span="6">
                        <div class="btn-end">
                            <span class="more-search" @click="showMore=!showMore">
                                <div>展开</div>
                            <i :class="showMore==true?'el-icon-arrow-down':'el-icon-arrow-up'"></i> </span>
                            <span class="btn-rest">
                            <el-button @click="resFuntion()" plain>重置</el-button>
                            </span>
                            <span class="btn-submit">
                            <el-button @click="getAllPage()" type="primary" plain>查询
                            </el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <transition name="el-zoom-in-top">
                    <div v-show="showMore">
                        <div class="head-section head-section2">
                            <el-col :span="6">
                                <el-form :inline="true">
                                    <el-form-item label="结束日期：">
                                        <el-date-picker
                                            v-model="query.endDate"
                                            type="date"
                                            value-format="MM-dd"
                                            format="MM-dd"
                                            popper-class="picker-date"
                                            placeholder="选择结束日期">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </div>
                    </div>
                </transition>

                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            {{priceName}}列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                          已选择{{ batchNum.length }}项
                            <span @click="cleanList" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>

                            <span class="pain-btn">
                                <el-button @click="handleClose" plain>退出
                            </el-button>
                            </span>

                            <span class="pain-btn">
                                <el-button @click="removeAll"
                                           v-hasCode="['elec:date:delete']" plain>删除
                            </el-button>
                            </span>
                            <span class="add-btn">
                                <el-button @click="addModel" type="primary"
                                           v-hasCode="['elec:date:add']" plain> 新增
                            </el-button>
                            </span>
                        </div>

                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table ref="equipTable" id="equipment" @selection-change="batchList"
                                      :data="tableData" height="100%"
                                      :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo">
                                <el-table-column align="center" type="selection"></el-table-column>
                                <el-table-column align="center" label="编号" type="index" :index="renIndex"></el-table-column>

                                <el-table-column align="center" label="名称" prop="name" min-width="150"
                                                 :show-overflow-tooltip="true">
                                    <template slot-scope="scope">
                                        <a href="javascript:void(0);" @click="timeSet(scope.row)">{{ scope.row.name }}</a>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" prop="no" label="模型序列" min-width="100px">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.no  === 1"> 模型1 </span>
                                        <span v-else-if="scope.row.no === 2">模型2</span>
                                        <span v-else-if="scope.row.no === 3">模型3</span>
                                        <span v-else-if="scope.row.no === 4">模型4</span>
                                    <span v-else>未知</span>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" prop="startDate" label="开始日期"
                                                 min-width="80"></el-table-column>

                                <el-table-column align="center" prop="endDate" label="结束日期"
                                                 min-width="80"></el-table-column>

                                <el-table-column align="center" prop="periodsAmount" label="时段个数"
                                                 min-width="110"></el-table-column>

                                <el-table-column align="center" label="操作" width="100" fixed="right">
                                    <template slot-scope="scope">
                                           <span class="edit-style" @click="timeSet(scope.row)" v-hasCode="['operation:elecprice:periods']">
                                                时段设置
                                            </span>
                                            <span class="edit-style" @click="compileEqu(scope.row)" v-hasCode="['elec:date:update']">
                                                编辑
                                            </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[20, 30, 50,100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>
                </div>

            </el-col>

        </el-row>
        <el-dialog
            :title="isequipemt?'新增时段(月-日)模型':'修改时段(月-日)模型'"
            :visible.sync="addOrUpdate"
            width="30%"
            @close="close">

            <el-form size="middle" ref="equipform" :rules="equipmentRules" :model="informationForm" label-width="160px">
                <el-row>
                    <el-col >
                        <el-form-item label="模型名称:" prop="name" class="form-name">
                            <el-input v-model="informationForm.name" maxlength="30" show-word-limit clearable></el-input>
                        </el-form-item>

                        <el-form-item label="模型序列" prop="no">
                            <el-select v-model="informationForm.no" placeholder="选择模型序列">
                                <el-option
                                    v-for="item in noList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="开始日期" prop="startDate">
                            <el-date-picker
                                v-model="informationForm.startDate"
                                type="date"
                                value-format="MM-dd"
                                format="MM-dd"
                                popper-class="picker-date"
                                placeholder="选择开始日期">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="结束日期" prop="endDate">
                            <el-date-picker
                                format="MM-dd"
                                value-format="MM-dd"
                                v-model="informationForm.endDate"
                                popper-class="picker-date"
                                placeholder="选择结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <span slot="footer" class="dialog-footer">
        <el-button @click="addOrUpdate = false">取 消</el-button>
        <el-button type="primary" @click="newequipemt">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    selectElecDatePage, addElecDate,
    updateElecDate, elecDateBatchDelete
} from '@/utils/operation'

export default {
    name: 'elecDate',
    components: {},
    data() {
        return {
            priceId: null, //国网电价模型id
            priceName:"",//国网电价名称

            showMore: false,
            query: {
                name: null,
                no:null,
                startDate: null,
                endDate: null,
                pageNum: 1,
                pageSize: 20
            },
            total: 0,
            addOrUpdate: false,
            isAdd: true,
            isequipemt: true,
            batchNum: [],
            tableData: [],

            informationForm: {
                priceId: null,
                name: "",
                no: null,
                startDate:null,
                endDate:null
            },
            equipmentRules: {
                name: [
                    {required: true, message: '请输入模型名称', trigger: 'blur'}
                ],
                no: [
                    {required: true, message: '请选择模型序列', trigger: 'blur'}
                ],
                startDate: [
                    {required: true, message: '请选择开始日期', trigger: 'blur'}
                ],
                endDate: [
                    {required: true, message: '请选择结束日期', trigger: 'blur'}
                ]
            },
            noList:[{label:"模型1",value:1},{label:"模型2",value:2},{label:"模型3",value:3},{label:"模型4",value:4}]
        }
    },

    computed: {

    },

    created() {
        this.clientHeight = document.body.clientHeight - 240;
        //this.parms = this.$route.params && this.$route.params.billingModelId;
        this.priceId = this.$route.query.id;
        this.priceName = this.$route.query.name;
        this.getAllPage();
    },
    methods: {
        headerStyleEvent({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row){
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },

        forceUpdate() {
            this.$forceUpdate();
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        //条件分页查询
        async getAllPage() {
            let data = {};
            data.priceId = this.priceId;//页面传递的模型id
            data.page = this.query.pageNum;
            data.size = this.query.pageSize;
            if(this.query.no){
                data.no = this.query.no;
            }
            if(this.query.name){
                data.name = this.query.name;
            }
            if(this.query.startDate){
                data.startDate = this.query.startDate;
            }
            if(this.query.endDate){
                data.endDate = this.query.endDate;
            }
            const result = await selectElecDatePage(data);
            if (result.data.code !== 200) return this.$msg.error('获取分时模型信息失败!');
            this.tableData = result.data.data.list;
            this.total = result.data.data.total;
        },

        getSearch() {
            this.query.pageNum = 1;
            this.getAllPage()
        },


        //重置
        resFuntion() {
            this.isAdd = true;
            this.query={
                name: null,
                no:null,
                startDate: null,
                endDate: null,
                pageNum: 1,
                pageSize: 20
            };
            this.getAllPage();
        },


        batchList(list) {
            this.batchNum = list
        },
        close() {
            this.isequipemt = true
            this.resetForm();
        },
        //新增与修改
        newequipemt() {
            this.$refs.equipform.validate(async valid => {
                if (!valid) return this.$msg.info('信息不能为空!')
                const data = JSON.parse(JSON.stringify(this.informationForm));
                data.priceId = this.priceId;
                try {
                    if (this.isequipemt) {
                        const res = await addElecDate(data)
                        if (res.data.code !== 200) return this.$msg.error('新增信息失败!' + res.data.msg);
                        this.$msg.success('新增信息成功!')
                        this.addOrUpdate = false
                        this.getAllPage()
                    } else {
                        const res = await updateElecDate(data)
                        if (res.data.code  !== 200) return this.$msg.error('修改信息失败!' + res.data.msg);
                        this.$msg.success('修改信息成功!')
                        this.addOrUpdate = false
                        this.getAllPage()
                    }
                } catch (error) {
                }
            })
        },

        timeSet(e){
            this.$router.push({
                path: '/elecTime',
                query:{
                    id:e.id,
                    name:e.name,
                    priceId: this.priceId,
                    priceName:this.priceName,
                }
            })
        },

        //修改信息填充
        async compileEqu(row) {
            this.informationForm = row;
            this.isAdd = true;
            this.addOrUpdate = true;
            this.isequipemt = false;
        },

        handleSizeChange(val) {
            this.query.pageSize = val
            this.getAllPage()
        },
        handleCurrentChange(val) {
            this.query.pageNum = val;
            this.getAllPage();
        },
        cleanList() {
            this.$refs.equipTable.clearSelection()
        },

        addModel() {
            this.addOrUpdate = true;
            this.resetForm();
        },

        resetForm(){
            this.informationForm = {
                priceId: null,
                name: "",
                no: null,
                startDate:null,
                endDate:null
            }
        },

        handleClose() {
            this.$router.push('/areaElec')
        },

        //批量删除
        async removeAll() {
            if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除选中项, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const list = this.batchNum.map(item => {
                    return item.id
                })
                const res = await elecDateBatchDelete(list)
                if (res.status !== 200) return this.$msg.error('删除失败')
                this.tableData = this.tableData.filter((item, i) => {
                    return item.id !== list[i]
                })
                this.$refs.equipTable.clearSelection()
                if (this.query.pageNum > 1 && this.tableData.length === 0) {
                    this.query.pageNum = this.query.pageNum - 1
                }
                this.$msg.success('删除成功')
                this.getAllPage()
            } catch (e) {
                console.log(e);
            }
        },
    },


    watch: {},


}
</script>

<style lang="less" scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

::v-deep .el-dialog__header {
    //background-color: #e4ebf1;

    .el-dialog__title {
        font-weight: bolder;

        .dialogCenter1 .block_5 {
            background-color: #e4ebf1;
            display: flex;
            flex-direction: row;
            margin: 10px 0;
            flex-wrap: wrap;

            [class^="block_5_box"] {
                text-align: left;
                box-sizing: border-box;
                padding: 10px 10px;
                display: flex;
                width: 25%;
                flex-direction: column;

                .contentbox {
                    font-weight: bold;
                }
            }
        }
    }
}

.block_5 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    padding: 20px 20px;
    flex-wrap: wrap;

    [class^="block_5_box"] {
        text-align: center;

        box-sizing: border-box;
        padding: 15px 15px;
        padding-left: 30px;
        display: flex;
        width: 30%;
        // flex-direction: column;
        .contentbox {
            font-weight: bold;
            padding-left: 8px;
        }
    }
}

.block_2 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    flex-wrap: wrap;

    [class^="block_2_box"] {
        text-align: left;
        box-sizing: border-box;
        padding: 10px 10px;
        display: flex;
        width: 20%;
        flex-direction: column;

        .contentbox {
            font-weight: bold;
        }
    }
}

.block_3 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    flex-wrap: wrap;

    [class^="block_3_box"] {
        text-align: left;
        box-sizing: border-box;
        padding: 10px 10px;
        display: flex;
        width: 20%;
        flex-direction: column;

        .contentbox {
            font-weight: bold;
        }
    }
}

.block_4 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    flex-wrap: wrap;

    [class^="block_4_box"] {
        text-align: left;
        box-sizing: border-box;
        padding: 10px 10px;
        display: flex;
        width: 20%;
        flex-direction: column;

        .contentbox {
            font-weight: bold;
        }
    }
}

.box1_1 {
    text-align: left;
    box-sizing: border-box;
    padding: 10px 10px 10px 0px;
    display: flex;
    width: 50%;
    flex-direction: column;
}

.box1_2 {
    text-align: left;
    box-sizing: border-box;
    padding: 10px 10px;
    display: flex;
    width: 50%;
    flex-direction: column;
}

.circular {
    border-radius: 5px;
}

.closeDialog {
    width: 80px;
    height: 30px;
    line-height: 30px;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
    color: #ffffff;
    background-color: #3981e2;
}

.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #dddee0; /*滚动条的背景颜色*/
    }
}

.out-container {
    width: 100%;
    /* height: var(--tableHeight);*/
    height: 660px;
    overflow: auto;
}

.inner-container {
    // min-width: 2600px;
    height: var(--tableHeight);
    overflow: hidden;
    scroll-behavior: smooth;
}
//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}
.more-search {
    display: flex;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    cursor: pointer;
    position: relative;
    left: -15px;
    i{
        line-height: 36px;
    }
}
.head-section{
    /deep/.el-input__inner { //更改input大小
        width: 250px !important;
        height: 36px;
        line-height: 36px;
    }
}
.head-section2 {
    overflow: hidden;
    padding: 0 16px 16px 16px;
    height: 100%;
}
.form-name{
    /deep/.el-input__inner,.el-input{
        width: 220px;
    }
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
/*选择日期，年份的隐藏 */
.picker-date {
    .el-date-picker__header {
        span:nth-child(3) { /*第三个标签是span的标签，把它隐藏*/
            display: none;
        }
        button:nth-child(1) {
            display: none;
        }
        button:nth-child(5) {
            display: none;
        }
    }
}
</style>

