<template>
  <el-card>
    <el-row>
      <el-col :span="3">
        <el-select v-model="informationVo.fstationNo" filterable placeholder="请选择换电站">
          <el-option
            v-for="item in stationList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-input placeholder="请输入车牌号" v-model.trim="informationVo.fcarNo"></el-input>
        <!--        <el-input placeholder="请输入闸机设备编号" v-model=" informationVo.fgateMacNo"></el-input>-->
      </el-col>
      <el-col :span="3">
      <el-input placeholder="请输入订单号" v-model.trim="informationVo.forderNo"></el-input>
    </el-col>
      <el-col :span="3">

        <el-select v-model="informationVo.fpaymentMode" filterable placeholder="请选择付款方式">
          <el-option
            v-for="item in paymentModeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="3">
        <el-input placeholder="请输入登录号码" v-model.trim="informationVo.floginNo"></el-input>
      </el-col>
      <el-col :span="4">
        <el-button @click="getOrderList()" type="primary">查询</el-button>
        <el-button @click="resFuntion">重置</el-button>
      </el-col>
    </el-row>

    <!--    <el-row>-->
    <!-- <el-button @click="order = true" type="primary" class="el-icon-plus" size="small"> 新增</el-button> -->
    <!--      <el-button size="small" icon="el-icon-download" @click="excels" type="primary">批量导出</el-button>-->
    <!--      <el-button size="small" @click="removeAll" icon="el-icon-delete" type="danger">选中删除</el-button>-->
    <!--    </el-row>-->
    <!--    <el-row>-->
    <!--      <el-alert show-icon type="info" :closable="false">-->
    <!--        <span slot="title">-->
    <!--          已选择{{batchNum.length}}项-->
    <!--           <span @click="cleanList" style="margin-left:10px;color:#409eff;cursor:pointer;">清空</span>-->
    <!--        </span>-->
    <!--      </el-alert>-->
    <!--    </el-row>-->
    <el-row>
      <el-table ref="orderTable" id="excel-tabel" @selection-change="batchList" :data="tableData" style="width: 100%"
                height="500">
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="编号" :index="renIndex" type="index" width="50"></el-table-column>
        <el-table-column prop="forderNo" label="订单号" min-width="210"></el-table-column>
        <el-table-column prop="fcarNo" label="车牌号" min-width="100"></el-table-column>
        <el-table-column prop="fstationNo" label="换电站编码" min-width="100"></el-table-column>
        <el-table-column prop="fstationName" label="换电站名称" min-width="150"></el-table-column>

        <el-table-column prop="fpaymentAmount" label="消费金额(元)" min-width="150"></el-table-column>
        <el-table-column prop="fpaymentMode" label="支付方式" min-width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.fpaymentMode === 1">微信支付</span>
            <span v-else-if="scope.row.fpaymentMode === 2">支付宝支付</span>
            <span v-else>保密</span>
          </template>
        </el-table-column>

        <el-table-column prop="floginNo" label="登录号码" min-width="210"></el-table-column>
        <el-table-column prop="ftime" label="支付时间" min-width="180"></el-table-column>
        <!-- <el-table-column label="操作" fixed="right" min-width="160">
          <template slot-scope="scope">
            <el-button size="small" @click="compileOrder(scope.row)" icon="el-icon-edit" type="primary">编辑</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </el-row>

    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[10,20, 25, 30]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-row>


    <!-- 新增和修改订单 -->
    <el-dialog
      :title="isOrder?'新增订单信息':'修改订单信息'"
      :visible.sync="order"
      width="30%"
      @close="close"
    >
      <el-form size="small" ref="orderFormRef" :rules="orderRules" :model="orderForm" label-width="100px">
        <el-row>
          <el-col :span="22">
            <el-form-item label="换电站编号:" prop="stationNo">
              <el-select v-model="orderForm.stationNo" placeholder="请选择换电站编码">
                <el-option
                  v-for="item in stationList1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车牌名称:" prop="carNo">
              <el-input @change="changeCarNo(orderForm.carNo)" v-model="orderForm.carNo"></el-input>
            </el-form-item>
            <el-form-item label="车辆VIN码:" prop="carVin">
              <el-input v-model="orderForm.carVin"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="order = false">取 消</el-button>
        <el-button type="primary" @click="newAddOrder">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
  import {
    getOrderAll,
    getOrderPage,
    addOrder,
    changeOrder,
    removeOrder
  } from "@/utils/operation";

  import {
    getStationOrder,
  orderPaymentPage
  } from "@/utils/operation2";

  import {getForceList, regionCodeSation} from '@/utils/station'
  import {regionData} from "element-china-area-data"
  import * as myStore from "@/utils/stroage";
  import moment from 'moment'

  export default {
    name: "orderName",
    data() {
      var licence = (rule, value, cb) => {
        const Reg = /^([京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][1-9DF][1-9ABCDEFGHJKLMNPQRSTUVWXYZ]\d{3}[1-9DF]|[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][\dABCDEFGHJKLNMxPQRSTUVWXYZ]{5})$/
        if (value !== '') {
          Reg.test(value) ? cb() : cb(new Error('车牌号格式错误'))
        } else {
          cb('请输入车牌号')
        }
      }
      return {
        cascaderValue: [],
        carValue: '',
        carVinValue: '',
        query: {
          pageNum: 1,
          pageSize: 10,
          resPageNum: 1
        },
        options: regionData,
        tableData: [],
        batchNum: [],
        orderList: [],
        order: false,
        isOrder: true,
        isPage: true,
        orderForm: {
          carNo: "",
          carVin: "",
          stationNo: "",
        },
        informationVo: {},
        paymentModeList: [
          {
            value: 1,
            label: '微信支付'
          },
          {
            value: 2,
            label: '支付宝支付'
          }
        ],
        stationType: '',
        orderRules: {
          carNo: [
            {required: true, validator: licence, trigger: 'blur'}
          ],
          carVin: [
            {required: true, message: '请输入活动名称', trigger: 'blur'}
          ],
          orderNo: [
            {required: true, message: '请输入订单名称', trigger: 'blur'}
          ],
          stationNo: [
            {required: true, message: '请输入场站名称', trigger: 'blur'}
          ]
        },
        total:0,
        stationList: [],
        stationList1: [],
        stationValue: '',
        coulm: [
          {prop: 'orderNo', label: '订单号'},
          {prop: 'carNo', label: '车牌号'},
          {prop: 'carVin', label: '车辆VIN码'},
          {prop: 'stationNo', label: '换电站编号'},
          {prop: 'createTime', label: '创建时间'}
        ]
      }
    },
    computed: {
      cascader() {
        if (this.cascaderValue.length === 1) {
          return this.cascaderValue[0]
        } else if (this.cascaderValue.length === 2) {
          return this.cascaderValue[1]
        } else if (this.cascaderValue.length === 3) {
          return this.cascaderValue[2]
        } else {
          return ''
        }
      },
      donwordList() {
        if (this.batchNum.length === 0) {
          return this.orderList.map(item => {
            return item
          })
        } else {
          return this.batchNum.map(item => {
            return item
          })
        }
      }
    },
    created() {
      this.getStation()
      this.getOrderList()
      this.getOrderListAll()
    },
    methods: {
      async getOrderList() {
        const res = await orderPaymentPage(this.query.pageNum, this.query.pageSize, this.informationVo);
        console.log(res)
        if (res.data.code !== 20000) return this.$msg.error("获取订单信息失败");
        this.tableData = res.data.data.rows.records
        this.total = res.data.data.rows.total
      },

      async getOrderListAll() {
        const res = await getOrderAll()
        this.orderList = res.data.data
      },
      async getStation() {
        const res = await getForceList()
        let newList = res.data.data
        this.stationList = newList.map(item => {
          return {
            value: item.stationNo,
            label: item.stationName
          }
        })
        this.stationList1 = this.stationList
      },
      async excels() {
        const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e => e)
        if (confirm !== 'confirm') return
        this.$download(this.donwordList, this.coulm, '订单管理')
      },
      batchList(list) {
        this.batchNum = list
      },
      //新增和修改订单信息
      newAddOrder() {
        try {
          this.$refs.orderFormRef.validate(async (valid) => {
            if (!valid) return this.$msg.info("订单信息不能为空!");
            const time = moment().format('YYYY-MM-DD HH:mm:ss')
            const data = JSON.parse(JSON.stringify(this.orderForm))
            data.orderNo = `${data.stationNo}${time}${data.carNo}`
            if (this.isOrder) {
              const res = await addOrder(data);
              if (res.status !== 200) return this.$msg.error("新增订单信息失败!");
              this.$msg.success("新增订单信息成功!");
              this.order = false;
              this.getOrderList();
            } else {
              const res = await changeOrder(data);
              if (res.status !== 200) return this.$msg.error("修改订单信息失败!");
              this.$msg.success("修改订单信息成功!");
              this.order = false;
              this.getOrderList();
            }
          });
        } catch (error) {
          console.log(error);
        }
      },
      close() {
        this.$refs.orderFormRef.resetFields()
        this.orderForm = {}
        this.isOrder = true;
      },
      //修改信息填充
      compileOrder(data) {
        this.order = true;
        this.isOrder = false;
        this.orderForm = JSON.parse(JSON.stringify(data))
      },
      renIndex(index) {
        return (this.query.pageNum - 1) * this.query.pageSize + index + 1
      },

      cleanList() {
        this.$refs.orderTable.clearSelection()
      },
      //删除
      async removeAll() {
        if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
        const config = await this.$MessageBox.confirm(
          "此操作将永久删除选中项, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        ).catch(e => e);
        if (config !== "confirm") return;
        try {
          const list = this.batchNum.map((item) => {
            return item.id;
          });
          const res = await removeOrder(list)
          if (res.status !== 200) return this.$msg.error('删除失败')
          this.tableData = this.tableData.filter((item, i) => {
            return item.id !== list[i]
          })
          this.$refs.orderTable.clearSelection()
          if (this.query.pageNum > 1 && this.tableData.length === 0) {
            this.query.pageNum = this.query.pageNum - 1
          }
          this.$msg.success('删除成功')
          this.getOrderList()

        } catch (e) {
          console.log(e);
        }
      },
      resFuntion() {
        this.informationVo = {}
        this.cascaderValue = []
        this.stationValue = ''
        this.stationType = ''
        this.carValue = ''
        this.carVinValue = ''
        this.isPage = true
        this.query = {
          pageNum: 1,
          pageSize: 10,
          resPageNum: 1
        }
        this.getOrderList()
      },
      handleSizeChange(val) {
        this.query.pageSize = val
        if (this.isPage) {
          this.getOrderList();
        } else {
          this.submit()
        }

      },
      handleCurrentChange(val) {
        this.query.pageNum = val
        if (this.isPage) {
          this.getOrderList();
        } else {
          this.query.resPageNum = val
          this.submit()
        }
      },
      changeCarNo(val) {
        this.orderForm.carNo = val.toUpperCase()
      },
      async changeCascader() {
        this.stationValue = ''
        const data = {
          regionCode: this.cascader,
          stationType: this.stationType
        }
        const res = await regionCodeSation(data)
        if (res.status !== 200) return this.$msg.error('换电站查询失败!')

        if (res.data.data.length !== 0) {
          this.stationList = res.data.data.map(item => {
            return {
              value: item.stationNo,
              label: item.stationName
            }
          })
        } else {
          this.stationList = []
        }
        if (this.stationList.length !== 0 && this.cascader !== '') {
          const newList = JSON.parse(getItem('order'))
          if (newList && newList[1].regionCode === this.cascader && newList[0].index > 3) {
            this.cascaderValue = this.changeDetSelect(newList[1].regionCode, this.options)
            this.stationValue = newList[1].stationNo
            this.stationType = newList[1].stationType
            this.carValue = newList[1].carNo
          }
        }
      },
      async changeSelect() {
        this.stationValue = ''
        const data = {
          regionCode: this.cascader,
          stationType: this.stationType
        }
        const res = await regionCodeSation(data)
        if (res.status !== 200) return this.$msg.error('换电站查询失败!')

        if (res.data.data.length !== 0) {
          this.stationList = res.data.data.map(item => {
            return {
              value: item.stationNo,
              label: item.stationName
            }
          })
        } else {
          this.stationList = []
        }
      }
    }
  };
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 10px;

    }
  }

  .el-select, .el-cascader {
    width: 100%;
  }
</style>
