<template>
  <!-- 编码规则管理 -->
  <el-card>
    <el-row>
      <el-col :span="4">
        <el-input placeholder="请输入规则名称" v-model.trim="queryInput"></el-input>
      </el-col>
      <el-col :span="4">
        <el-select placeholder="请选择规则状态" v-model.trim="statusSelect">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="10">
        <el-button type="primary" @click="queryFunction">查询</el-button>
        <el-button @click="restFunction">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="addDialogVisible = true"
      >新增</el-button>
      <el-button type="primary" icon="el-icon-upload2" size="small" @click="dialogImport = true">批量导入</el-button>
      <el-button type="primary" icon="el-icon-download" size="small" @click="download">批量导出</el-button>
      <el-button size="small" icon="el-icon-delete" type="danger" @click="delFunctionOfbtytype">选中删除</el-button>
    </el-row>
    <el-row>
      <el-alert :closable="false">
        <span slot="title">
          已选择{{ selectList.length }}项
          <span
            @click="toggleSelection"
            style="margin-left:10px;color:#409eff;cursor:pointer;"
          >清空</span>
        </span>
      </el-alert>
    </el-row>
    <el-row>
      <el-table
        :data="tableData"
        style="width: 100%"
        ref="multipleTable"
        @selection-change="selectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column type="index" :index="renIndex" label="编号" width="50"></el-table-column>
        <el-table-column prop="rulecode" label="规则编码" min-width="80"></el-table-column>
        <el-table-column prop="rulename" label="规则名称" min-width="80"></el-table-column>
        <el-table-column
          prop="rulecontent"
          :show-overflow-tooltip="true"
          label="规则内容"
          min-width="80"
        ></el-table-column>
        <el-table-column prop="ruledesc" :show-overflow-tooltip="true" label="规则描述" min-width="80"></el-table-column>
        <el-table-column prop="rulestate" label="规则状态" min-width="80">
          <template slot-scope="scope">
            <div :class="scope.row.rulestate ===1? 'status-item2' : 'status-item1'">
              <span></span>
              <span>{{ scope.row.rulestate ===1? "启用":"禁用" }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="creator" label="创建者" min-width="100"></el-table-column>
        <el-table-column prop="createtime" label="创建日期" min-width="110" :formatter="$dateFormat"></el-table-column>
        <el-table-column prop="modifytime" label="修改日期" min-width="110" :formatter="$dateFormat"></el-table-column>
        <el-table-column prop="disabletime" label="停用日期" min-width="110" :formatter="$dateFormat"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button
              icon="el-icon-edit"
              size="small"
              @click="handleEdit(scope.row)"
              type="primary"
            >编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[20, 25, 30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-row>
    <!-- 导入 -->
     <el-dialog title="请选择导入文件" :visible.sync="dialogImport" width="30%" @close="closeLoad">
      <el-upload
        class="upload-demo"
        ref="upload"
        action
        :on-change="handleChange"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :before-upload="beforeUpload"
        :file-list="fileList"
        :limit="1"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        :auto-upload="false"
      >
        <el-button size="small" type="primary">请选择 xlsx / xls 文件</el-button>
      </el-upload>
      <span slot="footer" class="dowload dialog-footer">
         <div>
          <el-button @click="dowtemplate">下载模版</el-button>
          <span>请不要擅自修改或删除模版</span>
        </div>
        <div>
          <el-button @click="dialogImport = false">取消</el-button>
        <el-button type="primary" :disabled="uploadDisabled" style="margin-top: 16px" @click="handleUpload">开始上传</el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 新增编码规则 -->
    <el-dialog :title="titleName?'新增编码规则':'修改编码规则'" :visible.sync="addDialogVisible" @close="close" width="50%">
      <el-form :model="addFrom" ref="addFrom" label-width="100px" :rules="rules" size="small">
        <el-row>
          <el-col style="margin:0;" :span="12">
            <el-form-item label="规则编码:" prop="rulecode">
              <el-input v-model="addFrom.rulecode" />
            </el-form-item>
            <el-form-item label="规则名称:" prop="rulename">
              <el-input v-model="addFrom.rulename" />
            </el-form-item>
            <el-form-item label="规则描述:" prop="ruledesc">
              <el-input v-model="addFrom.ruledesc" />
            </el-form-item>
          </el-col>
          <el-col style="margin:0;" :span="12">
            <el-form-item label="规则内容:" prop="rulecontent">
              <el-input v-model="addFrom.rulecontent" />
            </el-form-item>
            <el-form-item label="规则状态:" prop="rulestate">
              <el-select v-model="addFrom.rulestate"  >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="创建者:">
              <span>{{addFrom.creator}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpFunction">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  addNewCodingRule,
  delCodingRule,
  importExcelCodingRules,
  getAllCodingRules,
  upCodingRule,
  getCodingOfStatus,
  getCodingAll,
} from "@/utils/codingRuleManagementApi";
import  moment from 'moment'
export default {
  name: "codingName",
  data() {
    return {
      total: 0,
      pageSize: 20,
      pageNum: 1,
      resPageNum:1,
      queryInput: "",
      statusSelect: "",
      dialogVisible: false,
      tableData: [],
      addDialogVisible: false,
      titleName: true,
      isPage:true,
      addFrom: {
        creator: "",
        rulecode: "",
        rulecontent: "",
        ruledesc: "",
        rulename: "",
        rulestate: "",
      },
      rules: {
        rulecode: [{ required: true, message: "请输入编码", trigger: "blur" }], //"207",
        rulecontent: [
          { required: true, message: "请输入编码内容", trigger: "blur" },
        ], //"编码内容",
        ruledesc: [
          { required: true, message: "请输入编码描述", trigger: "blur" },
        ], //"编码描述",
        rulename: [
          { required: true, message: "请输入编码名称", trigger: "blur" },
        ], //"编码名称",
        rulestate: [{ required: true, message: "请选择状态", trigger: "blur" }], //"启用",
      },
      options: [
        {
          value: 1,
          label: "启用",
        },
        {
          value: 0,
          label: "禁用",
        },
      ],
      selectList: [],
      codingList: [],
      columns: [
        { label: "编码代码", prop: "rulecode" },
        { label: "编码名称", prop: "rulename" },
        { label: "编码内容", prop: "rulecontent" },
        { label: "编码描述", prop: "ruledesc" },
        { label: "编码状态", prop: "rulestate" }
      ],
      dialogImport:false,
      uploadDisabled:true,
      fileList:[]
    };
  },
  computed: {
    userName() {
      return JSON.parse(this.$store.state.user).username;
    },
     downloadList(){
       if(this.selectList.length === 0){
           return this.codingList.map(item=>{
              item.rulestate = item.rulestate === 0?'禁用':'启用'
              item.createtime = item.createtime !== null?moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'):null
              item.modifytime = item.modifytime !== null?moment(item.modifyTime).format('YYYY-MM-DD HH:mm:ss'):null
              item.disabletime = item.disabletime !== null?moment(item.disableTime).format('YYYY-MM-DD HH:mm:ss'):null
              return item
           })
       }else{
          return this.selectList.map(item=>{
              item.rulestate = item.rulestate === 0?'禁用':'启用'
              item.createtime = item.createtime !== null?moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'):null
              item.modifytime = item.modifytime !== null?moment(item.modifyTime).format('YYYY-MM-DD HH:mm:ss'):null
              item.disabletime = item.disabletime !== null?moment(item.disableTime).format('YYYY-MM-DD HH:mm:ss'):null
              return item
           })
       }
    }
  },
  created() {
    this.getCodingRules();
    this.getCoding()
    this.addFrom.creator = this.userName;
  },
  mounted() {},

  // 组件方法
  methods: {
    async getCoding() {
      const res = await getCodingAll();
      this.codingList = res.data.data;
    },
    async getCodingRules() {
     try {
        let data = { page: this.pageNum, size: this.pageSize };
      const res = await getAllCodingRules(data);
      if (res.status != 200) return this.$msg.error("获取失败");
      let resData = res.data.data.content;
      this.tableData = resData;
      this.total = res.data.data.totalElements;

     } catch (error) {

     }
    },
    renIndex(index){
      return (this.pageNum -1 ) * this.pageSize + index +1
    },
    handleEdit(row) {
      this.addDialogVisible = true
      this.titleName = false
      let postData = this.copyDeep(row)
      this.addFrom = postData
    },
    async delFunctionOfbtytype() {
      let rows = this.selectList;
      if (rows.length === 0) {
        this.$msg({
          type: "info",
          message: "请选中删除文件",
        });
      } else {
        const config = await this.$MessageBox.confirm(
          "此操作将永久删除选中项, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch((e) => e);
        if (config !== "confirm") return;
        const arrayId = rows.map((item) => {
          return item.id;
        });
        const res = await delCodingRule(arrayId);
        if (res.status != 200) {
          return this.$msg.error("删除失败");
        }
        this.tableData = this.tableData.filter((item, i) => {
          return item.id !== arrayId[i];
        });
        this.$refs.multipleTable.clearSelection();
        if (this.pageNum > 1 && this.tableData.length === 0) {
          this.pageNum = this.pageNum - 1;
        }
        this.$msg.success("删除成功");
        this.getCodingRules();
      }
    },
    async addOrUpFunction() {
      this.$refs.addFrom.validate(async (valid) => {
        if (!valid) return this.$msg("内容不能为空");
        try {
          let postData = this.copyDeep(this.addFrom);
          if (this.titleName) {
            let res = await addNewCodingRule(postData);
            if (res.status !== 200 && res.data.message === '已存在') return this.$msg.warning("规则已存在，请重新添加!")
            if (res.status != 200) return this.$msg.error("添加规则失败");
            this.$msg.success("添加规则成功");
            this.addDialogVisible = false;
            this.getCodingRules();
          }else{
            let res = await upCodingRule(postData);
            if (res.status != 200) return this.$msg.error("编辑规则失败");
            this.$msg.success("编辑规则成功");
            this.addDialogVisible = false;
            this.getCodingRules();
          }
        } catch (e) {
        }
      });
    },
    close() {
      const data = {
        creator: this.userName,
      };
      this.$refs.addFrom.resetFields();
      this.addFrom = data;
        this.titleName = true
      this.addDialogVisible = false

    },
    copyDeep(templateData) {
      // templateData 是要复制的数组或对象，这样的数组或者对象就是指向新的地址的
      return JSON.parse(JSON.stringify(templateData));
    },
    async queryFunction() {
      let data = {
        name: this.queryInput,
        state: this.statusSelect,
        page: this.resPageNum,
        size: this.pageSize,
      };
      let res = await getCodingOfStatus(data);
      if (res.status != 200) return this.$msg.error("查询失败");
      let resData = res.data.data.content;
      this.tableData = resData;
      this.total = res.data.data.totalElements;
      this.isPage = false
    },
    restFunction() {
      this.statusSelect = "";
      this.queryInput = "";
      this.pageNum = 1
      this.pageSize = 20
      this.resPageNum = 1
      this.isPage = true
      this.getCodingRules();
    },
    // 分页事件
    async handleSizeChange(val) {
      this.pageSize = val;
     if( this.isPage){
       this.getCodingRules();
     }else{
       this.queryFunction()
     }

    },
    async handleCurrentChange(val) {
      this.pageNum = val;
      if( this.isPage){
       this.getCodingRules();
     }else{
       this.resPageNum = val
       this.queryFunction()
     }
    },
    selectionChange(rows) {
      this.selectList = rows;
    },
    toggleSelection() {
        this.$refs.multipleTable.clearSelection();
    },
    async download() {
      const confirm = await this.$MessageBox
        .confirm("是否导出文件", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((e) => e);
      if (confirm !== "confirm") return;
       this.$download(this.downloadList, this.columns, "编码规则管理")
    },
      //下载模版
    dowtemplate(){
      const list =[
        {
          rulecode:'0000',
          rulename:'0000',
          rulecontent:'0000',
          ruledesc:'0000',
          rulestate:'启用1禁用0'
        }
      ]
      this.$download(list, this.columns, "编码规则管理")
    },
       //清空上传的文件
    closeLoad(){
        this.uploadDisabled = true
         this.$refs.upload.clearFiles();
    },
    //上传文件改变
    handleChange(file,fileList){
        if(fileList.length > 0){
          this.uploadDisabled = false
          this.fileList = fileList
        }
    },
     //上传文件之前
    beforeUpload(file){
        let fileTemp = file.raw
       if(fileTemp){
         if(fileTemp.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
         fileTemp.type === "application/vnd.ms-excel"){
        }else{
          this.$msg.warning('附件格式错误，请删除后重新上传!')
        }
       }
    },
    //上传文件移除
    handleRemove(file,fileList){
      this.fileList = fileList
      if(fileList.length < 1){
          this.uploadDisabled = true
      }
    },
   //开始上传
   async handleUpload(){
        if(!this.fileList.length === 0)return this.$msg.info('请选择要上传的文件')
        let formData = new FormData()
        formData.append('file',this.fileList[0].raw)
         formData.append('creator',this.userName)
        const res =await importExcelCodingRules(formData)
        if(res.status !== 200 )return this.$msg.error('文件上传失败')
        this.$msg.success('文件上传成功')
        this.dialogImport = false
        this.getCodingRules()
    },
    //删除文件之前的钩子
   async beforeRemove(file){
       return this.$MessageBox.confirm(`确定移除 ${file.name}？`)
    }
  },
};
</script>

<style lang="less" scoped>
.el-row {
  margin: 15px 0;

  .el-col {
    margin-right: 20px;
  }
}
.el-select {
  width: 100%;
}
.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #287ef7;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.alert-text {
  color: #62b2ff;
  margin-left: 10px;
  cursor: pointer;
}
.status-item1 {
  > span {
    padding: 0 3px;
  }
  :first-child {
    width: 5px;
    height: 5px;
    display: inline-block;
    border-radius: 50%;
    background-color: #ff4949;
    transform: translateY(-2px);
  }
}
.status-item2 {
  > span {
    padding: 0 3px;
  }
  :first-child {
    width: 5px;
    height: 5px;
    display: inline-block;
    border-radius: 50%;
    background-color: #6798f9;
    transform: translateY(-2px);
  }
}
.dowload{
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   >div{
     display: flex;
     align-items: center;
     &:first-child{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: flex-start;
       >span{
         font-size: 12px;
         color: #e10505;
       }
     }
     >button{
       margin-top: 0!important;
     }
   }
 }
</style>
