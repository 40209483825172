<template>
    <div class="app-container">
        <el-row class="main-flex">
            <!--运营商场站树-->
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <monitoring @emitToParent='getTreeData' @isShow="isHidden"></monitoring>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <!--                    <el-input placeholder="请从左侧选择运营商/场站" v-model.trim="treeData.operatorStationNameTree"-->
                <!--                              disabled></el-input>-->

                <div class="head-section">
<!--                    <el-col :span="7">-->
<!--                        <el-form :inline="true">-->
<!--                            <el-form-item label="运营商/车队名称：" class="battery-num">-->
<!--                                <el-input placeholder="请选择运营商/车队" v-model.trim="query.teamId"-->
<!--                                          clearable disabled></el-input>-->
<!--                            </el-form-item>-->
<!--                        </el-form>-->

<!--                    </el-col>-->
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="抬头：" class="battery-num">
                                <el-input placeholder="请输入抬头" v-model.trim="userName" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="6">
                        <el-form :inline="true" class="">
                            <el-form-item label="手机号：">
                                <el-input placeholder="请输入手机号" v-model.trim="phone" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="状态：" style="margin-left: 10px;" label-width="95px">
                                <el-select placeholder="请选择状态" v-model="stateValue" clearable>
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="4">
                        <div class="btn-end">
                            <span class="more-search" @click="showMore=!showMore"><div>展开</div>
                            <i :class="showMore==true?'el-icon-arrow-down':'el-icon-arrow-up'"></i> </span>
                            <span class="btn-rest">
                             <el-button @click="restFunction" plain>重置</el-button>
                            </span>
                            <span class="btn-submit">
                           <el-button type="primary" @click="getBatDatPage" plain>查询</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <transition name="el-zoom-in-top">
                    <div v-show="showMore">
                        <div class="head-section head-section2">
                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="申请日期：" >
                                        <el-date-picker
                                            v-model="appointmentDate"
                                            type="datetimerange"
                                            format="MM-dd HH:mm"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            :default-time="['00:00:00', '23:59:59']"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-form>

                            </el-col>

                        </div>
                    </div>
                </transition>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">更新列表
                        </div>
                    </el-col>
                    <!--table-->
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table ref="chargeDevTable" id="statiNo" :data="tableData"
                                      :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo">
                                <el-table-column align="center" label="编号" :index="renIndex" type="index"
                                                 width="50"></el-table-column>
                                <el-table-column align="center" prop="invoiceHeader" label="抬头"
                                                 width="200" show-overflow-tooltip></el-table-column>
                                <el-table-column align="center" prop="accountPhone" label="手机号"
                                                 width="120"></el-table-column>
                                <el-table-column align="center" prop="operatorName" label="运营商"
                                                 min-width="120"></el-table-column>
                                <el-table-column align="center" prop="teamName" label="车队"
                                                 min-width="150"></el-table-column>
                                <el-table-column align="center" label="开票金额"
                                                 width="160">
                                    <template slot-scope="scope">
                                        <div>
                                            {{
                                                scope.row.invoiceAmount
                                            }}元
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" label="申请时间" width="280">
                                    <template slot-scope="scope">
                                        <div>
                                            {{
                                                scope.row.createTime
                                            }}
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column align="left" prop="auditStatus" label="状态" width="120">
                                    <template slot-scope="scope">
                                        <div>
                                        <span v-if="scope.row.progress == 0" class="states"
                                              style="color:#FA9600;"><i
                                            style="background-color:#FA9600;"></i> 待审核 </span>
                                            <span v-else-if="scope.row.progress == 2" class="states"
                                                  style="color:#0D6CE4;"><i
                                                style="background-color:#0D6CE4;"></i> 审核通过</span>

                                            <!--                                            <span v-else-if="scope.row.progress == 3" class="states"
                                                                                              style="color:#0D6CE4;"><i
                                                                                            style="background-color:#0D6CE4;"></i> 开票中</span>
                                                                                        <span v-else-if="scope.row.progress == 4" class="states"
                                                                                              style="color:#0D6CE4;"><i
                                                                                            style="background-color:#0D6CE4;"></i> 开票完成</span>
                                                                                        <span v-else-if="scope.row.progress == 5" class="states"
                                                                                              style="color:#0D6CE4;"><i
                                                                                            style="background-color:#0D6CE4;"></i> 邮寄中</span>-->
                                            <span v-else-if="scope.row.progress == 3" class="states"
                                                  style="color:#26C3C0;"><i
                                                style="background-color:#26C3C0;"></i> 重开待审核</span>
                                            <span v-else class="states"
                                                  style="color:#FF6165;"><i
                                                style="background-color:#FF6165;"></i> 审核驳回</span>
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column label="操作" min-width="80" style="align-items: center;"
                                                 fixed="right" align="center">
                                    <template slot-scope="scope">
                                        <div style="display: flex;justify-content: center">
                                            <el-button @click="detail(scope.row)" class="btn-color"
                                                       v-if="scope.row.progress == 0 "
                                                       type="text">审核
                                            </el-button>
                                            <el-button @click="detail(scope.row,1)" class="btn-color"
                                                       v-else
                                                       type="text">查看
                                            </el-button>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>

                    <!--pagination-->
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10, 30, 50,100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>
                </div>
            </el-col>

        </el-row>
        <el-dialog
            title="开票审核"
            :visible.sync="dialogVisible"
            :before-close="handleClose"
            @close="cancelAudit" width="55%">
            <ul class="car-inf">
                <li>开票金额：</li>
                <li>{{ dialogzForm ? dialogzForm.invoiceAmount : '--' }}元</li>
            </ul>
            <div class="dialog-title">
                <div>消费记录</div>
                <div class="more-inf" @click="moreTable"><span>更多记录</span></div>
            </div>
            <div class="dialog-table">
                <el-table v-loading="loading" border ref="chargeDevTable2" id="statiNo" :data="dialogTableList"
                          :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo">
                    <el-table-column align="center" label="编号" :index="renIndex" type="index"
                                     width="50"></el-table-column>
                    <el-table-column align="center" label="类型"
                                     width="100">换电消费
                    </el-table-column>
                    <el-table-column align="center" prop="stationName" label="站点"
                                     width="150"></el-table-column>
                    <el-table-column align="center" label="金额"
                                     min-width="120">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.trRealAmt }}元
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="createTime" label="消费时间"
                                     min-width="100"></el-table-column>

                </el-table>
            </div>
            <div class="dialog-title">
                <div>开票信息</div>
            </div>
            <el-descriptions class="invoice-inf" :column="3" direction="vertical">
                <el-descriptions-item contentClassName="item-cell" label="抬头类型">
                    {{ dialogzForm ? dialogzForm.type == 1 ? '企业单位' : '个人/非企业' : '--' }}
                </el-descriptions-item>
                <el-descriptions-item contentClassName="item-cell" label="发票抬头">
                    {{ dialogzForm ? dialogzForm.invoiceHeader : '--' }}
                </el-descriptions-item>
                <el-descriptions-item v-if="dialogzForm?dialogzForm.type==1:true" contentClassName="item-cell"
                                      label="税号">{{ dialogzForm ? dialogzForm.taxId : '--' }}
                </el-descriptions-item>
                <el-descriptions-item v-if="dialogzForm?dialogzForm.type==1:true" contentClassName="item-cell"
                                      label="注册地址">{{ dialogzForm ? dialogzForm.address : '--' }}
                </el-descriptions-item>
                <el-descriptions-item v-if="dialogzForm?dialogzForm.type==1:true" contentClassName="item-cell"
                                      label="开户银行">{{ dialogzForm ? dialogzForm.bank : '--' }}
                </el-descriptions-item>
                <el-descriptions-item v-if="dialogzForm?dialogzForm.type==1:true" contentClassName="item-cell"
                                      label="银行账户">{{ dialogzForm ? dialogzForm.cardNo : '--' }}
                </el-descriptions-item>
            </el-descriptions>
            <div class="dialog-title dialog-title-s">
                <div>接收方式</div>
            </div>
            <el-descriptions class="invoice-inf" :column="3" direction="vertical">
                <el-descriptions-item contentClassName="item-cell" label="邮寄地址">
                    {{ dialogzForm ? dialogzForm.shippingAddress : '--' }}
                </el-descriptions-item>
                <el-descriptions-item contentClassName="item-cell" label="邮寄联系人">
                    {{ dialogzForm ? dialogzForm.recipient : '--' }}
                </el-descriptions-item>
                <el-descriptions-item contentClassName="item-cell" label="联系人电话">
                    {{ dialogzForm ? dialogzForm.contactPhone : '--' }}
                </el-descriptions-item>
                <el-descriptions-item contentClassName="item-cell" label="电子邮箱">
                    {{ dialogzForm ? dialogzForm.email : '--' }}
                </el-descriptions-item>
            </el-descriptions>
            <el-form ref="form" :inline="true" :rules="rules" label-position="top" :model="form" label-width="80px"
                     class="dialog-form">
                <el-form-item label="审核结果：" prop="check">
                    <!-- -->
                    <el-select v-model="form.check" placeholder="请选择审核结果" :disabled="examineValue==1">
                        <el-option label="通过" value="2"></el-option>
                        <el-option label="驳回" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注：" v-if="form.check==1">
                    <el-input v-model="form.remark" :disabled="examineValue==1"></el-input>
                </el-form-item>
            </el-form>
            <!--           v-if="examineValue!=1" -->
            <span slot="footer" class="dialog-footer-box" v-if="examineValue!=1">
                <div>
                    <el-button class="cancel-btn" @click="cancelAudit">取消</el-button>
                    <el-button class="confirm-btn" type="primary" @click="confirmAudit">确定</el-button>
                </div>
  </span>
        </el-dialog>

        <el-dialog
            :visible.sync="dialogVisibleTable"
            :before-close="handleClose2" @close="VisibleTableCancel" width="55%">
            <div class="dialog-table dialog-table-s">
                <el-table v-loading="loading" border ref="chargeDevTable2" id="statiNo" :data="moreListData"
                          :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo">
                    <el-table-column align="center" label="编号" :index="renIndex2" type="index"
                                     width="50"></el-table-column>
                    <el-table-column align="center" label="类型"
                                     width="100">换电消费
                    </el-table-column>
                    <el-table-column align="center" prop="stationName" label="站点"
                                     width="150"></el-table-column>
                    <el-table-column align="center" label="金额"
                                     min-width="120">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.trRealAmt }}元
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="createTime" label="消费时间"
                                     min-width="100"></el-table-column>
                </el-table>
                <div class="pagin">
                    <el-pagination
                        @size-change="handleSizeChange2"
                        @current-change="handleCurrentChange2"
                        :current-page="query2.pageNum"
                        :page-sizes="[10, 30, 50,100]"
                        :page-size="query2.pageSize"
                        layout="total, sizes, prev, pager, next"
                        :total="total2">
                    </el-pagination>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import monitoring from '@/components/tree/monitoringCarTree.vue';
// import {getInvoiceList, toAudit} from "@/utils/station";   del
import {getInvoiceList, getOrderDetail, getaudit} from "@/utils/operatorApi";

export default {
    name: 'powerChangeReservation',
    components: {monitoring},
    data() {
        return {
            loading: true,
            moreListData: null,
            showMore: false,
            isopenShow: true,
            query: {
                pageNum: 1,
                pageSize: 10,
                teamId: ''
            },
            query2: {
                pageNum: 1,
                pageSize: 10,
            },
            dataShow: {},
            tableData: [],
            total: 0,
            total2: 0,

            //左侧 运营商-场站树
            treeData: {
                operatorStationOptions: undefined,  //运营商-场站树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorStationNameTree: "",
                operatorStationIdTree: null,
                defaultExpandAll: true,
            },
            dialogVisible: false,
            form: {
                check: '',
                remark: ''
            },
            dialogzForm: null,
            orderId: null,
            //     search
            userName: '',
            phone: '',
            station: '',
            stateValue: null,
            options: [
                {
                    label: '待审核',
                    value: 0
                },
                {
                    label: '审核驳回',
                    value: 1
                }, {
                    label: '审核通过',
                    value: 2
                },
                {
                    label: '重开待审核',
                    value: 3
                },
                // {
                //     label: '开票中',
                //     value: 3
                // },
                // {
                //     label: '开票完成',
                //     value:4
                // },
                // {
                //     label: '邮寄中',
                //     value: 5
                // },
                // {
                //     label: '重开审核',
                //     value: 6
                // },

            ],
            appointmentDate: '',
            examineValue: 0,
            rules: {
                check: [
                    {required: true, message: '请选择审核结果', trigger: 'change'},
                ],
            },
            dialogVisibleTable: false,
            dialogTableList: null,
        };

    },
    created() {
        this.getBatDatPage();
    },
    // watch: {
    //     'form.check': {
    //         handler(newValue) {
    //             console.log(newValue,'33333')
    //         },
    //         // deep: true
    //     }
    // },
    computed: {},
    filters: {},
    methods: {
        moreTable() {
            this.dialogVisibleTable = true
        },
        handleClose(done) {
            done();
            this.form.remark = ''
            this.form.check = ''
        },
        handleClose2(done) {
            done();
        },
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            } else if (columnIndex == 8) {
                return 'padding-left:10px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        async getBatDatPage() {
            try {
                let operatorId;
                let stationId;
                let id = this.treeData.operatorTeamIdTree;

                if (id != "" && id != null) {
                    if (id.indexOf("operator") != -1) {
                        id = id.slice(9);
                        operatorId = Number(id);
                    } else {
                        id = id.slice(5);
                        stationId = Number(id)
                    }
                }

                let startTime = null;
                let endTime = null;
                if (this.appointmentDate) {
                    startTime = this.appointmentDate[0];
                    endTime = this.appointmentDate[1];
                }


                let data = {
                    pageNo: this.query.pageNum,
                    pageSize: this.query.pageSize,
                    operatorId: operatorId,
                    teamId: stationId,
                    header: this.userName,
                    phone: this.phone,
                    progress: this.stateValue,

                    // 时间
                    beginTime: startTime,
                    endTime: endTime,

                }
                const res = await getInvoiceList(data)
                if (res.data.code !== 200) return this.$msg.error('获取场站基础信息失败!')
                this.tableData = res.data.data.data
                this.total = res.data.data.total
            } catch (e) {
                console.log(e, 'err')
            }
        },
        // 消费记录
        async getOrderList() {
            let dataId = {
                id: this.orderId,
                page: this.query2.pageNum,
                size: this.query2.pageSize
            }
            await getOrderDetail(dataId).then(res => {
                let resData = res.data.data
                this.moreListData = resData.data
                let list = JSON.parse(JSON.stringify(this.moreListData))
                this.dialogTableList = list.splice(0, 3)
                this.total2 = res.data.data.total
                this.loading = false
            }).catch(err => {
                this.$message.error('查询消费记录失败');
                this.loading = false
            })
        },
        // 审核
        detail(data, state) {

            this.examineValue = state
            this.dialogzForm = data
            this.orderId = data.id

            // 消费记录
            this.getOrderList()

            // 查看為1
            if (state == 1) {
                this.form.check = data.progress ? String(data.progress) : ''
                this.form.remark = data.remark
            }
            this.dialogVisible = true
        },
        cancelAudit() {
            this.form.remark = ''
            this.form.check = ''
            this.$refs['form'].resetFields();
            this.dialogVisible = false
        },
        VisibleTableCancel() {
            this.dialogVisibleTable = false
        },
        // audit
        confirmAudit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let data = {
                        id: this.orderId,
                        progress: this.form.check,
                        remark: this.form.remark
                    }
                    getaudit(data).then((res) => {
                        this.dialogVisible = false
                        this.$message({
                            message: res.data.msg,
                            type: 'success'
                        });
                        this.getBatDatPage();
                        this.form.remark = ''
                        this.form.check = ''

                    }).catch(err => {
                        this.$message({
                            message: err.data.msg,
                            type: 'warning'
                        });
                    })
                } else {
                    console.log('error submit!!');
                    return;
                }
            });

        },

        restFunction() {
            //左侧 运营商-场站树
            this.treeData = {
                operatorStationOptions: undefined,  //运营商-场站树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorStationNameTree: "",
                operatorStationIdTree: null,
                defaultExpandAll: true,
            };
            this.query = {
                pageNum: 1,
                pageSize: 10,
            }
            this.userName = ''
            this.phone = ''
            this.station = ''
            this.stateValue = null
            this.appointmentDate = ''
            this.getBatDatPage()
        },

        handleSizeChange(val) {
            this.query.pageSize = val
            this.getBatDatPage()
        },
        handleSizeChange2(val) {
            this.query2.pageSize = val
            this.getOrderList()
        },
        handleCurrentChange(val) {
            this.query.pageNum = val
            this.getBatDatPage()
        },
        handleCurrentChange2(val) {
            this.query2.pageNum = val
            this.getOrderList()
        },
        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },
        renIndex2(index) {
            return (this.query2.pageNum - 1) * this.query2.pageSize + index + 1
        },
        close() {

        },

        cleanList() {
            this.$refs.chargeDevTable.clearSelection()
        },

        getTreeData(data) {
            this.treeData = data
            this.query.teamId = data.operatorTeamNameTree
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.treeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.status !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorOptions.push(res.data.data)
                this.treeData.operatorIdTree = res.data.data.id
                this.treeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },
    },


};
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

::v-deep .fixstyle {
    padding-left: 0 !important;
}

.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #dddee0; /*滚动条的背景颜色*/
    }
}

.inner-container {
    // min-width: 100% + 60px;
    height: var(--tableHeight);
    overflow: hidden;
}

.bm-view {
    width: 100%;
    height: 400px;
}


li {
    list-style: none;
}

.el-select, .el-cascader {
    width: 100%;
}

.el-dropdown {
    margin-left: 25px;
}

.states {
    > i {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 4px;
        //margin-top: 3px;

    }
}

.box1 {
    display: flex;
    align-items: center;
    margin: 0 !important;

    span {
        position: relative;
        font-size: 18px;
        font-weight: 700;
        margin-left: 20px;
    }

    .text::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: #1890ff;
        border-radius: 50%;
        top: 50%;
        left: -13px;
        transform: translate(0, -50%);
    }
}

.content-hander {
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 3px solid #2980e1;
    padding-left: 15px;

    > h3 {
        font-size: 16px;
        font-weight: 700;
    }
}

.content-title {
    height: 200px;

    .el-col {
        height: 100%;

        > img {
            width: 100%;
            height: 100%;
            box-shadow: 0 0 25px #333;
        }
    }

    .content-title-right {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        > ul {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;
            height: 100%;
        }
    }
}

.msg-title {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0;
    margin: 0 !important;

    span {
        color: #333;
    }

    .text1 {
        color: #595959;
        margin-left: 15px;
    }
}

.content-map {
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
}

.el-divider--horizontal {
    margin-bottom: 0;
}

.fileImg {
    display: flex;
    align-items: flex-start;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        border: 1px dashed #ccc;
        border-radius: 6px;
        margin-right: 20px;
        cursor: pointer;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
        }
    }

    div:hover {
        border: 1px dashed #1b80e1;

        i {
            color: #1b80e1;
        }
    }

}

.dowload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        display: flex;
        align-items: center;

        &:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            > span {
                font-size: 12px;
                color: #e10505;
            }
        }

        > button {
            margin-top: 0 !important;
        }
    }
}

.out-container {
    height: 100%;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

.btn-color {
    color: #0D6CE4;
}

/deep/ .el-dialog__title {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    color: #2F3133;

}

/deep/ .el-dialog__header {
    padding: 24px 24px 0 24px;
}

/deep/ .el-dialog__body {
    padding: 16px 24px 24px 24px;
}

.car-inf {
    font-size: 14px;
    font-weight: 400;
    color: #585858;

    li {
        margin-bottom: 8px;
    }

    li:last-child {
        margin-bottom: 0px;
    }
}

/deep/ .el-dialog {
    width: 360px;
}

.dialog-form {
    /deep/ .el-form-item__label {
        font-size: 14px;
        font-weight: 400;
        //line-height: 20px;
        color: #585858;
        padding: 16px 0 8px 0;
    }

    /deep/ .el-input__inner { //更改input大小
        //width: 368px;
        height: 36px;
        line-height: 36px;
    }

    /deep/ .el-form-item__label {
        padding: 0 !important;
    }
}

.dialog-footer-box {
    display: flex;
    justify-content: flex-end;
}

/deep/ .el-form-item {
    margin-bottom: 0;
}

/deep/ .el-form-item__content {
    width: 312px;
}

/deep/ .el-dialog__footer {
    padding: 0 24px 24px 24px;
}


.cancel-btn {
    border: 1px solid #0D6CE4;
    color: #0D6CE4;
    padding: 8px 30px;
    line-height: 20px;
    width: 88px;
}

.confirm-btn {
    background: #0D6CE4;
    color: #FFFFFF;
    padding: 8px 30px;
    line-height: 20px;
    width: 88px;
}

.more-search {
    display: flex;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    cursor: pointer;
    position: relative;
    left: -15px;

    i {
        line-height: 36px;
    }
}

.head-section {
    /deep/ .el-form-item__content {
        width: 250px !important;
    }

    /deep/ .el-input__inner { //更改input大小
        width: 250px !important;
        height: 36px;
        line-height: 36px;
    }
}

/deep/ .el-range-separator {
    width: 10%;
}

.head-section2 {
    overflow: hidden;
    padding: 0 16px 16px 16px;
    height: 100%;
}

/deep/ .el-date-editor .el-range-separator {
    line-height: 28px;
}

/deep/ .el-date-editor .el-range__icon {
    line-height: 1;
}

.dialog-title {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    color: #2F3133;
    margin: 24px 0 16px 0;
    display: flex;
    justify-content: space-between;

    .more-inf {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        color: #0D6CE4;
        cursor: pointer;
    }
}

.dialog-title-s {
    margin: 8px 0 16px 0;
}

.dialog-table {
    .el-table {
        height: 100%;
    }

    /deep/ .el-table__body-wrapper, .is-scrolling-none {
        height: 100% !important;
    }

    /deep/ th.el-table__cell { //head
        border-bottom: 1px solid #EEE !important;
        background: #FAFAFA !important;
        color: #2F3133 !important;
        font-size: 14px !important;
        //text-align: center;
        height: 40px !important;
        line-height: 40px !important;
    }

    /deep/ .el-table__row {
        height: 40px !important;
        line-height: 40px !important;
        color: #2F3133 !important;
        font-size: 14px !important;
    }

    /deep/ .el-table .el-table__cell {
        padding: 0;
    }
}

/deep/ .battery-num .el-form-item__label {
    width: 82px;
}

.dialog-table-s {
    .el-table {
        height: 443px;
    }

    /deep/ .el-table__body-wrapper, .is-scrolling-none {
        height: 100% !important;
    }
}

.invoice-inf {
    /deep/ .el-descriptions-item__cell {
        padding-bottom: 8px;
    }

    /deep/ .item-cell {
        padding-bottom: 16px;
        padding-right: 24px;
    }
}
/deep/.el-descriptions__body .el-descriptions__table .el-descriptions-item__cell{
    vertical-align: text-top;
}

</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>

