<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="运营商名称：">
                                <el-input
                                    v-model="queryParams.name"
                                    placeholder="请输入运营商名称"
                                    clearable
                                    @keyup.enter.native="handleQuery"
                                />
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="18">
                        <div class="btn-end">
                            <span class="btn-rest">
                            <el-button size="mini" @click="resetQuery">重置</el-button>
                            </span>
                            <span class="btn-submit">
                                <el-button type="primary" size="mini" @click="handleQuery" plain>查询</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            运营商列表
                        </div>
                        <div>
                             <span class="open-btn">
                                <el-button plain @click="toggleExpandAll">展开/折叠
                                </el-button>
                        </span>
                            <span class="add-btn">
                                <el-button type="primary" plain size="mini" @click="handleAdd"
                                           v-hasCode="['system:operator:add']">新增
                                </el-button>
                            </span>
                        </div>

                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table
                                v-if="refreshTable"
                                :data="operatorList"
                                :header-cell-style="{'text-align':'center'}"
                                :cell-style="{'text-align':'center'}"
                                row-key="id"
                                :default-expand-all="isExpandAll"
                                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                                height="100%"
                            >

                                <el-table-column prop="name" label="运营商名称" min-width="240" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column prop="code" label="运营商编码" min-width="180"></el-table-column>
                                <el-table-column prop="uscId" label="运营商统一社会信用代码" min-width="180"></el-table-column>
                                <el-table-column prop="registerAddress" label="运营商注册地址" min-width="120" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column prop="contactWay" label="联系方式" min-width="130"></el-table-column>
                                <el-table-column prop="principal" label="负责人" min-width="80" :show-overflow-tooltip="true"></el-table-column>


                                <el-table-column prop="isDefault" label="是否设为默认账户" min-width="100">
                                    <template slot-scope="scope">
                                    <span v-if="scope.row.isDefault === 0" style="color:orange;">否
                                    </span>
                                        <span v-else-if="scope.row.isDefault === 1" style="color:#0D6CE4;">是
                                    </span>
                                        <span v-else style="color:#FF6165;">未知
                                    </span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="account" label="账户号" min-width="130"></el-table-column>


                                <el-table-column align="center" prop="accountBalance" label="账户余额" :formatter="balanceFormat"
                                                 min-width="120"></el-table-column>

                                <el-table-column label="操作" align="center" class-name="small-padding fixed-width" fixed="right" min-width="200" >
                                    <template slot-scope="scope">

                                            <el-button type="text" @click="handleUpdate(scope.row)"
                                                       v-hasCode="['system:operator:update']" class="btn-color">
                                                修改
                                            </el-button>

                                        <!--
                                        <el-button type="text" @click="handleAdd(scope.row)"
                                                   v-hasCode="['system:operator:add']" class="btn-color">
                                            新增
                                        </el-button>
                                        -->

                                        <el-button v-if="scope.row.pid != 0" type="text"
                                                   @click="accountBind(scope.row)" v-hasCode="['operator:account:bind']" class="btn-color">
                                            账户绑定
                                        </el-button>

                                        <el-button type="text" v-if="(scope.row.pid != -1 && userOperatorId !== scope.row.id) || operatorAccess === true"
                                                   @click="handleDelete(scope.row)" v-hasCode="['system:operator:delete']" class="btn-color">
                                            删除
                                        </el-button>
                                        <!-- <el-button type="text" @click="handleMonitor(scope.row)"
                                                   v-hasCode="['system:operator:monitor']" class="btn-color">
                                            车辆监控
                                        </el-button>-->
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>
                </div>
            </el-col>
        </el-row>


        <!-- 添加或修改运营商对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="38%" append-to-body @close="close" class="popup_marker">
            <el-form ref="form" :model="form" :rules="rules" >
                <el-row :span="24" :gutter="16">
                    <el-col v-if="(form.pid !== null && form.pid !== -1 && userOperatorId !== form.id) || (operatorAccess === true)" :span="12">
                        <el-form-item label="上级运营商" prop="pid">
                            <treeselect v-model="form.pid" :options="operatorOptions" :normalizer="normalizer"
                                        placeholder="选择上级运营商"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="运营商名称" prop="name">
                            <el-input v-model="form.name" placeholder="请输入运营商名称"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                    <el-row :span="24" :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="运营商编码" prop="code">
                            <el-input v-model="form.code" placeholder="请输入运营商编码" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="运营商统一社会信用代码" prop="uscId">
                            <el-input v-model="form.uscId" placeholder="请输入运营商统一社会信用代码" maxlength="18" show-word-limit/>
                        </el-form-item>
                    </el-col>
                    </el-row>
                <el-row :span="24" :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="运营商注册地址" prop="registerAddress">
                            <el-input v-model="form.registerAddress" placeholder="请输入运营商注册地址"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="负责人" prop="principal">
                            <el-input v-model="form.principal" placeholder="请输入负责人" maxlength="20"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :span="24" :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="联系电话" prop="contactWay" class="el-form-item_bottom">
                            <el-input v-model="form.contactWay" placeholder="请输入联系电话" maxlength="11" show-word-limit/>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="close">取 消</el-button>
            </div>
        </el-dialog>



        <!-- 运营商扣费账户绑定 -->
        <el-dialog :title="bindTitle" :visible.sync="bindOpen" width="38%" append-to-body class="popup_marker">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :span="24" :gutter="16">
                    <el-col v-if="form.pid !== null" :span="12">
                        <el-form-item label="上级运营商" prop="pid">
                            <treeselect v-model="form.pid" :options="operatorOptions" :normalizer="normalizer"   :disabled="true"
                                        placeholder="选择上级运营商"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="运营商名称" prop="name">
                            <el-input v-model="form.name" placeholder="请输入运营商名称"  :disabled="true"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="运营商编码" prop="code">
                            <el-input v-model="form.code" placeholder="请输入运营商编码"  :disabled="true"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :span="24" :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="运营商统一社会信用代码" prop="uscId">
                            <el-input v-model="form.uscId" placeholder="请输入运营商统一社会信用代码" maxlength="18" show-word-limit :disabled="true"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="运营商注册地址" prop="registerAddress">
                            <el-input v-model="form.registerAddress" placeholder="请输入运营商注册地址"  :disabled="true"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="负责人" prop="principal">
                            <el-input v-model="form.principal" placeholder="请输入负责人" maxlength="20"  :disabled="true"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系电话" prop="contactWay">
                            <el-input v-model="form.contactWay" placeholder="请输入联系电话" maxlength="11"  :disabled="true"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :span="24" :gutter="16">
                    <el-col :span="12" >
                        <el-form-item label="默认(扣费)账户" prop="isDefault" class="el-form-item_bottom">
                            <el-radio-group v-model="form.isDefault">
                                <el-radio
                                    v-for="item in defaultOptions"
                                    :key="item.value"
                                    :label="item.value"
                                >{{ item.label }}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="账户号" prop="account" v-if="form.isDefault === 1" class="el-form-item_bottom" >
                            <el-input v-model="form.account" placeholder="扣费账户号" maxlength="20" show-word-limit/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="bindSubmitForm">确 定</el-button>
                <el-button @click="bindCancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    addOperator,
    delOperator,
    updateOperator, operatorList, operatorAccountList,
} from "@/utils/operatorApi";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {handleTree} from "@/utils/tree";
import * as myStore from "@/utils/stroage";
export default {
    name: "operatorName",
    components: {Treeselect},
    computed: {
    },
    data() {
        return {
            // 显示搜索条件
            showSearch: true,
            operatorsData: [],
            // 表格树数据
            operatorList: [],
            // 运营商树选项
            operatorOptions: [],
            // 弹出层标题
            title: "",
            bindTitle: "账户绑定",
            operatorAccess:false,//是否可以变更运营商:false：不可以变更,true:可以变更
            userOperatorId:null,//用户的云平台编码
            // 是否显示弹出层
            open: false,
            bindOpen: false,
            // 是否展开，默认全部展开
            isExpandAll: true,
            // 重新渲染表格状态
            refreshTable: true,
            // 查询参数
            queryParams: {
                name: undefined,
            },
            // 表单参数
            form: {
                pid: null,
                name: "",
                code: "",
                contactWay: null,
                principal: "",
                isDefault:0,
                account:"",
                uscId:"",
                registerAddress:""
            },
            defaultOptions: [
                {value: 0, label: "否"},
                {value: 1, label: "是"},
            ],
            // 表单校验
            rules: {
                pid: [
                    {required: true, message: "上级运营商不能为空", trigger: "blur"}
                ],
                name: [
                    {required: true, message: "运营商名称不能为空", trigger: "blur"}
                ],
                code: [
                    {required: true, message: "运营商编码不能为空", trigger: "blur"}
                ],
                uscId: [
                    {required: true, message: "运营商统一社会信用代码不能为空", trigger: "blur"}
                ],
                registerAddress: [
                    {required: true, message: "运营商注册地址不能为空", trigger: "blur"}
                ],
                contactWay: [
                    {
                        required: true,
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: "请输入正确的手机号码",
                        trigger: "blur"
                    }
                ],

                isDefault: [
                    {required: true, message: "请选择是否设置默认(扣费)账户", trigger: "blur"}
                ],

                account: [
                    {required: true, message: "账户信息不能为空", trigger: "blur"}
                ],
            }
        };
    },
    created() {
        this.getUserInfo();
        this.getList();
    },
    methods: {
        getUserInfo() {
            let usrinfo = JSON.parse(window.sessionStorage.getItem("electric-user"));
            if(usrinfo !== null && usrinfo.operatorId !== null) {
                console.log("=======usrinfo.operatorId=======",usrinfo.operatorId)
                this.userOperatorId = usrinfo.operatorId;
                if(this.userOperatorId === -1){
                    this.operatorAccess = true;
                }
                console.log("=======this.operatorAccess=======",this.operatorAccess)
            }else{
                this.operatorAccess = false;
            }
        },
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        /** 查询运营商列表 */
        async getList() {
            try {
                let res = await operatorAccountList();
                if (res.data.code !== 200) return this.$msg.error("获取运营商信息失败");
                this.operatorList = handleTree(res.data.data, "id");
                if (this.operatorsData.length <= 0) {
                    this.operatorsData = res.data.data;
                }
            } catch (error) {
            }
        },
        async getListById(id) {
            try {
                let url = "";
                if (id != "" && id != null) {
                    url += "?pid=" + id
                }
                let res = await operatorList(url);
                if (res.data.code !== 200) return this.$msg.error("获取运营商信息失败");
                this.operatorList = handleTree(res.data.data, "id")
            } catch (error) {
            }
        },
        /** 转换运营商数据结构 */
        normalizer(node) {
            if (node.children && !node.children.length) {
                delete node.children;
            }
            return {
                id: node.id,
                label: node.name,
                children: node.children
            };
        },

        // 取消按钮
        close() {
            console.log("11111")
            this.open = false;
            this.reset();
            console.log("22222")
        },
        bindCancel() {
            this.bindOpen = false;
            this.reset();
        },


        // 表单重置
        reset() {
            this.form = {
                id: undefined,
                pid: undefined,
                name: undefined,
                code: undefined,
                principal: undefined,
                contactWay: undefined,
                isDefault:0,
                account:"",
                uscId:"",
                registerAddress:""
            };
        },
        /** 搜索按钮操作 */
        handleQuery() {
            var list = this.operatorsData;
            list.some(item => {
                if (item.name === this.queryParams.name) {
                    this.getListById(item.id);
                    return true;
                }
            })
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.queryParams = {
                name: undefined,
            };
            this.getList();
        },

        /** 展开/折叠操作 */
        toggleExpandAll() {
            this.refreshTable = false;
            this.isExpandAll = !this.isExpandAll;
            this.$nextTick(() => {
                this.refreshTable = true;
            });
        },

        /** 新增按钮操作 */
        async handleAdd(row) {
            this.reset();
            if (row != undefined) {
                this.form.pid = row.id;
            }
            this.open = true;
            this.title = "添加运营商";

            /*let res = await operatorList();
            if (res.data.code !== 200) return this.$msg.error("获取运营商信息失败");
            this.operatorOptions = handleTree(res.data.data, "id")*/

            this.operatorOptions = this.operatorList;
        },

        balanceFormat(row) {
            var balance = "尚未绑定账户"; //stationType
            if (row.accountVO != null) {
                return row.accountVO.balance
            }
            return balance;
        },

        /** 修改按钮操作 */
        handleUpdate(row) {
            console.log("============row==========",row)
            this.reset();
            this.form = row;
            this.open = true;
            this.title = "修改运营商";
            /*let res = await operatorList();
            if (res.data.code !== 200) return this.$msg.error("获取运营商信息失败");
            this.operatorOptions = handleTree(res.data.data, "id")*/

            this.operatorOptions = this.operatorList;
        },

        /** 修改按钮操作 */
        accountBind(row) {
            this.reset();
            this.form = row;
            this.bindOpen = true;
            bindTitle: "运营商账户绑定";
            this.operatorOptions = this.operatorList
        },

        /** 提交按钮 */
        submitForm: function () {
            this.$refs.form.validate(async (valid) => {
                if (!valid) return
                if (this.form.id != undefined) {
                    var res = await updateOperator(this.form)
                    if (res.data.code != 200) {
                        this.$msg.error("修改运营商失败 " + res.data.msg);
                    } else {
                        this.$msg.success("修改运营商成功");
                        this.open = false;
                        this.getList();
                    }
                } else {
                    var res = await addOperator(this.form)
                    if (res.data.code != 200) {
                        this.$msg.error("新增运营商失败 " + res.data.msg);
                    } else {
                        this.$msg.success("新增运营商成功");
                        this.open = false;
                        this.getList();
                    }
                }
            })

        },


        /** 提交按钮 */
        bindSubmitForm: function () {
            this.$refs.form.validate(async (valid) => {
                if (!valid) return
                if (this.form.id != undefined) {
                    var res = await updateOperator(this.form)
                    if (res.data.code != 200) {
                        this.$msg.error("绑定失败: " + res.data.msg);
                    } else {
                        this.$msg.success("绑定账户成功");
                        this.bindOpen = false;
                        this.getList();
                    }
                } else {
                    var res = await addOperator(this.form)
                    if (res.data.code != 200) {
                        this.$msg.error("绑定失败 " + res.data.msg);
                    } else {
                        this.$msg.success("绑定账户成功");
                        this.bindOpen = false;
                        this.getList();
                    }
                }
            })
        },

        /** 删除按钮操作 */
        async handleDelete(row) {
            try {
                const confirm = await this.$MessageBox.confirm(
                    "是否确认删除名称为 ‘" + row.name + "’ 的运营商?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                );
                if (confirm !== "confirm") return;
                var list = [];
                list.push(row.id)
                const res = await delOperator(list);
                if (res.data.code !== 200) {
                    this.$msg.error("删除失败!" + res.data.msg);
                } else {
                    this.$msg.success("删除成功");
                }
                this.getList()
            } catch (e) {
            }
        },

        handleMonitor(data) {
            this.$router.push({path: '/vehicleMonitor', query: {data: JSON.stringify(data)}})
        }

    }
};
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

.bigbuttonsize {
    width: 106px;
    height: 40px;
    padding: 0 !important;
    font-size: 14px !important;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

.more-search {
    display: inline-block;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    cursor: pointer;
    position: relative;
    left: -15px;
}

.btn-color {
    color: #0D6CE4;
}


/*popup_marker弹窗标记*/
.popup_marker{

    /*   弹窗左右间距*/
    /deep/.el-dialog__header{
        padding: 24px 24px 0 24px;
    }
    /deep/ .el-dialog__body{
        padding: 24px;
    }
    /deep/.el-dialog__footer{
        padding: 0 24px 24px 24px;
    }
    /deep/.inline_element{
        margin-bottom: 16px;
    }
    /deep/.el-form-item--middle{
        margin-bottom: 16px;
    }
    /deep/.el-form-item{
        margin-bottom: 16px;
    }

    /*    弹窗文本框标题*/
    /deep/.el-form-item__label{
        line-height: 20px;
        margin-bottom: 8px;
        padding-bottom: 0;
    }
    /*    消除最后一排底部边框*/
    .el-form-item_bottom{
        margin-bottom: 0;
    }
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
.open-btn{
    .el-button {
        border: 1px solid #0D6CE4;
        color: #0D6CE4;
        background: #fff;
        padding: 8px 14px;
        line-height: 20px;
        font-size: 14px;
        width: 88px;
    }
}
</style>

