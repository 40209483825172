<template>
    <div :id="barId"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
    name: "barLine",
    props: {
        barId: {
            type: String,
            required: true,
            value: () => {}
        },
        barColor: {
            type: String,
            required: true,
            value: () => {
            }
        },
        // newPie: {
        //     type: Array,
        //     required: true,
        //     value: () => {
        //     }
        // },
    },
    data(){
        return{
            barChart: null
        }
    },
    mounted() {
        this.renderInit()
    },
    created() {
    },
    methods:{
        renderInit(){
            let dataArr1=[120, 200, 150, 80, 70, 110, 130]
            let dataArr2=[110, 100, 50, 180, 30, 100, 70]
            // dataArr1、dataArr2同series中需要传入的两个数组
            // 最大值 / 6 后向上取整=最大间隔
            // 最大间隔 * 6 = 最大值;
            let intervalY1 = Math.ceil(Math.max(...dataArr1) / 6);
            let intervalY2 = Math.ceil(Math.max(...dataArr2) / 6);
            // 因为暂时没数据。可设置为 0 时默认间隔,此处为 1
            intervalY1 = intervalY1 == 0 ? 1 : intervalY1;
            intervalY2 = intervalY2 == 0 ? 1 : intervalY2;

            if (this.barChart != null && this.barChart != "" && this.barChart != undefined) {
                this.barChart.dispose();//销毁
            }
            this.barChart = echarts.init(document.getElementById(this.barId));
            var option = {
                grid: {
                    top:'15%',
                    left: '1%',
                    right: '1%',
                    bottom: '0%',
                    containLabel: true
                },
                tooltip : {
                    show: true,
                    trigger: 'axis',
                    formatter (data) {
                        console.log(data,'params')
                        return (
                            '<span style="color:#585858;">' +
                            data[0].axisValue +
                            '<br>' +
                            '</span>' +
                            '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:rgba(55, 109, 247, 0.95);"></span>' +
                            '<span style="color:rgba(55, 109, 247, 0.95);">' +
                            data[0].seriesName +
                            '</span>' +
                            '<span style="color:#585858;margin-left:10px;">' +
                            data[0].data +
                            'kW·h' +
                            '</span>' +
                            '<br>' +
                            '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:rgba(102, 187, 131, 1);"></span>' +
                            '<span style="color:rgba(102, 187, 131, 1);">' +
                            data[1].seriesName +
                            '</span>' +
                            '<span style="color:#585858;margin-left:10px;">' +
                            data[1].data +
                            '元' +
                            '</span>'
                        );
                    }
                },
                legend: {
                    itemGap: 50,
                    data: ['用电电量', '电费'],
                    icon:'roundRect',
                    itemWidth:20,
                    itemHeight:20,
                },
                xAxis:
                    [
                        {
                            type: 'category',
                            data:['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel: {
                                color: '#585858'
                            },
                            axisTick:{
                                show:false
                            },
                            axisLine:{
                                lineStyle:{
                                    color:'#e0e6f1'
                                }
                            }
                        }
                    ],
                yAxis: [
                    {
                        type: 'value',
                        name: '用电电量（kW·h）',
                        axisLabel: {
                                color: '#585858' // 设置y轴字体颜色
                        },
                        nameTextStyle:{
                            color:'#2F3133',
                            padding:[0,0,0,35],		//表示[上,右,下,左]的边距
                        },
                        min: 0,
                        // 不想数据=max,可手动更改interval*7 或 (interval*6)+ 20
                        max: intervalY1 * 6, // 最大值
                        splitNumber: 6, // 坐标轴的分割段数(预估值)
                        interval: intervalY1, // 强制设置坐标轴分割间隔。
                    },
                    {
                        type: 'value',
                        name: '电费（元）',
                        axisLabel: {
                                color: '#585858' // 设置y轴字体颜色
                        },
                        nameTextStyle:{
                            color:'#2F3133',
                            // padding:[0,0,0,35],		//表示[上,右,下,左]的边距
                        },
                        min: 0,
                        max: intervalY2 * 6, // 最大值
                        splitNumber: 6, // 坐标轴的分割段数(预估值)
                        interval: intervalY2, // 强制设置坐标轴分割间隔。
                    }
                ],
                series: [
                    {
                        name:'用电电量',
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar',
                        yAxisIndex: 0
                    },
                    {
                        name:'电费',
                        data: [110, 100, 50, 180, 30, 100, 70],
                        type: 'line',
                        yAxisIndex: 1,
                        symbol: 'none',
                        itemStyle:{
                            color: "#53B997",//改变折线点的颜色
                        },
                        lineStyle: {
                                color: "#53B997", // 线条颜色
                        },
                    }
                ],
                color:this.barColor,
            }
            this.barChart.setOption(option)
            let chart = this.barChart
            setTimeout(() => {
                window.addEventListener("resize", () => {
                    chart.resize();
                })
            }, 200)
        }
    }
}
</script>

<style scoped >

</style>
