<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch"
                 label-width="68px">
            <el-form-item label="字典名称" prop="dictType">
                <el-select v-model="queryParams.dictType">
                    <el-option
                        v-for="item in typeOptions"
                        :key="item.id"
                        :label="item.dictName"
                        :value="item.dictType"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="字典标签" prop="dictLabel">
                <el-input
                    v-model="queryParams.dictLabel"
                    placeholder="请输入标签名称"
                    clearable
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="标签状态" prop="status">
                <el-select v-model="queryParams.status" placeholder="数据状态" clearable>
                    <el-option
                        v-for="dict in dict.type.sys_dict_status"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                    />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                    type="primary"
                    plain
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasCode="['system:dict:add']"
                >新增
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="success"
                    plain
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasCode="['system:dict:edit']"
                >修改
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasCode="['system:dict:delete']"
                >删除
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="warning"
                    plain
                    icon="el-icon-close"
                    size="mini"
                    @click="handleClose"
                >关闭
                </el-button>
            </el-col>
        </el-row>

        <el-table :data="dataList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="编号" align="center" prop="id"/>
            <el-table-column label="标签名称" align="center" prop="dictLabel">
            </el-table-column>
            <el-table-column label="标签编码" align="center" prop="dictValue"/>
            <el-table-column label="字典排序" align="center" prop="dictSort"/>
            <el-table-column label="状态" align="center" prop="status">
                <template slot-scope="scope">
                    <dict-tag :options="dict.type.sys_dict_status" :value="scope.row.status"/>
                </template>
            </el-table-column>
            <el-table-column label="备注" align="center" prop="remark" :show-overflow-tooltip="true"/>
            <el-table-column label="创建时间" align="center" prop="createTime" width="180">
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-edit"
                        @click="handleUpdate(scope.row)"
                        v-hasCode="['system:dict:edit']"
                    >修改
                    </el-button>
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-delete"
                        @click="handleDelete(scope.row)"
                        v-hasCode="['system:dict:delete']"
                    >删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
        />

        <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="字典编码">
                    <el-input v-model="form.dictType" :disabled="true"/>
                </el-form-item>
                <el-form-item label="标签名称" prop="dictLabel">
                    <el-input v-model="form.dictLabel" placeholder="请输入数据标签"/>
                </el-form-item>
                <el-form-item label="标签值" prop="dictValue">
                    <el-input v-model="form.dictValue" placeholder="请输入数据键值"/>
                </el-form-item>
                <el-form-item label="显示排序" prop="dictSort">
                    <el-input v-model="form.dictSort" type="number"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio
                            v-for="dict in dict.type.sys_dict_status"
                            :key="dict.value"
                            :label="dict.value"
                        >{{ dict.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="form.remark" type="textarea" placeholder="请输入内容"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import {
    getDictDataList, getDictData, deleteDictData, addDictData, editDictData,
    optionDictTypeSelect, getDictType, deleteDictType, editDictType, addDictType
} from "@/utils/dict";

export default {
    name: "dictData",
    dicts: ['sys_dict_status'],
    data() {
        return {
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 字典表格数据
            dataList: [],
            // 默认字典类型
            defaultDictType: "",
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 类型数据字典
            typeOptions: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                dictLabel: undefined,
                dictType: undefined,
                status: undefined
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                dictLabel: [
                    {required: true, message: "数据标签不能为空", trigger: "blur"}
                ],
                dictValue: [
                    {required: true, message: "数据键值不能为空", trigger: "blur"}
                ],
                dictSort: [
                    {required: true, message: "数据顺序不能为空", trigger: "blur"}
                ]
            }
        };
    },
    created() {
        const dictId = this.$route.params && this.$route.params.dictId;
        this.getType(dictId);
        this.getTypeList();
    },

    methods: {
        getType(dictId) {
            getDictType(dictId).then(response => {
                this.queryParams.dictType = response.data.data.dictType;
                this.defaultDictType = response.data.data.dictType;
                this.getList();
            });
        },

        getTypeList() {
            optionDictTypeSelect().then(response => {
                this.typeOptions = response.data.data;
            });
        },

        getList() {
            var data = {
                dictLabel: this.queryParams.dictLabel,
                dictType: this.queryParams.dictType,
                status: this.queryParams.status
            }

            var url = "page=" + this.queryParams.pageNum + "&size=" + this.queryParams.pageSize;
            data.url = url;
            getDictDataList(data).then(response => {
                this.dataList = response.data.data.data.list;
                this.total = response.data.data.data.total;
            });
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        reset() {
                this.form = {
                    id: undefined,
                    dictLabel: undefined,
                    dictValue: undefined,
                    dictSort: 0,
                    status: "0",
                    remark: undefined
                };
        },

        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },

        handleClose() {
            this.$router.push('/dict-type')
        },

        resetQuery() {
            this.reset();
            this.queryParams.dictType = this.defaultDictType;
            this.queryParams.status = undefined;
            this.queryParams.dictLabel = undefined;
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加字典数据";
            this.form.dictType = this.queryParams.dictType;
        },

        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length != 1
            this.multiple = !selection.length
        },

        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids
            getDictData(id).then(response => {
                this.form = response.data.data;
                this.open = true;
                this.title = "修改字典数据";
            });
        },

        submitForm: function () {
            this.$refs.form.validate(async valid => {
                if (!valid) return this.$msg.info('信息不能为空!');
                try {
                    if (this.form.id != undefined || this.form.id != null) {
                        const response = await editDictData(this.form);
                        if (response.data.code != 200) {
                            this.$msg.error('修改失败! ' + response.data.msg)
                        } else {
                            this.$msg.success('修改成功')
                            this.open = false;
                            this.getList();
                        }
                    } else {
                        const response = await addDictData(this.form);
                        if (response.data.code != 200) {
                            this.$msg.error('新增失败! ' + response.data.msg)
                        } else {
                            this.$msg.success('新增成功')
                            this.open = false;
                            this.getList();
                        }
                    }
                } catch (e) {
                }
            });
        },

        async handleDelete(row) {
            const ids = row.id || this.ids;
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除选中项, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const response = await deleteDictData(ids);
                if (response.data.code != 200) {
                    this.$msg.error('删除失败! ' + response.data.msg)
                } else {
                    this.$msg.success('删除成功')
                    this.getList();
                }
            } catch (e) {
                console.log(e);
            }
        },

    }
};
</script>
