3<template>
  <!-- 设备型号基础信息 -->
  <el-card>
    <el-row>
      <el-col :span="4">
        <el-input placeholder="请输入设备型号名称" v-model.trim="queryInput"></el-input>
      </el-col>
      <el-col :span="4">
        <el-select placeholder="请选择设备型号状态" v-model="statusSelect">
          <el-option
            v-for="item in selectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="10">
        <el-button type="primary" @click="queryFunction">查询</el-button>
        <el-button @click="restFunction">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-button type="primary" icon="el-icon-plus" size="small" @click="addDialog = true">新增</el-button>
      <el-button type="primary" icon="el-icon-upload2" size="small" @click="dialogImport = true">批量导入</el-button>
      <el-button
        type="primary"
        icon="el-icon-download"
        size="small"
        @click="download"
      >批量导出</el-button>
      <el-button size="small" icon="el-icon-delete" type="danger" @click="delFunctionOfbtytype">选中删除</el-button>
    </el-row>
    <el-row>
      <el-alert :closable="false">
        <span slot="title">
          已选择{{ selectList.length }}项
          <span
            @click="toggleSelection"
            style="margin-left:10px;color:#409eff;cursor:pointer;"
          >清空</span>
        </span>
      </el-alert>
    </el-row>
    <el-row>
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%"
        @selection-change="selectionChange"
        height="500"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column type="index" :index="renIndex" label="编号" width="50"></el-table-column>
        <el-table-column
          prop="eqmTypeCode"
          label="设备型号编码"
          min-width="150"
        ></el-table-column>
        <el-table-column prop="eqmTypeName" label="设备型号名称" min-width="110"></el-table-column>
        <el-table-column prop="eqmModeNo" label="设备类型编号" min-width="110"></el-table-column>
        <el-table-column prop="eqmModeName" label="设备类型名称" min-width="110"></el-table-column>
        <el-table-column prop="eqmModeState" label="设备型号状态" min-width="110">
          <template slot-scope="scope">
            <div :class="scope.row.eqmModeState==='1' ? 'status-item2' : 'status-item1'">
              <span></span>
              <span>{{ scope.row.eqmModeState==='1' ? "启用" : "禁用" }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="eqmTypeDesc" label="设备型号描述" min-width="110"></el-table-column>

        <el-table-column prop="creator" label="创建者"></el-table-column>
        <el-table-column prop="createTime" :formatter="$dateFormat" label="创建日期" width="110"></el-table-column>
        <el-table-column prop="modifyTime" :formatter="$dateFormat" label="修改日期" width="110"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button
              icon="el-icon-edit"
              size="small"
              @click="handleEdit(scope.row)"
              type="primary"
            >编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[20, 25, 30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-row>
    <!-- 导入 -->
  <el-dialog title="请选择导入文件" :visible.sync="dialogImport" width="30%" @close="closeLoad">
      <el-upload
        class="upload-demo"
        ref="upload"
        action
        :on-change="handleChange"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :before-upload="beforeUpload"
        :file-list="fileList"
        :limit="1"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        :auto-upload="false"
      >
        <el-button size="small" type="primary">请选择 xlsx / xls 文件</el-button>
      </el-upload>
      <span slot="footer" class="dowload dialog-footer">
        <div>
          <el-button @click="dowtemplate">下载模版</el-button>
          <span>请不要擅自修改或删除模版</span>
        </div>
        <div>
          <el-button @click="dialogImport = false">取消</el-button>
        <el-button type="primary" :disabled="uploadDisabled" style="margin-top: 16px" @click="handleUpload">开始上传</el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 新增 -->
    <el-dialog :title="titleName? '添加设备型号信息' : '修改设备型号信息'" :visible.sync="addDialog" width="40%" @close="close">
      <el-form size="small" ref="addFrom" :model="addFrom" label-width="120px" :rules="addRules">
        <el-row>
          <el-col style="margin:0;" :span="12">
            <el-form-item label="设备型号编码:" prop="eqmTypeCode">
              <el-input v-model="addFrom.eqmTypeCode" />
            </el-form-item>
            <el-form-item label="设备型号名称:" prop="eqmTypeName">
              <el-input v-model="addFrom.eqmTypeName" />
            </el-form-item>
            <el-form-item label="设备类型编号:" prop="eqmModeNo">
              <el-input v-model="addFrom.eqmModeNo" />
            </el-form-item>
            <el-form-item label="设备类型名称:" prop="eqmModeName">
              <el-input v-model="addFrom.eqmModeName" />
            </el-form-item>
          </el-col>
          <el-col style="margin:0;" :span="12">
            <el-form-item label="车辆型号状态:" prop="eqmModeState">
              <el-select v-model="addFrom.eqmModeState">
                <el-option
                  v-for="item in selectOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备型号描述:" prop="eqmTypeDesc">
              <el-input v-model="addFrom.eqmTypeDesc" />
            </el-form-item>
            <el-form-item label="创建者:">
              <span>{{addFrom.creator}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取消</el-button>
        <el-button type="primary" @click="addOrUpFunction">确定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  addNewEqmTypeBase,
  delEqmTypeBase,
  importExcelEqmTypeBases,
  getAllEqmTypeBases,
  upEqmTypeBase,
  getEqmTypeBaseOfStatus,
  getEqmTypeAll
} from "@/utils/basicInformationOfEquipmentModelApi";
import  moment from 'moment'
export default {
  name:'facilityName',
  data() {
    return {
      total: 0,
      pageSize: 20,
      pageNum: 1,
      resPageNum:1,
      tableData: [],
      addDialog: false,
      titleName: true,
      isPage:true,
      addFrom: {
        creator: "",
        eqmModeName: "",
        eqmModeNo: "",
        eqmModeState: null,
        eqmTypeCode: "",
        eqmTypeDesc: "",
        eqmTypeName: "",
      },
      addRules: {
        eqmModeName: [
          { required: true, message: "请输入类型名称", trigger: "blur" },
        ],
        eqmModeNo: [
          { required: true, message: "请输入类型编号", trigger: "blur" },
        ],
        eqmModeState: [
          { required: true, message: "请选择状态", trigger: "blur" },
        ],
        eqmTypeCode: [
          { required: true, message: "请输入型号编码", trigger: "blur" },
        ],
        eqmTypeDesc: [
          { required: true, message: "请输入型号描述", trigger: "blur" },
        ],
        eqmTypeName: [
          { required: true, message: "请输入型号名称", trigger: "blur" },
        ],
      },
      selectOptions: [
        {
          value: "1",
          label: "启用",
        },
        {
          value: "0",
          label: "禁用",
        },
      ],
      queryInput: "",
      statusSelect: "",
      selectList: [],
      facilityList:[],
      columns: [
        { label: "设备型号编码", prop: "eqmTypeCode" },
        { label: "设备型号名称", prop: "eqmTypeName" },
        { label: "设备类型编号", prop: "eqmModeNo" },
        { label: "设备类型名称", prop: "eqmModeName" },
        { label: "设备型号状态", prop: "eqmModeState" },
        { label: "设备型号描述", prop: "eqmTypeDesc" }
      ],
       dialogImport:false,
      uploadDisabled:true,
      fileList:[]
    };
  },
  computed: {
    userName() {
      return JSON.parse(this.$store.state.user).username;
    },
    downloadList(){
       if(this.selectList.length === 0){
           return this.facilityList.map(item=>{
              item.eqmModeState = item.eqmModeState === '0'?'禁用':'启用'
              item.createTime = item.createTime !== null?moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'):null
              item.modifyTime = item.modifyTime !== null?moment(item.modifyTime).format('YYYY-MM-DD HH:mm:ss'):null
              return item
           })
       }else{
          return this.selectList.map(item=>{
              item.carTypeState = item.carTypeState === 0?'禁用':'启用'
              item.createTime = item.createTime !== null?moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'):null
              item.modifyTime = item.modifyTime !== null?moment(item.modifyTime).format('YYYY-MM-DD HH:mm:ss'):null
              return item
           })
       }
    }
  },
  created() {
    this.getEqmTypeBases();
    this.getFacility()
    this.addFrom.creator = this.userName;
  },
  mounted() {},
  // 组件方法
  methods: {
    close() {
      const data = {
        creator: this.userName,
      };
      this.$refs.addFrom.resetFields();
      this.addFrom = data;
       this.titleName = true
      this.addDialog = false
    },
   async getFacility(){
      const res =await getEqmTypeAll()
      this.facilityList = res.data.data
    },
    async getEqmTypeBases() {
      let data = { page: this.pageNum, size: this.pageSize };
      const res = await getAllEqmTypeBases(data);
      if (res.status != 200) {
        return this.$msg.error("获取失败");
      }
      let cdata = res.data.data.content;
      this.tableData = cdata;
      this.total = res.data.data.totalElements;
    },
    handleEdit(row) {
      this.addDialog = true
       this.titleName = false
      let lsData = this.copyDeep(row)
      this.addFrom = lsData
    },
    copyDeep(templateData) {
      // templateData 是要复制的数组或对象，这样的数组或者对象就是指向新的地址的
      return JSON.parse(JSON.stringify(templateData));
    },

    async delFunctionOfbtytype() {
      let rows = this.selectList;
      if (rows.length === 0) {
        this.$msg({
          type: "info",
          message: "请选中删除文件",
        });
      } else {
        const config = await this.$MessageBox.confirm(
          "此操作将永久删除选中项, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch((e) => e);
        if (config !== "confirm") return;
        const arrayId = rows.map((item) => {
          return item.id;
        });
        const res = await delEqmTypeBase(arrayId);
        if (res.status !== 200) return this.$msg.error("删除失败");
        this.tableData = this.tableData.filter((item, i) => {
          return item.id !== arrayId[i];
        });
        this.$refs.multipleTable.clearSelection();
        if (this.pageNum > 1 && this.tableData.length === 0) {
          this.pageNum = this.pageNum - 1;
        }
        this.$msg.success("删除成功");
        this.getEqmTypeBases();
      }
    },
    async addOrUpFunction() {
      this.$refs.addFrom.validate(async (valid) => {
        if (!valid) return this.$msg("内容不能为空");
        try {
          let postData = this.copyDeep(this.addFrom);
          if (this.titleName) {
            const res = await addNewEqmTypeBase(postData);
            if (res.status !== 200 && res.data.message === '已存在') return this.$msg.warning("设备型号已存在，请重新添加!")
            if (res.status != 200 && res.data.message !== '已存在') return this.$msg.error("添加设备型号失败");
            this.$msg.success("添加设备型号成功");
            this.addDialog = false;
            this.getEqmTypeBases();
          }else{
             const res = await upEqmTypeBase(postData);
            if (res.status != 200) return this.$msg.error("编辑设备型号失败");
            this.$msg.success("编辑设备型号成功");
            this.addDialog = false;
            this.getEqmTypeBases();
          }
        } catch (e) {
        }
      });
    },
    renIndex(index){
      return (this.pageNum -1 ) * this.pageSize + index +1
    },
    async queryFunction() {
      let data = {
        name: this.queryInput,
        state: this.statusSelect,
        page: this.resPageNum,
        size: this.pageSize,
      };
      let res = await getEqmTypeBaseOfStatus(data);
      if (res.status != 200) return this.$msg.error("查询失败");
      let cdata = res.data.data.content;
      this.tableData = cdata;
      this.total = res.data.data.totalElements;
      this.isPage = false
    },
    restFunction() {
      this.statusSelect = "";
      this.queryInput = "";
      this.pageNum = 1
      this.pageSize = 20
      this.resPageNum = 1
      this.isPage = true
      this.getEqmTypeBases();
    },
    // 分页事件
    async handleSizeChange(val) {
      this.pageSize = val;
      if(this.isPage){
         this.getEqmTypeBases();
      }else{
        this.queryFunction()
      }

    },
    async handleCurrentChange(val) {
      this.pageNum = val;
      if(this.isPage){
         this.getEqmTypeBases();
      }else{
        this.queryFunction()
      }
    },
    toggleSelection() {
      this.$refs.multipleTable.clearSelection();
    },
    selectionChange(rows) {
      this.selectList = rows;
    },
   async download() {
       const confirm =await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e=>e)
        if(confirm !== 'confirm')return
        const list = [
        { label: "设备型号编码", prop: "eqmTypeCode" },
        { label: "设备型号名称", prop: "eqmTypeName" },
        { label: "设备类型编号", prop: "eqmModeNo" },
        { label: "设备类型名称", prop: "eqmModeName" },
        { label: "设备型号状态", prop: "eqmModeState" },
        { label: "设备型号描述", prop: "eqmTypeDesc" },
        { label: "创建者", prop: "creator" },
         { label: "创建日期", prop: "createTime" },
        { label: "修改日期", prop: "modifyTime" }
      ]
         this.$download(this.downloadList,list,'设备型号管理')

    },
      //下载模版
    dowtemplate(){
      const list =[
        {
          eqmTypeCode:'00000',
          eqmTypeName:'00000',
          eqmModeNo:'00000',
          eqmModeName:'00000',
          eqmModeState:'启用1禁用0',
          eqmTypeDesc:'ps:设备类型编号和设备型号编码是唯一值'
        }
      ]
      this.$download(list, this.columns, "设备型号管理")
    },
      //清空上传的文件
    closeLoad(){
        this.uploadDisabled = true
         this.$refs.upload.clearFiles();
    },
    //上传文件改变
    handleChange(file,fileList){
        if(fileList.length > 0){
          this.uploadDisabled = false
          this.fileList = fileList
        }
    },
     //上传文件之前
    beforeUpload(file){
        let fileTemp = file.raw
       if(fileTemp){
         if(fileTemp.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
         fileTemp.type === "application/vnd.ms-excel"){
        }else{
          this.$msg.warning('附件格式错误，请删除后重新上传!')
        }
       }
    },
    //上传文件移除
    handleRemove(file,fileList){
      this.fileList = fileList
      if(fileList.length < 1){
          this.uploadDisabled = true
      }
    },
   //开始上传
   async handleUpload(){
        if(!this.fileList.length === 0)return this.$msg.info('请选择要上传的文件')
        let formData = new FormData()
        formData.append('file',this.fileList[0].raw)
        formData.append('creator',this.userName)
        const res =await importExcelEqmTypeBases(formData)
        if(res.status !== 200)return this.$msg.error('文件上传失败')
        this.$msg.success('文件上传成功')
        this.dialogImport = false
        this.getEqmTypeBases()
    },
    //删除文件之前的钩子
   async beforeRemove(file){
       return this.$MessageBox.confirm(`确定移除 ${file.name}？`)
    }
  },
};
</script>

<style lang="less" scoped>
.el-row {
  margin: 15px 0;

  .el-col {
    margin-right: 20px;
  }
}

.el-col {
  border-radius: 4px;
}
.el-select {
  width: 100%;
}

.bg-purple-dark {
  background: #287ef7;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.alert-text {
  color: #62b2ff;
  margin-left: 10px;
  cursor: pointer;
}
.status-item1 {
  > span {
    padding: 0 3px;
  }
  :first-child {
    width: 5px;
    height: 5px;
    display: inline-block;
    border-radius: 50%;
    background-color: #ff4949;
    transform: translateY(-2px);
  }
}
.status-item2 {
  > span {
    padding: 0 3px;
  }
  :first-child {
    width: 5px;
    height: 5px;
    display: inline-block;
    border-radius: 50%;
    background-color: #6798f9;
    transform: translateY(-2px);
  }
}
 .dowload{
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   >div{
     display: flex;
     align-items: center;
     &:first-child{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: flex-start;
       >span{
         font-size: 12px;
         color: #e10505;
       }
     }
     >button{
       margin-top: 0!important;
     }
   }
 }
</style>
