<template>
    <div :id="pieName"></div>
</template>
<script>
import * as echarts from "echarts";

export default {
    name: "pieGroup",
    props: {
        pieName: {
            type: String,
            required: true,
            value: () => {
            }
        },
        pieColor: {
            type: String,
            required: true,
            value: () => {
            }
        },
        // newPie: {
        //     type: Array,
        //     required: true,
        //     value: () => {
        //     }
        // },
    },
    data() {
        return {
            myChart: null
        }
    },
    created() {
    },
    mounted() {
        this.renderInit()
    },
    watch: {
        // newPie: {
        //     handler(newValue, oldValue) {
        //         if (this.myChart) {
        //             if (newValue) {
        //                 var option = this.myChart.getOption()
        //                 option.series[0].data = newValue.list
        //                 this.myChart.setOption(option)
        //             } else {
        //                 var option = this.myChart.getOption()
        //                 option.series[0].data = oldValue.list
        //                 this.myChart.setOption(option)
        //             }
        //         } else {
        //             this.renderInit()
        //         }
        //     },
        //     deep: true
        // },
    },
    methods: {
        renderInit() {
            // let list = this.newPie
            if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
                this.myChart.dispose();//销毁
            }
            this.myChart = echarts.init(document.getElementById(this.pieName));
            var option = {
                backgroundColor: "#fff",
                series: {
                    name: 'Access',
                    type: 'pie',
                    clockwise: false,
                    radius: [40, 35],
                    itemStyle: {
                        borderRadius: 10, //圆角角度
                        borderWidth: 2,//边框宽度

                        color: this.pieColor,
                        shadowColor: this.pieColor,
                        shadowBlur: 0,

                    },
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    },
                    emphasis: {
                        scale: false
                    },
                    data: [{
                        value: 40.88,
                        itemStyle: {
                            borderWidth: 50,
                        },
                        label: {
                            formatter: function (params) {
                                return '{value|'+params.value+ '%'+'}' +'\n'+ '{name|同比}';
                            },
                            rich:{
                                value: {
                                    fontSize: 16,
                                    color:this.pieColor,
                                },
                                name: {
                                    fontSize: 14,
                                    color:this.pieColor,
                                    padding:[8,0,0,0]
                                },
                            },
                            position: 'center',
                            show: true,
                            fontSize: '20',
                            fontWeight: 'bold',
                            color: this.pieColor
                        },
                    }, {
                        value: 60,
                        name: 'invisible',
                        itemStyle: {
                            color: '#EEEEEE',
                        }
                    }]
                },
            }
            this.myChart.setOption(option)
            /* 解决饼图hover阴影消失问题 */
            this.myChart.on('mouseover', e => {
                let op = this.myChart.getOption()
                this.myChart.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: e.dataIndex,
                    color: e.color
                })
                this.myChart.setOption(option, true)
            })
            let chart = this.myChart
            setTimeout(() => {
                window.addEventListener("resize", () => {
                    chart.resize();
                })
            }, 200)
        }
    }
}
</script>

<style scoped>

</style>
