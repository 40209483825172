
<template>
  <el-card>
    <el-row>
        <el-col :span="4">
        <el-input placeholder="请输入车牌号" v-model="informationVo.carNo"></el-input>
      </el-col>
        <el-col :span="4">
        <el-input placeholder="请输入车辆VIN码" v-model="informationVo.carVin"></el-input>
      </el-col>
       <el-col :span="10">
        <el-button type="primary" @click="getAll()">查询</el-button>
        <el-button @click="restFunction">重置</el-button>
      </el-col>
    </el-row>

<!--    <el-row>-->
<!--      <el-button type="primary" size="small" class="el-icon-plus" @click="addvehicle">新增</el-button>-->
<!--      <el-button class="el-icon-delete" @click="removeAll" type="danger" size="small">选中删除</el-button>-->
<!--    </el-row>-->
<!--    <el-row>-->
<!--      <el-alert show-icon :closable="false">-->
<!--        <span slot="title">-->
<!--          已选择{{ selectList.length }}项-->
<!--          <span @click="clean" class="clean">清空</span>-->
<!--        </span>-->
<!--      </el-alert>-->
<!--    </el-row>-->
    <el-table
      :data="tableData"
      style="width: 100%"
      ref="multipleTable"
      @selection-change="selectionChange"
      height="500"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column type="index"  :index="renIndex" label="编号" min-width="50"></el-table-column>
      <el-table-column prop="fcarNo" label="车牌号" min-width="100"></el-table-column>
      <el-table-column prop="fcarVin" label="车辆VIN码"  min-width="110"></el-table-column>
      <el-table-column prop="fcarModel" label="车辆型号" min-width="100"></el-table-column>
      <el-table-column prop="fbrand" label="品牌"></el-table-column>
      <el-table-column prop="fchassisNumber" label="底盘号码" min-width="150"></el-table-column>
      <el-table-column prop="fengineNumber" label="电动机号码" min-width="130"></el-table-column>

      <el-table-column prop="fpurchaseDate" label="购车日期" min-width="110" ></el-table-column>
      <el-table-column prop="floginNo" label="登录号码" min-width="130"></el-table-column>

      <el-table-column prop="floginType" label="登录方式">
        <template slot-scope="scope">
          <span v-if="scope.row.floginType === 1">私人车辆</span>
          <span v-else-if="scope.row.floginType === 2">运营车辆</span>
          <span v-else-if="scope.row.floginType === 3">平台自有车辆</span>
        </template>
      </el-table-column>
      <el-table-column prop="fcarApc" label="车辆外观" min-width="130">
        <template slot-scope="scope">
           <el-avatar shape="square" :size="50" fit="fill" :src="scope.row.carApc"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="ftime" label="注册时间" min-width="110" :formatter="$dateFormat"></el-table-column>
<!--      <el-table-column label="操作" min-width="180" fixed="right">-->
<!--        <template slot-scope="scope">-->
<!--          <el-button-->
<!--            size="small"-->
<!--            icon="el-icon-edit"-->
<!--            @click="handleEdit(scope.row)"-->
<!--            type="primary"-->
<!--          >编辑</el-button>-->
<!--          <el-button-->
<!--            size="small"-->
<!--            icon="el-icon-search"-->
<!--            @click="$router.push({path:'/vehicle-record',query:{data:JSON.stringify(scope.row)}})"-->
<!--          >查看</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>

    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[10,20, 25, 30]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPages"
      ></el-pagination>
    </el-row>

    <el-dialog
      :title="isVehicle?'新增车辆信息':'修改车辆信息'"
      :visible.sync="addDialog"
      @close="close"
      width="50%"
    >
      <el-form size="small" ref="addFrom" :model="addFrom" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="11">
            <el-form-item label="车牌号:" prop="carNo">
              <el-input @change="changeCarNo(addFrom.carNo)" v-model="addFrom.carNo"></el-input>
            </el-form-item>
            <el-form-item label="车辆性质:" prop="carProperty">
              <el-select v-model="addFrom.carProperty">
                <el-option
                  v-for="item in vehicleNature"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车辆VIN码:" prop="carVin">
              <el-input v-model="addFrom.carVin"></el-input>
            </el-form-item>
            <el-form-item label="车辆型号:" prop="carModel">
              <el-input v-model="addFrom.carModel"></el-input>
            </el-form-item>
            <el-form-item label="车辆厂家:" prop="producer">
              <el-input v-model="addFrom.producer"></el-input>
            </el-form-item>
            <el-form-item label="购车日期:" prop="purchaseDate">
              <template>
                <div class="block">
                  <el-date-picker
                    type="date"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="addFrom.purchaseDate"
                    placeholder="选择日期时间"
                  ></el-date-picker>
                </div>
              </template>
            </el-form-item>
            <el-form-item label="车辆外观:" >
               <template>
                 <Avater @on-avater="onAvater" @on-imgchange="imgchange" :uploadImg="uploadImg" :isAvater="isAvater" />
                  </template>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="品牌:" prop="brand">
              <el-input v-model="addFrom.brand"></el-input>
            </el-form-item>
            <el-form-item label="底盘号码:" prop="chassisNumber">
              <el-input v-model="addFrom.chassisNumber"></el-input>
            </el-form-item>
            <el-form-item label="电动机号码:" prop="engineNumber">
              <el-input v-model="addFrom.engineNumber"></el-input>
            </el-form-item>
            <el-form-item label="车辆识别码:" prop="identifyCode">
              <el-input v-model="addFrom.identifyCode"></el-input>
            </el-form-item>
            <el-form-item label="所有人:" prop="possessor">
              <el-input v-model="addFrom.possessor"></el-input>
            </el-form-item>
            <el-form-item label="审核进度:" prop="auditState">
              <el-select :disabled="auditStatus" v-model="addFrom.auditState">
                <el-option
                  v-for="item in reviewProgress"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="创建者:">
              <span>{{addFrom.creator}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="addFunction">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
  import {CarInfoPage} from "@/utils/operation2"
import {
  // getCarBaseAll,
  addCarBaseInfo,
  delCarBaseInfo,
  getCarBaseInfoOfPage,
  upCarBaseInfo,
  getCarBaseInfoOfState
} from "@/utils/vehiclApi";
  import * as myStore from "@/utils/stroage";
// import Avater from '@/components/avater/index'

export default {
  name: "UserCar",
  components:{
      // Avater
  },
  data() {
    var licence = (rule, value, cb) => {
      const Reg = /^([京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][1-9DF][1-9ABCDEFGHJKLMNPQRSTUVWXYZ]\d{3}[1-9DF]|[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][\dABCDEFGHJKLNMxPQRSTUVWXYZ]{5})$/;
      if (value !== "") {
        Reg.test(value) ? cb() : cb(new Error("车牌号格式错误"));
      } else {
        cb("请输入车牌号");
      }
    };
    //定义数据
    return {
      informationVo:{},
      carValue: "",
      carVinValue: "",
      carModelValue: "",
      posserValue: "",
      stateValue: "",
      carType: "",
      query: {
        pageNum: 1,
        pageSize: 10,
        resPageNum:1
      },
      vehicleNature: [
        { value: 1, label: "私人车辆" },
        { value: 2, label: "运营车辆" },
        { value: 3, label: "平台自有车辆" },
      ],
      reviewProgress: [
        { value: 0, label: "未注册" },
        { value: 1, label: "审核中" },
        { value: 2, label: "审核通过" },
        { value: 3, label: "审核不通过" },
      ],
      options: [
        // 0：未注册；1：审核中；2：审核通过；3：审核不通过
        {
          value: "0",
          label: "未注册",
        },
        {
          value: "1",
          label: "审核中",
        },
        {
          value: "2",
          label: "审核通过",
        },
        {
          value: "3",
          label: "审核不通过",
        },
      ],
      imgAvatar:'',
      isPage: true,
      addDialog: false,
      isVehicle: true,

      selectList: [],
      tableData: [],
      msgListAll: [],
      stationNoList:[],
      totalPages: 0,
      auditStatus: false,
      addFrom: {
        auditState: null,
        brand: "",
        carApc: "",
        carModel: "",
        carNo: "",
        carProperty: null,
        carVin: "",
        chassisNumber: "",
        creator: "",
        engineNumber: "",
        identifyCode: "",
        possessor: "",
        producer: "",
        purchaseDate: null,
      },
      rules: {
        auditState: [
          { required: true, message: "请选择审核进度", trigger: "change" },
        ],
        carModel: [
          { required: true, message: "请输入车辆型号", trigger: "blur" },
        ],
        carProperty: [
          { required: true, message: "请选择车辆性质", trigger: "change" },
        ],
        carVin: [
          { required: true, message: "请输入车辆VIN码", trigger: "blur" },
        ],
        carNo: [{ required: true, validator: licence, trigger: "blur" }],
        producer: [
          { required: true, message: "请输入车辆厂家", trigger: "blur" },
        ],
        purchaseDate: [
          { required: true, message: "请选择购车日期", trigger: "change" },
        ]
      },
      coulm: [
        { prop: "carNo", label: "车牌号" },
        { prop: "rfidId", label: "卡号" },
        { prop: "carVin", label: "车辆VIN码" },
        { prop: "carModel", label: "车辆型号" },
        { prop: "brand", label: "品牌" },
        { prop: "chassisNumber", label: "底盘号码" },
        { prop: "engineNumber", label: "电动机号码" },
        { prop: "identifyCode", label: "车辆识别码" },
        { prop: "purchaseDate", label: "购车日期" },
        { prop: "producer", label: "车辆厂家" },
        { prop: "possessor", label: "所有人" },
        { prop: "carProperty", label: "车辆性质" },
        { prop: "carApc", label: "车辆外观" },
        { prop: "auditState", label: "审核进度" },
        { prop: "creator", label: "创建者" },
        { prop: "createTime", label: "创建时间" },
        { prop: "modifyTime", label: "修改时间" },
      ],
      fileAvater:null,
      isAvater:true,
      uploadImg:null
    };
  },
  computed: {
    userName() {
      return JSON.parse(this.$store.state.user).username;
    },
    // imgPath(){
    //   // return this.$store.state.imgURL2
    // }
  },
  created() {
    //进入页面自动运行
    this.getAll();
    // this.getMsgListAll();
    this.addFrom.creator = this.userName;
  },
  methods: {
    //自定义方法
    async getAll() {
     try {
      let res = await CarInfoPage(this.query.pageNum, this.query.pageSize,this.informationVo);
      console.log(res)
      if (res.data.code !== 20000) return this.$msg.error("获取车辆信息失败");
      // let datas = res.data.data.content;
      this.totalPages = res.data.data.rows.total;
      this.tableData =  res.data.data.rows.records;
     } catch (error) {

     }
    },
    // async getMsgListAll() {
    //   const res = await getCarBaseAll();
    //   this.msgListAll = res.data.data;
    // },
    selectionChange(rows) {
      this.selectList = rows;
    },
    onAvater(file){
       this.fileAvater = file
    },
    imgchange(val){
         this.isAvater = val
    },
    addvehicle() {
      this.addDialog = true;
      this.auditStatus = true;
      this.addFrom.auditState = 0;
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      if (this.isPage) {
        this.getAll();
      } else {
        this.getAll();
      }
    },
    handleCurrentChange(val) {
      this.query.pageNum = val;
      if (this.isPage) {
        this.getAll();
      } else {
        this.query.resPageNum = val
        this.getAll();
      }
    },
    close() {
      const data = {
        creator: this.userName,
      };
      this.$refs.addFrom.resetFields();
      this.imgAvatar = ''
      this.addFrom = data;
      this.isVehicle = true;
       this.isAvater = false
        this.uploadImg = null
      this.fileAvater = null
      this.addDialog = false
    },
    clean() {
      this.$refs.multipleTable.clearSelection();
    },
    //新增与修改
    addFunction() {
      this.$refs.addFrom.validate(async (valid) => {
        if (!valid) return this.$msg.info("车辆信息不能为空");
        try {
           let data = JSON.parse(JSON.stringify(this.addFrom))
           this.fileAvater && (data.carApc = this.fileAvater)
          if (this.isVehicle) {
            let res = await addCarBaseInfo(data);

            if (res.status != 200 && res.data.message === '车辆基础信息已存在') return this.$msg.error("车辆vin码已存在");
            // if (res.status != 200 && res.data.message !== '车辆基础信息已存在') return this.$msg.error("添加失败");
            // this.$msg.success("添加成功");
            if (res.status != 200 && res.data.message !== '车辆基础信息已存在') return this.$msg.error("车辆基础信息添加失败");
            this.$msg.success("车辆基础信息添加成功");
            this.addDialog = false;
            this.getAll();
          } else {
            let res = await upCarBaseInfo(data);
            // if (res.status != 200) return this.$msg.error("修改失败");
            // this.$msg.success("修改成功");
            if (res.status != 200) return this.$msg.error("车辆基础信息修改失败");
            this.$msg.success("车辆基础信息修改成功");
            this.addDialog = false;
            this.getAll();
          }
        } catch (e) {
        }
      });
    },
     renIndex(index){
      return (this.query.pageNum -1 ) * this.query.pageSize + index +1
    },
    carstorage(){
       const newList = JSON.parse(getItem('vahicleMsg'))
        if(newList && newList[1].carNo === this.carValue && newList[0].index > 3){
              this.carValue = newList[1].carNo
              this.carVinValue = newList[1].carVin
              this.carModelValue = newList[1].carModel
              this.posserValue = newList[1].possessor
              this.stateValue = newList[1].auditState
              this.carType = newList[1].carProperty
        }
    },
    //修改信息填充
    handleEdit(row) {
      this.addDialog = true;
      this.isVehicle = false;
      this.auditStatus = false;
      let data = JSON.parse(JSON.stringify(row))
      this.uploadImg = data.carApc
      this.addFrom = data
      this.getAll();
    },
    //重置
    restFunction() {
    this.informationVo={}
      this.query ={
        pageNum:1,
        pageSize:10,
        resPageNum:1
      }
      this.getAll();
    },
    changeCarNo(val) {
      this.addFrom.carNo = val.toUpperCase();
    },
    async removeAll() {
      if (this.selectList.length === 0)
        return this.$msg.info("请选择需要删除的车辆");
      const confirm = await this.$MessageBox.confirm(
        "此操作将删除选中的车辆?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );
      if (confirm !== "confirm") return;
      const list = this.selectList.map((item) => {
        return item.carNo;
      });
      const res = await delCarBaseInfo(list);
      if (res.status !== 200) return this.$msg.error("删除失败");
      this.tableData = this.tableData.filter((item, i) => {
        return item.carNo !== list[i];
      });
      this.$refs.multipleTable.clearSelection();
      if (this.query.pageNum > 1 && this.tableData.length === 0) {
        this.query.pageNum = this.query.pageNum - 1;
      }
      this.$msg.success("删除成功");
      this.getAll();
    },
  },
};
</script>

<style lang='less' scoped>
.el-row {
  margin: 15px 0;
  .el-col {
    margin-right: 20px;
  }
}
.el-select {
  width: 100%;
}
.vehicle-item {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 5px;
}
.clean {
  color: #409eff;
  margin-left: 10px;
  cursor: pointer;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.avatar-box{
  width: 100%;
   display: flex;
   align-items: flex-start;
    .avatar-item{
      width: 100px;
      height: 100px;
      border: 1px dashed #666;
      position: relative;
      border-radius: 10px;
      margin-right: 10px;

      >img{
        width: 100%;
        height: 100%;
         border-radius: 10px;
      }
      .icon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: 30px;
        color: #666;
      }
    }
    .avatar-item:hover{
      border: 1px dashed #409eff;
      .icon{
        color: #409eff;
      }
    }
}
</style>
