<template>
    <div class="app-container">
        <el-row class="main-flex">

            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="模型名称：">
                                <el-input placeholder="请输入模型名称" v-model.trim="query.name" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="17">
                        <div class="btn-end">
                    <span class="btn-rest">
                            <el-button @click="resFuntion">重置
                    </el-button>
                            </span>
                            <span class="btn-submit">
                       <el-button @click="getSearch" type="primary" plain>查询
                    </el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            电网电价模型列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                           已选择{{ batchNum.length }}项
                            <span @click="cleanList" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                              <el-button plain @click="removeAll"
                                         v-hasCode="['operation:elecprice:delete']">删除
                    </el-button>
                            </span>
                            <span class="add-btn">
                             <el-button type="primary" @click="addAreaElec"
                                        plain
                                        v-hasCode="['operation:elecprice:add']"> 新增
                    </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table ref="elecPriceTable" id="elecPrice" @selection-change="batchList" :data="tableData"
                                       :header-cell-style="headerStyleEvent"
                                      :cell-class-name="goodsInfo" height="100%">
                                <el-table-column align="center" type="selection" width="46" fixed></el-table-column>
                                <el-table-column align="center" type="index" :index="renIndex" label="编号"></el-table-column>
                                <el-table-column align="center" prop="operatorName" label="归属运营商" min-width="150"
                                                 :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column align="center" label="名称" prop="name" min-width="150"
                                                 :show-overflow-tooltip="true">
                                    <template slot-scope="scope">
                                        <a href="javascript:void(0);" @click="dateSet(scope.row)">{{ scope.row.name }}</a>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="jprice" label="尖电价" ></el-table-column>
                                <el-table-column align="center" prop="fprice" label="峰电价" ></el-table-column>
                                <el-table-column align="center" prop="pprice" label="平电价" ></el-table-column>
                                <el-table-column align="center" prop="gprice" label="谷电价" ></el-table-column>
                                <el-table-column align="center" prop="periodsAmount" label="时段个数"
                                                 min-width="110"></el-table-column>
                                <el-table-column align="center" label="操作" fixed="right" min-width="150">
                                    <template slot-scope="scope">

                                        <span class="edit-style" @click="dateSet(scope.row)" v-hasCode="['operation:elecprice:periods']">
                                                时段设置
                                            </span>
                                        <span @click="compileEqu(scope.row)" class="edit-style edit-margin"
                                                   v-hasCode="['operation:elecprice:update']">编辑
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>

                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10, 30,50,100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="totalPages">
                            </el-pagination>
                        </el-row>
                    </el-col>

                </div>

            </el-col>



        </el-row>

        <el-dialog :title="isAdd?'新增电网电价模型信息':'修改电网电价模型信息'"
            :visible.sync="addOrUpdate"
            width="40%" @close="close">
            <el-form size="middle" ref="elecPriceform" :rules="elecPriceRules" :model="informationForm" label-width="130px">
                <el-row>

                    <el-col >
                        <el-form-item label="归属运营商" prop="operatorId">
                            <TreeSelect v-model="informationForm.operatorId" :options="operatorTreeData.operatorOptions"
                                        placeholder="请选择运营商"/>
                        </el-form-item>

                        <el-form-item label="模型名称:" prop="name">
                            <el-input v-model="informationForm.name" maxlength="10" show-word-limit clearable></el-input>
                        </el-form-item>


                        <el-form-item label="尖电价:" prop="jprice">
                            <el-input v-model="informationForm.jprice"
                                      onkeyup="this.value=this.value.match(/\d+\.?\d{0,6}/)"
                                      onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                                      maxlength="10"
                                      show-word-limit clearable>
                            </el-input>
                        </el-form-item>


                        <el-form-item label="峰电价:" prop="fprice">
                            <el-input v-model="informationForm.fprice"
                                      onkeyup="this.value=this.value.match(/\d+\.?\d{0,6}/)"
                                      onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                                      maxlength="10"
                                      show-word-limit clearable>
                            </el-input>
                        </el-form-item>

                        <el-form-item label="平电价:" prop="pprice">
                            <el-input v-model="informationForm.pprice"
                                      onkeyup="this.value=this.value.match(/\d+\.?\d{0,6}/)"
                                      onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                                      maxlength="10"
                                      show-word-limit clearable>
                            </el-input>
                        </el-form-item>

                        <el-form-item label="谷电价:" prop="gprice">
                            <el-input v-model="informationForm.gprice"
                                      onkeyup="this.value=this.value.match(/\d+\.?\d{0,6}/)"
                                      onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                                      maxlength="10"
                                      show-word-limit clearable>
                            </el-input>
                        </el-form-item>

                        <el-form-item label="备注信息:" prop="remark" label-width="120px">
                            <el-input type="textarea" :rows="3" v-model="informationForm.remark" maxlength="100" show-word-limit></el-input>
                        </el-form-item>

                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="newequipemt">确 定</el-button>
      </span>
        </el-dialog>



    </div>
</template>

<script>
import '@/assets/font/font.css';
import {selectElecPricePage, updateElecPrice, addElecPrice, batchDelete} from '@/utils/operation'
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {operatorTree} from "@/utils/operatorApi";
import operatorTreeVue from "@/components/tree/operatorTree";

export default {
    name: 'areaElec',
    components: {TreeSelect, operatorTreeVue},
    data() {
        return {
            isopenShow: true,
            totalPages: 0,
            clientHeight: 0,
            startTime: '',
            endTime: '',
            informationVo: {},
            isEdit: false,
            isPage: true,//查询
            query: {
                name:"",
                pageNum: 1,
                pageSize: 10
            },
            addOrUpdate: false,
            isAdd: true,
            batchNum: [],
            tableData: [],
            allList: [],
            informationForm: {
                name:null,
                operatorId: null,
                jprice: null,
                fprice: null,
                pprice: null,
                gprice: null,
                remark:null
            },

            elecPriceRules: {
                operatorId: [
                    {required: true, message: '请选择归属运营商', trigger: 'blur'}
                ],
                name: [
                    {required: true, message: '请输入名称', trigger: 'blur'}
                ],
                jprice: [
                    {required: true, message: '请输入尖电价', trigger: 'blur'}
                ],
                fprice: [
                    {required: true, message: '请输入峰电价', trigger: 'blur'}
                ],
                pprice: [
                    {required: true, message: '请输入平电价', trigger: 'blur'}
                ],
                gprice: [
                    {required: true, message: '请输入谷电价', trigger: 'blur'}
                ],
            },

            //左侧 运营商-场站树
            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: null,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },

            //新增、修改时运营商树选择
            operatorTreeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: null,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },

        }
    },
    computed: {},
    created() {
        this.getOperatorTree();
        this.getAllPage()
        this.clientHeight = document.body.clientHeight - 270;

    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        //条件分页查询
        async getAllPage() {
            const data = {
                name:this.query.name,
                page: this.query.pageNum,
                size: this.query.pageSize,
            }
            if (this.treeData.operatorNameTree !== "" && this.treeData.operatorNameTree !== null) {
                data.operatorId = this.treeData.operatorIdTree;
            } else if (this.treeData.operatorOptions !== null && this.treeData.operatorOptions.length > 0) {
                data.operatorId = this.treeData.operatorOptions[0].id;
            }
            const result = await selectElecPricePage(data)
            if (result.data.code !== 200) return this.$msg.error('获取息失败!')
            this.tableData = result.data.data.list;
            this.totalPages = result.data.data.total;

        },

        getSearch() {
            this.query.pageNum = 1
            this.getAllPage()
        },

        //重置
        resFuntion() {
            this.treeData.operatorNameTree = "";
            this.treeData.operatorIdTree = null;
            this.resetQuery();
            this.resetForm();
            this.isEdit = false;
            this.getAllPage()
        },

        resetQuery(){
            this.query = {
                name:"",
                pageNum: 1,
                pageSize: 10
            };
        },

        resetTreeData() {
            this.treeData = {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: null,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            };
        },

        resetForm() {
            this.informationForm = {
                name:null,
                operatorId: null,
                jprice: null,
                fprice: null,
                pprice: null,
                gprice: null,
                remark:null
            }
        },

        batchList(list) {
            this.batchNum = list
        },
        close() {
            this.resetForm();
            this.isAdd = true
            this.addOrUpdate = false
            this.$refs['elecPriceform'].resetFields();
        },

        //新增与修改
        newequipemt() {
            this.$refs.elecPriceform.validate(async valid => {
                    if (!valid) return this.$msg.info('信息不能为空!')
                    try {
                        var data = this.informationForm;
                        if (this.isAdd) {
                            const res = await addElecPrice(data)
                            if (res.data.code !== 200) {
                                return this.$msg.error('新增电网电价模型失败!' + res.data.msg)
                            }
                            this.$msg.success('新增电网电价模型成功!')
                            this.addOrUpdate = false
                            this.getAllPage()
                        } else {
                            const res = await updateElecPrice(data)
                            if (res.data.code !== 200) {
                                return this.$msg.error('修改电网电价模型失败!' + res.data.msg)
                            } else {
                                this.$msg.success('修改电网电价模型成功!')
                                this.addOrUpdate = false
                                this.getAllPage()
                            }
                        }
                    } catch
                        (error) {
                    }
                }
            )

        },

        dateSet(e){
            this.$router.push({
                path: '/elecDate',
                query:{
                    id:e.id,
                    name:e.name
                }
            })
        },

        //修改信息填充
        async compileEqu(row) {
            this.isEdit = true;
            this.addOrUpdate = true;
            this.isAdd = false;
            this.informationForm = row;
        },

        handleSizeChange(val) {
            this.query.pageSize = val
            if (this.isPage) {
                this.getAllPage()
            } else {
                this.checkEqui()
            }
        },
        handleCurrentChange(val) {
            this.query.pageNum = val
            if (this.isPage) {
                this.getAllPage()
            } else {
                this.checkEqui()
            }
        },

        cleanList() {
            this.$refs.elecPriceTable.clearSelection()
        },
        addAreaElec() {
            this.addOrUpdate = true
            this.isEdit = false
            this.resetForm();
        },

        //批量删除
        async removeAll() {
            if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除选中项, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const list = this.batchNum.map(item => {
                    return item.id
                })
                const res = await batchDelete(list)
                if (res.data.code !== 200) {
                    return this.$msg.error('删除失败!' + res.data.msg)
                }
                this.tableData = this.tableData.filter((item, i) => {
                    return item.id !== list[i]
                })
                this.$refs.elecPriceTable.clearSelection()
                if (this.query.pageNum > 1 && this.tableData.length === 0) {
                    this.query.pageNum = this.query.pageNum - 1
                }
                this.$msg.success('删除成功')
                this.getAllPage()
            } catch (e) {
                console.log(e);
            }
        },


        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.operatorTreeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.data.code !== 200) return this.$msg.error("获取运营商信息失败");
                this.operatorTreeData.operatorOptions.push(res.data.data)
                this.operatorTreeData.operatorIdTree = res.data.data.id
                this.operatorTreeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },

        getTreeData(data) {
            this.treeData = data
        },



    },

    watch: {
    },


}
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #dddee0; /*滚动条的背景颜色*/
    }
}

.inner-container {
    // min-width: 3000px;
    height: var(--tableHeight);
    overflow: hidden;
}

.el-select {
    width: 100%;
}

.el-col {
    border-radius: 4px;
}


.vue-treeselect__control-arrow-container {
    padding-left: 16px;
}

.vue-treeselect--single .vue-treeselect__input {
    height: 40px;
    line-height: 40px;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}
.edit-margin {
    margin-right: 16px;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>

