<template>
  <!-- 区域信息管理 -->
  <el-card>
    <el-row>
      <el-col :span="4">
        <el-cascader
          v-model="cascaderValue"
          :options="address_data"
          :props="{checkStrictly: true}"
          placeholder="请选择省市区"
        ></el-cascader>
      </el-col>
      <el-col :span="5">
        <el-button type="primary" @click="onGetAreaCodeOrName">查询</el-button>
        <el-button @click="restFunction">重置</el-button>
      </el-col>
    </el-row>

    <!-- 按钮 -->
    <el-row>
      <el-button size="small" type="primary" icon="el-icon-plus" @click="dialogVisible2 = true">新增</el-button>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-upload2"
        @click="dialogImport = true"
      >批量导入</el-button>

      <el-button
        size="small"
        type="primary"
        icon="el-icon-download"
        @click="download"
      >批量导出</el-button>
      <el-button size="small" icon="el-icon-delete" type="danger" @click="handleDelete" style>选中删除</el-button>
    </el-row>
    <el-row class="alert">
      <el-alert :closable="false">
        <span slot="title">
          已选择{{ selectList.length }}项
          <span
            @click="toggleSelection"
            style="margin-left:10px;color:#409eff;cursor:pointer;"
          >清空</span>
        </span>
      </el-alert>
    </el-row>
    <el-row>
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%"
        @selection-change="selectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column type="index" :index="renIndex" label="编号" min-width="50"></el-table-column>
        <el-table-column prop="areaCode" label="区域编号" min-width="80">
          <template slot-scope="scope">
            {{areaCodeRender(scope.row.areaCode)}}
          </template>
        </el-table-column>
        <el-table-column prop="areaName" :show-overflow-tooltip="true" label="区域名称"  min-width="100"></el-table-column>
        <el-table-column prop="areaDesc" :show-overflow-tooltip="true" label="区域描述" min-width="100"></el-table-column>
        <el-table-column prop="admRegionOrNot" label="是否行政区域" min-width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.admRegionOrNot === 1">是</span>
            <span v-else-if="scope.row.admRegionOrNot === 0">否</span>
          </template>
        </el-table-column>
        <el-table-column prop="regionCode" label="区划代码" min-width="80">
          <template slot-scope="scope">
            {{areaCodeRender(scope.row.regionCode)}}
          </template>
        </el-table-column>
        <el-table-column prop="higherId" label="上级编号" min-width="80">
          <template slot-scope="scope">
            {{stringorArr(scope.row.higherId)}}
          </template>
        </el-table-column>
        <el-table-column prop="areaRank" label="区域级别" min-width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.areaRank === 1">省</span>
            <span v-else-if="scope.row.areaRank === 2">市</span>
            <span v-else-if="scope.row.areaRank === 3">县</span>
            <span v-else-if="scope.row.areaRank === 4">乡</span>
            <span v-else>村</span>
          </template>
        </el-table-column>
        <el-table-column prop="areaState" label="区域状态" min-width="100">
          <template slot-scope="scope">
            <div :class="scope.row.areaState === 1 ? 'status-item2' : 'status-item1'">
              <span></span>
              <span>{{ scope.row.areaState === 1? "启用" : "禁用" }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="100" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="small"
              @click="handleEdit(scope.row)"
            >编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[20, 25, 30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-row>
    <!-- 批量导入 -->
    <el-dialog title="请选择导入文件" :visible.sync="dialogImport" width="30%" @close="closeLoad">
      <el-upload
        class="upload-demo"
        ref="upload"
        action
        :on-change="handleChange"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :before-upload="beforeUpload"
        :file-list="fileList"
        :limit="1"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        :auto-upload="false"
      >
        <el-button size="small" type="primary">请选择 xlsx / xls 文件</el-button>
      </el-upload>
      <span slot="footer" class="dowload dialog-footer">
        <div>
          <el-button @click="dowtemplate">下载模版</el-button>
          <span>请不要擅自修改或删除模版</span>
        </div>
       <div>
          <el-button @click="dialogImport = false">取消</el-button>
        <el-button type="primary" :disabled="uploadDisabled" style="margin-top: 16px" @click="handleUpload">开始上传</el-button>
       </div>
      </span>
    </el-dialog>
    <!-- 新增 -->
    <el-dialog
      :title="addOrEdit ? '添加区域信息' : '修改区域信息'"
      @close="close"
      :visible.sync="dialogVisible2"
      width="55%"
    >
      <el-form
        ref="compileFromArea"
        :model="compileFromArea"
        label-width="100px"
        :rules="rules"
        size="middle"
      >
        <el-row>
          <el-col style="margin:0;" :span="10">
            <el-form-item label="区域名称:" prop="areaName">
              <el-cascader
                :options="address_data"
                v-model="selectedOptions"
                ref="elcascader"
                @change="areaHandleChange"
                :props="{ checkStrictly: true }"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="区域描述:" prop="areaDesc">
              <el-input v-model="compileFromArea.areaDesc"></el-input>
            </el-form-item>
            <el-form-item label="是否行政区:" prop="admRegionOrNot">
              <el-select v-model="compileFromArea.admRegionOrNot" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <el-col style="margin:0;" :span="10">
            <!-- <el-form-item label="区划代码:" >
             <el-input v-model="compileFromArea.regionCode" placeholder="请输入父级编码"></el-input>
           </el-form-item> -->
            <el-form-item label="区域级别:" prop="areaRank">
              <el-select v-model="compileFromArea.areaRank">
                <el-option
                  v-for="item in areaOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="区域状态:" prop="areaState">
              <el-select v-model="compileFromArea.areaState">
                <el-option
                  v-for="item in selectOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <!--
            <el-form-item label="创建者:">
              <span>{{compileFromArea.creator}}</span>
            </el-form-item-->

            <el-form-item label="上级编号:" prop="higherId">
              <el-input v-model="compileFromArea.higherId" placeholder="请输入父级编码"></el-input>
            </el-form-item>

          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="dialogVisible2 = false" plain>取消</el-button>
        <el-button type="primary" @click="addDregionClick">确定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  getInformation,
  upExcelImportDregion,
  addDregion,
  delDregion,
  upDregion,
  findSpecificPage,
  getArerCodeAll
} from "@/utils/basicInformation";
import { regionData } from "element-china-area-data";
import moment from 'moment';
export default {
  name: "arerName",
  data() {
    return {
      cascaderValue: [],
      total: 0,
      pageSize: 20,
      pageNum: 1,
      resPageNum:1,
      addOrEdit: true,
      address_data: regionData, // 选中地址
      isPage: true,
      areaMapOptions: [],
      selectedOptions: [],
      search: "",
      tableRowKey: 0,
      regionOrNot: 0,
      onSelect: 0,
      codeOrName: "",
      compileFromArea: {
        areaCode:'',
        areaName: "",
        areaDesc: "",
        admRegionOrNot: "",
        higherId: "",
        areaRank: "",
        areaState: "",
        creator: "",
      },
      excleImportUrl: "",
      dialogVisible2: false,
      dataFrom: {},
      options: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      selectOptions: [
        {
          value: 1,
          label: "启用",
        },
        {
          value: 0,
          label: "禁用",
        },
      ],
      value: "",
      tableData: [],
      rules: {
        areaCode: [
          { required: true, message: "请输入区域编码", trigger: "blur" },
        ],
        areaName: [
          { required: true, message: "请输入区域名称", trigger: "change" },
        ],
        areaDesc: [
          { required: true, message: "请输入区域描述", trigger: "blur" },
        ],
        admRegionOrNot: [
          { required: true, message: "请选择是否行政区划", trigger: "change" },
        ],
        areaRank: [
          { required: true, message: "请输入区域级别", trigger: "change" },
        ],
        higherId: [
          { required: true, message: "请输入上级编号", trigger: "blur" },
        ],
        areaState: [{ required: true, message: "请选择状态", trigger: "blur" }]
      },
      areaOptions: [
        {
          value: "1",
          label: "省",
        },
        {
          value: "2",
          label: "市",
        },
        {
          value: "3",
          label: "县",
        },
        {
          value: "4",
          label: "乡",
        },
        {
          value: "5",
          label: "村",
        },
        {
          value: "6",
          label: "组",
        }
      ],
      columns: [
        { label: "区域编码", prop: "areaCode" },
        { label: "区域名称", prop: "areaName" },
        { label: "区域描述", prop: "areaDesc" },
        { label: "是否行政区划", prop: "admRegionOrNot" },
        { label: "区划代码", prop: "regionCode" },
        { label: "上级编号", prop: "higherId" },
        { label: "区域级别", prop: "areaRank" },
        { label: "状态", prop: "areaState" }
      ],
      selectList: [],
      selectAll:[],
      dialogImport:false,
      uploadDisabled:true,
      fileList:[],
      typeIndex:0
    };
  },
  computed: {
    userName() {
      return JSON.parse(this.$store.state.user).username;
    },
    cascader(){
      return this.cascaderValue.join(',')
    },
    downloadList(){
      if(this.selectList.length === 0){
        return this.selectAll.map(item=>{
          item.admRegionOrNot = item.admRegionOrNot === 0?'否':'是'
          item.areaState =  item.areaState === 0?'禁用':'启用'
          item.createTime = item.createTime !== null?moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'):null
          item.modifyTime = item.modifyTime !== null?moment(item.modifyTime).format('YYYY-MM-DD HH:mm:ss'):null
          switch (item.areaRank) {
            case 1:
              item.areaRank = '省'
              break
            case 2:
              item.areaRank = '市'
              break
            case 3:
              item.areaRank = '县'
              break
            case 4:
              item.areaRank = '乡'
              break
            case 5:
              item.areaRank = '村'
              break
            default:
              break;
          }
          return item
        })
      }else{
        return this.selectList.map(item=>{
          item.admRegionOrNot = item.admRegionOrNot === 0?'否':'是'
          item.areaState =  item.areaState === 0?'禁用':'启用'
          item.createTime = item.createTime !== null?moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'):null
          item.modifyTime = item.modifyTime !== null?moment(item.modifyTime).format('YYYY-MM-DD HH:mm:ss'):null
          switch (item.areaRank) {
            case 1:
              item.areaRank = '省'
              break
            case 2:
              item.areaRank = '市'
              break
            case 3:
              item.areaRank = '县'
              break
            case 4:
              item.areaRank = '乡'
              break
            case 5:
              item.areaRank = '村'
              break
            default:
              break;
          }
          return item
        })
      }

    }
  },
  created() {
    this.startInformation();
    this.startInformationAll()
    this.compileFromArea.creator = this.userName;
  },
  methods: {
    //获取区域信息
    async startInformation() {
      let data = { page: this.pageNum, size: this.pageSize };
      const res = await getInformation(data);
      if (res.data.code !== 200) return this.$msg.error("获取区域列表失败");
      let datas = res.data.data.content;
      this.total = res.data.data.totalElements;
      this.tableData = datas;
    },
    //获取全部区域信息
    async startInformationAll(){
      const res =await getArerCodeAll()
      if(res.status !== 200)return this.$msg.error('获取所有区域失败')
      this.selectAll = res.data.data
    },
    //修改信息填充
    handleEdit( row) {
      this.dialogVisible2 = true
      this.addOrEdit = false;
      let data = this.copyDeep(row);
      this.tableRowKey = data.id;
      data.areaRank = data.areaRank+''
      this.compileFromArea = data;
      this.selectedOptions = data.areaCode.split(',')
    },
    areaCodeRender(val){
      let list = val.split(',')
      return list[list.length -1]
    },
    copyDeep(templateData) {
      // templateData 是要复制的数组或对象，这样的数组或者对象就是指向新的地址的
      return JSON.parse(JSON.stringify(templateData));
    },
    async handleDelete() {
      let rows = this.selectList;
      if (rows.length === 0) {
        this.$msg({
          type: "info",
          message: "请选中删除文件",
        });
      } else {
        const config = await this.$MessageBox.confirm(
          "此操作将永久删除选中项, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch((e) => e);
        if (config !== "confirm") return;

        const arrayId = rows.map((item) => {
          return item.id;
        }).join(",");
        const res = await delDregion(arrayId);
        if (res.data.code == 200) {
          return this.$msg.error("删除成功!");
        }else {
          return this.$msg.error("删除失败!");
        }
        this.tableData = this.tableData.filter((item, i) => {
          return item.id !== arrayId[i];
        });
        this.$refs.multipleTable.clearSelection();
        if (this.pageNum > 1 && this.tableData.length === 0) {
          this.pageNum = this.pageNum - 1;
        }
        this.$msg.success("删除成功");
        this.startInformation();
      }
    },
    async addDregionClick() {
      this.$refs.compileFromArea.validate(async (valid) => {
        if (!valid) return this.$msg("内容不能为空");
        try {
          const dataList = JSON.parse(JSON.stringify(this.compileFromArea))
          if (this.addOrEdit) {
            const res = await addDregion(dataList);
            if (res.status !== 200 && res.data.message === '区域已存在') return this.$msg.warning("区域已存在，请重新添加!")
            if (res.status !== 200 && res.data.message !== '区域已存在') return this.$msg.error("添加区域信息失败!");
            this.$msg.success("添加区域信息成功");
            this.dialogVisible2 = false;
            this.startInformation();
          } else {
            const res = await upDregion(dataList);
            if (res.status !== 200) return this.$msg.error("修改区域信息失败!");
            this.$msg.success("修改区域信息成功");
            this.dialogVisible2 = false
            this.startInformation();
          }
        } catch (e) {

        }
      });

      //编辑区域
    },
    async onGetAreaCodeOrName() {
      let data = {
        regionCode: this.cascader,
        page: this.resPageNum,
        size: this.pageSize,
      };
      const res = await findSpecificPage(data);
      if (res.status !== 200) return this.$msg.error("查询失败!");
      let datas = res.data.data.content;
      this.total = res.data.data.totalElements;
      this.tableData = datas;
      this.isPage = false;
    },
    close() {
      const data = {
        creator: this.userName,
      };
      this.$refs.compileFromArea.resetFields();
      this.compileFromArea = data;
      this.selectedOptions = []
      this.addOrEdit = true
      this.dialogVisible2 = false
    },
    // 分页事件
    async handleSizeChange(val) {
      this.pageSize = val;
      if (this.isPage) {
        this.startInformation();
      } else {
        this.onGetAreaCodeOrName();
      }
    },
    async handleCurrentChange(val) {
      this.pageNum = val;
      if (this.isPage) {
        this.startInformation();
      } else {
        this.resPageNum = val
        this.onGetAreaCodeOrName();
      }
    },
    selectionChange(rows) {
      this.selectList = rows;
    },
    stringorArr(val){
      let list = val.split(',')
      return list.length === 1?list[0]:list[1]
    },
    //导出
    async download() {
      const confirm = await this.$MessageBox
        .confirm("是否导出文件", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((e) => e)
      if (confirm !== "confirm") return
      const list =  [
        { label: "区域编码", prop: "areaCode" },
        { label: "区域名称", prop: "areaName" },
        { label: "区域描述", prop: "areaDesc" },
        { label: "是否行政区划", prop: "admRegionOrNot" },
        { label: "区划代码", prop: "regionCode" },
        { label: "上级编号", prop: "higherId" },
        { label: "区域级别", prop: "areaRank" },
        { label: "状态", prop: "areaState" },
        { label: "创建者", prop: "creator" },
        { label: "创建日期", prop: "createTime" },
        { label: "修改日期", prop: "modifyTime" }
      ]
      this.$download(this.downloadList, list, "区域信息管理")
    },
    //下载模版
    dowtemplate(){
      const list =[
        {
          areaCode:'00000',
          areaName:'00000',
          areaDesc:'00000',
          admRegionOrNot:'是为1,否为0',
          regionCode:'00000',
          higherId:'00000',
          areaRank:'1省2市3县4乡5村9非行政区划',
          areaState:'启用1禁用0'
        }
      ]
      this.$download(list, this.columns, "区域信息管理")
    },
    toggleSelection() {
      let rows = this.selectList;
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    restFunction() {
      this.cascaderValue = [];
      this.isPage = true;
      this.pageNum =1
      this.pageSize = 20
      this.resPageNum = 1
      this.startInformation();
    },
    areaHandleChange() {
      let arerData = this.$refs.elcascader.getCheckedNodes()[0]
      let serves = ''
      if(this.selectedOptions.length === 1){
        serves = '000000'
      }else if(this.selectedOptions.length === 2){
        serves = arerData.parent.value
      }else if(this.selectedOptions.length === 3){
        serves = arerData.parent.parent.value +','+ arerData.parent.value
      }
      this.compileFromArea.higherId = serves
      this.compileFromArea.areaName = arerData.pathLabels.join(",")
      this.compileFromArea.areaCode = arerData.path.join(',')
      this.compileFromArea.regionCode = arerData.path.join(',')
    },
    renIndex(index){
      return (this.pageNum -1 ) * this.pageSize + index +1
    },
    //清空上传的文件
    closeLoad(){
      this.uploadDisabled = true
      this.$refs.upload.clearFiles();
    },
    //上传文件改变
    handleChange(file,fileList){
      if(fileList.length > 0){
        this.uploadDisabled = false
        this.fileList = fileList
      }
    },
    //上传文件之前
    beforeUpload(file){
      let fileTemp = file.raw
      if(fileTemp){
        if(fileTemp.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          fileTemp.type === "application/vnd.ms-excel"){
        }else{
          this.$msg.warning('附件格式错误，请删除后重新上传!')
        }
      }
    },
    //上传文件移除
    handleRemove(file,fileList){
      this.fileList = fileList
      if(fileList.length < 1){
        this.uploadDisabled = true
      }
    },
    //开始上传
    async handleUpload(){
      if(!this.fileList.length === 0)return this.$msg.info('请选择要上传的文件')
      let formData = new FormData()
      formData.append('file',this.fileList[0].raw)
      formData.append('creator',this.userName)
      const res =await upExcelImportDregion(formData)
      if(res.status !== 200)return this.$msg.error('文件上传失败')
      if(res.data && res.data.data.slice(0,4) === '导入失败')return this.$msg.error('文件上传失败')
      this.$msg.success('文件上传成功')
      this.dialogImport = false
      this.startInformation()
    },
    //删除文件之前的钩子
    async beforeRemove(file){
      return this.$MessageBox.confirm(`确定移除 ${file.name}？`)
    }
  },
};
</script>

<style lang="less" scoped>
.el-row {
  margin: 15px 0;

  .el-col {
    margin-right: 20px;
  }
}
.el-cascader {
  width: 100%;
}
.el-select {
  width: 100%;
}

.status-item1 {
  > span {
    padding: 0 3px;
  }
  :first-child {
    width: 5px;
    height: 5px;
    display: inline-block;
    border-radius: 50%;
    background-color: #ff4949;
    transform: translateY(-2px);
  }
}
.status-item2 {
  > span {
    padding: 0 3px;
  }
  :first-child {
    width: 5px;
    height: 5px;
    display: inline-block;
    border-radius: 50%;
    background-color: #6798f9;
    transform: translateY(-2px);
  }
}

.dowload{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  >div{
    display: flex;
    align-items: center;
    &:first-child{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      >span{
        font-size: 12px;
        color: #e10505;
      }
    }
    >button{
      margin-top: 0!important;
    }
  }
}

</style>
