<template>
    <div class="app-container">
        <el-row class="main-flex">
<!--            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>
            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>-->
            <!-- 搜索区域 -->
            <el-col :span="21" class="main-content">
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="换电站：">
                                <el-select v-model="query.name" placeholder="请选择换电站" clearable>
                                    <!--                                <el-option-->
                                    <!--                                    v-for="item in billingEnumList"-->
                                    <!--                                    :key="item.k"-->
                                    <!--                                    :label="item.v"-->
                                    <!--                                    :value="item.k"-->
                                    <!--                                ></el-option>-->
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label=" 年月：">
                                <el-input v-model.trim="query.name" placeholder="请选择年月"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="日期：">
                                <el-date-picker
                                    v-model="query.dateVal"
                                    type="date"
                                    placeholder="请选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="5">
                        <div class="btn-end">
                        <span class="btn-rest export-btn">
                                <el-button @click="resFuntion" plain>
                                导出
                                </el-button>
                        </span>
                            <span class="btn-rest">
                                <el-button @click="resFuntion" plain>
                                重置
                                </el-button>
                            </span>
                            <span class="btn-submit">
                                <el-button type="primary" @click="getSearch" plain>查询
                                </el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  main部分-->
                <div class="section-main">
                    <div class="round-plant">
                        <div class="round-title">用电情况同环比</div>
                        <div class="loop-box">
                            <div class="round-num">
                                <div class="round-num-inf">4454</div>
                                <div class="round-num-unit">用电电量（kW·h）</div>
                            </div>
                            <div class="round-num ele-free">
                                <div class="round-num-inf">4454</div>
                                <div class="round-num-unit">电费（元）</div>
                            </div>
                        </div>

                        <div class="pie-plant">
                            <div class="pie-plant-inf">
                                <div class="pie-plant-date">22年11月</div>
                                <div class="pie-plant-num">6936<span>次</span></div>
                                <pie :pieName="pieFir" :pieColor="pieColorFir" style="width:96px;height: 96px"></pie>
                            </div>
                            <div class="pie-plant-inf">
                                <div class="pie-plant-date">22年11月</div>
                                <div class="pie-plant-num">6936<span>次</span></div>
                                <pie :pieName="pieSec" :pieColor="pieColorSec" style="width:96px;height: 96px"></pie>
                            </div>
                            <div class="pie-plant-inf">
                                <div class="pie-plant-date">22年11月</div>
                                <div class="pie-plant-num">6936<span>次</span></div>
                                <pie :pieName="pieThird" :pieColor="pieColorFir" style="width:96px;height: 96px"></pie>
                            </div>
                            <div class="pie-plant-inf">
                                <div class="pie-plant-date">23年11月</div>
                                <div class="pie-plant-num">936<span>次</span></div>
                                  <pie :pieName="pieFourth" :pieColor="pieColorSec" style="width:96px;height: 96px"></pie>
                            </div>
                        </div>
                    </div>
                    <div class="power-changes">
                        <div class="round-title">换电次数统计</div>
                        <bar :barId="barNumSta" :barColor="barColorFir" style="height: 100%"></bar>
                    </div>
                </div>
                <!-- -->
                <div class="section-main statistics">
                    <div class="statistics-plant">时段换电次数统计</div>
                    <div class="change-num-box">
                        <div v-for="(item,index) in eleList" :key="index" class="plant-box"
                             :style="'color:'+ item.color+';'+'border-bottom:2px solid'+ item.color+';'+'border-radius: 4px'">
                            <div class="plant-title">{{ item.name }}</div>
                            <div class="plant-num">{{item.value}}</div>
                        </div>
                    </div>
                    <bar :barId="bsiTimeSta" :barColor="barColorFir" style="height: 360px;"></bar>
                </div>
            </el-col>

        </el-row>

    </div>
</template>

<script>
// import operatorTreeVue from "@/components/tree/operatorTree.vue";
import pie from '@/views/statistics/powerChanges/pieGroup.vue';
import bar from './barLine.vue';

export default {
    name: "index",
    // elePie, bar
    components: {pie,bar},
    data() {
        return {
            isopenShow: true,
            //左侧运营商树
            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },
            query: {
                name: '',
                dateVal: ''
            },
            newPie: [
                {
                    name: '装备制造',
                    value: 54
                }],
            newBar: {},
            pieFir: 'pieFir',
            pieSec: 'pieSec',
            pieThird: 'pieThird',
            pieFourth: 'pieFourth',
            pieColorFir:'#FF6165',
            pieColorSec:'#66BB83',
            barNumSta: 'barNumSta',
            bsiTimeSta: 'bsiTimeSta',
            barColorFir:'rgba(55, 109, 247, 0.95)',
            eleList:[{
                color:'#FF6165',
                name:'尖用电电量（kW·h）',
                value:'6939',
            },
                {
                    color:'#FF6165',
                    name:'尖电费（元）',
                    value:'6939',
                },
                {
                    color:'#FA9600',
                    name:'峰用电电量（kW·h）',
                    value:'6939',
                },{
                    color:'#FA9600',
                    name:'峰电费（元）',
                    value:'6939',
                },{
                    color:'#0D6CE4',
                    name:'平用电电量（kW·h）',
                    value:'6939',
                },{
                    color:'#0D6CE4',
                    name:'平电费（元）',
                    value:'6939',
                },{
                    color:'#53B997',
                    name:'谷用电电量（kW·h）',
                    value:'6939',
                },{
                    color:'#53B997',
                    name:'谷电费（元）',
                    value:'6939',
                }

                ]

        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        getTreeData(data) {
            this.treeData = data
        },
        isHidden(message) {
            this.isopenShow = message
        },
        resFuntion() {

        },
        getSearch() {

        }
    }
}
</script>

<style lang="less" scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.main-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    height: 68px;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    //background: #FFFFFF;
    margin-top: 16px;
    //padding: 0px 0px 0px 16px;
    display: flex;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.main-flex {
    display: flex;

}

.loop-box{
    display: flex;
}
.round-plant {
    width: 704px;
    background: #FFFFFF;
    padding: 24px 16px;

    .round-num {
        flex: 1;
        margin-top: 16px;
        padding-bottom: 8px;
        border-bottom: 2px solid #0D6CE4;
        border-radius: 4px;
        margin-right: 16px;
    }
    .round-num:last-child {
        margin-right: 0;
    }
    .round-num-inf {
        font-family: Arial;
        font-size: 24px;
        font-style: italic;
        font-weight: 700;
        letter-spacing: 0em;
        color: #0D6CE4;
    }

    .round-num-unit {
        font-size: 14px;
        font-weight: 400;
        color: #0D6CE4;
    }
}

.round-title {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    color: #2F3133;
}

.export-btn {
    margin-right: 16px;
}

.power-changes {
    flex: 1;
    background: #FFFFFF;
    margin-left: 16px;
    padding: 24px 16px;
}

.pie-plant {
    display: flex;
    justify-content: space-between;
    padding: 0 28px;

}

.statistics {
    background: #FFFFFF;
    padding: 24px 16px;
    display: block;
    margin-bottom: 20px
}

.statistics-plant {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    color: #2F3133;
margin-bottom: 16px;
}

.pie-plant-inf {
    text-align: center;
    color: #2F3133;
    font-weight: 400;

    .pie-plant-date {
        font-size: 14px;
        margin-top: 18px;
        margin-bottom: 8px;
    }

    .pie-plant-num {
        font-family: PingFang SC;
        font-size: 18px;
        margin-bottom: 16px;

        span {
            font-size: 14px;
        }
    }

}
.ele-free {
    border-bottom: 2px solid #FA9600 !important;
    .round-num-inf,.round-num-unit{
        color: #FA9600;
    }
}
.change-num-box{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .plant-box{
        width: 100%;
        margin-right: 16px;
        box-shadow: 0px 2px 10px 0px #0000001A;
        padding: 8px;
    }
    .plant-box:last-child{
        margin-right: 0;
    }
    .plant-title{
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 8px;
    }
    .plant-num{
        font-family: PingFang SC;
        font-size: 18px;
        font-weight: 400;
    }
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
