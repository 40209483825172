<template>
    <!-- 换电站消费同比 -->
    <div class="content">
        <el-row class="content-title">
            <el-row class="head-section ">
                <el-col :span="6">
                    <el-form :inline="true">
                        <el-form-item label="换电站：">
                            <el-select @change="stationChange" v-model="stationNo" placeholder="请选择换电站" clearable>
                                <el-option v-for="item in stationList" :key="item.id" :label="item.stationName"
                                           :value="item.stationNo"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="7">
                    <el-form :inline="true">
                        <el-form-item label="日期：">
                            <el-date-picker value-format="yyyy-MM-dd" v-model="monthValue" type="month"
                                            placeholder="选择月" clearable></el-date-picker>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="11">
                    <div class="btn-end">
                    <span class="btn-rest">
                    <el-button class="buttonsize" @click="resFuntion" plain
                                >重置</el-button>
                     </span>
                        <span class="btn-submit">
                         <el-button class="buttonsize" @click="search" type="primary" plain
                                     >查询</el-button>
                        </span>
                    </div>
                </el-col>
            </el-row>
        </el-row>
        <el-row class="content-content">
            <div>
                <span class="content-content_pos" >{{ stationStr }}</span>
                <station-pie :newPie="newPie"></station-pie>
            </div>
            <div>
                <span class="content-content_pos" >{{ stationStr }}</span>
            <!-- <i id="changeI" :class="isEcharts?'iconfont icon-zhexiantu':'iconfont icon-zhuzhuangtu' "
                   @click="changeEcharts"></i>-->
                <station-bar :newBar="newBar"></station-bar>
            </div>
        </el-row>
        <el-row class="content-bottom">
            <div class="content-box-title">
                <span >{{ stationStr }}</span>
                <div class="open-btn">
                    <el-button @click="excelAll" size="small" plain>批量导出
                    </el-button>
                </div>
            </div>
            <div class="table-box mar-table">
                <el-table :data="tableData" style="width: 100%" :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}" >
                    <el-table-column prop="date" :formatter="chanRender" label="日期"></el-table-column>
                    <el-table-column prop="startTime" label="运行开始时间"></el-table-column>
                    <el-table-column prop="endTime" label="运行结束时间"></el-table-column>
                    <el-table-column prop="time" :label="yearList.time"></el-table-column>
                    <el-table-column prop="lastTime" :label="yearList.lastTime"></el-table-column>
                    <el-table-column prop="beforeTime" :label="yearList.beforeTime"></el-table-column>
                </el-table>
            </div>
            <el-col :span="24">
                <el-row class="pagin page-num">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10, 15, 20, 30]"
                        :page-size="size"
                        :current-page="page"
                        layout="total,sizes, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </el-row></el-col>
        </el-row>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {getForceList} from '@/utils/station'
import {regionData} from "element-china-area-data";
import stationPie from '@/components/statsiscComponents/station-pie'
import stationBar from '@/components/statsiscComponents/station-bar'
import moment from "moment";
import {getStaDaySwapOnYear} from "@/utils/statistics";

export default {
    name: "basisName",
    components: {
        stationPie,
        stationBar
    },
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            tableData: [],
            tableAll: [],
            options: regionData,
            stationTypeList: [
                {
                    value: '0',
                    label: "A类型",
                },
                {
                    value: '1',
                    label: "B类型",
                },
            ],
            stationList: [],
            stationType: null,
            stationNo: null,
            stationName: '',
            stationId: '',
            stationStr: '',
            monthValue: "",
            newPie: {},
            newBar: {},
            isEcharts: true,
            linkAll: {}
        };
    },
    created() {
        this.resFuntion();

    },
    computed: {
        yearList() {
            let month = this.monthValue
            month = month !== '' ? moment(month).format('YYYY-MM-DD') : moment().month('YYYY-MM-DD')
            return {
                time: moment(month).format('YYYY-MM'),
                lastTime: moment(month).add(-1, 'y').format("YYYY-MM"),
                beforeTime: moment(month).add(-2, 'y').format("YYYY-MM")
            }
        },
        monthTime() {
            if (this.monthValue === '') {
                return moment().month() + 1
            } else {
                return moment(this.monthValue).month() + 1
            }
        }
    },
    mounted() {
    },
    methods: {
        async getStation() {
            const res = await getForceList()
            this.stationList = res.data.data;
            this.stationNo = this.stationList.length > 0 ? this.stationList[0].stationNo : null
            this.stationName = this.stationList.length > 0 ? this.stationList[0].stationName : null
            this.renderInit()
        },
        resFuntion() {
            this.stationNo = ''
            this.stationType = ''
            this.monthValue = new Date()
            this.getStation()
        },
        search() {
            this.renderInit()
        },
        async renderInit() {
            try {
                let month = this.monthValue
                month = month !== '' ? moment(month).format('YYYY-MM-DD') : moment().month('YYYY-MM-DD')
                const data = {
                    stationNo: this.stationNo,
                    startTime: moment(month).format('YYYY-MM-DD HH:mm:ss'),
                }

                const res = await getStaDaySwapOnYear(data)
                let newList = res.data.data
                let newArr1 = newList.thisYear
                let newArr2 = newList.lastYear
                let newArr3 = newList.beforeYear
                this.linkAll = {
                    newArr1,
                    newArr2,
                    newArr3,
                    month
                }
                //折线、柱图数据格式化
                this.tranfromBar(newArr1, newArr2, newArr3, month)
                //分页表格查询
                this.tableList(newArr1, newArr2, newArr3)
                this.stationStr = `${this.stationName}-${this.yearList.time}月换电次数同比`
            } catch (error) {
            }

        },
        //换电消费同比
        tranfromBar(list1, list2, list3, month) {
            let arrAll = []
            let arrLine = []
            let newArr = []
            let lastArr = []
            let beforeArr = []
            let monthList = []
            let newsPie = {
                name: this.yearList.time,
                value: 0
            }
            let newlastPie = {
                name: this.yearList.lastTime,
                value: 0
            }
            let newbeforePie = {
                name: this.yearList.beforeTime,
                value: 0
            }
            let dayList = this.dayLIST(moment(month).daysInMonth())
            if (list1.length !== 0) {
                let obj = this.redcueList(list1, dayList)
                newsPie.value = obj.newsPie
                newArr = obj.newArr
                monthList = obj.monthList
                if (list2.length !== 0) {
                    let obj = this.redcueList(list2, dayList)
                    newlastPie.value = obj.newsPie
                    lastArr = obj.newArr
                }
                if (list3.length !== 0) {
                    let obj = this.redcueList(list3, dayList)
                    newbeforePie.value = obj.newsPie
                    beforeArr = obj.newArr
                }
            } else {
                if (list2.length !== 0) {
                    let obj = this.redcueList(list2, dayList)
                    newlastPie.value = obj.newsPie
                    lastArr = obj.newArr
                    monthList = obj.monthList
                    if (list3.length !== 0) {
                        let obj = this.redcueList(list3, dayList)
                        newbeforePie.value = obj.newsPie
                        beforeArr = obj.newArr
                    }
                } else {
                    if (list3.length !== 0) {
                        let obj = this.redcueList(list2, dayList)
                        newbeforePie.value = obj.newsPie
                        beforeArr = obj.newArr
                        monthList = obj.monthList
                    } else {
                        dayList.forEach(item => {
                            newArr.push(0)
                            lastArr.push(0)
                            beforeArr.push(0)
                            monthList.push(item)
                        })
                    }
                }
            }
            this.isEcharts ? arrAll.push({
                name: this.yearList.time,
                type: 'bar',
                data: newArr
            }) : arrLine.push({name: this.yearList.time, type: 'line', data: newArr})
            this.isEcharts ? arrAll.push({
                name: this.yearList.lastTime,
                type: 'bar',
                data: lastArr
            }) : arrLine.push({name: this.yearList.lastTime, type: 'line', data: lastArr})
            this.isEcharts ? arrAll.push({
                name: this.yearList.beforeTime,
                type: 'bar',
                data: beforeArr
            }) : arrLine.push({name: this.yearList.beforeTime, type: 'line', data: beforeArr})
            this.newBar = {
                arrAll: this.isEcharts ? arrAll : arrLine,
                monthList,
                yearList: [this.yearList.time, this.yearList.lastTime, this.yearList.beforeTime]
            }
            this.newPie = {
                list: this.countPercentage([newsPie, newlastPie, newbeforePie])
            }
        },

        //分页表单数据
        tableList(list1, list2, list3) {
            let newArr = []
            if (list1.length !== 0) {
                let dest = []
                list1.forEach(item => {
                    const data = {
                        date: item.dt,
                        startTime: item.st,
                        endTime: item.et,
                        time: item.tc,
                        lastTime: 0,
                        beforeTime: 0
                    }
                    dest.push(data)
                })
                if (list2.length !== 0) {
                    list2.forEach(item => {
                        let i = dest.findIndex(c => moment(c.date).date() === moment(item.dt).date())
                        const data = {
                            date: item.date,
                            startTime: item.st,
                            endTime: item.et,
                            time: 0,
                            lastTime: item.tc,
                            beforeTime: 0
                        }
                        i === -1 && dest.push(data)
                        i !== -1 && (dest[i].lastTime += item.tc)
                    })
                }
                if (list3.length !== 0) {
                    list3.forEach(item => {
                        let i = dest.findIndex(c => moment(c.date).date() === moment(item.dt).date())
                        const data = {
                            date: item.dt,
                            startTime: item.st,
                            endTime: item.et,
                            time: 0,
                            lastTime: 0,
                            beforeTime: item.tc
                        }
                        i === -1 && dest.push(data)
                        i !== -1 && (dest[i].beforeTime += item.tc)
                    })
                }
                newArr = dest
            } else {
                if (list2.length !== 0) {
                    list2.forEach(item => {
                        const data = {
                            date: item.dt,
                            startTime: item.st,
                            endTime: item.et,
                            time: 0,
                            lastTime: item.tc,
                            beforeTime: 0
                        }
                        dest.push(data)
                    })
                    if (list3.length !== 0) {
                        list3.forEach(item => {
                            let i = dest.findIndex(c => moment(c.date).date() === moment(item.dt).date())
                            const data = {
                                date: item.dt,
                                startTime: item.st,
                                endTime: item.st,
                                time: 0,
                                lastTime: 0,
                                beforeTime: item.tc
                            }
                            i === -1 && dest.push(data)
                            i !== -1 && (dest[i].beforeTime += item.tc)
                        })
                    }
                    newArr = dest
                } else {
                    if (list3.length !== 0) {
                        list2.forEach(item => {
                            const data = {
                                date: item.dt,
                                startTime: item.st,
                                endTime: item.et,
                                time: 0,
                                lastTime: 0,
                                beforeTime: item.tc
                            }
                            dest.push(data)
                        })
                        newArr = dest
                    }

                }
            }
            newArr = newArr.sort((a, b) => moment(a.date).date() - moment(b.date).date())
            this.tableAll = JSON.parse(JSON.stringify(newArr))
            let data = this.getTableData(this.page, this.size, newArr)
            this.tableData = data.data
            this.total = data.length
        },
        dayLIST(list) {
            let newArr = []
            if (list.length === 0) return [];
            for (let i = 1; i < list + 1; i++) {
                newArr.push(i);
            }
            return newArr;
        },

        changeEcharts() {
            this.isEcharts = !this.isEcharts
            this.tranfromBar(this.linkAll.newArr1, this.linkAll.newArr2, this.linkAll.newArr3, this.linkAll.month)
        },
        stationChange(e) {
            let value = this.stationList.filter(c => c.stationNo === e);
            this.stationName = value[0].stationName;
            this.stationNo = value[0].stationNo;
        },
        async excelAll() {
            const coulm = [
                {prop: 'date', label: '日期'},
                {prop: 'startTime', label: '运行开始时间'},
                {prop: 'endTime', label: '运行结束时间'},
                {prop: 'time', label: this.yearList.time},
                {prop: 'lastTime', label: this.yearList.lastTime},
                {prop: 'beforeTime', label: this.yearList.beforeTime},
            ]
            const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(e => e)
            if (confirm !== 'confirm') return
            // this.$download(this.tableData,coulm,'场站换电消费同比')
            this.$download(this.tableData, coulm, this.stationStr)
        },
        handleSizeChange(size) {
            this.size = size
            let data = this.getTableData(this.page, this.size, this.tableAll)
            this.tableData = data.data
            this.total = data.length
        },
        handleCurrentChange(page) {
            this.page = page
            let data = this.getTableData(this.page, this.size, this.tableAll)
            this.tableData = data.data
            this.total = data.length
        },
        countPercentage(countArray) {
            let list = countArray.map(c => c.value)
            var j = eval(list.join('+'));
            var resultArray = [];
            for (var i = 0; i < list.length; i++) {
                if (list[i] === 0) {
                    resultArray.push(0);
                } else {
                    var k = Math.floor((list[i] / j) * 100)
                    resultArray.push(k);
                }
            }
            countArray[0].value = resultArray[0]
            countArray[1].value = resultArray[1]
            countArray[2].value = resultArray[2]
            return countArray
        },
        redcueList(val, dayList) {
            let newArr = []
            let newsPie = 0
            let monthList = []
            dayList.forEach(item => {
                let index = monthList.findIndex(c => c === item)
                index === -1 && monthList.push(item)
                let i = val.findIndex(c => moment(c.dt).date() === item) //判断返回数组里面的换电日期是否等于当前月份里的日期
                if (i === -1) {
                    newArr.push({name: item, value: 0})
                } else {
                    newArr.push({name: item, value: val[i].tc})
                }
            })
            val.forEach(item => {
                newsPie += item.tc
            })
            return {
                newsPie,
                newArr,
                monthList
            }
        },
        chanRender(row, column) {
            return moment(row[column.property]).date() + '号'
        }
    },
};
</script>

<style lang='less' scoped>
.content {
    border: 0;
    box-shadow: none;
    background-color: #f0f2f5;
    width: 100%;
    height: 100%;
}

//.el-row {
//    margin: 15px 0;
//
//    .el-col {
//        margin-right: 20px;
//    }
//}

.el-select, .el-cascader {
    width: 100%;
}

.content-title {
    background-color: #fff;
    margin: 0;
    padding: 16px 12px;
}

.content-bottom {
    background-color: #fff;
    padding: 24px 16px 16px 16px;
    margin-bottom: 30px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.content-content {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    div {
        position: relative;
        background-color: #fff;

        &:nth-child(1) {
            width: 28%;
            margin-right: 15px;
        }

        &:nth-child(2) {
            width: 71%;
        }

        .content-content_pos {
            display: inline-block;
            font-weight: 400;
            color: #2F3133;
            font-size: 16px;
            padding: 24px 0 0 16px;
            font-family: PingFang SC;
        }
    }
}

#changeI {
    position: absolute;
    top: 12%;
    right: 0%;
    cursor: pointer;
    color: #666;
    margin-right: 50px;
    z-index: 999;
}

#changeI:hover {
    color: #2a81e3;
}

.content-box-title {
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 16px;
    color: #2F3133;
    font-family: PingFang SC;

    span {
        color: #2F3133;
    }
}
.btn-end {
    display: flex;
    justify-content: flex-end;
}
.mar-table{
    margin-top: 16px;
}
.page-num{
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
.open-btn{
    .el-button {
        border: 1px solid #0D6CE4;
        color: #0D6CE4;
        background: #fff;
        padding: 8px 16px;
        line-height: 20px;
        font-size: 14px;
        width: 88px;
    }
}
</style>
