<template>
  <el-card>
    <el-row style="margin:0;">
      <div class="auth-title">绑定运营商<i @click="goClick" class="iconfont icon-fanhui"></i></div>
    </el-row>
    <div class="auth-content-name">
      <span>登录用户名：</span>
      <span>{{userData.username}}</span>
    </div>

    <el-row>
      <el-col :span="4">
        <el-input placeholder="请输入运营商名称" v-model.trim="operatorNameValue" clearable></el-input>
      </el-col>

      <el-col :span="10">
        <el-button type="primary" @click="queryFunction">查询</el-button>
        <el-button @click="restFunction">重置</el-button>
      </el-col>
    </el-row>

    <el-row>
<!--      <el-button type="primary" size="small"  icon="el-icon-setting" @click="bindsAll">选中绑定</el-button>-->
      <el-button type="warning" size="small"  icon="el-icon-setting" @click="bindsAll">选中绑定</el-button>
      <el-button type="danger"  size="small"  icon="el-icon-setting" @click="unBindsAll">选中解绑</el-button>
    </el-row>

    <el-row>
      <el-alert show-icon :closable="false">
        <span slot="title">
          已选择{{ selectList.length }}项
          <span @click="clean" class="clean">清空</span>
        </span>
      </el-alert>
    </el-row>

    <el-table
      :data="tableData"
      style="width: 100%"
      ref="multipleTable"
      @selection-change="selectionChange"
      height="500"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column type="index"  :index="renIndex" label="编号" min-width="50" ></el-table-column>
      <el-table-column prop="name" label="运营商名称" min-width="80"></el-table-column>
      <el-table-column prop="principal" label="负责人" min-width="80"></el-table-column>
      <el-table-column prop="contactWay" label="联系方式"  min-width="80"></el-table-column>
      <el-table-column  min-width="1" :formatter="bindsFormat"></el-table-column>
      <el-table-column prop="status" label="状态" min-width="80">
        <template slot-scope="scope" >
          <span v-if="scope.row.activeStatus === 0"><i class="start" style="background-color: #1890ff;"></i> 已绑定</span>
          <span v-else><i class="start" style="background-color:#d43030;"></i>未绑定</span>
        </template>
      </el-table-column>
      <el-table-column label="编辑"  min-width="80" >
      <template slot-scope="scope">
          <el-switch
            @change="changeActiveStatus(scope.row)"
            v-model="scope.row.activeStatus"
            :active-value=0
            :inactive-value=1
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>


<!--      <el-table-column label="操作" min-width="180" fixed="right">
        <template slot-scope="scope">
          <el-button size="middle"  @click="unBinds(scope.row)" type="warning" icon="el-icon-setting">绑定运营商</el-button>
          <el-button size="middle"  @click="unBinds(scope.row)" type="primary" icon="el-icon-setting">解除绑定</el-button>
        </template>
      </el-table-column>-->

    </el-table>

    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[10,30, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPages"
      ></el-pagination>
    </el-row>



  </el-card>
</template>

<script>
import {bindUserOperator, operatorPage, userOperatorBindsList,unBindUserOperator} from "@/utils/operatorApi";
export default {
  name:'userOperator',
  data(){
    return{
      userData:null,
      operatorNameValue:"",
      selectList: [],
      query: {
        pageNum: 1,
        pageSize: 10,
        resPageNum:1,
        name:""
      },
      tableData: [],
      totalPages: 0,

      userOperatorList:[],

    }
  },
  created(){
    //接收页面跳转参数
    this.userData = JSON.parse(this.$route.query.data)
    //console.log("---------页面传递进来的数据--------(((--",this.userData,"--)))-------------")
    //进入页面自动运行
    this.getPage();
    this.getUserOperatorList();
  },
  computed:{

  },
  methods: {
    //查询当前用户绑定的运营商
    async getUserOperatorList(){
      let data={};
      data.userId = this.userData.id;
      let res = await userOperatorBindsList(data);
      if(res.data.code == 200){
        this.userOperatorList = res.data.data
      }
    },

    bindsFormat(row){
      //console.log("-----------bindsFormat-------(((--",row,"--)))-------------")
      this.userOperatorList.some(item=>{
        if(row.name.trim() !== "" && (item.name.trim() === row.name.trim())){
          row.activeStatus = 0;
          return true;
        }
      })
    },

    //修改运营商绑定状态
    async changeActiveStatus(row){
      let data={};
      let ids = [];
      ids.push(row.id);
      data.operatorIds = ids;
      data.userId = this.userData.id;
      if(row.activeStatus === 0){
        let res = await bindUserOperator(data)
        if(res.data.code == 200){
          this.$msg.success("绑定运营商成功！");
          this.getUserOperatorList();//绑定成功，重新获取已绑数据
        }else{
          this.$msg.error("绑定运营商失败！");
        }
        this.getPage()
      }else{
        let res = await unBindUserOperator(data)
        if(res.data.code == 200){
          this.$msg.success("解绑运营商成功！");
          this.getUserOperatorList();//解绑成功，重新获取已绑数据
          this.getPage()
        }else{
          this.$msg.error("解绑运营商失败！");
        }
        this.getPage()
      }
    },

    async bindsAll() {
      if (this.selectList.length === 0)
        return this.$msg.info("请选择需要绑定的运营商");

        this.$MessageBox.confirm(
          "此操作将绑定选中的运营商?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(async ()=>{
          const list = this.selectList.map((item) => {
            return item.id;
          });
          let data={};
          data.operatorIds = list;
          data.userId = this.userData.id;
          const  res = await bindUserOperator(data);

          if (res.data.code !== 200) return this.$msg.error("绑定失败");
          this.tableData = this.tableData.filter((item, i) => {
            return item.id !== list[i];
          });
          this.$refs.multipleTable.clearSelection();
          if (this.query.pageNum > 1 && this.tableData.length === 0) {
            this.query.pageNum = this.query.pageNum - 1;
          }
          this.$msg.success("绑定成功");
          this.getUserOperatorList()
          this.getPage();
          }).catch(() => {
          })
    },

    async unBindsAll() {
      if (this.selectList.length === 0)
        return this.$msg.info("请选择需要解绑的运营商");

      this.$MessageBox.confirm(
        "此操作将解绑选中的运营商?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async ()=>{
        const list = this.selectList.map((item) => {
          return item.id;
        });
        let data={};
        data.operatorIds = list;
        data.userId = this.userData.id;
        const  res = await unBindUserOperator(data);

        if (res.data.code !== 200) return this.$msg.error("解绑失败");
        this.tableData = this.tableData.filter((item, i) => {
          return item.id !== list[i];
        });
        this.$refs.multipleTable.clearSelection();
        if (this.query.pageNum > 1 && this.tableData.length === 0) {
          this.query.pageNum = this.query.pageNum - 1;
        }
        this.$msg.success("解绑成功");
        this.getUserOperatorList()
        this.getPage();
      }).catch(() => {
      })
    },

    async unBindsAll2() {
      if (this.selectList.length === 0)
        return this.$msg.info("请选择需要解绑的运营商");
      try {
        const confirm = await this.$MessageBox.confirm(
          "此操作将解绑选中的运营商?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
        if (confirm !== "confirm") return;
      }catch (e) {
      }
      const list = this.selectList.map((item) => {
        return item.id;
      });

      let data={};
      data.operatorIds = list;
      data.userId = this.userData.id;
      const  res = await unBindUserOperator(data);
      if (res.data.code !== 200) return this.$msg.error("解绑失败");
      this.tableData = this.tableData.filter((item, i) => {
        return item.id !== list[i];
      });
      this.$refs.multipleTable.clearSelection();
      if (this.query.pageNum > 1 && this.tableData.length === 0) {
        this.query.pageNum = this.query.pageNum - 1;
      }
      this.$msg.success("解绑成功");
      this.getUserOperatorList()
      this.getPage();
    },


    //分页查询
    async getPage() {
      try {
        let data = { page: this.query.pageNum, size: this.query.pageSize ,name:this.query.name};
        let res = await operatorPage(data);
        if (res.status !== 200) return tis.$msg.error("获取车队信息失败");
        let datas = res.data.data.content;
        this.totalPages = res.data.data.totalElements;
        this.tableData = datas;
      } catch (error) {
      }
    },

    renIndex(index){
      return (this.query.pageNum -1 ) * this.query.pageSize + index +1
    },

    //重置
    restFunction() {
      this.operatorNameValue = "";
      this.isPage = true;
      this.query ={
        pageNum:1,
        pageSize:10,
        resPageNum:1,
        name:""
      }
      this.getPage();
    },

    close() {
      const data = {
        creator: this.userName,
      };
      this.$refs.addForm.resetFields();
      this.addForm = data;
      this.isAdd = true;
      this.addDialog = false
    },

    // 分页事件
    handleSizeChange(val) {
      this.query.pageSize = val;
      if (this.isPage) {
        this.getPage();
      } else {
        this.queryFunction();
      }
    },

    async queryFunction() {
      try {
        this.query.name = this.operatorNameValue
        let res = await this.getPage();
        if (res.status != 200) return this.$msg.error("查询失败!");
        this.tableData = res.data.data.content;
        this.totalPages = res.data.data.totalElements;
        this.isPage = false;
      } catch (error) {}
    },

    selectionChange(rows) {
      this.selectList = rows;
    },

    handleCurrentChange(val) {
      this.query.pageNum = val;
      if (this.isPage) {
        this.getPage();
      } else {
        this.query.resPageNum = val
        this.queryFunction();
      }
    },

    clean() {
      this.$refs.multipleTable.clearSelection();
    },


    goClick(){
      this.$router.go(-1)
    }
  }
}
</script>

<style lang='less' scoped>
.el-row{
  margin: 15px 0;

}

.start {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 5px;
  margin-bottom: 2px;
}

.auth-title{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  border-left: 4px solid #1b80e1;
  font-weight: 700;
  .icon-fanhui{
    font-size: 20px;
    margin-left: 10px;
    color: #1b80e1;
    cursor: pointer;
  }
}
.auth-content-name{
  height: 30px;
  margin: 15px 0;
  span{
    font-weight: 700;
    font-size: 18px;
  }
}
.clean {
  color: #409eff;
  margin-left: 10px;
  cursor: pointer;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

/deep/.parentLable{
  >.el-checkbox__label{
    font-weight: 700;
    font-size: 16px;
    color: #333;
  }
  >.el-checkbox__input{
    .el-checkbox__inner{
      border-radius: 0;
    }
  }
}
</style>
