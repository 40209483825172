<template>
  <el-card>
    <el-row>
      <!--      <el-form :inline="true">-->
      <!--        <el-col :span="3">-->
      <!--          <el-input placeholder="请输入换电站编码" v-model.trim=" informationVo.fstationNo"></el-input>-->
      <!--        </el-col>-->
      <!--      </el-form>-->
      <el-col :span="3">
        <el-select v-model="informationVo.fstationNo" placeholder="请选择换电站编码">
          <el-option
            v-for="item in stationList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-button @click="getAllPage()" type="primary" icon="el-icon-search">查询</el-button>
        <el-button @click="resFuntion">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-button size="small" @click="addOrUpdate =true" class="el-icon-plus" type="primary"> 新增</el-button>
      <!--      <el-button type="primary" icon="el-icon-upload2" size="small" @click="importExcel">批量导入</el-button>-->
      <!--      <el-button size="small" icon="el-icon-download" @click="excelAll" type="primary">批量导出</el-button>-->
      <el-button size="small" @click="removeAll" type="danger" icon="el-icon-delete">选中删除</el-button>
    </el-row>
    <el-row>
      <el-alert show-icon type="info">
        <span slot="title">
          已选择{{batchNum.length}}项
           <span @click="cleanList" style="margin-left:10px;color:#409eff;cursor:pointer;">清空</span>
        </span>
      </el-alert>
    </el-row>
    <el-row>
      <el-table ref="equipTable" id="equipment" @selection-change="batchList" :data="tableData" style="width: 100%" height="500">
        <el-table-column type="selection"></el-table-column>
        <!--        <el-table-column label="序号" prop="fid"></el-table-column>-->
        <el-table-column label="编码" type="index" width="80" :index="renIndex"></el-table-column>
        <el-table-column label="换电站编码" prop="fstationNo" min-width="150"></el-table-column>
        <el-table-column label="换电站名称" prop="fstationName" min-width="150"></el-table-column>
        <el-table-column label="换电基础价格(元)" prop="fserveCharge" min-width="150"></el-table-column>
        <el-table-column label="基础价格折扣" prop="fserveDiscount" min-width="150"></el-table-column>
        <el-table-column label="电量计算因子(元)" prop="fenergyCharge" min-width="150"></el-table-column>
        <el-table-column label="电量单价折扣" prop="fenergyDiscount" min-width="150"></el-table-column>
        <el-table-column label="里程计算因子(元)" prop="fmileageCharge" min-width="150"></el-table-column>
        <el-table-column label="里程单价折扣" prop="fmileageDiscount" min-width="150"></el-table-column>
        <el-table-column label="总折扣" prop="ftotalDiscount"></el-table-column>
        <el-table-column label="创建者" prop="fcreator"></el-table-column>
        <el-table-column label="创建时间" prop="fcreateTime" min-width="180"></el-table-column>
        <el-table-column label="修改时间" prop="fmodifyTime" min-width="180"></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button @click="compileEqu(scope.row)" size="small" type="primary" icon="el-icon-edit">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[5, 10, 15]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-row>

    <el-dialog :title="isequipemt?'新增信息':'修改信息'" :visible.sync="addOrUpdate" width="50%" @close="close">
      <el-form size="small" ref="equipform" :rules="equipmentRules" :model="informationForm" label-width="130px">

        <el-row>
          <el-col :span="10">
            <el-form-item label="创建者:">
              <span>{{informationForm.fcreator}}</span>
            </el-form-item>
            <el-form-item label="换电站:" prop="fstationNo" >
              <el-select v-model="informationForm.fstationNo"  filterable placeholder="请选择换电站">
                <el-option
                  v-for="item in stationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="换电基础价格(元):" prop="fserveCharge">
              <el-input v-model="informationForm.fserveCharge"></el-input>
            </el-form-item>
            <el-form-item label="基础价格折扣:" prop="fserveDiscount">
              <el-input v-model="informationForm.fserveDiscount"></el-input>
            </el-form-item>
            <el-form-item label="电量计算因子(元):" prop="fenergyCharge">
              <el-input v-model="informationForm.fenergyCharge"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="电量单价折扣:" prop="fenergyDiscount">
              <el-input v-model="informationForm.fenergyDiscount"></el-input>
            </el-form-item>
            <el-form-item label="里程计算因子(元):" prop="fmileageCharge">
              <el-input v-model="informationForm.fmileageCharge"></el-input>
            </el-form-item>
            <el-form-item label="里程单价折扣:" prop="fmileageDiscount">
              <el-input v-model="informationForm.fmileageDiscount"></el-input>
            </el-form-item>
            <el-form-item label="总折扣:" prop="ftotalDiscount">
              <el-input v-model="informationForm.ftotalDiscount"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addOrUpdate = false">取 消</el-button>
        <el-button type="primary" @click="newequipemt">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
  import {
    stationMoneyFactorPage,
    addStationMoneyFactor,
    updateStationMoneyFactor,
    deleteStationMoneyFactor
  } from '@/utils/station2'
  import {getForceList} from '@/utils/station'

  import moment from 'moment'
  import {createAPI} from "../../../utils/request";
  import {mapState} from "vuex";

  export default {
    // name: 'recharge',
    data() {
      return {
        selectOptions: [
          {
            value: 1,
            label: "启用",
          },
          {
            value: 0,
            label: "禁用",
          },
        ],
        coulm: [
          // {prop:'fid',label:'序号'},
          {prop: 'fthValNo', label: '报警阀值编号'},
          {prop: 'fthValName', label: '报警阀值名称'},
          {prop: 'feqmNo', label: '设备编号'},
          {prop: 'feqmNo', label: '设备编号'},
          {prop: 'feqmName', label: '设备名称'},
          {prop: 'feqmModeNo', label: '设备类型编号'},
          {prop: 'feqmModeName', label: '设备类型名称'},
          {prop: 'fthValue', label: '报警值'},
          {prop: 'fthValType', label: '报警值类别'},
          {prop: 'fthValType', label: '报警阈值类别'},
          {prop: 'fremark', label: '备注'},
          {prop: 'fcreateTime', label: '创建时间'},
          {prop: 'fmodifyTime', label: '修改时间'}
        ],
        informationVo: {},
        isPage: true,//查询
        query: {
          pageNum: 1,
          pageSize: 5
        },
        total: 0,
        addOrUpdate: false,
        isequipemt: true,
        batchNum: [],
        tableData: [],
        allList: [],
        stateList: [
          {
            value: 1,
            label: '在役'
          },
          {
            value: 0,
            label: '退役'
          }
        ],
        informationForm: {},
        stationList: [],
        equipmentRules: {

          fnode: [
            {required: true, message: '请输入云平台区域编号', trigger: 'blur'}
          ],
          fstatus: [
            {required: true, message: '请选择状态', trigger: 'blur'}
          ],
          fdescription: [
            {required: true, message: '请输入云平台区域描述', trigger: 'blur'}
          ],

        },
        brandList: [],
        unitTypeList: [],
      }
    },
    computed: {
      ...mapState(['user']),
      userName() {
        return JSON.parse(this.$store.state.user).username
      }
    },
    created() {

      this.informationForm.fcreator = this.userName
      this.getAllPage()
      this.getStation()
      // this. getAllList()
    },
    methods: {
      //获取换电站编号
      async getStation() {
        const res = await getForceList();
        // console.log(res)
        this.stationList = res.data.data.map((item) => {
          return {
            value: item.stationNo,
            label: item.stationName,
          };
        });
      },
      renIndex(index) {
        return (this.query.pageNum - 1) * this.query.pageSize + index + 1
      },
      async getAllPage() {
        const result = await stationMoneyFactorPage(this.query.pageNum, this.query.pageSize, this.informationVo)
        console.log(result)
        if (result.data.code !== 20000)
          return this.$msg.error('获取信息失败!')
        this.tableData = result.data.data.rows.records
        this.total = result.data.data.rows.total
      },
      //重置
      resFuntion() {
        this.informationVo = {}
        this.getAllPage()
      },
      batchList(list) {
        this.batchNum = list
      },
      close() {
        const data = {
          fcreator: this.userName,
        }
        this.$refs.equipform.resetFields()
        this.informationForm = data
        this.isequipemt = true
      },

      //新增与修改
      newequipemt() {
        this.$refs.equipform.validate(async valid => {
          if (!valid) return this.$msg.info('信息不能为空!')
          const data = JSON.parse(JSON.stringify(this.informationForm))
          console.log(data)
          try {
            if (this.isequipemt) {
              // console.log(data)
              const res = await addStationMoneyFactor(data)
              // console.log(res)
              if (res.data.code !== 20000) return this.$msg.error('新增信息失败!')
              this.$msg.success('新增信息成功!')
              this.addOrUpdate = false
              this.getAllPage()
            } else {
              // console.log(data)
              const res = await updateStationMoneyFactor(data)
              // console.log(res)
              if (res.data.code !== 20000) return this.$msg.error('修改信息失败!')
              this.$msg.success('修改换信息成功!')
              this.addOrUpdate = false
              this.getAllPage()
            }
          } catch (error) {
          }
        })

      },
      //修改信息填充
      compileEqu(list) {
        this.addOrUpdate = true
        this.isequipemt = false
        var data = JSON.parse(JSON.stringify(list))
        this.informationForm = data
      },

      handleSizeChange(val) {
        this.query.pageSize = val
        if (this.isPage) {
          this.getequipment()
        } else {
          this.checkEqui()
        }
      },
      handleCurrentChange(val) {
        this.query.pageNum = val
        if (this.isPage) {
          this.getequipment()
        } else {
          this.checkEqui()
        }
      },
      // //获取所有电池型号信息
      // async getAllList(){
      //   const res =await getVehicleModelsAll()
      //   this.allList = res.data.data
      //   console.log(res)
      // },
      async excelAll() {
        const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e => e)
        if (confirm !== 'confirm') return
        if (this.batchNum.length === 0) {
          this.$download(this.allList, this.coulm, '报警阈值管理')
        } else {
          this.$download(this.batchNum, this.coulm, '报警阈值管理')
        }
      },
      cleanList() {
        this.$refs.equipTable.clearSelection()
      },
      //批量删除
      async removeAll() {
        if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
        const config = await this.$MessageBox.confirm(
          "此操作将永久删除选中项, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        ).catch(e => e);
        if (config !== "confirm") return;
        try {
          const list = this.batchNum.map(item => {
            return item.fid
          })
          const res = await deleteStationMoneyFactor(list)
          if (res.data.code !== 20000) return this.$msg.error('删除失败')
          this.tableData = this.tableData.filter((item, i) => {
            return item.id !== list[i]
          })
          this.$refs.equipTable.clearSelection()
          if (this.query.pageNum > 1 && this.tableData.length === 0) {
            this.query.pageNum = this.query.pageNum - 1
          }
          this.$msg.success('删除成功')
          this.getAllPage()
        } catch (e) {
          console.log(e);
        }
      }
    },
  }
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 20px;
    }
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
  }

</style>
